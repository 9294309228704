import React from "react";
import { useHistory } from "react-router";
import ButtonBasic from "../buttons/Basic";

export default function Installations({ dataCMS }) {
  const history = useHistory();

  return (
    <section className="pt-10 lg:pt-16">
      <h3
        data-aos="fade-up"
        className=" text-2xl lg:text-3xl text-te-blue-800 font-semibold text-center"
      >
        Nuestras Instalaciones
      </h3>

      <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5 mt-10 lg:mt-16 content-center">
        {dataCMS?.instalaciones?.map((installation) => (
          <div className="grid justify-center" key={installation.id}>
            <div
              data-aos="fade-up"
              className=" rounded-xl w-60 h-60 overflow-hidden relative"
            >
              <img
                src={installation?.imagen?.data?.attributes?.url}
                alt=""
                className="w-full h-full object-cover"
              />
              <div className="text-center text-te-blue-800 rounded-lg py-3 bg-white absolute z-10 bottom-3 inset-x-3 font-semibold">
                {installation?.titulo}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div data-aos="fade-up" className="flex justify-center mt-10 lg:mt-16">
        <ButtonBasic
          onClick={() => history.push("/servicio-al-cliente")}
          classNameFather="bg-te-orange-600"
          className="bg-te-orange-400 text-white"
        >
          Más Información
        </ButtonBasic>
      </div>
    </section>
  );
}
