import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import RegisterForm from "../../components/register/RegisterForm";
import Seo from "../../components/utils/Seo";
import SimpleLayout from "../../layouts/simple";

export default function Register() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/registro?populate[0]=Seo.ShareImage`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <>
      <Seo data={content.Seo} />

      <SimpleLayout>
        <div className="h-full flex items-center justify-center pb-20">
          <RegisterForm dataCMS={content} />
        </div>
      </SimpleLayout>
      <Footer />
    </>
  );
}
