import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { ReactComponent as Eye } from "../../assets/images/icons/eye.svg";
import personal from "../../assets/images/register/personal.png";
import axios from "axios";
import { DateTime } from "luxon";
import Spinner from "../utils/Spinner";
import InputMask from "react-input-mask";
import PaymentMethodStep from "./PaymentMethodStep";
// import { useAuth } from "../../contexts/auth";
import SelectCities from "./SelectCities";
import ReCAPTCHA from "react-google-recaptcha";
import SelectCitiesAddress from "./SelectCitiesAddress";

export default function PersonalForm({
  step,
  setStep,
  isCompleted,
  setIsCompleted,
  promotion,
  locker,
}) {
  const recaptchaRef = useRef(null);
  const [isShowingPass1, setIsShowingPass1] = useState(false);
  const [isShowingPass2, setIsShowingPass2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [userInfo, setUserInfo] = useState({});
  const [meta, setMeta] = useState({});
  // const { signup } = useAuth();

  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");

  function onChange() {
    if (recaptchaRef?.current?.getValue()) {
      setError("");
    }
  }

  const validationSchema = [
    //step 1
    Yup.object({
      name: Yup.string().required(),
      lastname: Yup.string().required(),
      email: Yup.string().required().email(),
      email2: Yup.string()
        .required("Confirmar el correo es requerido.")
        .oneOf([Yup.ref("email")], "Los correos no coinciden."),
      pass: Yup.string()
        .required("La contraseña es requerida.")
        .min(8, "La contraseña debe tener al menos 8 caracteres."),
      pass2: Yup.string()
        .required("Confirmar la contraseña es requerido.")
        .oneOf([Yup.ref("pass")], "Las contraseñas no coinciden."),
    }),
    //step 2
    Yup.object({
      direccion: Yup.string().required(),
      municipio: Yup.string().required(),
      departamento: Yup.string().required(),
      nit: Yup.string(),
      dui: Yup.string(),
      phone: Yup.string().required(),
      date: Yup.date().required(),
    }),
    Yup.object({
      terms: Yup.boolean().oneOf([true], "Must Accept Terms and Conditions"),
    }),
    Yup.object({}),
  ];

  const currentValidationSchema = validationSchema[step - 1];

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
    reset,
    setValue,
  } = useForm({
    mode: "all",
    resolver: yupResolver(currentValidationSchema),
  });

  // function useQuery() {
  //   const { search } = useLocation();

  //   return React.useMemo(() => new URLSearchParams(search), [search]);
  // }

  // const query = useQuery();

  const onSubmit = (data) => {
    const date = DateTime.fromISO(data.date).toFormat("yyyy/MM/dd");
    const depa = JSON.parse(data.departamento);
    const depadomi = data.deptodomi ? JSON.parse(data.deptodomi) : "";

    const body = {
      apellido: data.lastname,
      casillerorefiere: locker || null,
      clave: data.pass,
      codPromocion: promotion ? promotion : "",
      correo: data.email,
      departamento: depa.nombre,
      direccion: data.direccion,
      dui: data.dui,
      empresa: "",
      genero: "",
      giro: "",
      isTemporal: true,
      municipio: data.municipio,
      nacimiento: date,
      nit: data.nit,
      nombre: data.name,
      nrc: "",
      telefono: data.phone,

      direccion1: data.direccion1,
      domicilio1: data.domicilio1 || "",
      domicilio2: data.domicilio2 || "",
      ciudaddomi: data.ciudaddomi || "",
      deptodomi: depadomi.nombre || "",
    };

    setError("");
    setIsLoading(true);

    if (!recaptchaRef?.current?.getValue()) {
      setError("Compruebe que no es un robot.");
      setIsLoading(false);
      return;
    }

    // const toRedirect = query.get("d");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users`, body)
      .then((res) => {
        if (res.status === 200 && !res.data.error && res.data.data.insertId) {
          // setUserInfo(res.data.data);

          // const newObject = res.data.data.loginData;
          // newObject["idUser"] = res.data.data.idUser;
          // newObject["autologinUrl"] = res.data.data.autologinUrl;

          // signup(newObject, () => {});

          // if (watch("addCard") === "1") {
          //   addCard(res.data.data);
          // } else {
          //   if (toRedirect === "SM") {
          //     window.location.replace(res.data.data.autologinUrl || "");
          //   } else {
          sendEmails(data.email, res.data.data.insertId);
          //   }
          // }
        } else if (res.data.message === "ERROR_SIN_DATOS") {
          setError("No fue posible crear su casillero. Inténtelo más tarde.");
        } else {
          setError(res.data.error);
        }
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          if (error.response?.data?.message === "Error creating user") {
            setError("No fue posible crear su casillero. Inténtelo más tarde.");
          } else {
            setError("No fue posible crear su casillero. Inténtelo más tarde.");
          }
        } else {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const sendEmails = (email, id) => {
    const body = {
      to: email,
      from: "notificacion@transexpress.com.sv",
      subject: "Correo de Verificación",
      html: `
      <div>
        <h2>Enlace para verificar cuenta TransExpress:</h2>
        <p>Haz click en este enlace para verificar tu cuenta:</p>
        <p>
        <a href="https://www.transexpress.com.sv/validacion?activateId=${id}" rel="noopener" style="text-decoration: underline; color: #0068A5;" target="_blank">
          https://www.transexpress.com.sv/validacion?activateId=${id}
        </a>
        </p>
      </div>
      `,
      bcc: [],
      cc: [],
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/sendmail`, body)
      .then((res) => {
        setStep(4);
        setIsCompleted(true);
      });
  };

  useEffect(() => {
    reset({
      addCard: "1",
    });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (step === 1 && watch("email")) {
      const timer = setTimeout(() => {
        axios
          .get(`${process.env.REACT_APP_BASE_URL}/users/${watch("email")}/info`)
          .then((res) => {
            setEmailError(typeof res.data.email === "string");
          });
      }, 1000);

      return () => clearTimeout(timer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("email")]);

  // const getCardNumber = (type) => {
  //   if (type === "Visa" || type === "visa") {
  //     return 1;
  //   } else if (type === "Mastercard" || type === "mastercard") {
  //     return 2;
  //   } else {
  //     return 3;
  //   }
  // };

  // const addCard = (user) => {
  //   if (!meta.error) {
  //     const body = {
  //       idUser: user.id_cuenta_en_aplicacion,
  //       tipo: getCardNumber(meta.cardType?.displayName),
  //       numero: cardNumber.replace(/ /g, ""),
  //       mes: expiryDate.slice(0, 2),
  //       anio: expiryDate.slice(-2),
  //       cvv: cvc,
  //     };

  //     axios
  //       .post(
  //         `${process.env.REACT_APP_BASE_URL}/users/${user.numero_casillero}/cards`,
  //         body
  //       )
  //       .then((res) => {
  //         if (res.status === 200 && res.data.codigo_respuesta === 200) {
  //           setStep(4);
  //           setIsCompleted(true);
  //         } else {
  //           setError(
  //             "No fue posible agregar su tarjeta, puede agregarla despues desde su casillero."
  //           );
  //           setStep(4);
  //           setIsCompleted(true);
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.response?.data?.message) {
  //           setError("El servicio no está disponible. Inténtelo más tarde.");
  //         } else {
  //           setError("El servicio no está disponible. Inténtelo más tarde.");
  //         }
  //       });
  //   }
  // };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "copyAddress" && value.copyAddress) {
        setValue("domicilio1", value.direccion);
        setValue("domicilio2", value.direccion1);
        setValue("deptodomi", value.departamento);
        setValue("ciudaddomi", value.municipio);
      }

      if (name === "direccion" && value.direccion && value.copyAddress) {
        setValue("domicilio1", value.direccion);
      }
      if (name === "direccion1" && value.direccion1 && value.copyAddress) {
        setValue("domicilio2", value.direccion1);
      }
      if (name === "departamento" && value.departamento && value.copyAddress) {
        setValue("deptodomi", value.departamento);
      }
      if (name === "municipio" && value.municipio && value.copyAddress) {
        setValue("ciudaddomi", value.municipio);
      }

      if (name === "addAddress" && !value.addAddress) {
        setValue("copyAddress", false);
        setValue("domicilio1", "");
        setValue("domicilio2", "");
        setValue("deptodomi", "");
        setValue("ciudaddomi", "");
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {step >= 1 && (
          <div
            className={`border rounded-xl mt-10 p-5 shadow-md ${
              step === 1 ? "block" : "hidden"
            }`}
          >
            <h1 className=" font-bold text-te-blue-800 text-center text-2xl md:text-3xl">
              Obtén tu casillero gratis
            </h1>
            <p className=" md:text-lg text-te-gray-800 font-semibold text-center mt-2">
              Compra lo que quieras para tu negocio
            </p>
            <div className="mt-5">
              <div className="md:flex md:space-x-3">
                <div className="md:w-1/2">
                  <label
                    htmlFor="name"
                    className=" font-semibold text-te-gray-800 text-sm"
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    id="name"
                    {...register("name", { required: true })}
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                  />
                  {errors.name && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
                <div className="md:w-1/2 mt-3 md:mt-0">
                  <label
                    htmlFor="lastname"
                    className=" font-semibold text-te-gray-800 text-sm"
                  >
                    Apellido
                  </label>
                  <input
                    type="text"
                    id="lastname"
                    {...register("lastname", { required: true })}
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                  />
                  {errors.lastname && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
              </div>
              <div className="">
                <label
                  htmlFor="email"
                  className="block mt-3 font-semibold text-te-gray-800 text-sm"
                >
                  Correo electrónico
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", { required: true })}
                  className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                />
                {errors.email && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *Este campo es requerido.
                  </span>
                )}
                {emailError && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *El email ya está registrado.
                  </span>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="email2"
                  className="block mt-3 font-semibold text-te-gray-800 text-sm"
                >
                  Confirmar Correo electrónico
                </label>
                <input
                  type="email"
                  id="email2"
                  autoComplete="off"
                  {...register("email2", { required: true })}
                  className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                />
                {errors.email2 && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    {errors.email2?.message}
                  </span>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="pass"
                  className="block font-semibold text-te-gray-800 mt-3 text-sm"
                >
                  Crea una contraseña
                </label>
                <div className="flex items-center bg-te-gray-700 mt-2 py-2 rounded-lg">
                  <input
                    type={isShowingPass1 ? "text" : "password"}
                    id="pass"
                    {...register("pass", { required: true })}
                    className="block bg-te-gray-700  w-full focus:outline-none px-3 font-semibold text-te-blue-800"
                  />
                  <span className="mr-2">
                    <Eye
                      onClick={() => {
                        setIsShowingPass1(!isShowingPass1);
                      }}
                      className={`cursor-pointer h-3 fill-current ${
                        isShowingPass1 ? " text-black" : " text-te-gray-800"
                      }`}
                    />
                  </span>
                </div>
                {errors.pass && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    {errors.pass?.message}
                  </span>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="pass2"
                  className="block font-semibold text-te-gray-800 mt-3 text-sm"
                >
                  Confirmar contraseña
                </label>
                <div className="flex items-center bg-te-gray-700 mt-2 py-2 rounded-lg">
                  <input
                    type={isShowingPass2 ? "text" : "password"}
                    id="pass2"
                    {...register("pass2", { required: true })}
                    className="block bg-te-gray-700  w-full focus:outline-none px-3 font-semibold text-te-blue-800"
                  />
                  <span className="mr-2">
                    <Eye
                      onClick={() => {
                        setIsShowingPass2(!isShowingPass2);
                      }}
                      className={`cursor-pointer h-3 fill-current ${
                        isShowingPass2 ? " text-black" : " text-te-gray-800"
                      }`}
                    />
                  </span>
                </div>
                {errors.pass2 && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    {errors.pass2?.message}
                  </span>
                )}
              </div>
              <button
                onClick={() => setStep(2)}
                disabled={!isValid || emailError}
                type="button"
                className="w-full text-white border bg-te-blue-800 rounded-lg font-semibold mt-4 py-2 disabled:bg-te-gray-800 disabled:cursor-not-allowed"
              >
                Crear usuario
              </button>
              <div className=" text-te-blue-800 justify-center flex mt-5 text-sm">
                <p>
                  Ya tengo una cuenta{" "}
                  <Link
                    to="/login"
                    className=" text-te-orange-400 font-semibold"
                  >
                    Iniciar sesión
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
        {step >= 2 && (
          <div
            className={`border rounded-xl mt-10 p-5 shadow-md ${
              step === 2 ? "block" : "hidden"
            }`}
          >
            <h2 className=" font-bold text-te-blue-800 text-center text-2xl md:text-3xl">
              Dirección de entrega
            </h2>
            <p className=" md:text-lg text-te-gray-800 font-semibold text-center mt-2">
              Completa los campos obligatorios para el registro
            </p>
            <div className="mt-5">
              <div className="">
                <label
                  htmlFor="direccion"
                  className="block mt-3 font-semibold text-te-gray-800 text-sm"
                >
                  Dirección
                </label>
                <input
                  type="text"
                  id="direccion"
                  {...register("direccion", { required: true, maxLength: 50 })}
                  className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                  maxLength="50"
                />
                {errors.direccion && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *Este campo es requerido.
                  </span>
                )}
                <input
                  type="text"
                  id="direccion1"
                  {...register("direccion1", { maxLength: 50 })}
                  className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                  maxLength="50"
                />
              </div>

              <SelectCities register={register} watch={watch} errors={errors} />

              <div className="flex items-center my-2 text-te-gray-800">
                <input
                  type="checkbox"
                  id="addAddress"
                  {...register("addAddress", {})}
                  className="mr-2 h-5 w-5 text-te-blue-800"
                />
                <label htmlFor="addAddress">Agregar Servicio a Domicilio.</label>
              </div>
              <span className="addAddress text-te-gray-800">
                (Si desea servicio a domicilio marcar casilla, este tiene un
                costo adicional que será reflejado en su factura. Si deja
                tarjeta vinculada a su cuenta el cobro es automático.)
              </span>
              <div className="flex items-center mt-2 text-te-gray-800">
                <input
                  type="checkbox"
                  id="copyAddress"
                  disabled={!watch("addAddress")}
                  {...register("copyAddress", {})}
                  className="mr-2 h-5 w-5 text-te-blue-800"
                />
                <label htmlFor="copyAddress">
                  Usar la misma dirección de residencia?
                </label>
              </div>

              {watch("addAddress") && (
                <div className="">
                  <label
                    htmlFor="domicilio1"
                    className="block mt-3 font-semibold text-te-gray-800 text-sm"
                  >
                    Dirección
                  </label>
                  <input
                    type="text"
                    id="domicilio1"
                    {...register("domicilio1", {
                      required: true,
                      maxLength: 50,
                    })}
                    disabled={watch("copyAddress")}
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 disabled:opacity-60"
                    maxLength="50"
                  />
                  {errors.domicilio1 && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                  <input
                    type="text"
                    id="domicilio2"
                    {...register("domicilio2", { maxLength: 50 })}
                    disabled={watch("copyAddress")}
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 disabled:opacity-60"
                    maxLength="50"
                  />
                  <SelectCitiesAddress
                    register={register}
                    watch={watch}
                    errors={errors}
                    disabled={watch("copyAddress")}
                  />
                </div>
              )}

              <span className="block md:hidden mt-3 font-semibold text-te-gray-800 text-sm">
                Tipo de documento (Sin guiones)
              </span>

              <div className="space-x-3 md:mt-3 flex">
                <div className="w-1/4 md:w-1/2">
                  <span className="hidden md:block font-semibold text-te-gray-800 text-sm">
                    Tipo documento
                  </span>
                  <span
                    type="text"
                    className="block rounded-lg text-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold bg-te-blue-800"
                  >
                    NIT
                  </span>
                </div>
                <div className="w-3/4 md:w-1/2">
                  <label
                    htmlFor="nit"
                    className="hidden md:block font-semibold text-te-gray-800 text-sm"
                  >
                    N. Documento (Sin guiones)
                  </label>
                  <InputMask
                    id="nit"
                    type="text"
                    {...register("nit", { required: true })}
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    mask="99999999999999"
                    maskChar=""
                  />
                  {errors.nit && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
              </div>
              <div className="flex space-x-3 mt-1">
                <div className="w-1/4 md:w-1/2">
                  <span className="block rounded-lg text-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold bg-te-blue-800">
                    DUI
                  </span>
                </div>
                <div className="w-3/4 md:w-1/2">
                  <InputMask
                    type="text"
                    {...register("dui", { required: true })}
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    mask="999999999"
                    maskChar=""
                  />
                  {errors.dui && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
              </div>
              <p className=" mt-1 ">
                <a
                  href="https://portaldgii.mh.gob.sv/ssc/serviciosinclave/consulta/duinit/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-te-blue-800 text-sm hover:underline"
                >
                  ¿Ya homologaste tu DUI?
                </a>
              </p>

              <div className="">
                <label
                  htmlFor="phone"
                  className="block mt-3 font-semibold text-te-gray-800 text-sm"
                >
                  Teléfono de contacto
                </label>
                <InputMask
                  type="text"
                  id="phone"
                  {...register("phone", { required: true })}
                  className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                  mask="99999999"
                  maskChar=""
                />
                {errors.phone && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *Este campo es requerido.
                  </span>
                )}
              </div>
              <div className="">
                <label
                  htmlFor="date"
                  className="block mt-3 font-semibold text-te-gray-800 text-sm"
                >
                  Fecha de nacimiento
                </label>
                <input
                  id="date"
                  type="date"
                  {...register("date", {})}
                  className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                />
                {errors.date && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *Este campo es requerido.
                  </span>
                )}
              </div>
              <button
                disabled={!isValid}
                onClick={() => setStep(3)}
                type="button"
                className="w-full text-white border bg-te-blue-800 rounded-lg relative font-semibold mt-5 mb-4 py-2 disabled:bg-te-gray-800 disabled:cursor-not-allowed"
              >
                Siguiente
              </button>
            </div>
          </div>
        )}

        {step >= 3 && (
          <div
            className={`border rounded-xl mt-10 p-5 shadow-md ${
              step === 3 ? "block" : "hidden"
            }`}
          >
            <h1 className=" font-bold text-te-blue-800 text-center text-2xl md:text-3xl">
              Agregar método de pago
            </h1>
            <p className=" md:text-lg text-te-gray-800 font-semibold text-center mt-2">
              Puedes agregar tu tarjeta ahora o luego de tu registro
            </p>

            <div className="pt-8 flex flex-col lg:flex-row justify-between items-center">
              <p className=" text-te-blue-800 font-semibold">
                ¿Quieres agregar un método de pago?
              </p>
              <div className="flex flex-1 items-center justify-around space-x-5 lg:space-x-0">
                <div className=" text-te-gray-800 flex items-center">
                  <input
                    type="radio"
                    {...register("addCard", {})}
                    value="1"
                    name="addCard"
                    className="mr-1"
                  />
                  Si
                </div>
                <div className=" text-te-gray-800 flex items-center">
                  <input
                    type="radio"
                    {...register("addCard", {})}
                    value="0"
                    name="addCard"
                    className="mr-1"
                  />{" "}
                  No
                </div>
              </div>
            </div>

            {watch("addCard") === "1" && (
              <PaymentMethodStep
                setMeta={setMeta}
                setCardNumber={setCardNumber}
                setExpiryDate={setExpiryDate}
                setCvc={setCvc}
                cardNumber={cardNumber}
                expiryDate={expiryDate}
                cvc={cvc}
              />
            )}

            <div className="flex items-center mt-5 text-te-gray-800 font-semibold">
              <input
                type="checkbox"
                id="terms"
                {...register("terms", {})}
                className="mr-2 h-5 w-5"
              />
              <label htmlFor="terms">
                Acepto los{" "}
                <a
                  href="/terminos-y-condiciones"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline"
                >
                  términos y condiciones
                </a>
                .
              </label>
            </div>

            <div className="flex justify-center mt-5">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={onChange}
              />
            </div>

            <button
              disabled={
                watch("addCard") === "0"
                  ? isLoading || !isValid
                  : isLoading || meta.error || !isValid
              }
              type="submit"
              className="w-full text-white border bg-te-blue-800 rounded-lg font-semibold mt-5 mb-4 py-2 disabled:bg-te-gray-800 disabled:cursor-not-allowed relative"
            >
              Crear casillero
              {isLoading && (
                <div className="absolute right-3 top-0 h-full flex items-center">
                  <Spinner color="#ffffff" type="spinningBubbles" />
                </div>
              )}
            </button>
          </div>
        )}
      </form>

      {step === 4 && isCompleted && (
        <div className="border rounded-xl mt-32 p-5 shadow-md">
          <div className="flex justify-center relative">
            <img
              src={personal}
              alt=""
              className="h-28 absolute -mt-5 transform -translate-y-1/2"
            />
          </div>
          <h1 className=" font-bold text-te-blue-800 text-center text-3xl mt-16">
            ¡Se ha enviado un correo para verificar tu cuenta!
          </h1>
          <p className="text-lg text-te-gray-800 font-semibold text-center mt-5 md:px-10">
            Ingresa al enlace desde tu correo electrónico para verificar tu
            cuenta.
          </p>
        </div>
      )}

      {error && (
        <p className="text-center text-te-red-900 font-semibold mt-5">
          *{error}
        </p>
      )}
    </>
  );
}
