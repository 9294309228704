import { ArrowLeftIcon } from "@heroicons/react/solid";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Spinner from "../utils/Spinner";

export default function Content({ data, isLoading }) {
  return (
    <>
      {!isLoading ? (
        <div>
          {data?.attributes ? (
            <>
              <section className="lg:container lg:mt-10">
                <div className="h-64 lg:h-96 lg:rounded-2xl overflow-hidden relative">
                  <div className="absolute h-full w-full">
                    <img
                      src={
                        data?.attributes?.Banner?.data?.attributes?.formats
                          ?.xlarge?.url ||
                        data?.attributes?.Banner?.data?.attributes?.formats
                          ?.large?.url
                      }
                      alt=""
                      className="h-full object-cover w-full"
                    />
                  </div>
                  <div className=" bg-te-blue-800 bg-opacity-40 absolute w-full h-full"></div>
                  <div
                    data-aos="fade-up"
                    className="absolute w-full h-full flex items-center justify-center"
                  >
                    <h1 className="text-white relative text-2xl lg:text-3xl font-bold">
                      {data?.attributes?.Titulo}
                    </h1>
                  </div>
                </div>
              </section>
              <section className="text-te-gray-800 container xl:px-32 mb-20">
                <div
                  data-aos="fade-up"
                  className="mt-16 prose lg:prose-lg max-w-none"
                >
                  <ReactMarkdown>{data?.attributes?.Contenido}</ReactMarkdown>
                </div>
              </section>
            </>
          ) : (
            <div className="text-3xl font-semibold text-center text-te-blue-800 py-20 min-h-screen">
              No encontramos lo que buscas
              <div className="my-10 flex justify-center">
                <Link
                  to="/"
                  className="text-xl underline text-te-blue-800 rounded-lg font-semibold text-center flex items-center"
                >
                  <ArrowLeftIcon className="text-te-blue-800 w-5 mr-4" /> Ir a
                  Inicio
                </Link>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex justify-center h-screen mt-20">
          <Spinner color="#141c82" type="spinningBubbles" />
        </div>
      )}
    </>
  );
}
