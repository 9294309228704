import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { Fragment, useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/images/icons/close-modal.svg";
import { formatCurrency, formatPercent } from "../../services/numbers";
import couponIcon from "../../assets/images/icons/couponIcon.svg";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/auth";
import axios from "axios";
import Spinner from "../utils/Spinner";
import { sendEmailCoupon } from "../../emails/coupon";
import { Link } from "react-router-dom";

export default function CalculatorModal({
  isOpen,
  closeModal,
  results,
  category,
}) {
  const { user } = useAuth();
  const closeButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState("");
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const totalServices =
    parseFloat(results.Seguro) +
    parseFloat(results.Tramite) +
    parseFloat(results.Transporte) +
    parseFloat(results.Seguridad);

  const cupon = parseFloat(results?.VCupon || 0);
  const totalServicesTaxAndTE = parseFloat(results?.Total - results?.Precio);
  const totalBruto = totalServicesTaxAndTE - cupon;

  useEffect(() => {
    if (user) {
      reset({
        email: user.Correo,
      });
    }
    // eslint-disable-next-line
  }, []);

  const categorySelected = JSON.parse(category || "{}");

  const onSubmit = async (data) => {
    const body = {
      casillero: user?.PoBox || null,
      correo: data.email,
      articulo: categorySelected.Descripcion,
    };

    setIsLoading(true);
    setIsCompleted("");
    setError("");

    try {
      const resWithLocker = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/users/${data.email}/info`
      );

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/coupon`, body)
        .then((res) => {
          if (res.data.data && !res.data.error) {
            sendEmails(
              res.data.data,
              data,
              categorySelected,
              results,
              resWithLocker.data.email
            );
            setIsCompleted("Se ha enviado tu cupón!");
          }
        })
        .catch((error) => {
          if (error.response?.data?.message === "Ya tienes un cupon activo") {
            setError("Ya tienes un cupón activo");
            return;
          }
          if (error.response?.data?.message === "Email no existe") {
            setError("Correo no asignado.");
            return;
          }
          setError("El servicio no está disponible. Inténtelo más tarde.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (e) {
      setError("El servicio no está disponible. Inténtelo más tarde.");
      return;
    }
  };

  const sendEmails = (data, dataForm, categorySelected, results, locker) => {
    const body = {
      to: dataForm.email,
      from: "notificacion@transexpress.com.sv",
      subject: "Felicidades, tienes un certificado para tu próxima compra.",
      html: sendEmailCoupon(data, dataForm, categorySelected, results, locker),
      bcc: ["tecupon2021@gmail.com"],
      cc: [],
    };

    axios.post(`${process.env.REACT_APP_BASE_URL}/sendmail`, body);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left align-middle transition-all transform">
              <div className="flex justify-end mb-3">
                <span
                  ref={closeButtonRef}
                  onClick={closeModal}
                  className=" cursor-pointer"
                >
                  <img src={closeIcon} alt="" className="h-8" />
                </span>
              </div>
              <div className="bg-te-gray-300 shadow-xl rounded-2xl p-4 lg:p-6">
                <div className="flex flex-col lg:flex-row justify-center items-center pb-2 mt-2">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-bold text-te-blue-900"
                  >
                    Resultados de tu cálculo
                  </Dialog.Title>
                  {/* <span className="font-semibold text-sm">
                    Accesorios para Moto ({formatPercent(data.Arancel)})
                  </span> */}
                </div>
                <div className="mt-5">
                  <div className="p-3 flex flex-col lg:flex-row justify-between border-2 rounded-xl w-full bg-te-green-900">
                    <span className="text-white font-semibold">Valor de producto</span>
                    <span className=" text-white font-semibold">
                      {formatCurrency(results?.Precio)}
                    </span>
                  </div>
                  <div className="p-3 border-b flex-col lg:flex-row flex justify-between">
                    <span className=" text-te-blue-900 font-semibold">
                      Arancel
                    </span>
                    <span className=" text-te-gray-800 font-semibold">
                      {formatPercent(results?.Arancel)}
                    </span>
                  </div>
                  <div className="p-3 flex flex-col lg:flex-row justify-between border-2 rounded-xl w-full bg-te-blue-900">
                    <span className="text-white font-semibold">
                      Impuesto (IVA de internación)
                    </span>
                    <span className="text-white font-semibold">
                      {formatCurrency(results?.Impuesto)}
                    </span>
                  </div>
                </div>
                <div className="mt-3 bg-white px-3">
                  <div className="py-3 border-b-2 flex flex-col lg:flex-row justify-between">
                    <span className=" text-te-blue-900 font-semibold">
                      Trámite
                    </span>
                    <span className=" text-te-gray-800 font-semibold">
                      {formatCurrency(results?.Tramite)}
                    </span>
                  </div>
                  <div className="py-3 border-b-2 flex flex-col lg:flex-row justify-between">
                    <span className=" text-te-blue-900 font-semibold">
                      Transporte (Flete + Combustible)
                    </span>
                    <span className=" text-te-gray-800 font-semibold">
                      {formatCurrency(results?.Transporte)}
                    </span>
                  </div>
                  <div className="py-3 border-b-2 flex flex-col lg:flex-row justify-between">
                    <span className=" text-te-blue-900 font-semibold">
                      Seguro
                    </span>
                    <span className=" text-te-gray-800 font-semibold">
                      {formatCurrency(results?.Seguro)}
                    </span>
                  </div>
                  <div className="py-3 flex flex-col lg:flex-row justify-between">
                    <span className=" text-te-blue-900 font-semibold">
                      Seguridad
                    </span>
                    <span className=" text-te-gray-800 font-semibold">
                      {formatCurrency(results?.Seguridad)}
                    </span>
                  </div>
                </div>
                <div className="mt-5">
                  {/* <div className="py-3 border-b flex flex-col lg:flex-row justify-between">
                    <span className=" text-te-blue-900 font-semibold">
                      Premio por Pre Alerta (si aplica)
                    </span>
                    <span className=" text-te-gray-800 font-semibold">
                      - {formatCurrency(results?.PremioAlerta)}
                    </span>
                  </div> */}
                  <div className="p-3 flex flex-col lg:flex-row justify-between border-2 rounded-xl w-full bg-te-blue-900">
                    <span className="text-white font-semibold">
                      Total de servicios Trans Express
                    </span>
                    <span className="text-white font-semibold">
                      {formatCurrency(totalServices)}
                    </span>
                  </div>
                  <div className="p-3 flex flex-col lg:flex-row justify-between border-2 rounded-xl w-full bg-te-blue-900 mt-3">
                    <span className="text-white font-semibold">
                      Total impuestos más servicios TE
                    </span>
                    <span className="text-white font-semibold">
                      {formatCurrency(totalServicesTaxAndTE)}
                    </span>
                  </div>
                  <div className="p-3 flex flex-col lg:flex-row justify-between border-2 rounded-xl w-full bg-te-blue-900 mt-3">
                    <span className="text-white font-semibold">
                      Cupón de descuento*
                    </span>
                    <span className="text-white font-semibold">
                      - {formatCurrency(results?.VCupon)}
                    </span>
                  </div>

                  <div className="p-3 flex flex-col lg:flex-row justify-between border-2 rounded-xl w-full bg-te-blue-900 mt-3">
                    <span className="text-white font-semibold">
                      Total impuestos más servicios TE (cupón incluido)
                    </span>
                    <span className="text-white font-semibold">
                      {formatCurrency(totalBruto)}
                    </span>
                  </div>
                </div>

                <Disclosure>
                  {({ open }) => (
                    <>
                      <div className="mt-4 flex flex-col lg:flex-row lg:space-x-5">
                        <Disclosure.Button
                          type="button"
                          className="flex items-center order-2 lg:order-1 justify-center relative lg:w-1/2 w-full px-8 py-3 mt-2 lg:mt-0 text-sm font-semibold text-white bg-te-orange-400 rounded-lg focus:outline-none"
                        >
                          <img
                            src={couponIcon}
                            alt="icono de cupón"
                            className="h-6 left-3 absolute"
                          />
                          Obtener cupón
                          <ChevronDownIcon
                            className={`absolute right-3 h-6 ${
                              open ? " transform rotate-180" : ""
                            }`}
                          />
                        </Disclosure.Button>
                        <button
                          type="button"
                          className="flex order-1 lg:order-2 justify-center lg:w-1/2 w-full px-8 py-3 text-sm font-semibold text-te-blue-800 bg-transparent border-2 border-te-blue-800 rounded-lg focus:outline-none"
                          onClick={closeModal}
                        >
                          Calcular nuevamente
                        </button>
                      </div>
                      <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel className="p-4 bg-white border-2 mt-4 rounded-xl">
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="">
                              <label
                                htmlFor="email"
                                className="text-sm text-te-gray-800 font-semibold block"
                              >
                                Correo electrónico
                              </label>
                              <input
                                id="email"
                                type="email"
                                {...register("email", { required: true })}
                                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-1 font-semibold text-sm text-te-blue-800"
                              />
                              {errors.email && (
                                <span className=" text-te-red-900 text-xs mt-2">
                                  *Este campo es requerido.
                                </span>
                              )}
                            </div>

                            <div className="flex justify-end">
                              <button
                                type="submit"
                                disabled={isLoading}
                                className="flex justify-center w-full lg:w-1/2 mt-4 px-6 py-3 text-sm font-semibold text-te-orange-400 bg-transparent border-2 border-te-orange-400 rounded-lg focus:outline-none relative"
                              >
                                Obtener
                                {isLoading && (
                                  <div className="absolute right-2 top-0 h-full flex items-center">
                                    <Spinner
                                      color="#f3944b"
                                      type="spinningBubbles"
                                    />
                                  </div>
                                )}
                              </button>
                            </div>
                          </form>

                          {error && (
                            <div className="mt-5 text-te-red-900 font-semibold">
                              *{error}{" "}
                              {error === "Correo no asignado." && (
                                <Link to="/register" className="underline">
                                  Regístrate aqui.
                                </Link>
                              )}
                            </div>
                          )}
                          {isCompleted && (
                            <div className="mt-5 text-te-green-700 font-semibold">
                              *{isCompleted}
                            </div>
                          )}
                        </Disclosure.Panel>
                      </Transition>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
