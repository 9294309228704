import React from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import PendingToCalculate from "../../components/pendingToCalculate"

export default function SiteLayout({ children }) {
  return (
    <>
      <Navbar />

      {children}

      <PendingToCalculate />
      <Footer />
    </>
  );
}
