import { Dialog, Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronRightIcon,
  SelectorIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../contexts/auth";

import walletIcon from "../../../assets/images/icons/wallet.svg";
import closeIcon from "../../../assets/images/icons/close-modal.svg";
import visa from "../../../assets/images/locker/visa.svg";
import mc from "../../../assets/images/locker/mc.svg";
import card from "../../../assets/images/locker/card.svg";

import axios from "axios";
import { formatMoney } from "accounting";
import Spinner from "../../utils/Spinner";

export default function ButtonPay({ data }) {
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="">
      <button
        onClick={() => setOpenModal(true)}
        className="py-3 w-40 bg-te-orange-400 flex items-center justify-between rounded-lg px-3 text-white font-semibold"
      >
        <img src={walletIcon} alt="" className="h-4" />
        Pagar
        <ChevronRightIcon className="h-4 text-white" />
      </button>

      {openModal && (
        <PayModal openModal={openModal} closeModal={closeModal} data={data} />
      )}
    </div>
  );
}

const PayModal = ({ openModal, closeModal, data }) => {
  let closeButtonRef = useRef(null);
  const [selected, setSelected] = useState({});
  const [cards, setCards] = useState([]);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);
  const [error, setError] = useState("");
  const [completed, setCompleted] = useState("");

  useEffect(() => {
    getCards();
    // eslint-disable-next-line
  }, []);

  const getCards = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/${user.idUser}/cards`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.codigo_respuesta !== 400 &&
          res.data.lista_tarjetas
        ) {
          setCards(res.data.lista_tarjetas);
          setSelected(res.data.lista_tarjetas[0]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCardImage = (type) => {
    if (type === 1) {
      return <img src={visa} alt="" className="h-4" />;
    } else if (type === 2) {
      return <img src={mc} alt="" className="h-8" />;
    } else {
      return <img src={card} alt="" className="h-8" />;
    }
  };

  const handlerPayment = (cardData, packageData) => {
    setError("");

    const body = {
      idUser: user.idUser,
      idTarjeta: cardData.id_tarjeta, //solo el id
      guiaPaquete: packageData.Guia,
      tipoPago: cardData.id_tipo_tarjeta, //el mismo tipo de pago al momento de guardar cards
      montoTotal:
        Number(packageData.ValorImpuestos) + Number(packageData.ValorTE),
      montoDescuento: 0, //dejale 0
      cargoEnvio: 0, //dejale 0
      montoTE: Number(packageData.ValorTE),
      montoImpuesto: Number(packageData.ValorImpuestos),
      telefono: user.Telefono || "", //Traetelo si lo tienes
      direccion: "", //dejar en blanco
      colonia: "", //dejar en blanco
      puntoReferencia: "", //dejar en blanco
    };

    setIsLoadingPayment(true);

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/packages/${user.PoBox}/pay`,
        body,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data && !res.data.error) {
          setCompleted("Pago Realizado");
        }
      })
      .catch((error) => {
        setError("Algo salió mal, Inténtalo de nuevo.");
      })
      .finally(() => {
        setIsLoadingPayment(false);
      });
  };

  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 text-left align-middle transition-all transform">
              <div className="flex justify-end mb-3">
                <span
                  onClick={closeModal}
                  ref={closeButtonRef}
                  className=" cursor-pointer"
                >
                  <img src={closeIcon} alt="" className="h-8" />
                </span>
              </div>

              <div className="bg-te-gray-300 shadow-xl rounded-2xl p-4 lg:p-6">
                <span className=" text-te-gray-800 font-semibold">
                  Seleccionar tarjeta
                </span>
                <div className="mt-1 relative z-10">
                  <Listbox value={selected} onChange={setSelected}>
                    <div className="relative">
                      <Listbox.Button className="relative w-full h-12 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 flex items-center">
                        <span>{getCardImage(selected.id_tipo_tarjeta)}</span>
                        <span className="block truncate ml-5 font-semibold text-te-blue-800 text-lg">
                          {selected.numero_tarjeta}
                        </span>
                        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon
                            className="w-5 h-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {cards.map((card, cardIdx) => (
                            <Listbox.Option
                              key={cardIdx}
                              className={({ active }) =>
                                `${
                                  active
                                    ? "text-te-blue-800 bg-te-blue-100"
                                    : "text-te-gray-800"
                                }
                                cursor-default select-none relative h-12 pl-10 pr-4 flex items-center`
                              }
                              value={card}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span className="w-10">
                                    {getCardImage(card.id_tipo_tarjeta)}
                                  </span>
                                  <span
                                    className={`${
                                      selected ? "font-semibold" : "font-normal"
                                    } block truncate ml-5`}
                                  >
                                    {card.numero_tarjeta}
                                  </span>
                                  {selected ? (
                                    <span
                                      className={`${
                                        active
                                          ? " text-te-orange-600"
                                          : " text-te-orange-600"
                                      }
                                  absolute inset-y-0 left-0 flex items-center pl-3`}
                                    >
                                      <CheckIcon
                                        className="w-5 h-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>

                <div className="mt-5 flex justify-between items-center">
                  <div className="text-te-gray-800 font-semibold text-lg">
                    Total a pagar:{" "}
                    <span className=" text-te-blue-800">
                      {formatMoney(
                        Number(data.ValorImpuestos) + Number(data.ValorTE)
                      )}
                    </span>
                  </div>

                  <div className="">
                    <button
                      disabled={isLoadingPayment || isLoading || completed}
                      onClick={() => handlerPayment(selected, data)}
                      className={`py-3 px-12 ${
                        !completed ? " bg-te-orange-400" : "bg-te-green-700"
                      } text-white font-semibold rounded-lg relative flex items-center`}
                    >
                      {!completed ? "Pagar" : "Pagado"}
                      {completed && (
                        <CheckIcon className="h-6 text-white absolute right-3" />
                      )}
                      {isLoadingPayment && (
                        <div className="absolute right-3 top-0 h-full flex items-center">
                          <Spinner color="#ffffff" type="spinningBubbles" />
                        </div>
                      )}
                    </button>
                  </div>
                </div>

                {error && (
                  <div className="text-te-red-900 font-semibold mt-4">
                    *{error}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
