import React, { useEffect, useState } from "react";
import box from "../../../assets/images/icons/locker-box.svg";
import Spinner from "../../utils/Spinner";
import { formatMoney } from "accounting";
import { DateTime } from "luxon";
import axios from "axios";
import ShoppingMallPackagesDetailModal from "./ShoppingMallPackageDetailModal";

export default function ShoppingMallPackagesMobile({
  isLoading,
  packages,
  error,
}) {
  const [isShowingAll, setIsShowingAll] = useState(false);

  return (
    <div className="bg-te-gray-700 -mx-5 pb-8">
      <div className="container">
        <div className="flex items-center justify-between py-5">
          <h2 className=" text-te-blue-800 text-xl font-bold">
            Shopping Mall ({packages.length || "0"})
          </h2>
          {packages.length > 3 && (
            <>
              {!isShowingAll ? (
                <div
                  onClick={() => {
                    setIsShowingAll(true);
                  }}
                  className=" underline text-te-gray-800 font-semibold text-lg"
                >
                  Ver todos
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsShowingAll(false);
                  }}
                  className=" underline text-te-gray-800 font-semibold text-lg"
                >
                  Ocultar
                </div>
              )}
            </>
          )}
        </div>
        {isLoading && !packages.length && (
          <div className="py-10 flex justify-center">
            <Spinner color="#141c82" type="spinningBubbles" />{" "}
          </div>
        )}
        {!isLoading && error && (
          <span className="block text-center py-10">{error}</span>
        )}

        {!isShowingAll ? (
          <div className="space-y-5 pb-10">
            {!!packages.length &&
              packages
                .slice(0, 3)
                .map((value, index) => (
                  <PackageCard value={value} key={index} />
                ))}
          </div>
        ) : (
          <div className="space-y-5 pb-10">
            {!!packages.length &&
              packages.map((value, index) => (
                <PackageCard value={value} key={index} />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

const PackageCard = ({ value }) => {
  const [, setIsLoading] = useState(false);
  const [packageInfo, setPackageInfo] = useState({});
  const [, setError] = useState("");

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/services/pedido_detalle/${value.id_compra}`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_SHOPPING_MALL_TOKEN}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setPackageInfo(res.data.data?.items);
        } else {
          setError("No existen datos de paquetes.");
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {};
  }, [value]);

  return (
    <>
      <div className=" bg-white p-4 rounded-lg shadow-lg">
        <div className="flex-1 flex">
          <div className="">
            <img src={box} alt="" className="h-7" />
          </div>
          <div className="ml-3">
            {/* <span className="block font-semibold text-te-blue-800">
                  SAL1591007265
                </span> */}
            <span className="mt-1 inline-block bg-te-blue-800 text-white rounded-r-full rounded-bl-full px-3 py-1 text-sm font-semibold">
              Comprado
            </span>
          </div>
        </div>
        {/* <div className="mt-2">
              <div className="text-te-gray-800 truncate">
                Guia: 420331959400111108
              </div>
            </div> */}
        <div className="">
          <div className="text-te-gray-800">
            Fecha de creación:{" "}
            {DateTime.fromISO(value.fecha_creacion.replace(/ /g, "T")).toFormat(
              "dd-MM-yyyy"
            )}
          </div>
        </div>
        <div className="bg-te-gray-700 rounded-lg p-2 flex items-center justify-between mt-2 font-semibold">
          <span className="text-te-blue-800">
            {formatMoney(value.total_compra)}
          </span>
          <span className="text-te-gray-800">USD</span>
        </div>
        <div className="mt-3">
          <span
            onClick={() => setOpenModal(true)}
            className=" py-3 block w-full border-te-blue-800 border-2 rounded-lg text-te-blue-800 text-center font-semibold"
          >
            Ver detalles
          </span>
        </div>
      </div>

      <ShoppingMallPackagesDetailModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={packageInfo}
      />
    </>
  );
};
