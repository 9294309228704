import { DateTime } from "luxon";
import { formatCurrency } from "../services/numbers";

export const sendEmailCoupon = (
  data,
  dataForm,
  categorySelected,
  results,
  locker
) => {
  return `
  <div style="background-color:#003399">
  <table
    cellpadding="0"
    cellspacing="0"
    width="100%"
    style="background-color:#fff;border-collapse:collapse;border-spacing:0px"
  >
    <tbody>
      <tr>
        <td
          align="center"
          valign="top"
          style="padding:0;Margin:0;width:570px"
        >
          <table
            cellpadding="0"
            cellspacing="0"
            width="100%"
            role="presentation"
            style="border-collapse:collapse;border-spacing:0px"
          >
            <tbody>
              <tr>
                <td
                  align="center"
                  style="padding:15px;Margin:0;font-size:0px"
                >
                  <img
                    src="https://te-prod-space.nyc3.cdn.digitaloceanspaces.com/te-cms/babfee05a253acadfb65aeaafbb1fb3d.png?updated_at=2022-02-15T04:52:11.625Z"
                    alt=""
                    style="display:block;border:0;outline:none;text-decoration:none"
                    width="139"
                    height="33"
                    class="CToWUd"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  <table
    width="100%"
    cellspacing="0"
    cellpadding="0"
    style="border-collapse:collapse;border-spacing:0px;padding:0;Margin:0;width:100%;height:100%;background-repeat:repeat;background-position:center top;background-color:#003399"
  >
    <tbody>
      <tr>
        <td valign="top" style="padding:0;Margin:0">
          <table
            cellpadding="0"
            cellspacing="0"
            align="center"
            style="border-collapse:collapse;border-spacing:0px;table-layout:fixed!important;width:100%"
          >
            <tbody>
              <tr>
                <td align="center" style="padding:0;Margin:0">
                  <table
                    bgcolor="#003399"
                    align="center"
                    cellpadding="0"
                    cellspacing="0"
                    style="border-collapse:collapse;border-spacing:0px;background-color:#003399;width:600px"
                  >
                    <tbody>
                      <tr>
                        <td
                          align="left"
                          bgcolor="#003399"
                          style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:20px;background-color:#003399"
                        >
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="border-collapse:collapse;border-spacing:0px"
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding:0;Margin:0;width:560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="border-collapse:collapse;border-spacing:0px"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          align="center"
                                          style="padding:0;Margin:0"
                                        >
                                          <h1 style="Margin:0;line-height:43px;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;font-size:36px;font-style:normal;font-weight:normal;color:#ffffff">
                                            <strong>
                                              ${data?.nombreCliente || " "}
                                            </strong>
                                          </h1>
                                          <br />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          align="left"
                                          style="padding:0;Margin:0"
                                        >
                                          <h2 style="Margin:0;line-height:29px;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;font-size:24px;font-style:normal;font-weight:normal;color:#afeeee;text-align:center">
                                            <strong>
                                              ¡Gracias por tu preferencia!
                                            </strong>
                                          </h2>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          align="center"
                                          style="padding:0;Margin:0"
                                        >
                                          <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:32px;color:#ffffff;font-size:21px">
                                            Nos complace enviarte tu cupón,
                                            para que lo utilices en la
                                            cotización que acabas de
                                            realizar.
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          bgcolor="#003399"
                          style="padding:30px;Margin:0;background-color:#003399;border-radius:20px"
                        >
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="border-collapse:collapse;border-spacing:0px"
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding:0;Margin:0;width:388px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="border-collapse:collapse;border-spacing:0px"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          align="center"
                                          bgcolor="#0340A4"
                                          style="padding:15px;Margin:0;border-radius:50px;display:block;width:15rem;margin:auto"
                                        >
                                          <h1 style="Margin:0;line-height:36px;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;font-size:30px;font-style:normal;font-weight:normal;color:#ffffff;text-align:center">
                                            <b>${data?.casillero || " - "}</b>
                                          </h1>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          align="center"
                                          style="Margin:0;font-size:0;padding:20px"
                                        >
                                          <table
                                            border="0"
                                            width="100%"
                                            height="100%"
                                            cellpadding="0"
                                            cellspacing="0"
                                            role="presentation"
                                            style="border-collapse:collapse;border-spacing:0px"
                                          >
                                            <tbody>
                                              <tr>
                                                <td style="background:none;height:1px;width:100%;margin:0px">
                                                  <center>
                                                    <img
                                                      src="https://te-prod-space.nyc3.cdn.digitaloceanspaces.com/te-cms/141daa9f80624a2274c4194e29cd7b6e.jpg"
                                                      style="width:285px;height:75px;display:block;border:0;outline:none;text-decoration:none"
                                                      class="CToWUd"
                                                    />
                                                    <br />
                                                  </center>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
  
                          <div style="display:flex;width:100%;">
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              align="left"
                              style="border-collapse:collapse;border-spacing:0px;display:inline-block;"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:38px"
                                  >
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      width="100%"
                                      role="presentation"
                                      style="border-collapse:collapse;border-spacing:0px"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              Casillero:
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              <strong>
                                                ${locker || " - "}
                                              </strong>
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding:20px;Margin:0;font-size:0"
                                          >
                                            <table
                                              border="0"
                                              width="100%"
                                              height="100%"
                                              cellpadding="0"
                                              cellspacing="0"
                                              role="presentation"
                                              style="border-collapse:collapse;border-spacing:0px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding:0;Margin:0;border-bottom:1px solid #003399;background:none;height:1px;width:100%;margin:0px"></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:270px"
                                  >
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      width="100%"
                                      role="presentation"
                                      style="border-collapse:collapse;border-spacing:0px"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              Monto de cupón:
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              <strong>
                                                ${formatCurrency(
                                                  data?.discount || 0
                                                )}
                                              </strong>
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding:20px;Margin:0;font-size:0"
                                          >
                                            <table
                                              border="0"
                                              width="100%"
                                              height="100%"
                                              cellpadding="0"
                                              cellspacing="0"
                                              role="presentation"
                                              style="border-collapse:collapse;border-spacing:0px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding:0;Margin:0;border-bottom:1px solid #003399;background:none;height:1px;width:100%;margin:0px"></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:270px"
                                  >
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      width="100%"
                                      role="presentation"
                                      style="border-collapse:collapse;border-spacing:0px"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              Fecha de creación:
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              <strong>${
                                                DateTime.now().toFormat(
                                                  "dd/MM/yyyy"
                                                ) || " "
                                              }</strong>
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding:20px;Margin:0;font-size:0"
                                          >
                                            <table
                                              border="0"
                                              width="100%"
                                              height="100%"
                                              cellpadding="0"
                                              cellspacing="0"
                                              role="presentation"
                                              style="border-collapse:collapse;border-spacing:0px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding:0;Margin:0;border-bottom:1px solid #003399;background:none;height:1px;width:100%;margin:0px"></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <table
                              cellpadding="0"
                              cellspacing="0"
                              style="border-collapse:collapse;border-spacing:0px;display:inline-block;"
                            >
                              <tbody>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:38px"
                                  >
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      width="100%"
                                      role="presentation"
                                      style="border-collapse:collapse;border-spacing:0px"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              Valor del producto:
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              <strong>${formatCurrency(
                                                results?.Precio || 0
                                              )}</strong>
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding:20px;Margin:0;font-size:0"
                                          >
                                            <table
                                              border="0"
                                              width="100%"
                                              height="100%"
                                              cellpadding="0"
                                              cellspacing="0"
                                              role="presentation"
                                              style="border-collapse:collapse;border-spacing:0px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding:0;Margin:0;border-bottom:1px solid #003399;background:none;height:1px;width:100%;margin:0px"></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:270px"
                                  >
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      width="100%"
                                      role="presentation"
                                      style="border-collapse:collapse;border-spacing:0px"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              Categoría:
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              <strong>
                                                ${
                                                  categorySelected?.Descripcion ||
                                                  " "
                                                }
                                              </strong>
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding:20px;Margin:0;font-size:0"
                                          >
                                            <table
                                              border="0"
                                              width="100%"
                                              height="100%"
                                              cellpadding="0"
                                              cellspacing="0"
                                              role="presentation"
                                              style="border-collapse:collapse;border-spacing:0px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding:0;Margin:0;border-bottom:1px solid #003399;background:none;height:1px;width:100%;margin:0px"></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:270px"
                                  >
                                    <table
                                      cellpadding="0"
                                      cellspacing="0"
                                      width="100%"
                                      role="presentation"
                                      style="border-collapse:collapse;border-spacing:0px"
                                    >
                                      <tbody>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              Fecha de vencimiento:
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="left"
                                            style="padding:0;Margin:0"
                                          >
                                            <p style="Margin:0;font-family:'source sans pro','helvetica neue',helvetica,arial,sans-serif;line-height:24px;color:#f0ffff;font-size:16px">
                                              <strong>${
                                                data?.valid.substr(0, 10) || " "
                                              }</strong>
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            align="center"
                                            style="padding:20px;Margin:0;font-size:0"
                                          >
                                            <table
                                              border="0"
                                              width="100%"
                                              height="100%"
                                              cellpadding="0"
                                              cellspacing="0"
                                              role="presentation"
                                              style="border-collapse:collapse;border-spacing:0px"
                                            >
                                              <tbody>
                                                <tr>
                                                  <td style="padding:0;Margin:0;border-bottom:1px solid #003399;background:none;height:1px;width:100%;margin:0px"></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    align="left"
                                    style="padding:0;Margin:0;width:270px"
                                  ></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
  
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="border-collapse:collapse;border-spacing:0px"
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding:0;Margin:0;width:560px"
                                ></td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td
                          align="left"
                          style="padding:0;Margin:0;padding-top:20px;padding-left:20px;padding-right:20px"
                        >
                          <table
                            cellpadding="0"
                            cellspacing="0"
                            width="100%"
                            style="border-collapse:collapse;border-spacing:0px"
                          >
                            <tbody>
                              <tr>
                                <td
                                  align="center"
                                  valign="top"
                                  style="padding:0;Margin:0;width:560px"
                                >
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="border-collapse:collapse;border-spacing:0px"
                                  >
                                    <tbody>
                                      <tr>
                                        <td
                                          align="center"
                                          style="padding:0;Margin:0;font-size:0px"
                                        >
                                          <img
                                            src="https://te-prod-space.nyc3.cdn.digitaloceanspaces.com/te-cms/1d43db69a634d6113e787da3fe61cefb.png?updated_at=2022-02-15T04:52:13.701Z"
                                            alt=""
                                            style="display:block;border:0;outline:none;text-decoration:none"
                                            width="560"
                                            height="472"
                                            class="CToWUd a6T"
                                            tabindex="0"
                                          />
                                          <div
                                            class="a6S"
                                            dir="ltr"
                                            style="opacity: 0.01; left: 713px; top: 1289.5px;"
                                          >
                                            <div
                                              id=":wz"
                                              class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q"
                                              role="button"
                                              tabindex="0"
                                              aria-label="Descargar el archivo adjunto "
                                              data-tooltip-class="a1V"
                                              data-tooltip="Descargar"
                                            >
                                              <div class="akn">
                                                <div class="aSK J-J5-Ji aYr"></div>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <table
                                    cellpadding="0"
                                    cellspacing="0"
                                    width="100%"
                                    role="presentation"
                                    style="border-collapse:collapse;border-spacing:0px"
                                  >
                                    <tbody>
                                      <tr>
                                        <td style="color:#ffffff">
                                          <br />
                                          <br />
                                          <small>
                                            <b>Términos y condiciones.</b>
                                            <br />
                                            -Cupón valido únicamente para la
                                            cotización del producto
                                            realizado.
                                            <br />
                                            -Cupón no es transferible entre
                                            casillero.
                                            <br />
                                            -Para hacer uso del cupón debe
                                            presentarlo impreso.
                                            <br />
                                            -Cupón no aplica con otras
                                            promociones.
                                            <br />
                                            -Presenta este cupón en caja.
                                            <br />
                                          </small>
                                          <br />
                                          <br />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  </div>
    `;
};
