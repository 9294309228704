import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import PersonalInfo from "./PersonalInfo";
import DeliveryAddress from "./DeliveryAddress";
import PaymentMethod from "./PaymentMethod";
// import Users from "./Users";
import PersonalInfoMobile from "./PersonalInfoMobile";
import PaymentMethodMobile from "./PaymentMethodMobile";
import DeliveryAddressMobile from "./DeliveryAddressMobile";
import axios from "axios";
import { useAuth } from "../../../contexts/auth";
import MyAddress from "./MyAddress";

export default function Information() {
  const [error, setError] = useState("");
  const [cards, setCards] = useState([]);
  const [, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    getCards();
    // eslint-disable-next-line
  }, []);

  const getCards = () => {
    axios

      .get(`${process.env.REACT_APP_BASE_URL}/users/${user.idUser}/cards`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (
          res.data &&
          res.data.codigo_respuesta !== 400 &&
          res.data.lista_tarjetas
        ) {
          setCards(res.data.lista_tarjetas);
        }
      })
      .catch(() => {
        setError("El servicio no está disponible.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="lg:flex-1 hidden lg:block">
        <div className="flex flex-row">
          <div className="w-1/2">
            <h1 className=" text-te-blue-800 text-3xl font-bold">
              Información de la cuenta
            </h1>
          </div>
          <div className="w-1/3">
            {/* {user.promocion === "N" && (
              <>
                <Link
                  to="/micasillero/promocion"
                  className="flex w-full items-center font-bold text-xl rounded-lg p-3 text-te-orange-400 bg-te-blue-800"
                >
                  <span className="w-8 mr-3">
                    <img src={points} alt="" />
                  </span>
                  Registrate a la promoción
                </Link>
              </>
            )} */}
          </div>
        </div>
        <Tab.Group>
          <Tab.List className=" w-full mt-8">
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Información Personal
                </span>
              )}
            </Tab>
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Mi Domicilio
                </span>
              )}
            </Tab>
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Mi Casillero USA
                </span>
              )}
            </Tab>

            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Método de Pago
                </span>
              )}
            </Tab>

            {/* <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected ? "border-te-blue-800 text-te-blue-800" : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Usuarios
                </span>
              )}
            </Tab> */}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <PersonalInfo />
            </Tab.Panel>
            <Tab.Panel>
              <DeliveryAddress />
            </Tab.Panel>
            <Tab.Panel>
              <MyAddress />
            </Tab.Panel>
            {user.idApp !== 0 && (
              <Tab.Panel>
                <PaymentMethod
                  cards={cards}
                  setCards={setCards}
                  error={error}
                />
              </Tab.Panel>
            )}
            {/* <Tab.Panel>
              <Users />
            </Tab.Panel> */}
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="lg:hidden">
        <PersonalInfoMobile />

        <DeliveryAddressMobile />

        <h2 className="text-te-blue-800 text-xl font-semibold mt-5">
          Mi Casillero USA
        </h2>

        <MyAddress />

        {user.idApp !== 0 && (
          <PaymentMethodMobile
            cards={cards}
            setCards={setCards}
            error={error}
          />
        )}
      </div>
    </>
  );
}
