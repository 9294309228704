import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Spinner from "../utils/Spinner";

export default function Newsletter() {
  const [content, setContent] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [, setError] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/banner-newsletter?populate=*`)
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/newsletter`, {
        email: data.email,
      })
      .then(() => {
        setSuccess(true);
        reset();
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <section className="lg:py-16">
      <div className="lg:container lg:px-32">
        <div
          data-aos="fade-up"
          className="lg:rounded-xl bg-te-blue-800 flex flex-col lg:flex-row lg:px-16 py-16 container"
        >
          <div className="lg:w-1/3">
            <h4 className=" text-3xl text-white font-semibold text-center lg:text-left">
              {content?.Titulo}
            </h4>
            <p className=" text-white mt-5 lg:w-80 text-center lg:text-left">
              {content?.Subtitulo}
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-5 lg:mt-0 lg:w-2/3 lg:items-center lg:pl-20 lg:flex"
          >
            <input
              type="email"
              {...register("email", { required: true })}
              className={`h-12 rounded lg:rounded-none lg:rounded-l bg-white w-full px-5 focus:outline-none ${
                errors.email ? "border-2 border-te-red-800" : ""
              }`}
              placeholder="Ingresa tu correo electrónico"
            />

            {!success ? (
              <button
                type="submit"
                disabled={isLoading}
                className="h-12 rounded lg:rounded-none lg:rounded-r bg-te-orange-400 px-12 w-full lg:w-auto text-white font-semibold mt-5 lg:mt-0 relative"
              >
                Suscríbete
                {isLoading && (
                  <div className="absolute right-2 top-0 h-full flex items-center">
                    <Spinner color="#ffffff" type="spinningBubbles" />
                  </div>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="h-12 rounded lg:rounded-none lg:rounded-r bg-te-green-700 px-12 w-full lg:w-auto text-white font-semibold mt-5 lg:mt-0 relative"
              >
                Suscrito
              </button>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}
