import React, { useContext, createContext, useState, useEffect } from "react";
import jwt from "jsonwebtoken";
import { useLocation } from "react-router-dom";

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export function useAuth() {
  return useContext(authContext);
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useProvideAuth() {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("te-user")) || null
  );

  const query = useQuery();

  useEffect(() => {
    const token = query.get("a");

    if (!user && token) {
      const decoded = jwt.verify(token, "te-service");

      const correctObject = { ...decoded.infoData };
      correctObject["autologinUrl"] = decoded.autologinUrl;
      correctObject["idUser"] = decoded.idUser;
      correctObject["casillero"] = decoded.PoBox;
      correctObject["token"] = token;

      setUser({...correctObject,...user});
    }
  }, [query, user]);

  const signin = async (data, cb) => {
    setUser(data);
    localStorage.setItem("te-user", JSON.stringify(data));
    cb();
  };

  const signout = () => {
    localStorage.removeItem("te-user");
    setUser(null);
  };

  const signup = async (data, cb) => {
    setUser(data);
    localStorage.setItem("te-user", JSON.stringify(data));
    cb();
  };

  return {
    user,
    setUser,
    signin,
    signout,
    signup,
  };
}
