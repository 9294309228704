import React, { useState } from "react";

export default function ServicesAndTypes({ dataCMS }) {
  const [active, setActive] = useState(0);

  return (
    <section className="bg-te-gray-700">
      <div className="container flex flex-col lg:flex-row py-10 lg:py-16 lg:px-32">
        <div className="lg:w-1/2">
          <h3
            data-aos="fade-up"
            className="text-te-blue-800 text-2xl lg:text-3xl font-semibold"
          >
            {dataCMS?.TituloServicios}
          </h3>

          <div className="" data-aos="fade-up">
            <div
              onClick={() => {
                setActive(0);
              }}
              className={`rounded-2xl w-full lg:w-96 p-5 mt-10 lg:mt-16 group transition-all duration-300 cursor-pointer ${
                active === 0 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <h4
                className={`text-2xl font-bold ${
                  active === 0 ? "text-white" : "text-blue-900"
                }`}
              >
                {dataCMS?.TituloServicio1}
              </h4>
              <p
                className={`group-focus: mt-3 pb-4 whitespace-pre-line ${
                  active === 0 ? "text-white" : "text-te-gray-800"
                }`}
              >
                {dataCMS?.DescripcionServicio1}
              </p>
            </div>
            <div
              onClick={() => {
                setActive(1);
              }}
              className={`rounded-2xl w-full lg:w-96 p-5 mt-10 group transition-all duration-300 cursor-pointer ${
                active === 1 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <h4
                className={`text-2xl font-bold ${
                  active === 1 ? "text-white" : "text-blue-900"
                }`}
              >
                {dataCMS?.TituloServicio2}
              </h4>
              <p
                className={`group-focus: mt-3 pb-4 whitespace-pre-line ${
                  active === 1 ? "text-white" : "text-te-gray-800"
                }`}
              >
                {dataCMS?.DescripcionServicio2}
              </p>
            </div>
          </div>
        </div>
        {active === 0 && (
          <div
            data-aos="zoom-in"
            className="lg:w-1/2 px-10 2xl:px-20 lg:flex items-center hidden"
          >
            <img src={dataCMS?.ImagenServicio1?.data?.attributes?.url} alt="" />
          </div>
        )}
        {active === 1 && (
          <div
            data-aos="zoom-in"
            className="lg:w-1/2 px-10 2xl:px-20 lg:flex items-center hidden"
          >
            <img src={dataCMS?.ImagenServicio2?.data?.attributes?.url} alt="" />
          </div>
        )}
      </div>
    </section>
  );
}
