import React from "react";
import te from "../../assets/images/locker/te-locker.svg";
import box from "../../assets/images/icons/locker-box.svg";
import {
  getStatus,
  getStatusColor,
} from "../locker/Packages/PackagesDelivered";

export default function Information({ packageInfo }) {
  return (
    <div className="md:border-2 md:border-te-gray-700 rounded-xl md:mt-10 md:px-5 lg:px-10 w-full">
      <div className="flex items-center justify-between md:border-b py-5">
        <span className="font-bold text-xl text-te-blue-800">
          Información del paquete
        </span>
        <div className="hidden md:block">
          <img src={te} alt="" className="h-5" />
        </div>
      </div>

      <div className="my-5 flex flex-col md:flex-row justify-between shadow-md md:shadow-none pb-5 md:pb-0 px-5 md:px-0 rounded-xl">
        <div className="flex-shrink-0 flex md:block items-center">
          <div className="inline-flex items-center justify-center rounded-full md:p-3 md:border-2 border-te-gray-700">
            <img src={box} alt="" className="h-8 " />
          </div>
          <div className="ml-5 md:hidden">
            <span className="block text-lg text-te-blue-800 font-semibold">
              {packageInfo.Guia}
            </span>
            <span
              className={`mt-1 inline-block ${getStatusColor(
                packageInfo.UltimoEstatusDesc
              )} rounded-r-full rounded-bl-full px-3 py-1 text-sm font-semibold`}
            >
              {packageInfo.ClienteSubioFactura === "S"
                ? "En Proceso"
                : getStatus(packageInfo.UltimoEstatusDesc)}
            </span>
          </div>
        </div>

        <div className="md:pl-10 space-y-4 text-sm md:text-base mt-5">
          <div className=" font-semibold w-full flex md:block">
            <span className="inline-block w-32 md:w-40 text-te-gray-800">
              Tracking:
            </span>
            <span className="inline-block flex-1 flex-shrink-0 text-te-blue-800 truncate">
              {packageInfo.Tracking}
            </span>
          </div>
          <div className=" font-semibold">
            <span className="inline-block w-32 md:w-40 text-te-gray-800">
              Guía TE:
            </span>
            <span className="inline-block text-te-blue-800">
              {packageInfo.Guia}
            </span>
          </div>
          <div className=" font-semibold">
            <span className="inline-block w-32 md:w-40 text-te-gray-800">
              Recibido en Miami:
            </span>
            <span className="inline-block text-te-blue-800">
              {packageInfo.FechaIngreso} / {packageInfo.HoraIngreso}
            </span>
          </div>
          <div className=" font-semibold">
            <span className="inline-block w-32 md:w-40 text-te-gray-800">
              Remitente:
            </span>
            <span className="inline-block text-te-blue-800">
              {packageInfo.NombreRemitente}
            </span>
          </div>
          <div className=" font-semibold">
            <span className="inline-block w-32 md:w-40 text-te-gray-800">
              Destinatario:
            </span>
            <span className="inline-block text-te-blue-800">
              {packageInfo.NombreDestinatario}
            </span>
          </div>
        </div>

        <div className="mt-4 space-y-3 hidden md:block">
          <div className="font-semibold">
            <span className="inline-block w-28 text-te-gray-800">Estatus:</span>
            <span
              className={`mt-1 inline-block ${getStatusColor(
                packageInfo.UltimoEstatusDesc
              )} rounded-r-full rounded-bl-full px-3 py-1 text-sm font-semibold`}
            >
              {packageInfo.ClienteSubioFactura === "S"
                ? "En Proceso"
                : getStatus(packageInfo.UltimoEstatusDesc)}
            </span>
          </div>
          <div className="font-semibold flex items">
            <span className="inline-block w-28 text-te-gray-800">
              Contenido:
            </span>
            <span className="inline-block max-w-xs text-te-blue-800">
              {typeof packageInfo.Contenido === "string"
                ? packageInfo.Contenido
                : "No definido"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
