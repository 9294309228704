import axios from "axios";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import addFile from "../../assets/images/icons/add-file.svg";
import { ReactComponent as Question } from "../../assets/images/icons/question.svg";
import s3, { config } from "../../configs/spaces";
import { useAuth } from "../../contexts/auth";
import { sendInvoice } from "../../services/sendInvoice";
import ButtonBasic from "../buttons/Basic";
import { getFileExtension } from "../utils/getFileExtension";
import Spinner from "../utils/Spinner";
import Tooltip from "../tooltip/Tooltip";

export default function SendInvoiceForm() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState("");
  const [guia, setGuia] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({});

  const watchFile = watch("file", false);

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  useEffect(() => {
    if (query.get("g")) {
      setGuia(query.get("g"));
      if (user) {
        reset({
          email: user.Correo,
          locker: user.PoBox,
        });
      }
      return;
    }

    if (query.get("cb")) {
      setGuia(query.get("cb"));

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/packages/${query.get("cb")}/data`
        )
        .then((res) => {
          reset({
            email: res.data?.Email,
            Remitente: res.data?.Remitente,
            tracking: res.data?.NumeroTracking,
            Consigna: res.data?.Destinatario,
            locker: res.data?.Casillero,
          });
        });
      return;
    }

    if (user) {
      reset({
        email: user.Correo,
        locker: user.PoBox,
      });
    }

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");

    const blob = data.file[0];

    blob.hash = DateTime.now().toMillis();

    const ramdom = Math.random().toString(36).slice(2);

    const extension = getFileExtension(blob.name);

    const params = {
      ACL: "public-read",
      Body: blob,
      Bucket: `${config.bucketName}`,
      Key: `te-facturas/${blob.hash}${ramdom}.${extension}`,
    };

    s3.putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          setError("No se pudo subir el archivo.");
          return;
        } else {
          const imageUrl = `${config.digitalOceanSpaces}te-facturas/${blob.hash}${ramdom}.${extension}`;
          callback(imageUrl, `${blob.hash}${ramdom}.${extension}`);
        }
      });

    const callback = (imageUrl, fileName) => {
      sendInvoice(data, user, imageUrl, fileName, guia)
        .then((res) => {
          if (res.data.message === "Email no existe") {
            setError("El correo ingresado no existe.");
            return;
          }
          if (res.data && !res.data.error) {
            setSuccessful(true);
            reset();
          } else {
            setError("El servicio no está disponible. Inténtelo más tarde.");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  };

  return (
    <>
      {!successful ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full max-w-md rounded-lg border lg:shadow-lg mt-5 lg:mt-0 text-te-blue-800"
        >
          <div className="p-6">
            {guia && (
              <div className="font-semibold text-sm text-te-gray-800 mb-2">
                Guia: <span className=" text-te-blue-800">{guia}</span>
              </div>
            )}
            <div className="">
              <label
                htmlFor="locker"
                className="text-sm text-te-gray-800 font-semibold block"
              >
                Casillero
              </label>
              <input
                id="locker"
                type="text"
                {...register("locker", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.locker && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="">
              <label
                htmlFor="email"
                className="text-sm text-te-gray-800 font-semibold mt-3 block"
              >
                Correo electrónico
              </label>
              <input
                id="email"
                type="email"
                {...register("email", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.email && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="">
              <label
                htmlFor="Contenido"
                className="text-sm text-te-gray-800 font-semibold mt-3 block"
              >
                Contenido del paquete <Tooltip tooltip="No colocar caracteres especial puntos, comas o signos." />
              </label>
              <input
                id="Contenido"
                type="text"
                {...register("Contenido", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.Contenido && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="">
              <label
                htmlFor="Remitente"
                className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
              >
                Remitente/Tienda
              </label>
              <input
                id="Remitente"
                type="text"
                {...register("Remitente", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.Remitente && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            {!user && (
              <div className="mt-3">
                <label
                  htmlFor="Consigna"
                  className="text-sm text-te-gray-800 font-semibold block"
                >
                  Nombre
                </label>
                <input
                  id="Consigna"
                  type="text"
                  {...register("Consigna", { required: true })}
                  className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                />
                {errors.Consigna && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *Este campo es requerido.
                  </span>
                )}
              </div>
            )}

            {!query.get("cb") && (
              <div className="">
                <label
                  htmlFor="tracking"
                  className="text-sm text-te-gray-800 font-semibold mt-3 block"
                >
                  N. de Tracking de tu transportista &nbsp;
                  <Tooltip tooltip="El número de tracking o guía de rastreo del transportista debe ser correcto, para que su prealerta sea efectiva." />
                </label>
                <input
                  id="tracking"
                  type="text"
                  {...register("tracking", { required: true })}
                  className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                />
                {errors.tracking && (
                  <span className=" text-te-red-900 text-xs mt-2">
                    *Este campo es requerido.
                  </span>
                )}
              </div>
            )}

            <div className="">
              <label
                htmlFor=""
                className="text-sm text-te-gray-800 font-semibold mt-3 block items-center"
              >
                Factura comercial &nbsp;
                <Tooltip tooltip="Asegúrate de subir la factura correspondiente al tracking o guía de rastreo del transportista. " />
              </label>
              {!watchFile?.length ? (
                <div
                  onClick={() => document.getElementById("file").click()}
                  className="border-2 border-te-gray-700 bg-te-blue-100 lg:bg-white bg-opacity-30 hover:bg-te-blue-100 transition hover:bg-opacity-30 border-dashed rounded-xl py-5 mt-2 cursor-pointer"
                >
                  <div className="">
                    <img src={addFile} alt="" className="h-14 mx-auto" />
                  </div>
                  <>
                    <p className="text-center mt-3 text-sm">
                      <span className=" text-te-orange-400 font-semibold">
                        Sube un archivo
                      </span>
                    </p>
                    <p className="text-te-gray-800 text-center text-sm">
                      PNG, JPG, JPEG, DOCX, XLSX, PDF
                    </p>
                  </>
                </div>
              ) : (
                <div className=" text-center px-5 py-5 mt-5 font-semibold flex flex-col justify-center items-center border rounded-lg">
                  {document.getElementById("file").files[0].name}

                  <span
                    className=" text-te-blue-800 underline cursor-pointer"
                    onClick={() => {
                      reset({ ...getValues(), file: "" });
                    }}
                  >
                    Quitar archivo
                  </span>
                </div>
              )}
              {errors.file?.type === "acceptedFormats" && (
                <span className="block text-te-red-900 text-xs mt-2">
                  *{errors.file.message}
                </span>
              )}
              {errors.file?.type === "limitSize" && (
                <span className="block text-te-red-900 text-xs mt-2">
                  *{errors.file.message}
                </span>
              )}
              {errors.file?.type === "required" && (
                <span className="block text-te-red-900 text-xs mt-2">
                  *Es necesario subir tu factura.
                </span>
              )}
              <input
                type="file"
                className="hidden"
                id="file"
                accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
                {...register("file", {
                  required: true,
                  validate: {
                    acceptedFormats: (files) => {
                      if (files[0]) {
                        return (
                          [
                            "image/jpg",
                            "image/jpeg",
                            "image/png",
                            "application/pdf",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "application/vnd.ms-excel",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/msword",
                          ].includes(files[0]?.type) ||
                          "Este tipo de archivo no es permitido."
                        );
                      } else {
                        return null;
                      }
                    },
                    limitSize: (files) => {
                      if (files[0]) {
                        const maxAllowedSize = 4 * 1024 * 1024;
                        return (
                          files[0].size < maxAllowedSize ||
                          "El tamaño maximo de archivo debe ser de 4MB."
                        );
                      } else {
                        return null;
                      }
                    },
                  },
                })}
              />
            </div>
            <div className="mt-6 flex space-x-5">
              <button
                type="button"
                onClick={() => reset()}
                disabled={isLoading}
                className=" w-full bg-white text-blue-800 border border-te-blue-800 rounded-lg font-semibold py-2"
              >
                Limpiar campos
              </button>
              <button
                type="submit"
                disabled={isLoading}
                className=" w-full bg-te-blue-400 text-white font-semibold rounded-lg py-2 relative"
              >
                Enviar factura
                {isLoading && (
                  <div className="absolute right-2 top-0 h-full flex items-center">
                    <Spinner color="#ffffff" type="spinningBubbles" />
                  </div>
                )}
              </button>
            </div>
            {error && (
              <div className=" text-te-red-900 font-semibold mt-5">
                *{error}
              </div>
            )}
          </div>
        </form>
      ) : (
        <div className="bg-te-gray-700 rounded-lg p-10 flex flex-col w-full max-w-md my-10 lg:my-20">
          <p className=" text-te-blue-800 text-xl text-center">
            La factura de tu compra ha sido enviada con éxito. El número de tracking o guía de rastreo del transportista debe ser correcto, para que su prealerta sea efectiva.
          </p>

          <div className="flex justify-center mt-5">
            <ButtonBasic
              className=" bg-te-blue-800 text-white"
              classNameFather="bg-te-blue-900"
              onClick={() => {
                setSuccessful(false);
                reset();
              }}
            >
              Enviar otra factura
            </ButtonBasic>
          </div>
        </div>
      )}
    </>
  );
}
