import React from "react";
import agency from "../../assets/images/contact/agency.png";
import kiosk from "../../assets/images/contact/kiosk.png";

export default function Offices({ dataCMS }) {
  return (
    <section className="container lg:pl-20 pb-20">
      <h3
        id="sucursales"
        data-aos="fade-up"
        className="font-semibold text-te-blue-800 text-2xl"
      >
        Nuestras sucursales
      </h3>

      {dataCMS?.Surcursales?.map((office, index) => (
        <OfficeElement key={index} data={office} />
      ))}
    </section>
  );
}

const OfficeElement = ({ data }) => {
  return (
    <div data-aos="fade-up" className="flex mt-10 lg:mt-20">
      <div className="lg:w-1/2 flex flex-col lg:flex-row">
        <div className="flex-shrink-0">
          <img
            src={data?.Tipo === "Agencia" ? agency : kiosk}
            alt=""
            className="h-16 lg:h-20 "
          />
        </div>
        <div className="lg:ml-5 mt-5 lg:mt-0">
          <h4 className="font-semibold text-te-blue-800 text-2xl lg:text-3xl">
            {data?.Titulo}
          </h4>
          <div className="text-te-gray-800 max-w-lg mt-5 lg:text-lg">
            <span className=" font-semibold">Dirección:</span> <br />
            <a
              href={data?.EnlaceMapa}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              {data?.Direccion}
            </a>{" "}
            <br />
            <span className=" font-semibold">Tel:</span> {data?.Telefono} <br />
            {data?.TelefonoAux && (
              <p className="font-semibold">
                Recolección Courier:{" "}
                <span className=" font-normal">{data?.TelefonoAux}</span>
              </p>
            )}
            <br />
            <p className=" font-semibold">Horarios: </p>
            <p className=" whitespace-pre-line ">{data?.Horarios}</p>
          </div>
        </div>
      </div>

      <div className="flex-1 hidden lg:flex">
        <div className="mx-auto">
          <img
            src={data?.Imagen?.data?.attributes?.url}
            alt=""
            className="h-72 w-96 rounded-xl object-cover"
          />
          <p className="mt-5 text-center text-te-blue-800 font-semibold text-lg">
            <a
              href={data?.EnlaceMapa}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline"
            >
              Ver en Mapa
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
