import React, { useState } from "react";
import box from "../../../assets/images/icons/locker-box.svg";
import Spinner from "../../utils/Spinner";
import { Link } from "react-router-dom";
import { getStatus, getStatusColor } from "./PackagesDelivered";

export default function ActivePackagesMobile({ isLoading, packages, error }) {
  const [isShowingAll, setIsShowingAll] = useState(false);

  return (
    <div className=" bg-te-gray-700 -mx-5 pb-8">
      <div className="container">
        <div className="flex items-center justify-between py-5">
          <h2 className=" text-te-blue-800 text-xl font-bold">
            Activos ({packages.length || "0"})
          </h2>
          {packages.length > 3 && (
            <>
              {!isShowingAll ? (
                <div
                  onClick={() => {
                    setIsShowingAll(true);
                  }}
                  className=" underline text-te-gray-800 font-semibold text-lg"
                >
                  Ver todos
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsShowingAll(false);
                  }}
                  className=" underline text-te-gray-800 font-semibold text-lg"
                >
                  Ocultar
                </div>
              )}
            </>
          )}
        </div>
        {isLoading && (
          <div className="py-10 flex justify-center">
            {" "}
            <Spinner color="#141c82" type="spinningBubbles" />{" "}
          </div>
        )}
        {!isLoading && error && (
          <span className="block text-center py-10">{error}</span>
        )}

        {!isShowingAll ? (
          <div className="space-y-5">
            {!!packages.length &&
              packages
                .slice(0, 3)
                .map((value, index) => (
                  <PackageCard value={value} key={index} />
                ))}
          </div>
        ) : (
          <div className="space-y-5">
            {!!packages.length &&
              packages.map((value, index) => (
                <PackageCard value={value} key={index} />
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

const PackageCard = ({ value }) => {
  return (
    <div className=" bg-white p-4 rounded-lg shadow-lg">
      <div className="flex-1 flex">
        <div className="">
          <img src={box} alt="" className="h-7" />
        </div>
        <div className="ml-3">
          <span className="block font-semibold text-te-blue-800">
            {value.Guia}
          </span>
          <span
            className={`mt-1 inline-block ${getStatusColor(
              value.UtlimoEstatus
            )} rounded-r-full rounded-bl-full px-3 py-1 text-sm font-semibold`}
          >
            {getStatus(value.UtlimoEstatus)}
          </span>
        </div>
      </div>

      <div className="mt-2">
        <div className="text-te-gray-800 truncate">
          Guia: {value.NumeroTracking}
        </div>
      </div>

      {/* <div className="">
            <div className="text-te-gray-800">
              Recibido en Miami: {value.FechaDeIngreso}
            </div>
          </div> */}

      <div className="mt-5">
        <Link
          to={`/packages/${value.Guia}`}
          className=" py-3 block w-full border-te-blue-800 border-2 rounded-lg text-te-blue-800 text-center font-semibold"
        >
          Ver detalles
        </Link>
      </div>
    </div>
  );
};
