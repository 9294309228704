import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import React from "react";
import Spinner from "../../utils/Spinner";
import iconGray from "../../../assets/images/icons/locker-user-gray.svg";

export default function YourReferredMobile({
  yourReferred,
  isLoadingYourReferred,
  errorYourReferred,
}) {
  return (
    <div>
      <div className="mt-5 border-2 border-te-gray-700 rounded-xl overflow-hidden">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex items-center justify-between w-full text-left focus:outline-none font-semibold text-te-blue-800 border-b  p-4`}
              >
                <span>TUS REFERIDOS</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "transform rotate-180" : ""
                  } w-5 h-5 transition text-te-blue-800`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="">
                  <div className="">
                    {!isLoadingYourReferred ? (
                      <div className="w-full">
                        {yourReferred.length ? (
                          <>
                            <div className="w-full space-y-3 p-3">
                              {yourReferred.map((value, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col bg-te-gray-700 bg-opacity-50 rounded-lg p-4"
                                >
                                  <div className="flex-1 flex items-center">
                                    <img src={iconGray} alt="" className="h-8 w-8 mr-4" />
                                    <div className="text-te-blue-800 font-bold">
                                      {value.Cuenta}
                                    </div>
                                  </div>
                                  <div className="flex-1 mt-2">
                                    <div className="text-te-gray-800 font-semibold">
                                      {value.Nombre}
                                    </div>
                                  </div>
                                  <div className="flex-1">
                                    <div className="text-te-gray-800 font-semibold">
                                      {value.Email}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <span className="flex justify-center py-6 text-te-gray-800 font-semibold text-lg">
                            {errorYourReferred}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center py-12 justify-center">
                        <Spinner color="#0d1256" type="spinningBubbles" />
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
