import React, { useEffect, useState } from "react";
import { useCalculator } from "../../contexts/calculator";
import { useForm } from "react-hook-form";
import CalculatorModal from "./CalculatorModal";
import axios from "axios";
import { calculate } from "../../services/calculate";
import { ChevronDownIcon } from "@heroicons/react/solid";
import Spinner from "../utils/Spinner";

export default function CalculatorForm() {
  const { setValues } = useCalculator();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [calculatedData, setCalculatedData] = useState({});
  const [dutys, setDutys] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    updateData();
    // eslint-disable-next-line
  }, [watch().value, watch().weight, watch().category]);

  useEffect(() => {
    getDutys();
  }, []);

  const getDutys = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/dutys`)
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setDutys(res.data.data);
        }
      })
      .catch(() => {});
  };

  const updateData = () => {
    if (watch().value || watch().weight || watch().category) {
      setValues({
        isActive: true,
        value: watch().value,
        weight: watch().weight,
        category: watch().category,
      });
    } else {
      setValues({
        isActive: false,
        value: "",
        weight: "",
        category: "",
      });
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    calculate(data)
      .then((res) => {
        if (res.data.error) {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        } else {
          setCalculatedData(res.data.data);
          openModal();
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const cleanForm = () => {
    reset();
  };

  return (
    <>
      <div className=" w-full max-w-md rounded-lg border lg:shadow-lg mt-5 lg:mt-0">
        <div className="p-6 border-b">
          <p className="text-3xl text-te-blue-800 font-bold text-center">
            Calculadora
          </p>
          <p className="text-te-gray-800 text-center mt-2 px-5">
            Completa los siguientes campos y recibe{" "}
            <br className="hidden lg:block" /> el costo total de tu compra
          </p>
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="p-6 text-te-blue-800"
        >
          <div className="">
            <label
              htmlFor=""
              className="text-sm text-te-gray-800 font-semibold"
            >
              Tipo de mercadería
            </label>
            <div className="flex items-center relative">
              <select
                id=""
                {...register("category", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm appearance-none"
              >
                <option value="">Selecciona una categoría de tu compra</option>
                {dutys.map((duty, index) => (
                  <option value={JSON.stringify(duty)} key={index}>
                    {duty.Descripcion} ({duty.Porcentaje}%)
                  </option>
                ))}
              </select>
              <ChevronDownIcon className="absolute h-6 right-2 mt-2 pointer-events-none" />
            </div>
            {errors.category && (
              <span className=" text-te-red-900 text-xs mt-2">
                *Este campo es requerido.
              </span>
            )}
          </div>
          <div className="mt-3 flex space-x-4">
            <div className="w-1/2">
              <label
                htmlFor=""
                className="text-sm text-te-gray-800 font-semibold"
              >
                Peso de la mercadería
              </label>
              <div className="flex items-center relative bg-te-gray-700 mt-2 rounded-lg ">
                <input
                  type="number"
                  step="0.01"
                  {...register("weight", {
                    required: true,
                    min: 0.01,
                  })}
                  className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none font-semibold text-sm"
                />
                <span className="inline-flex right-0 h-full items-center text-te-gray-800 font-semibold mr-3">
                  LBS.
                </span>
              </div>
              {errors.weight && errors.weight.type === "required" && (
                <span className="text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
              {errors.weight?.type === "min" && (
                <span className="text-te-red-900 text-xs mt-2">
                  *No se aceptan números negativos.
                </span>
              )}
            </div>
            <div className="w-1/2">
              <label
                htmlFor="value"
                className="text-sm text-te-gray-800 font-semibold"
              >
                Valor de la factura
              </label>
              <div className="flex items-center relative bg-te-gray-700 mt-2 rounded-lg ">
                <input
                  type="number"
                  step="0.01"
                  {...register("value", { required: true, min: 0.01 })}
                  className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none font-semibold text-sm"
                />
                <span className="inline-flex right-0 h-full items-center text-te-gray-800 font-semibold mr-3">
                  USD
                </span>
              </div>
              {errors.value && errors.value.type === "required" &&(
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}

              {errors.value?.type === "min" &&(
                <span className="text-te-red-900 text-xs mt-2">
                  *No se aceptan números negativos.
                </span>
              )}
            </div>
          </div>
          <button
            disabled={isLoading}
            className=" w-full bg-te-blue-400 text-white font-semibold rounded-lg mt-6 py-2 relative"
          >
            Calcular
            {isLoading && (
              <div className="absolute right-2 top-0 h-full flex items-center">
                <Spinner color="#ffffff" type="spinningBubbles" />
              </div>
            )}
          </button>
          <button
            onClick={cleanForm}
            className=" w-full bg-white text-blue-800 border border-te-blue-800 rounded-lg font-semibold mt-3 py-2"
          >
            Limpiar campos
          </button>
          {error && (
            <div className="mt-5 text-te-red-900 font-semibold text-center">
              *{error}
            </div>
          )}
        </form>
      </div>

      <CalculatorModal
        isOpen={isOpen}
        closeModal={closeModal}
        results={calculatedData}
        category={watch().category}
      />
    </>
  );
}
