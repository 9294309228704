import axios from "axios";
import React, { useEffect, useState } from "react";
// import Clients from "../../components/about/Clients";
import Information from "../../components/about/Information";
import Installations from "../../components/about/Installations";
import MainAbout from "../../components/about/Main";
// import Statistics from "../../components/about/Statistics";
import Newsletter from "../../components/general/Newsletter";
import Services from "../../components/home/Services";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function About() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/acerca-de-nosotros?populate[0]=instalaciones.imagen&populate[1]=ImagenInformacion&populate[2]=ImagenPrincipal&populate[3]=Seo.ShareImage`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <Seo data={content?.Seo} />

      <SiteLayout>
        <MainAbout dataCMS={content} />

        {/* <Statistics dataCMS={content} /> */}

        {/* <Clients dataCMS={content} /> */}

        <Information dataCMS={content} />

        <Installations dataCMS={content} />

        <Services />

        <Newsletter />
      </SiteLayout>
    </main>
  );
}
