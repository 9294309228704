import React from "react";
import destinations from "../../assets/images/courierService/destinations.png";

export default function Destinations({ dataCMS }) {
  return (
    <section className="">
      <div className="container flex flex-col lg:flex-row py-10 lg:py-16 lg:px-32">
        <div className="lg:w-1/2">
          <h3
            data-aos="fade-up"
            className="text-te-blue-800 text-2xl lg:text-3xl font-semibold"
          >
            {dataCMS?.TituloDestinos}
          </h3>

          <p
            data-aos="fade-up"
            className=" text-te-gray-800 text-xl lg:text-2xl max-w-xs mt-5 font-semibold whitespace-pre-line"
          >
            {dataCMS?.subtituloDestinos}
          </p>

          <p data-aos="fade-up" className="text-te-gray-800 max-w-sm mt-5 whitespace-pre-line">
            {dataCMS?.DescripcionDestinos}
          </p>

          <p data-aos="fade-up" className="mt-5 text-blue-800 font-semibold">
            *​Todos los envios estan sujetos a proceso de aduana en destino.
          </p>
        </div>
        <div
          data-aos="fade-up"
          className="lg:w-1/2 lg:px-10 2xl:px-20 flex items-center mt-8 lg:mt-0"
        >
          <img src={destinations} alt="" />
        </div>
      </div>
    </section>
  );
}
