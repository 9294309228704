import React, { useEffect, useMemo, useState } from "react";
import jwt from "jsonwebtoken";
import { Link, useLocation } from "react-router-dom";
import SiteLayout from "../../layouts/site";
import { useForm } from "react-hook-form";
import { ReactComponent as Eye } from "../../assets/images/icons/eye.svg";
import Spinner from "../../components/utils/Spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

export default function RestorePassword() {
  const [isShowing, setIsShowing] = useState(false);
  const [isShowing2, setIsShowing2] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCharginPage, setIsCharginPage] = useState(true);
  const [email, setEmail] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState("");

  const validationSchema = Yup.object({
    pass: Yup.string()
      .required("La contraseña es requerida.")
      .min(8, "La contraseña debe tener al menos 8 caracteres."),
    pass2: Yup.string()
      .required("Confirmar la contraseña es requerido.")
      .oneOf([Yup.ref("pass")], "Las contraseñas no coinciden."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  useEffect(() => {
    const token = query.get("c");

    try {
      let decoded = jwt.verify(token, "te-restore");
      setEmail(decoded.email);
      setIsCharginPage(false);
    } catch (e) {
      setIsCharginPage(false);
    }
  }, [query]);

  const onSubmit = (data) => {
    setIsLoading(true);

    const body = { email: email, passw: data.pass };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/change`, body)
      .then((res) => {
        setIsCompleted(true);
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SiteLayout>
      <div className=" min-h-screen">
        {!isCharginPage ? (
          <>
            {email ? (
              <div className=" max-w-xl container ">
                <p className=" text-2xl font-semibold text-te-blue-800 mt-16 text-center">
                  Restablecer contraseña
                </p>

                <div class="mt-6 mb-4 bg-te-blue-100 text-te-blue-800 flex items-center rounded-xl p-4 font-semibold" style={{marginTop:10}}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class=" text-te-blue-800 h-6 mr-3"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                  <p>Estimado Cliente actualiza tu contraseña</p>
                </div>

                {!isCompleted ? (
                  <form onSubmit={handleSubmit(onSubmit)} className="mt-8">
                    <label
                      htmlFor="email"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Correo electrónico
                    </label>
                    <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                      {email}
                    </span>

                    <label
                      htmlFor="pass"
                      className="block font-semibold text-te-gray-800 mt-3 text-sm"
                    >
                      Nueva Contraseña
                    </label>
                    <div className="flex items-center bg-te-gray-700 mt-2 py-2 rounded-lg">
                      <input
                        id="pass"
                        type={isShowing ? "text" : "password"}
                        {...register("pass", { required: true })}
                        className="block bg-te-gray-700  w-full focus:outline-none px-3 font-semibold text-te-blue-800"
                      />
                      <span className="mr-2">
                        <Eye
                          onClick={() => {
                            setIsShowing(!isShowing);
                          }}
                          className={`cursor-pointer h-3 fill-current ${
                            isShowing ? " text-black" : " text-te-gray-800"
                          }`}
                        />
                      </span>
                    </div>
                    {errors.pass && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *{errors.pass?.message}
                      </span>
                    )}

                    <label
                      htmlFor="pass2"
                      className="block font-semibold text-te-gray-800 mt-3 text-sm"
                    >
                      Confirmar Contraseña
                    </label>
                    <div className="flex items-center bg-te-gray-700 mt-2 py-2 rounded-lg">
                      <input
                        id="pass2"
                        type={isShowing2 ? "text" : "password"}
                        {...register("pass2", { required: true })}
                        className="block bg-te-gray-700  w-full focus:outline-none px-3 font-semibold text-te-blue-800"
                      />
                      <span className="mr-2">
                        <Eye
                          onClick={() => {
                            setIsShowing2(!isShowing2);
                          }}
                          className={`cursor-pointer h-3 fill-current ${
                            isShowing2 ? " text-black" : " text-te-gray-800"
                          }`}
                        />
                      </span>
                    </div>
                    {errors.pass2 && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *{errors.pass2?.message}
                      </span>
                    )}
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="w-full text-white border bg-te-blue-800 rounded-lg font-semibold mt-3 py-2 relative"
                    >
                      Actualizar contraseña
                      {isLoading && (
                        <div className="absolute right-3 top-0 h-full flex items-center">
                          <Spinner color="#ffffff" type="spinningBubbles" />
                        </div>
                      )}
                    </button>
                    {error && (
                      <div className="block mt-4 text-te-red-800 font-semibold text-center">
                        *{error}
                      </div>
                    )}
                  </form>
                ) : (
                  <div className="">
                    <p className=" text-center text-lg text-te-blue-900 font-semibold py-10">
                      Contraseña restablecida con exito!
                    </p>
                    <Link to="/login">
                      <button className="w-full text-white border bg-te-blue-800 rounded-lg font-semibold mt-3 py-2 relative">
                        Iniciar Sesión
                      </button>
                    </Link>
                  </div>
                )}
              </div>
            ) : (
              <div className=" text-red-800 text-center py-20 font-semibold text-2xl">
                Este enlace ha caducado!
              </div>
            )}
          </>
        ) : (
          <div className=""></div>
        )}
      </div>
    </SiteLayout>
  );
}
