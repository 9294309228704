import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import ActivePackages from "./ActivePackages";
import PackagesDelivered from "./PackagesDelivered";
import ShoppingMallPackages from "./ShoppingMallPackages";
import axios from "axios";
import { useAuth } from "../../../contexts/auth";
import ActivePackagesMobile from "./ActivePackagesMobile";
import PackagesDeliveredMobile from "./PackagesDeliveredMobile";
import ShoppingMallPackagesMobile from "./ShoppingMallPackagesMobile";

export default function Packages() {
  const [activePackages, setActivePackages] = useState([]);
  const [deliveredPackages, setDeliveredPackages] = useState([]);
  const [shoppingMallPackages, setShoppingMallPackages] = useState([]);

  const [errorActive, setErrorActive] = useState("");
  const [errorDelivered, setErrorDelivered] = useState("");
  const [errorShoppingMall, setErrorShoppingMall] = useState("");

  const [isLoadingActivePackages, setIsLoadingActivePackages] = useState(false);
  const [isLoadingDeliveredPackages, setIsLoadingDeliveredPackages] =
    useState(false);
  const [isLoadingShoppingMallPackages, setIsLoadingShoppingMallPackages] =
    useState(false);

  const { user } = useAuth();
  // console.log("nivekino");

  useEffect(() => {
    setIsLoadingActivePackages(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/packages/${user.PoBox}?actives=true`
      )
      .then((res) => {
        if (res.data.data) {
          if (res.data.data?.length) {
            setActivePackages(res.data.data);
          } else {
            setErrorActive("No existen datos de paquetes.");
          }
        }
      })
      .catch(() => {
        setErrorActive("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoadingActivePackages(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoadingDeliveredPackages(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/packages/${user.PoBox}?actives=false`
      )
      .then((res) => {
        if (res.data.data) {
          if (res.data.data?.length) {
            setDeliveredPackages(res.data.data);
          } else {
            setErrorDelivered("No existen datos de paquetes.");
          }
        }
      })
      .catch(() => {
        setErrorDelivered(
          "El servicio no está disponible. Inténtelo más tarde."
        );
      })
      .finally(() => {
        setIsLoadingDeliveredPackages(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoadingShoppingMallPackages(true);
    axios
      .get(
        `${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/services/pedidos_cliente/${user.idUser}`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_SHOPPING_MALL_TOKEN}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data && res.data.data?.length) {
          setShoppingMallPackages(res.data.data);
        } else {
          setErrorShoppingMall("No existen datos de paquetes.");
        }
      })
      .catch(() => {
        setErrorShoppingMall(
          "El servicio no está disponible. Inténtelo más tarde."
        );
      })
      .finally(() => {
        setIsLoadingShoppingMallPackages(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex-1 hidden lg:block">
        <h1 className="text-te-blue-800 text-3xl font-bold">Paquetes</h1>
        <Tab.Group>
          <Tab.List className=" w-full mt-10">
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Activos ({activePackages.length || "0"})
                </span>
              )}
            </Tab>
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Entregados ({deliveredPackages.length || "0"})
                </span>
              )}
            </Tab>
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-lg border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Shopping Mall ({shoppingMallPackages.length || "0"})
                </span>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <ActivePackages
                packages={activePackages}
                error={errorActive}
                isLoading={isLoadingActivePackages}
              />
            </Tab.Panel>
            <Tab.Panel>
              <PackagesDelivered
                packages={deliveredPackages}
                error={errorDelivered}
                isLoading={isLoadingDeliveredPackages}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ShoppingMallPackages
                packages={shoppingMallPackages}
                error={errorShoppingMall}
                isLoading={isLoadingShoppingMallPackages}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="lg:hidden">
        <ActivePackagesMobile
          packages={activePackages}
          error={errorActive}
          isLoading={isLoadingActivePackages}
        />

        <PackagesDeliveredMobile
          packages={deliveredPackages}
          error={errorDelivered}
          isLoading={isLoadingDeliveredPackages}
        />

        <ShoppingMallPackagesMobile
          packages={shoppingMallPackages}
          error={errorShoppingMall}
          isLoading={isLoadingShoppingMallPackages}
        />
      </div>
    </>
  );
}
