import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../contexts/auth";
import ButtonBasic from "../buttons/Basic";

export default function Services() {
  const { user } = useAuth();
  const history = useHistory();
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/seccion-servicios?populate=*`)
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <section className="relative mt-10">
      <div className="py-10 lg:py-16 relative">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-2xl lg:text-3xl font-semibold text-center container"
        >
          {content?.Titulo}
        </h3>
        <p
          data-aos="fade-up"
          className="text-te-gray-800 lg:text-lg text-center max-w-3xl mx-auto mt-4 container"
        >
          {content?.Descripcion}
        </p>

        <div className="container lg:space-x-10 space-y-16 lg:space-y-0 flex flex-col lg:flex-row items-center justify-center mt-20">
          <div
            data-aos="fade-up"
            className="max-w-xs w-full relative flex flex-col items-center bg-te-gray-600 rounded-xl p-6 lg:h-96"
          >
            <div className="absolute -mt-16">
              <div className="h-20 w-20 rounded-lg bg-te-blue-900 shadow-lg flex items-center justify-center p-4">
                <div>
                  <img
                    src={content?.ImagenOpcion1?.data?.attributes?.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="flex h-full flex-col items-center justify-between">
              <p className=" text-3xl text-te-blue-800 font-bold text-center mt-8">
                {content?.TituloOpcion1}
              </p>
              <p className=" text-te-gray-800 text-center text-sm mt-3">
                {content.DescripcionOpcion1}
              </p>
              <ButtonBasic
                onClick={() => history.push("/casillero")}
                classNameFather="bg-te-orange-600 mt-8 w-40"
                className="bg-te-orange-400 text-white"
              >
                Más Información
              </ButtonBasic>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="max-w-xs w-full relative flex flex-col items-center bg-te-gray-600 rounded-xl p-6 lg:h-96"
          >
            <div className="absolute -mt-16">
              <div className="h-20 w-20 rounded-lg bg-te-blue-900 shadow-lg flex items-center justify-center p-4">
                <div>
                  <img
                    src={content?.ImagenOpcion2?.data?.attributes?.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="flex h-full flex-col items-center justify-between">
              <p className=" text-3xl text-te-blue-800 font-bold text-center mt-8">
                {content?.TituloOpcion2}
              </p>
              <p className=" text-te-gray-800 text-center text-sm mt-3">
                {content?.DescripcionOpcion2}
              </p>
              <ButtonBasic
                onClick={() => history.push("/carga-de-importacion")}
                classNameFather="bg-te-orange-600 mt-8 w-40"
                className="bg-te-orange-400 text-white"
              >
                Más Información
              </ButtonBasic>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="max-w-xs w-full relative flex flex-col items-center bg-te-gray-600 rounded-xl p-6 lg:h-96"
          >
            <div className="absolute -mt-16">
              <div className="h-20 w-20 rounded-lg bg-te-blue-900 shadow-lg flex items-center justify-center p-4">
                <div>
                  <img
                    src={content?.ImagenOpcion1?.data?.attributes?.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="flex h-full flex-col items-center justify-between">
              <p className=" text-3xl text-te-blue-800 font-bold text-center mt-8">
                {content?.TituloOpcion3}
              </p>
              <p className=" text-te-gray-800 text-center text-sm mt-3">
                {content?.DescripcionOpcion3}
              </p>
              <ButtonBasic
                onClick={() => history.push("/courier")}
                classNameFather="bg-te-orange-600 mt-8 w-40"
                className="bg-te-orange-400 text-white"
              >
                Más Información
              </ButtonBasic>
            </div>
          </div>

          <div
            data-aos="fade-up"
            className="max-w-xs w-full relative flex flex-col items-center bg-te-gray-600 rounded-xl p-6 lg:h-96"
          >
            <div className="absolute -mt-16">
              <div className="h-20 w-20 rounded-lg bg-te-blue-900 shadow-lg flex items-center justify-center p-4">
                <div>
                  <img
                    src={content?.ImagenShoppingMall?.data?.attributes?.url}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="flex h-full flex-col items-center justify-between">
              <p className=" text-3xl text-te-blue-800 font-bold text-center mt-8">
                {content?.TituloShoppingMall}
              </p>
              <p className=" text-te-gray-800 text-center text-sm mt-3">
                {content?.DescripcionShoppingMall}
              </p>
              <ButtonBasic
                onClick={() => {
                  user?.autologinUrl
                    ? window.location.replace(user.autologinUrl)
                    : window.location.replace(
                        process.env.REACT_APP_SHOPPING_MALL_BASE_URL
                      );
                }}
                classNameFather="bg-te-orange-600 mt-8 w-40"
                className="bg-te-orange-400 text-white"
              >
                Ir a la tienda
              </ButtonBasic>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
