import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactComponent as Eye } from "../../assets/images/icons/eye.svg";
import { useAuth } from "../../contexts/auth";
import Spinner from "../utils/Spinner";
import RestorePasswordModal from "./RestorePasswordModal";
import ReCAPTCHA from "react-google-recaptcha";

export default function LoginForm() {
  const recaptchaRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorRedirect, setErrorRedirect] = useState("");
  const { signin } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const { from } = location.state || {
    from: { pathname: "/micasillero/packages" },
  };

  useEffect(() => {
    if (from.pathname === "/micasillero/referidos") {
      setErrorRedirect(
        "Para referir a tus amigos debes crear una cuenta o entrar con la que ya tienes."
      );
    }
  }, [from]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function onChange() {
    if (recaptchaRef?.current?.getValue()) {
      setError("");
    }
  }

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const onSubmit = (data) => {
    setIsLoading(true);

    if (!recaptchaRef?.current?.getValue()) {
      setError("Compruebe que no es un robot.");
      setIsLoading(false);
      return;
    }

    const toRedirect = query.get("d");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/login`, {
        email: data.email,
        password: data.password,
      })

      .then((res) => {

        if (res.status === 200 || !res.data.error) {
          const token = res.data.data.token;
          const newObject = res.data.data.infoData;
          newObject["idUser"] = res.data.data.idUser;
          newObject["autologinUrl"] = res.data.data.autologinUrl;
          newObject["token"] = token;

          console.log(newObject);

          signin(newObject, async () => {
            await axios.get(`${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/login/autologin?jwt=${token}`, { withCredentials: true });
            if (toRedirect === "SM") {
              window.location.replace(res.data.data.autologinUrl || "");
            } else {
              history.replace(from);
            }
          });
        } else {
          setError(res.data.error);
        }
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          if (error.response?.data?.message === "Email or password incorrect") {
            setError("El correo o contraseña es incorrecto.");
          } else {
            setError("El correo o contraseña es incorrecto.");
          }
        } else {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    let token_sm = query.get("token_sm");
    if (token_sm) {
      autologin_sm(token_sm);
    }

  }, []);
  async function autologin_sm(token) {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/users/autologin_sm`, {
        token: token,
      })

      .then((res) => {

        console.log(res);

        if (res.status === 200 || !res.data.error) {
          const token = res.data.data.token;
          const newObject = res.data.data.infoData;
          newObject["idUser"] = res.data.data.idUser;
          newObject["autologinUrl"] = res.data.data.autologinUrl;
          newObject["token"] = token;



          signin(newObject, async () => {
            console.log("autologin ok");
            window.close();
          });
        }

      });
  }

  return (
    <div className="container flex flex-col items-center justify-center mb-20">
      <p className="text-center text-te-red-900 font-semibold text-sm my-5 lg:my-10 max-w-sm">
        {errorRedirect && <span>{errorRedirect}</span>}
      </p>
      <div className=" w-full max-w-md border rounded-xl p-5 md:shadow-md">
        <h1 className=" font-bold text-te-blue-800 text-center text-3xl">
          Ingresa a tu cuenta
        </h1>
        <p className=" md:text-lg text-te-gray-800 font-semibold text-center mt-2">
          Introduce tu correo y tu clave para entrar
        </p>

        <div class="mt-6 mb-4 bg-te-blue-100 text-te-blue-800 flex items-center rounded-xl p-4 font-semibold" style={{marginTop:10}}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" class=" text-te-blue-800 h-6 mr-3"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
          <p>Estimado Cliente actualiza tu contraseña</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-10">
          <label
            htmlFor="email"
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Correo electrónico
          </label>
          <input
            type="email"
            id="email"
            {...register("email", { required: true })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
          />
          {errors.email && (
            <span className=" text-te-red-900 text-xs mt-2">
              *Este campo es requerido.
            </span>
          )}
          <label
            htmlFor="pass"
            className="block font-semibold text-te-gray-800 mt-3 text-sm"
          >
            Contraseña
          </label>
          <div className="flex items-center bg-te-gray-700 mt-2 py-2 rounded-lg">
            <input
              id="pass"
              type={isShowing ? "text" : "password"}
              {...register("password", { required: true })}
              className="block bg-te-gray-700  w-full focus:outline-none px-3 font-semibold text-te-blue-800"
            />
            <span className="mr-2">
              <Eye
                onClick={() => {
                  setIsShowing(!isShowing);
                }}
                className={`cursor-pointer h-3 fill-current ${isShowing ? " text-black" : " text-te-gray-800"
                  }`}
              />
            </span>
          </div>
          {errors.password && (
            <span className=" text-te-red-900 text-xs mt-2">
              *Este campo es requerido.
            </span>
          )}
          <div className="flex justify-end mt-3">
            <button
              onClick={openModal}
              type="button"
              className=" text-te-blue-800 font-semibold hover:underline text-sm"
            >
              ¿Olvidaste tu contraseña?
            </button>
          </div>

          <div className="flex justify-center mt-5">
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
              onChange={onChange}
            />
          </div>

          <button
            type="submit"
            disabled={isLoading}
            className="w-full text-white border bg-te-blue-800 rounded-lg font-semibold mt-3 py-2 relative"
          >
            Ingresar
            {isLoading && (
              <div className="absolute right-3 top-0 h-full flex items-center">
                <Spinner color="#ffffff" type="spinningBubbles" />
              </div>
            )}
          </button>
          <div className=" text-te-blue-800 justify-center flex mt-5 text-sm">
            <p>
              ¿No tienes una cuenta?{" "}
              <Link
                to="/register"
                className=" text-te-orange-400 font-semibold"
              >
                Crea tu casillero
              </Link>
            </p>
          </div>
        </form>
      </div>

      {error && (
        <p className="text-center text-te-red-900 font-semibold mt-5">
          *{error}
        </p>
      )}

      <RestorePasswordModal isOpen={isOpen} closeModal={closeModal} />
    </div>
  );
}
