import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../../contexts/auth";
import { sendEmailData } from "../../../emails/updateData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("*Correo electrónico inválido")
    .required("*Correo electrónico es requerido"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]+$/, "*Número de teléfono inválido")
    .required("*Número de teléfono es requerido"),
  address: Yup.string().required("*Dirección es requerida"),
});

export default function Exempt() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [descriptions, setDescriptions] = useState(["", "", ""]);
  const { user } = useAuth();

  useEffect(() => {
    console.log("Updated selectedFiles:", selectedFiles);
  }, [selectedFiles]);

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];

    if (
      file &&
      (file.type.startsWith("image/") ||
        file.type === "application/pdf" ||
        file.type === "application/msword")
    ) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const base64Content = event.target.result.split(",")[1];
        const updatedFiles = [...selectedFiles];
        updatedFiles[index] = base64Content;
        setSelectedFiles(updatedFiles);
      };

      reader.onerror = (error) => {
        console.error("Error converting file to base64:", error);
      };

      reader.readAsDataURL(file);
    } else {
      console.error("Invalid file type");
    }
  };

  const handleDescriptionChange = (e, index) => {
    const newDescriptions = [...descriptions];
    newDescriptions[index] = e.target.value;
    setDescriptions(newDescriptions);
  };

  const sendEmail = (Form, user) => {
    const body = {
      to: "tecupon2021@gmail.com, actualizacion.datos@transexpress.com.sv",
      from: "notificacion@transexpress.com.sv",
      subject: `${user.PoBox} - actualización de datos.`,
      html: sendEmailData(
        user.PoBox,
        Form.email,
        Form.phoneNumber,
        Form.address
      ),
    };

    try {
      axios.post(`${process.env.REACT_APP_BASE_URL}/sendmail`, body);
      console.log("Email sent");
      return true;
    } catch (error) {
      console.log("Falla: " + error);
      return false;
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      let duiImageAdded = false;

      for (let i = 0; i < selectedFiles.length; i++) {
        if (i < 3 && !selectedFiles[i]) {
          toast.error(
            `Debe agregar Acuerdo y Documento que ampara la exención de impuestos`
          );
          return;
        }

        const requestBody = {
          casillero: user.PoBox,
          imagen: selectedFiles[i] || "",
          descripcion: descriptions[i] || "",
        };

        if (i < 3 && selectedFiles[i]) {
          duiImageAdded = true;
        }

        if (selectedFiles[i]) {
          await axios
            .post(
              `${process.env.REACT_APP_BASE_URL}/send-documentation`,
              requestBody,
              {
                headers: {
                  Authorization: `Bearer ${user.token}`,
                },
              }
            )
            .then((res) => {
              if (res.status === 200) {
                const emailSent = sendEmail(values, user);
                if (!emailSent) {
                  toast.error("Error al enviar el correo electrónico");
                } else {
                  setSelectedFiles(["", "", ""]);
                  setDescriptions(["", "", ""]);
                  resetForm();
                  toast.success("Datos enviados correctamente");
                }
              }
            });
        }
      }

      if (!duiImageAdded) {
        toast.error(
          "Debe agregar Acuerdo y Documento que ampara la exención de impuestos"
        );
      }
    } catch (error) {
      toast.error("Datos requeridos");
      console.log(error);
    }

    setSubmitting(false);
  };

  return (
    <>
      <div className="mt-5 border-2 border-te-gray-700 rounded-xl p-6 ">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          progress={undefined}
          theme="dark"
        />

        <Formik
          initialValues={{
            email: "",
            phoneNumber: "",
            address: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="mt-5">
              <div className="md:flex md:space-x-3">
                <div className="md:w-1/2">
                  <label
                    htmlFor="email"
                    className="block font-semibold text-te-gray-800 text-sm sm:text-xs"
                  >
                    Correo electrónico (vigente)
                  </label>
                  <Field
                    type="text"
                    id="email"
                    name="email"
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-te-red-900 text-xs mt-2"
                  />
                </div>

                <div className="md:w-1/2 mt-3 md:mt-0">
                  <label
                    htmlFor="phoneNumber"
                    className="block font-semibold text-te-gray-800 text-sm sm:text-xs"
                  >
                    Número de teléfono (vigente)
                  </label>
                  <Field
                    type="number"
                    id="phoneNumber"
                    name="phoneNumber"
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="text-te-red-900 text-xs mt-2"
                  />
                </div>

                <div className="md:w-1/2 mt-3 md:mt-0">
                  <label
                    htmlFor="address"
                    className="block font-semibold text-te-gray-800 text-sm sm:text-xs"
                  >
                    Dirección de domicilio para facturación electrónica
                  </label>
                  <Field
                    type="text"
                    id="address"
                    name="address"
                    className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="text-te-red-900 text-xs mt-2"
                  />
                </div>
              </div>

              {[
                "Acuerdo",
                "Documento que ampara la exención de impuestos",
                "Pasaporte, Carnet de residente o Nit.",
              ].map((label, index) => (
                <div className="md:flex md:space-x-3 mt-5" key={index}>
                  <div className="md:w-full">
                    <label
                      htmlFor={`image-${index}`}
                      className="block font-semibold text-te-gray-800 text-sm"
                    >
                      {label}
                    </label>
                    <input
                      type="file"
                      id={`image-${index}`}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                      accept="image/*"
                      onChange={(e) => handleImageChange(e, index)}
                    />

                    <input
                      type="text"
                      placeholder="Descripción"
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                      value={descriptions[index]}
                      onChange={(e) => handleDescriptionChange(e, index)}
                    />
                  </div>
                </div>
              ))}

              <button
                type="submit"
                disabled={isSubmitting}
                className="text-white bg-te-blue-800 font-semibold px-10 py-2 rounded-lg flex items-center relative mt-5"
              >
                Enviar
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}
