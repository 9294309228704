import React from "react";
import Spinner from "../../utils/Spinner";

export default function YourReferred({
  yourReferred,
  isLoadingYourReferred,
  errorYourReferred,
}) {
  return (
    <div className="">
      {!isLoadingYourReferred ? (
        <div className="w-full">
          {yourReferred.length ? (
            <>
              <div className="w-full space-y-3 p-3">
                {yourReferred.map((value, index) => (
                  <div
                    key={index}
                    className="flex bg-te-gray-700 bg-opacity-50 rounded-lg py-3"
                  >
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Nombre}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Email}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Cuenta}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <span className="flex justify-center py-12 text-te-gray-800 font-semibold text-lg">
              {errorYourReferred}
            </span>
          )}
        </div>
      ) : (
        <div className="flex items-center py-12 justify-center">
          <Spinner color="#0d1256" type="spinningBubbles" />
        </div>
      )}
    </div>
  );
}
