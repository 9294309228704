import React from "react";
import Content from "../../components/referrals/Content";
import SiteLayout from "../../layouts/site";

export default function Referrals() {
  return (
    <>
      <SiteLayout>
        <Content />
      </SiteLayout>
    </>
  );
}
