import React, { useState, useEffect } from "react";
import "./Tooltip.css";

// Función para generar un identificador único
const generateUniqueId = (() => {
  let count = 0;
  return () => `tooltip-${count++}`;
})();

const Tooltip = ({ tooltip }) => {
  const [idTooltip, setIdTooltip] = useState("");

  useEffect(() => {
    setIdTooltip(generateUniqueId());
  }, []);

  const calculateTooltipPosition = (e) => {
    const target = e.target.getAttribute("data-tooltip-target");
    const tooltipElement = document.querySelector(`[data-tooltip="${target}"]`);
    const position = e.target.getBoundingClientRect();
    const tooltipWidth = 200;
    const tooltipHeight = tooltipElement.getBoundingClientRect().height;

    const topTooltip = window.scrollY + position.top - tooltipHeight;
    let leftTooltip = window.scrollX + position.left + position.width;

    // Verificar si el tooltip se sale del borde derecho de la ventana
    const windowWidth = window.innerWidth;
    if (leftTooltip + tooltipWidth + 10 > windowWidth) {
      // Colocar el tooltip al lado izquierdo del elemento
      leftTooltip = window.scrollX + position.left - tooltipWidth;
    }

    return { topTooltip, leftTooltip, tooltipElement };
  };

  const MouseOver = (e) => {
    const { topTooltip, leftTooltip, tooltipElement } = calculateTooltipPosition(e);
    tooltipElement.style.top = `${topTooltip}px`;
    tooltipElement.style.left = `${leftTooltip}px`;
    tooltipElement.classList.remove("hidden");
  };

  const MouseOut = (e) => {
    const target = e.target.getAttribute("data-tooltip-target");
    const tooltipElement = document.querySelector(`[data-tooltip="${target}"]`);
    tooltipElement.classList.add("hidden");
  };

  return (
    <div className="inline-block select-none">
      <span
        onMouseOver={MouseOver}
        onMouseOut={MouseOut}
        data-tooltip-target={idTooltip}
        className="tooltip-te cursor-pointer w-4 h-4 flex justify-center items-center rounded-full text-xs border-1 border-te-blue-900"
      >
        ?
      </span>
      <div data-tooltip={idTooltip} className="hidden tooltip-te-width absolute z-50 whitespace-normal break-words bg-white border-2 border-te-gray-700 rounded-lg py-1.5 px-3 font-sans text-sm font-normal focus:outline-none">
        {tooltip}
      </div>
    </div>
  );
};

export default Tooltip;
