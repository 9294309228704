import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import closeIcon from "../../../assets/images/icons/close-modal.svg";

export default function ShoppingMallPackagesDetailModal({
  openModal,
  setOpenModal,
  data,
}) {
  const closeButtonRef = useRef(null);

  return (
    <>
      <Transition appear show={openModal} as={Fragment}>
        <Dialog
          initialFocus={closeButtonRef}
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto w-full"
          onClose={() => setOpenModal(false)}
        >
          <div className="min-h-screen px-4 text-center w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl my-8 overflow-hidden text-left align-middle transition-all transform">
                <div className="flex justify-end mb-3">
                  <span
                    onClick={() => setOpenModal(false)}
                    ref={closeButtonRef}
                    className=" cursor-pointer"
                  >
                    <img src={closeIcon} alt="" className="h-8" />
                  </span>
                </div>

                <div className="bg-te-gray-300 shadow-xl rounded-2xl p-4 lg:p-6">
                  <p className="font-bold text-xl text-te-blue-800">
                    Información del Pedido
                  </p>

                  {!!data.length ? (
                    <div className="border-2 border-te-gray-700 rounded-xl mt-5 flex">
                      <div className="w-full">
                        <div className="hidden lg:flex border-b-2 border-te-gray-700 p-3 text-sm uppercase text-te-blue-800 font-bold mt-5">
                          <div className="flex-1 text-center">tracking</div>
                          <div className="flex-1 text-center">
                            Sitio de origen
                          </div>
                          <div className="flex-1 text-center">cantidad</div>
                          <div className="flex-1 text-center">comentario</div>
                        </div>

                        <div className="w-full space-y-3 p-3">
                          {data.map((value, index) => (
                            <React.Fragment key={index}>
                              <div className="bg-te-gray-700 bg-opacity-50 rounded-lg py-3 text-sm text-te-gray-800 font-semibold hidden lg:flex">
                                <div className="flex-1">
                                  <div className="text-center">
                                    {value.tracking}
                                  </div>
                                </div>
                                <div className="flex-1">
                                  <div className="text-center">
                                    {value.sitio_origen_desc}
                                  </div>
                                </div>
                                <div className="flex-1">
                                  <div className="text-center">
                                    {value.cantidad}
                                  </div>
                                </div>
                                <div className="flex-1">
                                  <div className="text-center">
                                    {value.comentarios}
                                  </div>
                                </div>
                              </div>

                              <div className="border-b-2 pb-5 font-semibold lg:hidden">
                                <div className="flex items-center">
                                  <div className="mr-3 text-te-blue-800">
                                    Tracking:
                                  </div>
                                  <div className="text-te-gray-800">
                                    {value.tracking}
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="mr-3 text-te-blue-800">
                                    Sitio de origen:
                                  </div>
                                  <div className="text-te-gray-800">
                                    {value.sitio_origen_desc}
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="mr-3 text-te-blue-800">
                                    Cantidad:
                                  </div>
                                  <div className="text-te-gray-800">
                                    {value.cantidad}
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <div className="mr-3 text-te-blue-800">
                                    Comentario:
                                  </div>
                                  <div className="text-te-gray-800">
                                    {value.comentarios}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-5 bg-te-blue-100 py-4 px-5 text-te-blue-800 font-semibold rounded-xl">
                      No se encontraron detalles de este paquete.
                    </div>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
