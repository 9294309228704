import React, { useState } from "react";
import ButtonBasic from "../buttons/Basic";
import ImportCargoModal from "../importCargo/ImportCargoModal";

export default function RequestQuote({ dataCMS }) {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };
  

  return (
    <section className="lg:py-20">
      <div className="lg:container lg:px-32">
        <div
          data-aos="fade-up"
          className="lg:rounded-xl bg-te-blue-100 flex flex-col justify-center items-center px-5 lg:px-16 py-14 "
        >
          <p className="text-3xl lg:text-4xl text-center text-blue-800 font-semibold">
            {dataCMS?.TituloSubBanner}
          </p>
          <p className=" lg:text-lg mt-5 text-center text-te-gray-800"></p>
          <ButtonBasic
            onClick={openModal}
            classNameFather="bg-te-orange-600 mt-10 w-full lg:w-auto"
            className=" text-white bg-te-orange-400"
          >
            Solicitar cotización
          </ButtonBasic>
        </div>
      </div>

      <ImportCargoModal isOpen={isOpen} closeModal={closeModal} />
    </section>
  );
}
