import React from "react";
import { DateTime } from "luxon";
import Spinner from "../../utils/Spinner";

export default function PointsDetail({ isLoadingDetails, userPointsDetails }) {
  return (
    <div className="">
      {!isLoadingDetails ? (
        <div className="w-full">
          {userPointsDetails.length ? (
            <>
              <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold mt-5">
                <div className="flex-1 text-center">fecha</div>
                <div className="flex-1 text-center">CONCEPTO</div>
                <div className="flex-1 text-center">PUNTOS</div>
                <div className="flex-1 text-center">ACUMULADOS</div>
                <div className="flex-1 text-center">REFERENCIA</div>
                <div className="flex-1 text-center">TRANSACCIÓN</div>
              </div>
              <div className="w-full space-y-3 p-3">
                {userPointsDetails.map((value, index) => (
                  <div
                    key={index}
                    className="flex bg-te-gray-700 bg-opacity-50 rounded-lg py-3"
                  >
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {DateTime.fromISO(value.Fecha).toFormat(
                          "dd / MM / yyyy"
                        )}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Concepto}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Puntos}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Acumulado}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Referencia}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {typeof value.Transaccion === "string"
                          ? value.Transaccion
                          : ""}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <span className="block text-center font-semibold text-te-blue-800 py-10">
              No ha canjeado sus puntos
            </span>
          )}
        </div>
      ) : (
        <div className="flex items-center py-12 justify-center">
          <Spinner color="#0d1256" type="spinningBubbles" />
        </div>
      )}
    </div>
  );
}