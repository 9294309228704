import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import brand from "../../assets/images/brand.svg";
import menuIcon from "../../assets/images/icons/menuIcon.svg";
import blank from "../../assets/images/blank.png";
import { useAuth } from "../../contexts/auth";
import SidebarNavigation from "../navbar/SidebarNavigation";
import { XIcon } from "@heroicons/react/solid";
import MobileButtons from "../navbar/MobileButtons";
import ButtonBasic from "../buttons/Basic";

export default function SimpleNavbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const { user } = useAuth();
  const history = useHistory();

  return (
    <>
      <div className="top-0 sticky z-40">
        <div className=" bg-white border-b border-te-gray-700">
          <div className="container mx-auto flex items-center justify-between h-20 lg:h-24">
            <div className="">
              <Link to="/">
                <img src={brand} alt="" className="h-8 lg:h-10" />
              </Link>
            </div>

            <div className="items-center space-x-5 hidden xl:flex">
              <Link to="/login" className="text-te-blue-800 font-semibold">
                Iniciar Sesión
              </Link>
              <ButtonBasic
                onClick={() => history.push("/register")}
                classNameFather="bg-te-orange-600"
                className="bg-gradient-to-r from-te-orange-400 to-te-orange-600 text-white"
              >
                {" "}
                Regístrate Gratis
              </ButtonBasic>
            </div>

            {!openMenu ? (
              <div onClick={() => setOpenMenu(true)} className="lg:hidden">
                {!user ? (
                  <img src={menuIcon} alt="" />
                ) : (
                  <div className="flex items-center">
                    <span className="inline text-te-gray-800 font-semibold">
                      Mi Cuenta
                    </span>
                    <div className=" rounded-full overflow-hidden w-10 h-10 ml-3">
                      <img
                        src={blank}
                        alt=""
                        className="h-full w-full object-cover"
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div onClick={() => setOpenMenu(false)} className="lg:hidden">
                <XIcon className=" text-te-blue-800 h-8" />
              </div>
            )}
          </div>
        </div>
        <MobileButtons />

        <SidebarNavigation open={openMenu} setOpen={setOpenMenu} />
      </div>
    </>
  );
}
