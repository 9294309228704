import React from "react";
import Slider from "react-slick";

export default function Stores({ dataCMS }) {
  let settings = {
    centerMode: true,
    centerPadding: "30px",
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 4000,
    pauseOnHover: false,
    initialSlide: 1,
    cssEase: "linear",
  };

  return (
    <section className="bg-te-gray-600">
      <div className="pt-10 lg:pt-16">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-2xl lg:text-3xl font-semibold text-center container"
        >
          {dataCMS?.TituloTiendas}
        </h3>
        <p
          data-aos="fade-up"
          className="text-te-gray-800 lg:text-lg text-center max-w-3xl mx-auto mt-4 container"
        >
          {dataCMS?.DescripcionTiendas}
        </p>

        <div className="">
          <Slider {...settings}>
            {dataCMS.Tiendas?.data?.map((store, index) => (
              <div
                key={index}
                className={`h-72 my-20 mx-5 ${
                  (index + 1) % 2 === 0 ? "pt-32" : ""
                }`}
              >
                <a
                  href={store.attributes?.caption || "#"}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-32 h-32 shadow-xl rounded-full bg-white flex items-center justify-center p-4 place-self-center transform transition hover:scale-110"
                >
                  <div className="mt-2">
                    <img
                      src={store.attributes?.url}
                      alt=""
                      className=" max-h-12"
                    />
                  </div>
                </a>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
}
