import { LocationMarkerIcon } from "@heroicons/react/solid";
import React from "react";

export default function DeliveryDetails({ packageInfo }) {
  return (
    <div className=" w-full mt-10 ">
      <p className="font-bold text-xl text-te-blue-800">Detalle de envío</p>

      <div className="border-2 border-te-gray-700 rounded-xl mt-5 flex flex-col md:flex-row">
        <div className="md:w-1/2 bg-te-gray-700 pl-5 md:pl-10 py-4">
          <p className="text-te-gray-800 text-lg font-semibold">Remitente</p>

          <div className="my-3 md:my-5 flex items-center text-te-blue-800 font-semibold text-lg">
            <LocationMarkerIcon className="text-te-blue-800 h-6 mr-3" />{" "}
            {packageInfo.NombreRemitente}
          </div>

          <p className="md:mt-5 text-te-gray-800 font-semibold">
            {packageInfo.DireccionRemitente}, {packageInfo.CiudadRemitente},{" "}
            {packageInfo.EstadoRemitente}
          </p>
        </div>
        <div className="md:w-1/2 py-4 pl-5 md:pl-10">
          <p className="text-te-gray-800 text-lg font-semibold">Destinatario</p>

          <div className="my-3 md:my-5 flex items-center text-te-blue-800 font-semibold text-lg">
            <LocationMarkerIcon className="text-te-blue-800 h-6 mr-3" />{" "}
            {packageInfo.NombreDestinatario}
          </div>

          <p className="md:mt-5 text-te-gray-800 font-semibold">
            {packageInfo.DireccionDestinatario},{" "}
            {packageInfo.CiudadDestinatario}
          </p>
        </div>
      </div>
    </div>
  );
}
