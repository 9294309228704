import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../contexts/auth";
import Spinner from "../../utils/Spinner";

export default function AddReferred({ getYourReferred, getManageReferrals }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");
    setIsCompleted();

    const body = {
      casillero: user.PoBox,
      nombreremite: user.Name || user.Company,
      nombrereferido: data.name,
      emailreferido: data.email,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/references`, body)
      .then((res) => {
        if (res.data.data && res.status === 200) {
          if (res.data.message === "Usuario ya esta referido") {
            setError("Este usuario ya ha sido referido.");
          } else {
            reset();
            setIsCompleted("¡Hemos recibido a tu referido!");
            getYourReferred();
            getManageReferrals();
          }
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="mt-4 md:mt-8 px-5 md:px-0">
      <span className=" text-te-blue-800 font-semibold">
        Persona que quieres referir
      </span>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-2 flex flex-col md:flex-row md:items-end">
        <div className="">
          <input
            type="text"
            className={`bg-te-gray-700 bg-opacity-50 py-2 px-3 border-2 rounded-lg placeholder-te-gray-800 focus:outline-none w-full md:w-64 font-semibold text-te-blue-800 ${
              errors.email ? "border-te-red-900" : "border-transparent"
            }`}
            {...register("name", { required: true })}
            placeholder="Nombre"
          />
          <input
            type="email"
            className={`inline-block md:ml-4 mt-3 bg-te-gray-700 bg-opacity-50 py-2 px-3 border-2  rounded-lg placeholder-te-gray-800 focus:outline-none w-full md:w-64 font-semibold text-te-blue-800 ${
              errors.email ? "border-te-red-900" : "border-transparent"
            }`}
            {...register("email", { required: true })}
            placeholder="Correo electrónico"
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="text-te-blue-800 border-2 border-te-blue-800 px-10 py-2 mt-3 md:mt-0 md:ml-4 rounded-lg font-semibold relative"
        >
          Referir
          {isLoading && (
            <div className="absolute right-1 top-0 h-full flex items-center">
              <Spinner color="#141c82" type="spinningBubbles" />
            </div>
          )}
        </button>
      </form>

      {isCompleted && (
        <div className=" text-te-green-700 mt-4">{isCompleted}</div>
      )}
      {error && <div className="text-te-red-900 mt-4">*{error}</div>}
    </div>
  );
}
