import React from "react";
import { Link } from "react-router-dom";
import ButtonBasic from "../buttons/Basic";

export default function MainAbout({ dataCMS }) {
  return (
    <section className="container flex flex-col lg:flex-row py-10 lg:py-20 2xl:px-20">
      <div className="lg:w-1/2 flex justify-center items-center">
        <div className="">
          <h3
            data-aos="fade-up"
            className=" font-bold text-te-blue-800 text-3xl lg:text-5xl whitespace-pre-line"
          >
            {dataCMS?.Titulo}
          </h3>
          <p
            data-aos="fade-up"
            className="text-te-gray-800 mt-5 max-w-lg lg:text-lg whitespace-pre-line text-justify"
          >
            {dataCMS?.Descripcion}
          </p>
          <div data-aos="fade-up" className="mt-8">
            <Link to="/register">
              <ButtonBasic
                classNameFather="bg-te-blue-800"
                className="bg-te-blue-900 text-white"
              >
                Crea tu casillero
              </ButtonBasic>
            </Link>
          </div>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="lg:w-1/2 flex justify-center mt-10 lg:mt-0"
      >
        <img
          src={dataCMS?.ImagenPrincipal?.data?.attributes?.url}
          alt=""
          className="w-full max-w-lg"
        />
      </div>
    </section>
  );
}
