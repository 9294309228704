import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import userI from "../../assets/images/icons/locker-user.svg";
import userGray from "../../assets/images/icons/locker-user-gray.svg";
import box from "../../assets/images/icons/locker-box.svg";
import boxGray from "../../assets/images/icons/locker-box-gray.svg";
import coupon from "../../assets/images/icons/locker-coupons.svg";
import couponGray from "../../assets/images/icons/locker-coupons-gray.svg";
import points from "../../assets/images/icons/locker-points.svg";
import pointsGray from "../../assets/images/icons/locker-points-gray.svg";
import refer from "../../assets/images/icons/refer.svg";
import referGray from "../../assets/images/icons/refer-gray.svg";
import React, { useState, Fragment, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";

const options = [
  {
    name: "Mi Casillero",
    icon: userI,
    iconGray: userGray,
    url: "/micasillero",
  },
  {
    name: "Mis Paquetes",
    icon: box,
    iconGray: boxGray,
    url: "/micasillero/packages",
  },
  {
    name: "Envíanos tu Factura",
    icon: coupon,
    iconGray: couponGray,
    url: "/micasillero/enviar-factura",
  },
  {
    name: "Puntos TE",
    icon: points,
    iconGray: pointsGray,
    url: "/micasillero/puntos",
  },
  {
    name: "Referir Amigos",
    icon: refer,
    iconGray: referGray,
    url: "/micasillero/referidos",
  },
  {
    forVip: true,
    name: "Estado de Cuenta",
    icon: coupon,
    iconGray: couponGray,
    url: "/micasillero/estado-cuenta",
  },
  {
    forVip: true,
    name: "Control de Peso",
    icon: coupon,
    iconGray: couponGray,
    url: "/micasillero/control-peso",
  },
  // },
  // {
  //   valido: true,
  //   name: "Actualización de Datos",
  //   icon: userI,
  //   iconGray: userGray,
  //   url: "/micasillero/actualizacion-datos",
  // },
  // {
  //   promotion: true,
  //   name: "Promoción",
  //   icon: points,
  //   iconGray: pointsGray,
  //   url: "/micasillero/promocion",
  // },
];

export default function DropdownMobile() {
  const [selected, setSelected] = useState(options[0]);
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/micasillero") {
      setSelected(options[0]);
    } else if (pathname === "/micasillero/packages") {
      setSelected(options[1]);
    } else if (pathname === "/micasillero/enviar-factura") {
      setSelected(options[2]);
    } else if (pathname === "/micasillero/puntos") {
      setSelected(options[3]);
    } else if (pathname === "/micasillero/referidos") {
      setSelected(options[4]);
    } else if (pathname === "/micasillero/estado-cuenta") {
      setSelected(options[5]);
    } else if (pathname === "/micasillero/control-peso") {
      setSelected(options[6]);
    }
    // } else if (pathname === "/micasillero/actualizacion-datos") {
    //   setSelected(options[7]);
    // } else if (pathname === "/micasillero/promocion") {
    //   setSelected(options[8]);
    // }
  }, [location]);

  return (
    <div className="lg:hidden mb-5">
      <div className="w-full">
        <Listbox value={selected} onChange={setSelected}>
          {({ open }) => (
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-te-blue-800 text-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                <div className="flex items-center">
                  <span className="block truncate w-8">
                    <img src={selected.icon} alt="" className="h-5" />
                  </span>
                  <span className="block truncate font-semibold">
                    {selected.name}
                  </span>
                </div>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <ChevronDownIcon
                    className={`w-5 h-5 text-white transform transition ${
                      open ? "rotate-180" : ""
                    }`}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-md max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option, personIdx) => {
                     if (option.valido && user.valido !== "N") {
                       return null;
                     }

                   // if (option.valido && user.PoBox !== "100") {
                   //   return null;
                   // }

                    // if (option.promotion && user.promocion !== "N") {
                    //   return null;
                    // }

                    // if (option.forVip && user.vip !== "S") {
                    //   return null;
                    // }

                    return (
                      <Listbox.Option
                        key={personIdx}
                        className={`${
                          option.name === selected.name ? "hidden" : ""
                        } cursor-default select-none relative py-2 pl-10 pr-4`}
                        value={option}
                      >
                        <Link to={option.url}>
                          <span
                            className={`block truncate font-semibold text-te-blue-800`}
                          >
                            {option.name}
                          </span>
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 w-8`}
                          >
                            <img
                              src={option.iconGray}
                              alt=""
                              className="h-5 filter grayscale"
                            />
                          </span>
                        </Link>
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
    </div>
  );
}
