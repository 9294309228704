import React, { useEffect, useState } from "react";
import details from "../../../assets/images/locker/details.svg";
import box from "../../../assets/images/icons/locker-box.svg";
import Spinner from "../../utils/Spinner";
import { formatMoney } from "accounting";
import { DateTime } from "luxon";
import axios from "axios";
import ShoppingMallPackagesDetailModal from "./ShoppingMallPackageDetailModal";

export default function ShoppingMallPackages({ isLoading, packages, error }) {
  return (
    <div className="mt-5 border-2 border-te-gray-700 rounded-xl mb-20">
      {isLoading && (
        <div className="py-10 flex justify-center">
          {" "}
          <Spinner color="#141c82" type="spinningBubbles" />{" "}
        </div>
      )}

      {!isLoading && error && (
        <span className="block text-center py-10">{error}</span>
      )}

      {!isLoading && !!packages.length && (
        <div className="w-full">
          <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold">
            <div className="flex-1 text-center">Guía</div>
            <div className="flex-1 text-center">valor</div>
            <div className="flex-1 text-center">fecha de compra</div>
            <div className="flex-1 text-center">Detalle</div>
          </div>

          <div className="w-full space-y-4 p-3">
            {packages.map((value, index) => (
              <PackageRow data={value} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const PackageRow = ({ data }) => {
  const [, setIsLoading] = useState(false);
  const [packageInfo, setPackageInfo] = useState({});
  const [, setError] = useState("");

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/services/pedido_detalle/${data.id_compra}`,
        {
          headers: {
            Authorization: `Basic ${process.env.REACT_APP_SHOPPING_MALL_TOKEN}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setPackageInfo(res.data.data?.items);
        } else {
          setError("No existen datos de paquetes.");
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {};
  }, [data]);

  return (
    <>
      <div className="flex bg-te-gray-700 bg-opacity-50 rounded-lg">
        <div className="flex-1 py-3 flex">
          <div className="ml-5">
            <img src={box} alt="" className="h-7" />
          </div>
          <div className="ml-3">
            <span className="block text-sm font-semibold text-te-blue-800">
              {packageInfo[0]?.guia_miami}
            </span>
            <span className="mt-1 inline-block bg-te-blue-800 text-white rounded-r-full rounded-bl-full px-3 py-1 text-xs font-semibold">
              Comprado
            </span>
          </div>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <div className="bg-white rounded-lg w-40 p-2 flex items-center justify-between text-sm">
            <span className="text-te-blue-800 font-semibold">
              {formatMoney(data.total_compra)}
            </span>
            <span className="text-te-gray-800">USD</span>
          </div>
        </div>
        <div className="flex-1 flex items-center justify-center">
          <div className="text-te-gray-800 font-semibold text-sm">
            {/* 22 May 2021 */}
            {DateTime.fromISO(data.fecha_creacion.replace(/ /g, "T")).toFormat(
              "dd-MM-yyyy"
            )}
          </div>
        </div>
        <div className="flex-1 flex items-center">
          <img
            onClick={() => setOpenModal(true)}
            src={details}
            alt=""
            className=" mx-auto h-8 cursor-pointer"
          />
        </div>
      </div>

      <ShoppingMallPackagesDetailModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={packageInfo}
      />
    </>
  );
};
