import { ArrowRightIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import icon1 from "../../assets/images/home/icon1.png";
import icon2 from "../../assets/images/home/icon2.png";
import icon3 from "../../assets/images/home/icon3.png";
import ilu1 from "../../assets/images/home/ilu1.png";
import ilu2 from "../../assets/images/home/ilu2.png";
import ilu3 from "../../assets/images/home/ilu3.png";

export default function Benefits({ dataCMS }) {
  const [selected, setSelected] = useState(0);

  return (
    <section className="bg-white">
      <div className="py-10 lg:py-16">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-2xl lg:text-3xl font-semibold text-center container"
        >
          {dataCMS?.TituloBeneficios}
        </h3>
        <p
          data-aos="fade-up"
          className="text-te-gray-800 lg:text-lg text-center max-w-3xl mx-auto mt-4 container"
        >
          {dataCMS?.DescripcionBeneficios}
        </p>

        <div className="mt-16 container flex justify-center">
          <div
            data-aos="fade-up"
            className="lg:w-1/2 flex flex-col items-center justify-center space-y-10"
          >
            <BenefitCard
              icon={icon1}
              title={dataCMS?.subtituloBeneficio1}
              active={selected === 0}
              onClick={() => setSelected(0)}
            />
            <BenefitCard
              icon={icon2}
              title={dataCMS?.subtituloBeneficio2}
              active={selected === 1}
              onClick={() => setSelected(1)}
            />
            <BenefitCard
              icon={icon3}
              title={dataCMS?.subtituloBeneficio3}
              active={selected === 2}
              onClick={() => setSelected(2)}
            />
          </div>
          <div className="w-1/2 justify-center items-center hidden lg:flex">
            <div data-aos="fade-up" className="max-w-xl">
              {selected === 0 && (
                <img data-aos="zoom-in" src={ilu1} alt="" className="w-full" />
              )}
              {selected === 1 && (
                <img data-aos="zoom-in" src={ilu2} alt="" className="w-full" />
              )}
              {selected === 2 && (
                <img data-aos="zoom-in" src={ilu3} alt="" className="w-full" />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const BenefitCard = ({ icon, title, active, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center p-6 w-full max-w-lg relative rounded-2xl transition cursor-pointer ${
        active ? "bg-te-blue-100" : "bg-te-gray-500"
      }`}
    >
      <div className=" w-20 lg:w-24 flex-shrink-0">
        <img src={icon} alt="" className="lg:ml-5" />
      </div>

      <p className=" text-te-blue-900 text-xl lg:text-2xl font-bold ml-10 lg:ml-16 lg:w-56">
        {title}
      </p>

      <div
        className={`absolute right-0 -mr-7 shadow-lg rounded-full h-14 w-14 items-center justify-center transition hidden lg:flex ${
          active ? "bg-te-orange-400 text-white" : "bg-white text-te-blue-900"
        }`}
      >
        <ArrowRightIcon className="h-5" />
      </div>
    </div>
  );
};
