import React, { Fragment, useRef, useState } from "react";
import { useAuth } from "../../../contexts/auth";
import blank from "../../../assets/images/blank.png";
import { PencilIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import EditAddressForm from "./EditAddressForm";
import axios from "axios";

export default function DeliveryAddress() {
  const { user, setUser } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const deleteAddress = () => {
    setIsDeleting(true);
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/address`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }, {
        data: { codigo: user.codigo },
      })
      .then((res) => {
        if (res.status === 200) {
          const newUser = { ...user };
          newUser.domi1 = null;
          newUser.domi2 = null;
          newUser.domiciudad = null;
          newUser.CobroPorEntrega = "N";

          localStorage.setItem("te-user", JSON.stringify(newUser));
          setUser(newUser);
          setOpenDeleteModal(false);
          setSuccess("Domicilio eliminado correctamente");
        } else {
          setError("No se pudo eliminar el domicilio");
        }
      })
      .catch(() => {
        setError("No se pudo eliminar el domicilio");
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <div className="mt-5 border-2 border-te-gray-700 rounded-xl p-6 mb-20">
      <div className="pb-8 pt-4 border-b-2">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div className="rounded-full overflow-hidden w-16 h-16 mx-3">
              <img src={blank} alt="" className="h-full w-full object-cover" />
            </div>
            <div className="flex flex-col items-start font-semibold mr-3 text-xl">
              <span className=" text-te-blue-800">
                {user?.Name || user?.Company}
              </span>
              <span className=" text-te-orange-400">{user?.PoBox}</span>
            </div>
          </div>
          {user.Domicilio !== "S" && (
            <>
              {user.CobroPorEntrega !== "S" && (
                <>
                  {!user.domi1 && (
                    <div className="">
                      <button
                        onClick={() => {
                          setIsEdit(false);
                          setOpenModal(true);
                        }}
                        className=" text-white bg-te-blue-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                      >
                        Agregar dirección{" "}
                        <PlusIcon className="text-white h-5 ml-2" />
                      </button>
                    </div>
                  )}
                </>
              )}
              {user.CobroPorEntrega !== "N" && (
                <>
                  {user.domi1 && !showForm && (
                    <div className="flex space-x-5">
                      <div className="">
                        <button
                          onClick={() => setOpenDeleteModal(true)}
                          disabled={isDeleting}
                          className=" text-white bg-te-gray-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                        >
                          Eliminar <TrashIcon className="text-white h-5 ml-2" />
                        </button>
                      </div>
                      <div className="">
                        <button
                          onClick={() => {
                            setIsEdit(true);
                            setShowForm(true);
                          }}
                          disabled={isDeleting}
                          className=" text-white bg-te-blue-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                        >
                          Editar <PencilIcon className="text-white h-5 ml-2" />
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {showForm && (
        <EditAddressForm setShowForm={setShowForm} isEdit={isEdit} />
      )}

      {user.domi1 && !showForm && (
        <div className="mt-5">
          <div className="">
            <label
              htmlFor="email"
              className=" font-semibold text-te-gray-800 text-sm"
            >
              Dirección de entrega
            </label>
            <span
              id="text"
              className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-gray-800"
            >
              {user.domi1 || "-"}
            </span>
            <span
              id="text"
              className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-gray-800"
            >
              {user.domi2 || "-"}
            </span>
          </div>

          <div className="flex space-x-5">
            <div className="w-1/2">
              <label
                htmlFor="ciudad"
                className="block font-semibold text-te-gray-800 text-sm mt-3"
              >
                Municipio
              </label>
              <span
                id="ciudad"
                className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-gray-800"
              >
                {user.domiciudad || "-"}
              </span>
            </div>
            <div className="w-1/2">
              <label
                htmlFor="departamento"
                className="block font-semibold text-te-gray-800 text-sm mt-3"
              >
                Departamento
              </label>
              <span
                id="departamento"
                className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-gray-800"
              >
                {user.domipais || "-"}
              </span>
            </div>
          </div>
          {error && (
            <div className="mt-3 text-te-red-900 font-semibold">*{error}</div>
          )}
        </div>
      )}

      {success && (
        <div className="flex justify-end">
          <div className="mt-3 text-te-green-700 font-semibold">*{success}</div>
        </div>
      )}

      {openModal && (
        <Modal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          accept={() => {
            setShowForm(true);
            setOpenModal(false);
            setSuccess("");
          }}
        />
      )}
      {openDeleteModal && (
        <DeleteModal
          openModal={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          deleteAddress={deleteAddress}
        />
      )}
    </div>
  );
}

const DeleteModal = ({ openModal, closeModal, deleteAddress }) => {
  let closeButtonRef = useRef(null);

  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 text-left align-middle transition-all transform">
              <div className="bg-white p-8 rounded-lg">
                <p className=" text-te-blue-800 font-semibold text-xl">
                  ¿Seguro que quieres eliminar el domicilio?
                </p>
                <div className="flex justify-end mt-5 space-x-5">
                  <button
                    onClick={closeModal}
                    className=" text-white bg-te-gray-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={deleteAddress}
                    className=" text-white bg-te-blue-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

const Modal = ({ openModal, closeModal, accept }) => {
  let closeButtonRef = useRef(null);

  return (
    <Transition appear show={openModal} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 text-left align-middle transition-all transform">
              <div className="bg-white p-8 rounded-lg">
                <p className=" text-te-blue-800 font-semibold text-xl">
                  Las entregas a domicilio, tendrán un cargo adicional
                </p>
                <div className="flex justify-end mt-4 space-x-5">
                  <button
                    onClick={closeModal}
                    className=" text-white bg-te-gray-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={accept}
                    className=" text-white bg-te-blue-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
