import React, { useEffect, useState } from "react";
import status1 from "../../assets/images/tracking/1.svg";
import status2 from "../../assets/images/tracking/2.svg";
import status3 from "../../assets/images/tracking/3.svg";
import status4 from "../../assets/images/tracking/4.svg";
import status5 from "../../assets/images/tracking/5.svg";
import status61 from "../../assets/images/tracking/61.svg";
// import status62 from "../../assets/images/tracking/62.svg";
import status7 from "../../assets/images/tracking/7.svg";

export default function StatusBar({ status }) {
  const [position, setPosition] = useState({});

  const options = [
    {
      name: "miami",
      percent: "0",
      step: 1,
    },
    {
      name: "retained",
      percent: "16",
      step: 2,
    },
    {
      name: "dispatched",
      percent: "32",
      step: 3,
    },
    {
      name: "customs",
      percent: "48",
      step: 4,
    },
    {
      name: "warehouse",
      percent: "64",
      step: 5,
    },
    {
      name: "delivery",
      percent: "82",
      step: 6,
    },
    {
      name: "delivered",
      percent: "100",
      step: 7,
    },
  ];

  const getStatus = (status) => {
    if (status.entregado === "S") {
      return "delivered";
    } else if (status.domicilio === "S") {
      return "delivery";
    } else if (status.bodega === "S") {
      return "warehouse";
    } else if (status.aduana === "S") {
      return "customs";
    } else if (status.Despachado === "S") {
      return "dispatched";
    } else if (status.Retenido === "S") {
      return "retained";
    } else if (status.Miami === "S") {
      return "miami";
    }
  };

  useEffect(() => {
    const value = options.find((value) => value.name === getStatus(status));
    setPosition(value);
    // eslint-disable-next-line
  }, [status]);

  return (
    <div className="bg-te-gray-700 rounded-2xl mt-10 relative pt-10 px-10 pb-10 md:pb-16 h-full">
      <div className="w-full h-1 bg-te-gray-400 rounded-full relative my-5 hidden md:block">
        <div
          className="absolute h-full bg-te-blue-800"
          style={{
            width: `${position?.percent}%`,
          }}
        ></div>
      </div>

      <div className="relative h-full">
        <div className="flex h-full min-h-full w-1 bg-te-gray-400 rounded-full absolute overflow-hidden md:hidden ml-7">
          <div
            className="absolute w-full inset-y-0 bg-te-blue-800"
            style={{
              height: `${position?.percent}%`,
            }}
          ></div>
        </div>
        <div className="flex flex-col md:flex-row justify-between w-full md:absolute  transform md:-translate-y-12 space-y-6 md:space-y-0 ml-0.5 md:ml-0">
          <div className="md:-ml-1 flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 1 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status1} alt="" className="h-6" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-1">
              En Miami
            </span>
          </div>
          <div className="flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 2 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status2} alt="" className="h-6" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-1">
              Retenido
            </span>
          </div>
          <div className="flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 3 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status3} alt="" className="h-6" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-3">
              Despachado
            </span>
          </div>
          <div className="flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 4 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status4} alt="" className="h-6" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-2">
              En aduana
            </span>
          </div>
          <div className="flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 5 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status5} alt="" className="h-6" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-2">
              En bodega
            </span>
          </div>
          <div className="flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 6 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status61} alt="" className="h-5" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-1">
              Domicilio
            </span>
          </div>
          <div className="-mr-3 flex md:block items-center">
            <div
              className={`h-14 w-14 flex items-center justify-center rounded-full  ${
                position?.step >= 7 ? "bg-te-blue-800" : "bg-white"
              }`}
            >
              <div>
                <img src={status7} alt="" className="h-6" />
              </div>
            </div>
            <span className=" text-te-blue-800 font-semibold block mt-1 ml-5 md:-ml-2">
              Entregado
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
