import { ArrowRightIcon, CheckIcon } from "@heroicons/react/solid";
import React from "react";
import referred from "../../assets/images/locker/referred.png";
import AddNewReferred from "./AddNewReferred";

export default function Content() {
  return (
    <div className="container py-12">
      <div className=" max-w-6xl mx-auto">
        <h1 className="text-te-blue-800 text-2xl md:text-3xl font-bold">
          Referir amigos
        </h1>
        <div className="mt-10 flex justify-center">
          <div className="">
            <img src={referred} alt="" className="hidden md:inline" />
          </div>
          <img src={referred} alt="" className="md:hidden mx-auto" />
        </div>

        <AddNewReferred />
        
        <div className="space-y-1 bg-te-gray-600 rounded-xl p-1 lg:p-4 mt-10 inline-block w-full">
          <p className=" text-te-blue-800 font-semibold mb-4">
            Toma en cuenta lo siguiente:
          </p>
          <ul className="space-y-2">
            {[
              "El Programa de Referidos de Trans Express consiste en otorgar beneficios a nuestros usuarios que nos refieran amigos y que estos aperturen un casillero y transporten paquetes.",
              "La persona que refiere debe poseer un casillero vigente y activo, y el que recibe la invitación debe registrarse con un correo electrónico que no esté registrado previamente en nuestra base de datos.",
              "El beneficio consiste en que el que refiere, acumulara $10 dólares en su cuenta de puntos por cada referido que cree su casillero y lo active transportando su primer paquete, el referido recibirá $10 dólares al momento de crear el casillero y los mismos pueden consultarse en las cuentas del programa de puntos, puede acceder a la misma desde su cuenta privada en la página de Trans Express. ",
              "Los puntos equivalentes a los $10 deberán ser canjeados por certificados de dinero en Mi Cuenta. Al canjearlos recibirá un email en el correo que tiene registrado el titular de la cuenta, con el monto solicitado.",
            ].map((value, index) => (
              <li
                key={index}
                className=" text-te-gray-800 flex items-start text-sm lg:text-base"
              >
                <CheckIcon className=" text-te-orange-600 h-5 mr-2 flex-shrink-0 mt-1" />{" "}
                {value}
              </li>
            ))}
          </ul>
        </div>
        <div className=" text-te-blue-800 md:text-lg text-center mt-10 flex items-center justify-center font-semibold mb-20 underline">
          Consulta los términos y condiciones{" "}
          <ArrowRightIcon className="ml-3 h-5 text-te-orange-400 hidden lg:inline" />
        </div>
      </div>
    </div>
  );
}
