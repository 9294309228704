import axios from "axios";
import React, { useEffect, useState } from "react";
import Footer from "../../components/footer";
import LoginForm from "../../components/login/LoginForm";
import Seo from "../../components/utils/Seo";
import SimpleLayout from "../../layouts/simple";

export default function Login() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/iniciar-sesion?populate[Seo][populate]=*`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <>
      <Seo data={content.Seo} />

      <SimpleLayout>
        <div className="h-full flex items-center justify-center">
          <LoginForm />
        </div>
      </SimpleLayout>
      <Footer />
    </>
  );
}
