import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useAuth } from "../../contexts/auth";
import Spinner from "../utils/Spinner";
import CourierResult from "./CourierResult";
import TrackingResult from "./TrackingResult";
import { useHistory, useLocation } from "react-router-dom";
import UnknownResult from "./UnknownResult";

export default function Content() {
  const [packageInfo, setPackageInfo] = useState({});
  const [courierInfo, setCourierInfo] = useState({});
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { IdPackage } = useParams();
  const { user } = useAuth();
  const history = useHistory();
  const location = useLocation();

  const [isCourier, setIsCourier] = useState(false);
  const [isUnknown, setIsUnknown] = useState(false);
  const [isNormal, setIsNormal] = useState(false);

  useEffect(() => {
    setPackageInfo({});
    setCourierInfo({});
    setError("");
    setIsLoading(true);
    setIsCourier(false);
    setIsUnknown(false);
    setIsNormal(false);

    axios
      .get(
        `${
          process.env.REACT_APP_BASE_URL
        }/packages/${IdPackage.trim()}/trackings`
      )
      .then((res) => {
        if (res.data.data && !res.data.error) {
          if (res.data.data.Desconocido === "S") {
            setIsUnknown(true);

            if (!user) {
              /// enviar usuario a hacer login y regresar.
              history.replace({
                pathname: "/login",
                state: { from: location },
              });
            } else {
              /// Muestra la info del paquete desconocido
              if (res.data.data?.length) {
                setPackageInfo(res.data.data[0]);
              } else {
                setPackageInfo(res.data.data);
              }

              return;
            }
          }

          if (
            res.data.data.Desconocido === "N" &&
            res.data.data.Guia_x0020_TE === "GuiaCourier"
          ) {
            setIsCourier(true);
            if (res.data.data?.length) {
              setPackageInfo(res.data.data[0]);
            } else {
              setPackageInfo(res.data.data);
            }
            if (!res.data.data.Tracking) {
              axios
                .get(
                  `${
                    process.env.REACT_APP_BASE_URL
                  }/packages/${IdPackage.trim()}/courier`
                )
                .then((res) => {
                  if (res.data.data && !res.data.error) {
                    setCourierInfo(res.data);
                    setIsLoading(false);
                    return;
                  } else {
                    setError("No hay información de este paquete.");
                    setIsLoading(false);
                    return;
                  }
                });
              return;
            }
            if (res.data.data.Guia_x0020_TE === "Guia o Tracking no Existe") {
              setError("El paquete no ha sido reconocido.");
              return;
            }
          }

          setIsNormal(true);

          if (res.data.data.Guia_x0020_TE === "Guia o Tracking no Existe") {
            setError("No hay información de este paquete.");
            return;
          }

          if (res.data.data?.length) {
            setPackageInfo(res.data.data[0]);
          } else {
            setPackageInfo(res.data.data);
          }
        } else {
          setIsUnknown(true);
          setError("No hay información de este paquete.");
        }
      })
      .catch(() => {
        setIsUnknown(true);
        setError("No hay información de este paquete.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [IdPackage, history, location, user]);

  return (
    <>
      {!isLoading && error && (
        <div className="mt-10 text-center text-te-blue-800 font-semibold text-3xl">
          {error}
        </div>
      )}

      {!isLoading && !error && (
        <>
          {isNormal && <TrackingResult packageInfo={packageInfo} />}

          {isCourier && (
            <CourierResult
              courierInfo={courierInfo}
              packageInfo={packageInfo}
            />
          )}

          {isUnknown && (
            <UnknownResult
              courierInfo={courierInfo}
              packageInfo={packageInfo}
              tracking={IdPackage.trim()}
            />
          )}
        </>
      )}

      {isLoading && (
        <div className="py-10 flex justify-center">
          <Spinner color="#141c82" type="spinningBubbles" />
        </div>
      )}
    </>
  );
}
