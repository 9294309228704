import React from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import Content from "../../components/tracking/Content";
import Seo from "../../components/utils/Seo";

export default function Tracking() {
  return (
    <main>
      <Seo
        data={{
          keywords: null,
          metaDescription: "Global e-Commerce Logistics",
          metaTitle: "Trans-Express El Salvador",
          preventIndexing: false,
        }}
      />

      <div className="min-h-screen">
        <Navbar />
        <Content />
      </div>
      <Footer />
    </main>
  );
}
