import axios from "axios";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/auth";
import { formatCurrency } from "../../../services/numbers";
import Spinner from "../../utils/Spinner";

export default function AccountState() {
  const [error, setError] = useState("");
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [saldoAnterior, setSaldoAnterior] = useState("0.00");
  const { user } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    getStates();
    // eslint-disable-next-line
  }, []);

  const getStates = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/users/${user.codigo}/states-total`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        let saldoCalculado = res.data.data.saldoanterior;
        setSaldoAnterior(res.data.data.saldoanterior);
        axios
          .get(
            `${process.env.REACT_APP_BASE_URL}/users/${user.codigo}/states`,
            {
              headers: {
                Authorization: `Bearer ${user.token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.data?.length) {
              let newArray = res.data.data;

              res.data.data.forEach((element) => {
                let calculo =
                  +saldoCalculado - +element.valorabono + +element.valorcargo;
                element.saldo = calculo;
                saldoCalculado = calculo;
              });

              setStates(newArray);
            } else {
              setError("No existen registros en su estado de cuenta.");
            }
          })
          .catch(() => {
            setError("El servicio no está disponible.");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
  };

  return (
    <div className="flex-1 pb-20">
      <h1 className="text-te-blue-800 text-3xl font-bold">Estado de Cuenta</h1>
      {isLoading && (
        <div className="py-10 flex justify-center">
          {" "}
          <Spinner color="#141c82" type="spinningBubbles" />{" "}
        </div>
      )}

      {!isLoading && error && (
        <span className="block text-center py-10 text-te-blue-800 text-lg">
          {error}
        </span>
      )}

      {!isLoading && !!states.length && (
        <div className="">
          <div className=" bg-white shadow-lg border-2 border-te-gray-700 mt-10 rounded-lg p-5">
            <p className=" text-te-gray-800 font-semibold">
              Estado de cuenta del casillero:{" "}
              <span className=" text-te-blue-800">{user.PoBox}</span>
            </p>
            <p className=" text-te-gray-800 font-semibold mt-6">
              <span className="w-24 inline-block">Nombre:</span>{" "}
              <span className=" text-te-blue-800">
                {user.Name || user.Company}
              </span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-24 inline-block">Correo:</span>{" "}
              <span className=" text-te-blue-800">{user.Correo}</span>
            </p>
          </div>

          <div className="bg-te-blue-100 mt-5 rounded-lg p-4 flex justify-end">
            <p className=" font-semibold text-te-gray-800 text-lg">
              Saldo Anterior:{" "}
              <span className=" text-te-blue-800">
                {formatCurrency(saldoAnterior)}
              </span>
            </p>
          </div>

          <div className="w-full mt-5 hidden md:block">
            <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold">
              <div className="w-52 text-center">Fecha Emisión</div>
              <div className="flex-1 text-center">Descripción</div>
              <div className="w-28 text-center">Cargo</div>
              <div className="w-28 text-center">Abono</div>
              <div className="w-28 text-center">Saldo</div>
            </div>
            <div className="w-full space-y-4 p-3">
              {states.length &&
                states.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className="flex bg-te-gray-700 bg-opacity-50 rounded-lg"
                    >
                      <div className="w-52 py-3 flex justify-center">
                        {DateTime.fromISO(
                          value.fecha.replace(/ /g, "T")
                        ).toFormat("dd-MM-yyyy")}
                      </div>
                      <div className="flex-1 flex items-center text-sm">
                        {value.tta_Descripcion} - {value.concepto}
                        {(value.tta_Descripcion === "Pago FF" ||
                          value.tta_Descripcion === "Nota  de Credito") &&
                          "Pago Recibido"}
                      </div>
                      <div className="w-28 flex items-center justify-center text-sm">
                        {formatCurrency(value.valorcargo) || "-"}
                      </div>
                      <div className="w-28 flex items-center justify-center text-sm">
                        {formatCurrency(value.valorabono) || "-"}
                      </div>
                      <div className="w-28 flex items-center justify-center text-sm">
                        {formatCurrency(value.saldo) || "-"}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="w-full mt-5 md:hidden pb-10">
            <div className="w-full space-y-4">
              {states.length &&
                states.map((value, index) => (
                  <div
                    key={index}
                    className="flex flex-col bg-te-gray-700 bg-opacity-50 rounded-lg p-3 text-te-blue-800"
                  >
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Fecha Emisión:
                      </span>{" "}
                      {DateTime.fromISO(
                        value.fecha.replace(/ /g, "T")
                      ).toFormat("dd-MM-yyyy")}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Descripción:
                      </span>{" "}
                      {value.tta_Descripcion} - {value.concepto}
                      {(value.tta_Descripcion === "Pago FF" ||
                        value.tta_Descripcion === "Nota  de Credito") &&
                        "Pago Recibido"}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Cargo:
                      </span>{" "}
                      {formatCurrency(value.valorcargo) || "-"}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Abono:
                      </span>{" "}
                      {formatCurrency(value.valorabono) || "-"}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Saldo:
                      </span>{" "}
                      {formatCurrency(value.saldo) || "-"}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <p className="mt-5 font-semibold text-te-blue-800">
            NOTA: Este detalle solo comprende los últimos 90 dias de
            facturación.
          </p>
        </div>
      )}
    </div>
  );
}
