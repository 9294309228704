import React from "react";
import ReactMarkdown from "react-markdown";
import pdf from "./assets/terminos_y_condiciones_de_uso_de_sitio_y_servicios.pdf";

export default function Content({ data }) {
  const DownloadButton = () => {
    const handleDownload = () => {
      const downloadLink = document.createElement("a");
      downloadLink.href = pdf; // Ruta relativa al archivo PDF
      downloadLink.download =
        "Términos y condiciones de uso de sitio y servicios.pdf"; // Nombre del archivo a descargar
      downloadLink.click();
    };

    return (
      <button
        className="inline-flex rounded-lg bg-te-blue-800"
        onClick={handleDownload}
      >
        <span className="bg-te-blue-900 text-white py-3 px-4 rounded-lg text-sm w-full focus:outline-none font-semibold transition transform hover:-translate-y-1 active:translate-y-0">
          Descargar Términos y condiciones
        </span>
      </button>
    );
  };

  return (
    <div>
      <section className="lg:container">
        <div className="lg:mt-10 h-64 lg:h-96 lg:rounded-2xl overflow-hidden relative">
          <div className="absolute h-full w-full bg-te-gray-700"></div>
          <div className="absolute w-full h-full flex flex-col items-center justify-center">
            <h1
              data-aos="fade-up"
              className=" text-te-blue-800 relative text-2xl lg:text-3xl font-bold"
            >
              Términos y Condiciones
            </h1>
          </div>
        </div>
      </section>

      <section className="text-te-gray-800 container xl:px-32 mb-20">
        <div data-aos="fade-up" className="mt-16 prose lg:prose-lg max-w-none">
          <DownloadButton />
          <ReactMarkdown>{data.Contenido}</ReactMarkdown>
        </div>
      </section>
    </div>
  );
}
