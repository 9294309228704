import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import brand from "../../assets/images/brand.svg";
import Dropdown from "./DropDown";
import menuIcon from "../../assets/images/icons/menuIcon.svg";
import { ArrowRightIcon, XIcon } from "@heroicons/react/solid";
import ButtonBasic from "../buttons/Basic";
import DropdownUser from "./DropdownUser";
import { useAuth } from "../../contexts/auth";
import SidebarNavigation from "./SidebarNavigation";
import TrackingBar from "./TrackingBar";
import blank from "../../assets/images/blank.png";
import shopingMallImg from "../../assets/images/BotonShoppingMall.png";
import MobileButtons from "./MobileButtons";
import { useNav } from "../../contexts/Navigation";
import flag from "../../assets/images/svflag.png";
import "./assets/navbar.css";

export default function Navbar() {
  const [promo, setPromo] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();
  const { user, signout } = useAuth();
  const { navigation } = useNav();

  useEffect(() => {
    if (user && !user?.PoBox) {
      history.push("/");
      signout();
    }
    // eslint-disable-next-line
  }, []);

  const getNavComponent = (navItem, index) => {
    if (navItem.TituloDropDown) {
      return <Dropdown key={index} content={navItem} />;
    }

    return (
      <li key={index}>
        <NavLink
          to={navItem.Enlace}
          activeClassName="bg-te-blue-100 text-te-blue-800"
          className="rounded-lg px-2 py-1 hover:bg-te-blue-100 hover:text-te-blue-800 inline-flex text-sm"
        >
          {navItem.Etiqueta}
        </NavLink>
      </li>
    );
  };

  return (
    <div className="top-0 sticky z-40">
      {promo && (
        <div className="bg-te-blue-900">
          <div className="container relative flex justify-center items-center text-sm h-10 ">
            <Link
              to="/micasillero/referidos"
              className="text-white font-semibold lg:hidden"
            >
              !Gana $10! Refiere a un amigo ahora
            </Link>
            <span className="text-white font-semibold hidden lg:inline">
              ¡Ya puedes referir a tus amigos! Gana $10
            </span>
            <Link
              to="/micasillero/referidos"
              className="text-te-orange-400 mx-3 font-bold hidden lg:inline"
            >
              Referir
            </Link>
            <Link
              to="/micasillero/referidos"
              className="text-te-orange-400 mx-3 font-bold lg:hidden"
            >
              <ArrowRightIcon className="h-4" />
            </Link>
            <button
              onClick={() => {
                setPromo(false);
              }}
              className="cursor-pointer absolute right-4"
            >
              <XIcon className="h-5 text-white" />
            </button>
          </div>
        </div>
      )}

      <div className=" bg-white border-b border-te-gray-700">
        <div className="container flex items-center justify-between h-16 lg:h-24">
          <div className="">
            <Link to="/">
              <img src={brand} alt="" className="h-8 lg:h-10" />
            </Link>
          </div>
          <div className="hidden lg:flex">
            <nav>
              <ul className="flex items-center h-full 2xl:space-x-3 font-semibold text-te-gray-800">
                {navigation?.Navegacion?.map((navItem, index) =>
                  getNavComponent(navItem, index)
                )}

                <li
                  activeClassName="bg-te-blue-100 text-te-blue-800"
                  className="rounded-lg px-2 py-1 hover:bg-te-blue-100 hover:text-te-blue-800 inline-flex text-sm cursor-pointer"
                  onClick={() => {
                    user?.autologinUrl
                      ? window.location.replace(user.autologinUrl)
                      : window.location.replace(
                          process.env.REACT_APP_SHOPPING_MALL_BASE_URL
                        );
                  }}
                >
                  <img src={shopingMallImg}
                       alt="Ingresa a Shopping Mall para hacer compras todo incluido"
                       className="h-10 rounded"
                  />
                </li>
              </ul>
            </nav>
          </div>

          {!user ? (
            <div className="items-center space-x-5 hidden xl:flex">
              <img
                src={flag}
                alt="Bandera de El Salvador"
                className="h-10 rounded"
              />
              <Link
                to="/login"
                className="text-te-blue-800 font-semibold text-sm margin-nivekino"
              >
                Iniciar Sesión
              </Link>
              <ButtonBasic
                onClick={() => history.push("/register")}
                classNameFather="bg-te-orange-600"
                className="bg-gradient-to-r from-te-orange-400 to-te-orange-600 text-white text-sm"
              >
                {" "}
                Regístrate Gratis
              </ButtonBasic>
            </div>
          ) : (
            <DropdownUser />
          )}

          {!openMenu ? (
            <div onClick={() => setOpenMenu(true)} className="lg:hidden">
              {!user ? (
                <div className="flex items-center">
                  <img
                    src={flag}
                    alt="Bandera de El Salvador"
                    className="h-8 mr-3 rounded"
                  />
                  <img src={menuIcon} alt="" />
                </div>
              ) : (
                <div className="flex items-center">
                  <img
                    src={flag}
                    alt="Bandera de El Salvador"
                    className="h-8 mr-3 rounded"
                  />
                  <span className="inline text-te-gray-800 font-semibold">
                    Mi Cuenta
                  </span>
                  <div className="rounded-full overflow-hidden w-10 h-10 ml-3">
                    <img
                      src={blank}
                      alt=""
                      className="h-full w-full object-cover"
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div onClick={() => setOpenMenu(false)} className="lg:hidden">
              <XIcon className=" text-te-blue-800 h-8" />
            </div>
          )}
        </div>
      </div>

      <TrackingBar />

      <SidebarNavigation open={openMenu} setOpen={setOpenMenu} />

      <MobileButtons />
    </div>
  );
}
