import { ChevronDownIcon, LogoutIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as Facebook } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as Whatsapp } from "../../assets/images/icons/whatsapp.svg";
import { Disclosure, Transition } from "@headlessui/react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import ButtonBasic from "../buttons/Basic";
import { useAuth } from "../../contexts/auth";
import blank from "../../assets/images/blank.png";
import { useNav } from "../../contexts/Navigation";
import axios from "axios";

export default function SidebarNavigation({ open, setOpen }) {
  const auth = useAuth();
  const { navigation } = useNav();

  const { user, signout } = auth;
  const history = useHistory();

  open ? disableBodyScroll(document) : enableBodyScroll(document);

  useEffect(() => {
    window.scroll(0, 0);

    return () => {
      enableBodyScroll(document);
    };
  }, [open]);

  async function remoteLogout() {
    await axios.get(
      `${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/login/logout`,
      { withCredentials: true }
    );
  }

  const getNavComponent = (navItem, index) => {
    if (navItem.TituloDropDown) {
      return (
        <li key={index} className="border-b border-te-gray-700 p-5">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full justify-between items-center text-te-blue-800 font-semibold text-xl">
                  {navItem.TituloDropDown}{" "}
                  <ChevronDownIcon
                    className={`h-8 text-te-orange-400 transition transform ${
                      open ? "rotate-180" : ""
                    }`}
                  />
                </Disclosure.Button>

                <Transition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel static>
                    {navItem.OpcionesDropDown.map((navItem, index) => (
                      <div key={index}>
                        <Link
                          to={navItem.Enlace}
                          className="text-te-blue-800 inline-block mt-4"
                        >
                          {navItem.Etiqueta}
                        </Link>
                      </div>
                    ))}
                  </Disclosure.Panel>
                </Transition>
              </>
            )}
          </Disclosure>
        </li>
      );
    }

    return (
      <li key={index} className="border-b border-te-gray-700 p-5">
        <Link
          to={navItem.Enlace}
          className="flex justify-between items-center text-te-blue-800 font-semibold text-xl"
        >
          {navItem.Etiqueta}
        </Link>
      </li>
    );
  };

  return (
    <aside
      className={`transform right-0 top-26 w-screen h-full min-h-screen overflow-y-scroll bg-white fixed z-50 transition-all duration-300 lg:hidden pb-28 ${
        open ? "translate-x-0" : "translate-x-full"
      }`}
    >
      {auth.user && (
        <div className=" bg-te-blue-100 bg-opacity-30 rounded-lg mt-3 mx-3 p-3">
          <div className="flex items-center">
            <div className=" rounded-full overflow-hidden w-12 h-12 mr-3">
              <img src={blank} alt="" className="h-full w-full object-cover" />
            </div>
            <div className="flex flex-col items-start font-semibold mr-3">
              <span className=" text-te-blue-800 truncate w-56 text-left">
                {user?.Name || user?.Company}
              </span>
              <span className=" text-te-orange-400 truncate w-48 text-left">
                {user?.PoBox}
              </span>
            </div>
          </div>
          <div className="mt-2">
            <Link
              to="/micasillero"
              onClick={() => {
                setOpen(false);
              }}
              className={`flex items-center w-full px-2 py-2 text-lg text-te-blue-800 font-semibold border-b border-white`}
            >
              Mi Casillero
            </Link>
            <Link
              to="/micasillero/packages"
              onClick={() => {
                setOpen(false);
              }}
              className={`flex items-center w-full px-2 py-2 text-lg text-te-blue-800 font-semibold border-b border-white`}
            >
              Mis Paquetes
            </Link>
            <div
              onClick={() => {
                history.push("/");
                signout();
                setOpen(false);
                remoteLogout();
              }}
              className={`cursor-pointer flex rounded-md items-center w-full px-2 py-2 text-lg text-te-blue-800 font-semibold mt-1`}
            >
              Cerrar Sesión <LogoutIcon className="h-5 text-te-blue-800 ml-4" />
            </div>
          </div>
        </div>
      )}

      <nav className="">
        <ul>
          {navigation?.Navegacion?.map((navItem, index) =>
            getNavComponent(navItem, index)
          )}

          <li className="border-b border-te-gray-700 p-5">
            <span
              onClick={() => {
                user?.autologinUrl
                  ? window.location.replace(user.autologinUrl)
                  : window.location.replace(
                      process.env.REACT_APP_SHOPPING_MALL_BASE_URL
                    );
              }}
              className="flex justify-between items-center text-te-blue-800 font-semibold text-xl"
            >
              Shopping Mall
            </span>
          </li>
        </ul>
      </nav>

      <div className="p-5 mt-4">
        <p className=" text-te-gray-800 font-semibold text-lg">
          Nuestras Redes
        </p>
        <div className="space-x-10 flex mt-3 text-te-blue-800">
          <a
            href="https://www.facebook.com/TransExpressSV"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook className="fill-current" />
          </a>
          <a
            href="https://www.instagram.com/transexpresssv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Instagram className="fill-current" />
          </a>
          <a
            href="https://api.whatsapp.com/message/RJPQ4WSV4XHHN1"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Whatsapp className="fill-current" />
          </a>
        </div>
      </div>

      {!auth.user && (
        <div className="w-full p-5">
          <Link to="/login">
            <ButtonBasic
              classNameFather="w-full"
              className=" bg-white w-full border-2 border-te-gray-700 text-te-blue-800 text-lg"
            >
              Iniciar Sesión
            </ButtonBasic>
          </Link>
          <Link to="/register">
            <ButtonBasic
              classNameFather="w-full mt-5"
              className=" bg-gradient-to-r from-te-orange-400 to-te-orange-600 text-white text-lg"
            >
              Regístrate Gratis
            </ButtonBasic>
          </Link>
        </div>
      )}
    </aside>
  );
}
