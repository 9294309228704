import React from "react";
import StatusBar from "./StatusBar";
import { DateTime } from "luxon";
import {
  getStatusColorbyTracking,
  getStatusTextbyTracking,
} from "../utils/packages";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import box from "../../assets/images/icons/locker-box.svg";
import { useHistory } from "react-router-dom";

export default function TrackingResult({ packageInfo }) {
  const history = useHistory();

  return (
    <div className="container mt-10 lg:px-32">
      <p className="text-te-blue-800 text-2xl md:text-3xl lg:text-center font-bold">
        Información de tu paquete
      </p>
      <div className="md:border-2 border-te-gray-700 rounded-2xl mt-10 px-5 lg:px-10 w-full shadow-lg md:shadow-none">
        <div className="my-5 flex justify-between pb-5">
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex justify-between flex-shrink-0 items-center">
              <div className="md:block inline-flex items-center justify-center rounded-full p-3 md:border-2 border-te-gray-700">
                <img src={box} alt="" className="h-8" />
              </div>

              <div className="md:hidden">
                <span
                  className={`mt-1 block ${
                    packageInfo.SubioFactura === "S"
                      ? "text-te-blue-400 bg-te-blue-100"
                      : getStatusColorbyTracking(packageInfo)
                  } rounded-r-full rounded-bl-full px-3 py-1 text-sm font-semibold`}
                >
                  {packageInfo.SubioFactura === "S"
                    ? "En Proceso"
                    : getStatusTextbyTracking(packageInfo) || " "}
                </span>
              </div>
            </div>
            <div className="md:pl-10 space-y-4 text-sm md:text-base mt-3 md:mt-5 w-full">
              <div className="font-semibold w-full flex md:block">
                <span className="inline-block w-32 md:w-40 text-te-gray-800">
                  Tracking:
                </span>
                <span className=" flex-1 text-te-blue-800 truncate">
                  {packageInfo.Tracking}
                </span>
              </div>
              <div className="font-semibold">
                <span className="inline-block w-32 md:w-40 text-te-gray-800">
                  Guía TE:
                </span>
                <span className="inline-block text-te-blue-800">
                  {packageInfo.Guia_x0020_TE}
                </span>
              </div>
              <div className="font-semibold">
                <span className="inline-block w-32 md:w-40 text-te-gray-800">
                  Recibido en Miami:
                </span>
                <span className="inline-block text-te-blue-800">
                  {DateTime.fromISO(packageInfo.Fecha_x0020_Ingreso).toFormat(
                    "dd/MM/yyyy"
                  )}
                </span>
              </div>
            </div>
          </div>

          <div className="mt-4 hidden md:block">
            <div className="font-semibold">
              <span className="inline-block w-20 text-te-gray-800">
                Estatus:
              </span>
              <span
                className={`mt-1 inline-block ${
                  packageInfo.SubioFactura === "S"
                    ? "text-te-blue-400 bg-te-blue-100"
                    : getStatusColorbyTracking(packageInfo)
                } rounded-r-full rounded-bl-full px-3 py-1 text-sm font-semibold`}
              >
                {packageInfo.SubioFactura === "S"
                  ? "En Proceso"
                  : getStatusTextbyTracking(packageInfo) || " "}
              </span>
            </div>
            {/* <div className="mt-8 flex justify-center">
          <ButtonBasic
            classNameFather="bg-te-blue-900"
            className=" bg-te-blue-800 text-white px-8 text-lg"
          >
            Ver detalle
          </ButtonBasic>
        </div> */}
          </div>
        </div>
      </div>

      <StatusBar status={packageInfo} />

      <div className="flex justify-center mt-10 mb-20">
        <p
          onClick={() => {
            history.goBack();
          }}
          className="flex items-center underline text-te-blue-800 font-semibold text-2xl cursor-pointer"
        >
          <ArrowLeftIcon className="h-6 text-te-blue-800 mr-2" />
          Regresar
        </p>
      </div>
    </div>
  );
}
