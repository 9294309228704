import React from "react";
import SendInvoiceForm from "./SendInvoiceForm";

export default function MainSendInvoice({ dataCMS }) {
  return (
    <section className=" container flex flex-col lg:flex-row py-10 2xl:px-20">
      <div className="lg:w-1/2 flex justify-center mt-5">
        <div className="">
          <h1
            data-aos="fade-up"
            className=" text-te-orange-400 text-2xl font-semibold"
          >
            {dataCMS?.TituloNaranja}
          </h1>
          <h3
            data-aos="fade-up"
            className=" font-bold text-te-blue-800 text-3xl lg:text-5xl mt-5 whitespace-pre-line"
          >
            {dataCMS?.Titulo}
          </h3>
          <p
            data-aos="fade-up"
            className="text-te-gray-800 mt-5 max-w-md lg:text-xl whitespace-pre-line"
          >
            {dataCMS?.Descripcion}
          </p>
        </div>
      </div>
      <div data-aos="fade-up" className="lg:w-1/2 flex justify-center">
        <SendInvoiceForm />
      </div>
    </section>
  );
}
