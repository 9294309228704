import { ArrowRightIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import boxIcon from "../../assets/images/icons/box.svg";

export default function TrackingBar() {
  const [value, setValue] = useState("");

  return (
    <>
      <div className="bg-te-gray-700 h-16 hidden lg:flex justify-center">
        <div className="flex items-center">
          <img
            src={boxIcon}
            alt="ilustración de un paquete"
            className="h-4 mr-3"
          />
          <span className=" text-te-blue-900 font-bold">
            Localiza tu paquete
          </span>
          <ArrowRightIcon className="ml-3 text-te-blue-900 h-4 w-4" />
        </div>
        <div className="flex items-center ml-5 flex-1 max-w-xl">
          <div className="rounded-full bg-white px-3 py-2 flex items-center justify-between w-full">
            <LocationMarkerIcon
              className={`${
                value ? "text-te-orange-400" : "text-te-gray-800"
              } h-5 w-5`}
            />
            <input
              type="text"
              placeholder="Ubica aquí tu número de rastreo o guía"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className=" border-0 focus:ring-0 font-semibold focus:outline-none w-full mx-2 placeholder-te-gray-800 text-te-blue-800 text-sm"
            />
            <Link
              to={`/tracking/${value}`}
              className="text-te-blue-800 font-semibold mr-2"
            >
              Rastrear
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
