import React from "react";
import coupon5 from "../../../assets/images/locker/coupon5.png";
import coupon10 from "../../../assets/images/locker/coupon10.png";
import coupon20 from "../../../assets/images/locker/coupon20.png";
import coupon50 from "../../../assets/images/locker/coupon50.png";
import coupon100 from "../../../assets/images/locker/coupon100.png";

export default function Certificates() {
  return (
    <div className="px-10 py-10">
      <p className="text-lg text-te-blue-800 font-semibold">5 Certificados</p>

      <div className="mt-5 grid grid-cols-3 gap-6">
        {[
          { value: "$5.00", cost: 500, img: coupon5 },
          { value: "$10.00", cost: 1000, img: coupon10 },
          { value: "$20.00", cost: 2000, img: coupon20 },
          { value: "$50.00", cost: 5000, img: coupon50 },
         /*  { value: "$100.00", cost: 10000, img: coupon100 }, */
        ].map((value, index) => (
          <div
            key={index}
            className="bg-te-green-50 bg-opacity-30 rounded-lg p-5"
          >
            <img src={value.img} alt="" className="mx-auto" />
            <div className="mt-5 flex items-center justify-between font-semibold">
              <span className=" text-te-gray-800">{value.value}</span>
              <span className=" text-te-blue-800">{value.cost} pts.</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
