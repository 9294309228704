import React from "react";
import ButtonBasic from "../buttons/Basic";
import ContactForm from "./ContactForm";
import { ReactComponent as WhatsApp } from "../../assets/images/icons/whatsapp.svg";

export default function MainCustomerService({ dataCMS }) {
  return (
    <section className=" container flex flex-col lg:flex-row py-10 lg:py-20 2xl:px-20">
      <div className="lg:w-1/2 flex justify-center items-center">
        <div data-aos="fade-up" className="">
          <h1 className=" text-te-orange-400 text-3xl font-semibold">
            {dataCMS?.TituloNaranja}
          </h1>
          <h3 className=" font-bold text-te-blue-800 text-3xl lg:text-5xl mt-5">
            {dataCMS?.Titulo}
          </h3>
          <p className="text-te-gray-800 mt-5 max-w-md font-semibold lg:text-xl">
            {dataCMS?.Descripcion}
          </p>

          <a
            href="https://api.whatsapp.com/message/RJPQ4WSV4XHHN1"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-10"
          >
            <ButtonBasic
              classNameFather="bg-te-green-700 mt-5 w-full lg:w-auto"
              className="text-te-green-600 border-2 bg-white border-te-green-600 flex items-center justify-center"
            >
              <WhatsApp className="h-5 mr-2 fill-current" /> Escríbenos por
              Whatsapp
            </ButtonBasic>
          </a>

          <p
            onClick={() => {
              const element = document.getElementById("sucursales");
              const y =
                element.getBoundingClientRect().top +
                window.pageYOffset -
                250;

              window.scrollTo({ top: y, behavior: "smooth" });
            }}
            className="text-te-blue-800 mt-5 lg:mt-12 max-w-md font-semibold text-xl underline cursor-pointer"
          >
            Nuestras sucursales
          </p>
        </div>
      </div>
      <div
        data-aos="fade-up"
        className="lg:w-1/2 flex items-start justify-center mt-8 lg:mt-0"
      >
        <ContactForm />
      </div>
    </section>
  );
}
