import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomLink from "../utils/CustomLink";

export default function SubBanners({ dataCMS }) {
  const [current, setCurrent] = useState(0);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    beforeChange: (prev, next) => {
      setCurrent(next);
    },
    appendDots: (dots) => (
      <div>
        <div className="flex items-center justify-center space-x-5">
          {dots.map((item, index) => {
            return (
              <div key={index} className="flex w-2 h-2">
                {item.props.children}
              </div>
            );
          })}
        </div>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`w-2 h-2 border border-te-blue-800 rounded-full transition-all duration-300 cursor-pointer ${
          i === current ? "opacity-100 bg-te-blue-800" : ""
        }`}
      ></div>
    ),
  };

  return (
    <>
      <section
        data-aos="fade-up"
        className="container lg:px-24 mt-10 hidden md:block"
      >
        <Slider {...settings} className="">
          {dataCMS?.SliderSecundario?.data?.map((dataImage, index) => (
            <div key={index} className="focus:outline-none">
              <CustomLink to={dataImage.attributes?.caption}>
                <img
                  src={dataImage.attributes?.url}
                  alt=""
                  className="rounded-xl"
                />
              </CustomLink>
            </div>
          ))}
        </Slider>
      </section>
      <section data-aos="fade-up" className="mt-10 md:hidden">
        <Slider {...settings} className="">
          {dataCMS?.SliderSecundarioMobile?.data?.map((dataImage, index) => (
            <div key={index} className="focus:outline-none">
              <CustomLink to={dataImage.attributes?.caption}>
                <img
                  src={dataImage.attributes?.url}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </CustomLink>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
}
