import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import referred from "../../../assets/images/locker/referred.png";
import referredRes from "../../../assets/images/locker/referred-res.png";
import { ArrowRightIcon, CheckIcon } from "@heroicons/react/outline";
import YourReferred from "./YourReferred";
import AdministrateReferred from "./AdministrateReferred";
import axios from "axios";
import { useAuth } from "../../../contexts/auth";
import AddReferred from "./AddReferred";
import { Link } from "react-router-dom";
import YourReferredMobile from "./YourReferredMobile";
import AdministrateReferredMobile from "./AdministrateReferredMobile";

export default function Referred() {
  const [yourReferred, setYourReferred] = useState([]);
  const [isLoadingYourReferred, setIsLoadingYourReferred] = useState(false);
  const [errorYourReferred, setErrorYourReferred] = useState("");

  const [manageReferrals, setManageReferrals] = useState([]);
  const [isLoadingManageReferrals, setIsLoadingManageReferrals] =
    useState(false);
  const [errorManageReferrals, setErrorManageReferrals] = useState("");

  const { user } = useAuth();

  const getYourReferred = () => {
    setIsLoadingYourReferred(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user/${user.PoBox}/references?status=compra`
      )
      .then((res) => {
        if (res.data.data) {
          if (res.data.data?.length) {
            setYourReferred(res.data.data);
          } else {
            setErrorYourReferred("Aún no tienes referidos");
          }
        }
      })
      .catch(() => {
        setErrorYourReferred(
          "El servicio no está disponible. Inténtelo más tarde."
        );
      })
      .finally(() => {
        setIsLoadingYourReferred(false);
      });
  };

  const getManageReferrals = () => {
    setIsLoadingManageReferrals(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/user/${user.PoBox}/references?status=invitado`
      )
      .then((res) => {
        if (res.data.data) {
          if (res.data.data?.length) {
            setManageReferrals(res.data.data);
          } else {
            setErrorManageReferrals("Aún no tienes referidos");
          }
        }
      })
      .catch(() => {
        setErrorManageReferrals(
          "El servicio no está disponible. Inténtelo más tarde."
        );
      })
      .finally(() => {
        setIsLoadingManageReferrals(false);
      });
  };

  useEffect(() => {
    getYourReferred();
    getManageReferrals();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex-1">
      <h1 className="text-te-blue-800 text-2xl md:text-3xl font-bold">
        Referir amigos
      </h1>
      <p className="text-te-gray-800 mt-1 font-semibold">
        Administra tus referidos
      </p>

      <div className="mt-8">
        <img src={referred} alt="" className="hidden md:inline" />
        <img src={referredRes} alt="" className="md:hidden" />
      </div>

      <AddReferred
        getYourReferred={getYourReferred}
        getManageReferrals={getManageReferrals}
      />

      <div className="border-2 border-te-gray-700 mt-8 rounded-xl hidden lg:block">
        <Tab.Group>
          <Tab.List className=" w-full">
            <Tab className="w-1/2">
              {({ selected }) => (
                <span
                  className={`font-bold inline-block uppercase border-b-2 w-full py-5 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  tus referidos
                </span>
              )}
            </Tab>
            <Tab className="w-1/2">
              {({ selected }) => (
                <span
                  className={`font-bold inline-block uppercase border-b-2 w-full py-5 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  administrar referidos
                </span>
              )}
            </Tab>
          </Tab.List>

          <Tab.Panels>
            <Tab.Panel>
              <YourReferred
                yourReferred={yourReferred}
                isLoadingYourReferred={isLoadingYourReferred}
                errorYourReferred={errorYourReferred}
              />
            </Tab.Panel>
            <Tab.Panel>
              <AdministrateReferred
                manageReferrals={manageReferrals}
                isLoadingManageReferrals={isLoadingManageReferrals}
                errorManageReferrals={errorManageReferrals}
                getManageReferrals={getManageReferrals}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="lg:hidden mt-10">
        <YourReferredMobile
          yourReferred={yourReferred}
          isLoadingYourReferred={isLoadingYourReferred}
          errorYourReferred={errorYourReferred}
        />
        <AdministrateReferredMobile
          manageReferrals={manageReferrals}
          isLoadingManageReferrals={isLoadingManageReferrals}
          errorManageReferrals={errorManageReferrals}
          getManageReferrals={getManageReferrals}
        />
      </div>

      <div className="space-y-1 bg-te-gray-600 rounded-xl p-4 lg:p-4 mt-10 inline-block w-full">
        <p className=" text-te-blue-800 font-semibold mb-4 text-xl">
          Toma en cuenta lo siguiente:
        </p>
        <ul className=" space-y-4 md:space-y-2">
          {[
            "El Programa de Referidos de Trans Express consiste en otorgar beneficios a nuestros usuarios que nos refieran amigos y que estos aperturen un casillero y transporten paquetes.",
            "La persona que refiere debe poseer un casillero vigente y activo, y el que recibe la invitación debe registrarse con un correo electrónico que no esté registrado previamente en nuestra base de datos.",
            "El beneficio consiste en que el que refiere, acumulara $10 dólares en su cuenta de puntos por cada referido que cree su casillero y lo active transportando su primer paquete, el referido recibirá $10 dólares al momento de crear el casillero y los mismos pueden consultarse en las cuentas del programa de puntos, puede acceder a la misma desde su cuenta privada en la página de Trans Express. ",
            "Los puntos equivalentes a los $10 deberán ser canjeados por certificados de dinero en Mi Cuenta. Al canjearlos recibirá un email en el correo que tiene registrado el titular de la cuenta, con el monto solicitado.",
          ].map((value, index) => (
            <li
              key={index}
              className=" text-te-gray-800 flex items-start md:text-sm lg:text-base"
            >
              <CheckIcon className=" text-te-orange-600 h-5 mr-2 flex-shrink-0 mt-1" />{" "}
              {value}
            </li>
          ))}
        </ul>
      </div>

      <Link
        to="/terminos-referidos"
        className=" text-te-blue-800 md:text-lg text-center mt-10 flex items-center justify-center font-semibold mb-20 underline"
      >
        Consulta los términos y condiciones{" "}
        <ArrowRightIcon className="ml-3 h-5 text-te-orange-400 hidden lg:inline" />
      </Link>
    </div>
  );
}
