import React, { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/outline";
import addFile from "../../../assets/images/icons/add-file.svg";
import { ReactComponent as Question } from "../../../assets/images/icons/question.svg";
import { useForm } from "react-hook-form";
import closeIcon from "../../../assets/images/icons/close-modal.svg";
import { useAuth } from "../../../contexts/auth";
import { sendInvoice } from "../../../services/sendInvoice";
import Spinner from "../../utils/Spinner";
import { DateTime } from "luxon";
import s3, { config } from "../../../configs/spaces";
import { getFileExtension } from "../../utils/getFileExtension";

export default function ButtonSendInvoive({ data }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [error, setError] = useState("");
  let closeButtonRef = useRef(null);

  const closeModal = () => {
    setOpenModal(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({});

  const watchFile = watch("file", false);

  const onSubmit = async (dataForm) => {
    setIsLoading(true);
    setError("");

    const blob = dataForm.file[0];

    blob.hash = DateTime.now().toMillis();

    const ramdom = Math.random().toString(36).slice(2);

    const extension = getFileExtension(blob.name);

    const params = {
      ACL: "public-read",
      Body: blob,
      Bucket: `${config.bucketName}`,
      Key: `te-facturas/${blob.hash}${ramdom}.${extension}`,
    };

    // Sending the file to the Spaces
    s3.putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          setError("No se pudo subir el archivo.");
          return;
        } else {
          const imageUrl = `${config.digitalOceanSpaces}te-facturas/${blob.hash}${ramdom}.${extension}`;
          callback(imageUrl, `${blob.hash}${ramdom}.${extension}`);
        }
      });

    const callback = (imageUrl, fileName) => {
      sendInvoice(dataForm, user, imageUrl, fileName, data.Guia)
        .then((res) => {
          if (res.data.message === "Email no existe") {
            setError("El correo ingresado no existe.");
            return;
          }
          if (res.data && !res.data.error) {
            setSuccessful(true);
            closeModal();
            reset();
          } else {
            setError("El servicio no está disponible. Inténtelo más tarde.");
          }
          setIsLoading(false);
        })
        .catch(() => {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  };

  useEffect(() => {
    if (user) {
      reset({
        email: user.Correo,
        locker: user.PoBox,
        tracking: data.NumeroTracking,
        file: "",
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <button
        onClick={() => {
          if (!successful) {
            setOpenModal(true);
          } else {
            return;
          }
        }}
        className={`py-3 w-40 ${
          !successful ? "bg-te-blue-800" : "bg-te-green-700"
        }  flex items-center justify-between rounded-lg px-3 text-white font-semibold`}
      >
        {!successful ? "Envíanos tu factura" : "Factura enviada"}
        {!successful ? (
          <ChevronRightIcon className="h-4 text-white" />
        ) : (
          <CheckIcon className="h-4 text-white" />
        )}
      </button>

      <Transition appear show={openModal} as={Fragment}>
        <Dialog
          initialFocus={closeButtonRef}
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto w-full"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center w-full">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left align-middle transition-all transform">
                <div className="flex justify-end mb-3">
                  <span
                    onClick={closeModal}
                    ref={closeButtonRef}
                    className=" cursor-pointer"
                  >
                    <img src={closeIcon} alt="" className="h-8" />
                  </span>
                </div>

                <div className="bg-te-gray-300 shadow-xl rounded-2xl p-4 lg:p-6">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="w-full text-te-blue-800"
                  >
                    {data?.Guia && (
                      <div className="font-semibold text-sm text-te-gray-800 mb-2">
                        Guia:{" "}
                        <span className=" text-te-blue-800">{data?.Guia}</span>
                      </div>
                    )}
                    <div className="">
                      <label
                        htmlFor="email"
                        className="text-sm text-te-gray-800 font-semibold block"
                      >
                        Correo electrónico
                      </label>
                      <input
                        id="email"
                        type="email"
                        {...register("email", { required: true })}
                        className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                      />
                      {errors.email && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *Este campo es requerido.
                        </span>
                      )}
                    </div>
                    <div className="">
                      <label
                        htmlFor="Contenido"
                        className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                      >
                        Contenido del paquete
                      </label>
                      <input
                        id="Contenido"
                        type="text"
                        {...register("Contenido", { required: true })}
                        className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                      />
                      {errors.Contenido && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *Este campo es requerido.
                        </span>
                      )}
                    </div>
                    <div className="">
                      <label
                        htmlFor="Remitente"
                        className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                      >
                        Remitente/Tienda
                      </label>
                      <input
                        id="Remitente"
                        type="text"
                        {...register("Remitente", { required: true })}
                        className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                      />
                      {errors.Remitente && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *Este campo es requerido.
                        </span>
                      )}
                    </div>
                    {!user && (
                      <div className="mt-3">
                        <label
                          htmlFor="Consigna"
                          className="text-sm text-te-gray-800 font-semibold block"
                        >
                          Nombre
                        </label>
                        <input
                          id="Consigna"
                          type="text"
                          {...register("Consigna", { required: true })}
                          className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                        />
                        {errors.Consigna && (
                          <span className=" text-te-red-900 text-xs mt-2">
                            *Este campo es requerido.
                          </span>
                        )}
                      </div>
                    )}

                    <div className="">
                      <label
                        htmlFor="tracking"
                        className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                      >
                        N. de Tracking de tu transportista
                        <Question className="h-4" />
                      </label>
                      <input
                        id="tracking"
                        type="text"
                        {...register("tracking", { required: true })}
                        className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                      />
                      {errors.tracking && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *Este campo es requerido.
                        </span>
                      )}
                    </div>
                    <div className="">
                      <label
                        htmlFor=""
                        className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                      >
                        Factura comercial
                        <Question className="h-4" />
                      </label>
                      {!watchFile?.length ? (
                        <div
                          onClick={() =>
                            document.getElementById("file").click()
                          }
                          className="border-2 border-te-gray-700 bg-te-blue-100 lg:bg-white bg-opacity-30 hover:bg-te-blue-100 transition hover:bg-opacity-30 border-dashed rounded-xl py-5 mt-2 cursor-pointer"
                        >
                          <div className="">
                            <img
                              src={addFile}
                              alt=""
                              className="h-14 mx-auto"
                            />
                          </div>
                          <>
                            <p className="text-center mt-3 text-sm">
                              <span className=" text-te-orange-400 font-semibold">
                                Sube un archivo
                              </span>
                            </p>
                            <p className="text-te-gray-800 text-center text-sm">
                              PNG, JPG, JPEG, DOCX, XLSX, PDF
                            </p>
                          </>
                        </div>
                      ) : (
                        <div className=" text-center px-5 py-5 mt-5 font-semibold flex flex-col justify-center items-center border rounded-lg">
                          {document.getElementById("file").files[0].name}

                          <span
                            className=" text-te-blue-800 underline cursor-pointer"
                            onClick={() => {
                              reset({ ...getValues(), file: "" });
                            }}
                          >
                            Quitar archivo
                          </span>
                        </div>
                      )}
                      {errors.file?.type === "acceptedFormats" && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *{errors.file.message}
                        </span>
                      )}
                      {errors.file?.type === "limitSize" && (
                        <span className="block text-te-red-900 text-xs mt-2">
                          *{errors.file.message}
                        </span>
                      )}
                      {errors.file?.type === "required" && (
                        <span className="block text-te-red-900 text-xs mt-2">
                          *Es necesario subir tu factura.
                        </span>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        id="file"
                        accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
                        {...register("file", {
                          required: true,
                          validate: {
                            acceptedFormats: (files) => {
                              if (files[0]) {
                                return (
                                  [
                                    "image/jpg",
                                    "image/jpeg",
                                    "image/png",
                                    "application/pdf",
                                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                    "application/vnd.ms-excel",
                                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                    "application/msword",
                                  ].includes(files[0]?.type) ||
                                  "Este tipo de archivo no es permitido."
                                );
                              } else {
                                return null;
                              }
                            },
                            limitSize: (files) => {
                              if (files[0]) {
                                const maxAllowedSize = 4 * 1024 * 1024;
                                return (
                                  files[0].size < maxAllowedSize ||
                                  "El tamaño maximo de archivo debe ser de 4MB."
                                );
                              } else {
                                return null;
                              }
                            },
                          },
                        })}
                      />
                    </div>
                    {/* <div className="flex items-center justify-between mt-3">
                      <div className="text-te-gray-800 font-semibold text-sm flex items-center">
                        <input
                          type="checkbox"
                          onChange={() => {
                            setIsOpen(true);
                          }}
                          className="mr-2"
                        />
                        Copiar y pegar factura comercial.
                      </div>
                      <Question className="h-4" />

                      <CopyPasteInvoice
                        isOpen={isOpen}
                        closeModal={closeModal}
                        register={register}
                      />
                    </div> */}
                    <div className="mt-6 flex space-x-5">
                      <button
                        type="button"
                        onClick={() => reset()}
                        disabled={isLoading}
                        className=" w-full bg-white text-blue-800 border border-te-blue-800 rounded-lg font-semibold py-2"
                      >
                        Limpiar campos
                      </button>
                      <button
                        type="submit"
                        disabled={isLoading}
                        className=" w-full bg-te-blue-400 text-white font-semibold rounded-lg py-2 relative"
                      >
                        Enviar factura
                        {isLoading && (
                          <div className="absolute right-2 top-0 h-full flex items-center">
                            <Spinner color="#ffffff" type="spinningBubbles" />
                          </div>
                        )}
                      </button>
                    </div>
                    {error && (
                      <div className=" text-te-red-900 font-semibold mt-5">
                        *{error}
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
