import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import { ReactComponent as Dollar } from "../../../assets/images/icons/dollar.svg";
import { ReactComponent as Star } from "../../../assets/images/icons/star.svg";
import React, { useState } from "react";
import RedeemPointsModal from "./RedeemPointsModal";

export default function RedeemPointsMobile({ userPoints, getPoints }) {
  const [values, setValues] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <div className="mt-10 border-2 border-te-gray-700 rounded-xl overflow-hidden p-4">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex items-center justify-between w-full text-left focus:outline-none font-semibold text-te-blue-800`}
              >
                <span>CANJE DE PUNTOS</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "transform rotate-180" : ""
                  } w-5 h-5 transition text-te-blue-800`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pb-2 mt-5">
                  {userPoints > 500 ? (
                    <p className=" text-te-gray-800 font-semibold mt-5">
                      Certificados disponibles
                    </p>
                  ) : (
                    <p className=" text-te-gray-800 font-semibold mt-5">
                      No tiene certificados disponibles
                    </p>
                  )}
                  <div className="mt-5 space-y-3">
                    {userPoints >= 500 && (
                      <div className="flex items-center">
                        <div className="bg-te-gray-700 bg-opacity-50 p-3 flex justify-between items-center rounded-lg">
                          <div className="flex items-center">
                            <div className=" flex items-center pr-3 border-r">
                              <Dollar className="h-6 mr-1" />
                              <span className="text-te-blue-800 font-semibold">
                                $5.00
                              </span>
                            </div>
                            <div className=" flex items-center pl-2">
                              <Star className="h-6 mr-2" />
                              <span className="text-te-orange-400 font-semibold">
                                500 pts.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setValues({
                              points: 500,
                              description: "Certificado por $5",
                            });
                          }}
                          className="cursor-pointer py-3 text-te-blue-800 font-semibold text-sm underline ml-5"
                        >
                          Canjear
                        </div>
                      </div>
                    )}
                    {userPoints >= 1000 && (
                      <div className="flex items-center">
                        <div className="bg-te-gray-700 bg-opacity-50 p-3 flex justify-between items-center rounded-lg">
                          <div className="flex items-center">
                            <div className=" flex items-center pr-3 border-r">
                              <Dollar className="h-6 mr-1" />
                              <span className="text-te-blue-800 font-semibold">
                                $10.00
                              </span>
                            </div>
                            <div className=" flex items-center pl-2">
                              <Star className="h-6 mr-2" />
                              <span className="text-te-orange-400 font-semibold">
                                1000 pts.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setValues({
                              points: 1000,
                              description: "Certificado por $10",
                            });
                          }}
                          className="cursor-pointer py-3 text-te-blue-800 font-semibold text-sm underline ml-5"
                        >
                          Canjear
                        </div>
                      </div>
                    )}
                    {userPoints >= 2000 && (
                      <div className="flex items-center">
                        <div className="bg-te-gray-700 bg-opacity-50 p-3 flex justify-between items-center rounded-lg">
                          <div className="flex items-center">
                            <div className=" flex items-center pr-3 border-r">
                              <Dollar className="h-6 mr-1" />
                              <span className="text-te-blue-800 font-semibold">
                                $20.00
                              </span>
                            </div>
                            <div className=" flex items-center pl-2">
                              <Star className="h-6 mr-2" />
                              <span className="text-te-orange-400 font-semibold">
                                2000 pts.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setValues({
                              points: 2000,
                              description: "Certificado por $20",
                            });
                          }}
                          className="cursor-pointer py-3 text-te-blue-800 font-semibold text-sm underline ml-5"
                        >
                          Canjear
                        </div>
                      </div>
                    )}
                    {userPoints >= 5000 && (
                      <div className="flex items-center">
                        <div className="bg-te-gray-700 bg-opacity-50 p-3 flex justify-between items-center rounded-lg">
                          <div className="flex items-center">
                            <div className=" flex items-center pr-3 border-r">
                              <Dollar className="h-6 mr-1" />
                              <span className="text-te-blue-800 font-semibold">
                                $50.00
                              </span>
                            </div>
                            <div className=" flex items-center pl-2">
                              <Star className="h-6 mr-2" />
                              <span className="text-te-orange-400 font-semibold">
                                5000 pts.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setValues({
                              points: 5000,
                              description: "Certificado por $50",
                            });
                          }}
                          className="cursor-pointer py-3 text-te-blue-800 font-semibold text-sm underline ml-5"
                        >
                          Canjear
                        </div>
                      </div>
                    )}
                    {/* {(userPoints >= 10000)} */}
                    { false && (
                      <div className="flex items-center">
                        <div className="bg-te-gray-700 bg-opacity-50 px-1 py-3 flex justify-between items-center rounded-lg">
                          <div className="flex items-center">
                            <div className=" flex items-center pr-3 border-r">
                              <Dollar className="h-6 mr-1" />
                              <span className="text-te-blue-800 font-semibold">
                                $100.00
                              </span>
                            </div>
                            <div className=" flex items-center pl-2">
                              <Star className="h-6 mr-2" />
                              <span className="text-te-orange-400 font-semibold">
                                10000 pts.
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          onClick={() => {
                            setIsOpen(true);
                            setValues({
                              points: 10000,
                              description: "Certificado por $100",
                            });
                          }}
                          className="cursor-pointer py-3 text-te-blue-800 font-semibold text-sm underline ml-5"
                        >
                          Canjear
                        </div>
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
      <RedeemPointsModal
        values={values}
        isOpen={isOpen}
        closeModal={closeModal}
        getPoints={getPoints}
      />
    </div>
  );
}