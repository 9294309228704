import axios from "axios";
import React, { useState } from "react";
import { useAuth } from "../../../contexts/auth";
import Spinner from "../../utils/Spinner";

export default function AdministrateReferred({
  manageReferrals,
  isLoadingManageReferrals,
  errorManageReferrals,
  getManageReferrals,
}) {
  return (
    <div className="">
      {!isLoadingManageReferrals ? (
        <div className="w-full">
          {manageReferrals.length ? (
            <>
              <div className="flex p-3 uppercase text-te-blue-800 font-bold mt-5">
                <div className="w-1/5 text-center">acciones</div>
                <div className="w-1/5 text-center">nombre</div>
                <div className="w-1/5 text-center">correo</div>
                <div className="w-1/5 text-center">estado</div>
                <div className="w-1/5 text-center">observación</div>
                {/* <div className="flex-1 text-center">cuenta</div>
                <div className="flex-1 text-center">fecha de compra</div>
                <div className="flex-1 text-center">fecha de certificado</div> */}
              </div>
              <div className="w-full space-y-3 p-3">
                {manageReferrals.map((value, index) => (
                  <div
                    key={index}
                    className="flex bg-te-gray-700 bg-opacity-50 rounded-lg py-3"
                  >
                    <div className="w-1/5 flex items-center justify-center text-sm">
                      <ButtonRemember
                        value={value}
                        getManageReferrals={getManageReferrals}
                      />
                    </div>
                    <div className="w-1/5 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Nombre}
                      </div>
                    </div>
                    <div className="w-1/5 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Email}
                      </div>
                    </div>
                    <div className="w-1/5 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Estado}
                      </div>
                    </div>
                    <div className="w-1/5 flex items-center justify-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Observacion}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <span className="flex justify-center py-12 text-te-gray-800 font-semibold text-lg">
              {errorManageReferrals}
            </span>
          )}
        </div>
      ) : (
        <div className="flex items-center py-12 justify-center">
          <Spinner color="#0d1256" type="spinningBubbles" />
        </div>
      )}
    </div>
  );
}

export const ButtonRemember = ({ value, getManageReferrals }) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [, setError] = useState("");

  const rememberHandle = (values) => {
    setIsLoading(true);

    const body = {
      email: values.Email, //El correo del referido
      nombrereferido: values.Nombre, //Nombre del referido
      quienremite: user.Name || user.Company, //Quien refiere (La persona en la sesion)
    };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/user/${user.PoBox}/reminds`,
        body
      )
      .then((res) => {
        if (res.data.data && res.status === 200) {
          getManageReferrals();
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <button
      onClick={() => {
        rememberHandle(value);
      }}
      disabled={isLoading}
      className=" bg-te-gray-800 text-white font-semibold py-2 px-10 rounded-lg relative w-full md:w-auto"
    >
      Recordar
      {isLoading && (
        <div className="absolute right-1 top-0 h-full flex items-center">
          <Spinner color="#ffffff" type="spinningBubbles" />
        </div>
      )}
    </button>
  );
};
