import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Spinner from "../../components/utils/Spinner";
import { useAuth } from "../../contexts/auth";
import SimpleLayout from "../../layouts/simple";

export default function ValidateUser() {
  const [step, setStep] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  const { signup } = useAuth();
  const [return_sm,setReturn_sm] = useState(null);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const idAccount = query.get("activateId");

  useEffect(() => {
    if (!idAccount) {
      history.push("/");
      return;
    }

    axios
      .get(`${process.env.REACT_APP_BASE_URL}/get-temporal-user/${idAccount}`)
      .then((res) => {
        if (res.data.message === "No data") {
          setError("No fue posible verificar su cuenta.");
          setIsLoading(false);
          return;
        }

        const body = res.data;
        let _return_sm = res.data.origin_sm && res.data.origin_sm!=="" ? res.data.origin_sm : null;
        setReturn_sm(_return_sm);
        axios
          .post(`${process.env.REACT_APP_BASE_URL}/users`, body)
          .then((res) => {
            if (res.status === 200 && !res.data.error && res.data.data) {
              setUserInfo(res.data.data);
              let token =  res.data.data.token;
              const newObject = res.data.data.loginData;
              newObject["idUser"] = res.data.data.idUser;
              newObject["autologinUrl"] = res.data.data.autologinUrl;
              newObject["token"] = token;

              signup(newObject,async () => {
                await axios.get(`${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/login/autologin?jwt=${token}`,{ withCredentials: true });
              });
              setStep(1);
              setIsCompleted(true);
            } else if (res.data.message === "ERROR_SIN_DATOS") {
              setError(
                "No fue posible crear su casillero. Inténtelo más tarde."
              );
            } else if (res.data.message) {
              setError("El email ya está registrado.");
            } else {
              setError(res.data.error);
            }
          })
          .catch((error) => {
            if (error.response?.data?.message) {
              if (error.response?.data?.message === "Error creating user") {
                setError(
                  "No fue posible crear su casillero. Inténtelo más tarde."
                );
              } else {
                setError(
                  "No fue posible crear su casillero. Inténtelo más tarde."
                );
              }
            } else {
              setError("El servicio no está disponible. Inténtelo más tarde.");
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idAccount]);

  return (
    <SimpleLayout>
      <div className="container flex justify-center">
        <div className="w-full max-w-lg">
          {step === 0 && (
            <div className="border rounded-xl mt-20 p-5 shadow-md">
              {!error && (
                <h1 className=" font-bold text-te-blue-800 text-center text-2xl">
                  Validando cuenta. Por favor espere...
                </h1>
              )}
              <div className="flex justify-center mt-5">
                {isLoading && (
                  <div className="h-full flex items-center">
                    <Spinner color="#141c82" type="spinningBubbles" />
                  </div>
                )}
              </div>
            </div>
          )}
          {step === 1 && isCompleted && (
            <div className="border rounded-xl mt-32 p-5 shadow-md">
              <h1 className=" font-bold text-te-blue-800 text-center text-3xl mt-5">
                ¡Tu casillero ha sido creado!
              </h1>
              <p className=" text-lg text-te-gray-800 font-semibold text-center mt-2">
                Ya puedes realizar tus compras en línea <br />
                de una forma fácil y segura
                <br />
                <label htmlFor="">Guarda esta información de tu casillero.</label>
              </p>
              <div className="border-b mx-5 mt-5"></div>
              <div className="mt-5 bg-te-blue-100 p-5 rounded-xl">
                <p className="text-te-blue-800 text-sm font-semibold text-center">
                  Resumen de tu registro:
                </p>
                <p className="text-te-blue-800 font-semibold text-center text-xl mt-4">
                  Nombre de usuario personal: <br />
                  <span className=" font-bold">{userInfo.loginData.Name}</span>
                </p>
                <p className="text-te-blue-800 font-semibold text-center text-xl mt-4">
                  Número de casillero:
                  <br />
                  <span className=" font-bold text-te-orange-400">
                    {userInfo.numero_casillero}
                  </span>
                </p>
              </div>
              {
                return_sm ? 
                (
                  <a href={return_sm}  className="w-full block text-white border bg-te-blue-800 rounded-lg font-semibold mt-5 mb-4 py-2 shadow-md text-center">Seguir comprando</a>
         
                ):(
                  <Link
                  to="/micasillero"
                  className="w-full block text-white border bg-te-blue-800 rounded-lg font-semibold mt-5 mb-4 py-2 shadow-md text-center"
                >
                  Ir a mi cuenta
                </Link>
                )
              }
             
              {/* <div className="flex items-center justify-center mb-5">
              <span
                onClick={() => {
                  user?.autologinUrl
                    ? window.location.replace(user.autologinUrl)
                    : window.location.replace(
                        process.env.REACT_APP_SHOPPING_MALL_BASE_URL
                      );
                }}
                className="text-center text-te-blue-800 font-semibold cursor-pointer"
              >
                Shopping Mall
              </span>
            </div> */}
            </div>
          )}

          {error && (
            <p className="text-center text-te-red-900 font-semibold mt-5">
              *{error}
            </p>
          )}
          
        </div>
      </div>
    </SimpleLayout>
  );
}
