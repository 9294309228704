import React from "react";
import SiteLayout from "../../layouts/site";

const blackTerms = () => {
  return (
    <>
      <main>
        <SiteLayout>
          <section className="lg:container lg:mt-10">
            <div className="h-64 lg:h-96 lg:rounded-2xl overflow-hidden relative">
              <div className="absolute h-full w-full">
                {/* <img src="" alt="" className="h-full object-cover w-full" /> */}
              </div>
              <div className=" bg-te-blue-800 bg-opacity-40 absolute w-full h-full"></div>
              <div
                data-aos="fade-up"
                className="absolute w-full h-full flex items-center justify-center"
              >
                <h1 className="text-white relative text-2xl lg:text-3xl font-bold text-center">
                  TERMINOS Y CONDICIONES <br /> PROMOCIÓN DE LIBRA $1.99
                </h1>
              </div>
            </div>
          </section>
          <section className="text-te-gray-800 container xl:px-32 mb-20">
            <div
              data-aos="fade-up"
              className="mt-16 prose lg:prose-lg max-w-none"
            >
              1. Tarifa especial de transporte de Miami a El Salvador de $1.99
              por libra, más combustible, más IVA.
              <br />
              <br />
              2. Máximo hasta 10 libras. El mínimo a facturar debe ser 2 libras.
              <br />
              <br />
              3. Exclusivo para clientes que compren del día hasta viernes 24,
              sábado 25 y lunes 27 de noviembre a través de los siguientes
              canales de Trans-Express: kioscos (Plaza Mundo, Metrocentro Santa
              Ana), Agencia La Mascota, Agencia San Miguel (Centro comercial El
              Encuentro Sitio)
              <br />
              <br />
              4. El pago de los servicios conexos como tramite de aduana,
              seguro, reempaque, identificación de paquete, servicio a domicilio
              y otros similares, le serán cargados al momento de
              aceptar/autorizar la compra.
              <br />
              <br />
              5. El pago de impuestos de importación, almacenaje y otros pagos a
              terceros generados, serán cargados al momento de efectuar la
              compra.
              <br />
              <br />
              6.Trans-Express no es responsable por la calidad de los productos
              ordenados a través de sus servicios de transporte, a pesar de lo
              anterior, Trans-Express asistirá a los usuarios en cualquier
              reclamo que se derive de la utilización de los servicios.
              <br />
              <br />
              7. No incluye retornos.
              <br />
              <br />
              8. Descuento no aplica para empleados de Trans-Express.
              <br />
              <br />
              9. No aplica promoción sobre promoción.
            </div>
          </section>
        </SiteLayout>
      </main>
    </>
  );
};

export default blackTerms;
