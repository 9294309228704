import axios from "axios";
import React, { useEffect, useState } from "react";
import CreateLocker from "../../components/general/CreateLocker";
import HowDoesItWork from "../../components/home/HowDoesItWork";
import CalculateCost from "../../components/lockerService/CalculateCost";
import Details from "../../components/lockerService/Details";
import MainLockerService from "../../components/lockerService/MainLockerService";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function LockerService() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/servicio-casillero?populate=*`)
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <Seo data={content.Seo} />

      <SiteLayout>
        <MainLockerService dataCMS={content} />

        <HowDoesItWork />

        <Details dataCMS={content} />

        <CalculateCost dataCMS={content} />

        <CreateLocker dataCMS={content} />
      </SiteLayout>
    </main>
  );
}
