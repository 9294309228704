import axios from "axios";
import { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/auth";
import Spinner from "../../utils/Spinner";
import { PrinterIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";

export default function WeightControl() {
  const [showTooltip, setShowTooltip] = useState(false);
  const [error, setError] = useState("");
  const [states, setStates] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    getStates();
    // eslint-disable-next-line
  }, []);

  const getStates = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/movements`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data?.length) {
          setStates([...res.data.data].reverse());
        } else {
          setError("No existen registros.");
        }
      })
      .catch(() => {
        setError("El servicio no está disponible.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex-1 pb-20">
      <h1 className="text-te-blue-800 text-3xl font-bold">Control de Pesos</h1>
      {isLoading && (
        <div className="py-10 flex justify-center">
          {" "}
          <Spinner color="#141c82" type="spinningBubbles" />{" "}
        </div>
      )}

      {!isLoading && error && (
        <span className="block text-center py-10 text-te-blue-800 text-lg">
          {error}
        </span>
      )}

      {!isLoading && !!states.length && (
        <div className="mx-auto max-w-2xl">
          <div className="flex justify-end mt-5">
            <button
              onClick={() => {
                window.print();
              }}
              className="flex items-center hover:underline text-te-blue-800"
            >
              <PrinterIcon className="h-5 mr-2" /> Imprimir control de pesos
            </button>
          </div>
          <div className=" bg-white shadow-lg border-2 border-te-gray-700 mt-5 rounded-lg p-5">
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Casillero:</span>{" "}
              <span className=" text-te-blue-800">{user.PoBox}</span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Nombre:</span>{" "}
              <span className=" text-te-blue-800">
                {user.Name || user.Company}
              </span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Fecha de inicio:</span>{" "}
              <span className=" text-te-blue-800">
                {DateTime.fromISO(
                  user.fecha_inicio.replace(/ /g, "T")
                ).toFormat("dd-MM-yyyy")}
              </span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Fecha de fin:</span>{" "}
              <span className=" text-te-blue-800">
                {DateTime.fromISO(user.fecha_fin.replace(/ /g, "T")).toFormat(
                  "dd-MM-yyyy"
                )}
              </span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Libraje contratado:</span>{" "}
              <span className=" text-te-blue-800">{user.libraje}</span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Tipo:</span>{" "}
              <span className=" text-te-blue-800">{user.acumulacion}</span>
            </p>
            <p className=" text-te-gray-800 font-semibold">
              <span className="w-36 inline-block">Peso Acumulado:</span>{" "}
              <span className=" text-te-blue-800">
                {states[0].TotalLbs} LBS.
              </span>
            </p>
          </div>

          <div className="w-full mt-10 hidden md:block">
            <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold relative">
              <div className="w-32 text-center">Referencia</div>
              <div className="w-32 text-center">Fecha</div>
              <div className="flex-1 text-center">Descripción</div>
              <div className="w-28 text-center">Pieza</div>
              <div
                onMouseEnter={() => {
                  setShowTooltip(true);
                }}
                onMouseLeave={() => {
                  setShowTooltip(false);
                }}
                className="w-28 text-center inline-flex justify-center items-start"
              >
                Peso (LBS) <QuestionMarkCircleIcon className="h-3" />
                {showTooltip && (
                  <div className="absolute right-0 top-0 p-1 bg-gray-400 text-white rounded-lg font-normal normal-case text-xs transform -translate-y-5">
                    Peso ya incluye Fuel Surcharge
                  </div>
                )}
              </div>
            </div>
            <div className="w-full space-y-4 p-3">
              {states.length &&
                states.map((value, index) => (
                  <div
                    key={index}
                    className="flex bg-te-gray-700 bg-opacity-50 rounded-lg"
                  >
                    <div className="w-32 py-3 flex justify-center">
                      {typeof value.RegId === "string" ? value.RegId : "-"}
                    </div>
                    <div className="w-32 flex items-center justify-center text-sm">
                      {DateTime.fromISO(
                        value.Fecha.replace(/ /g, "T")
                      ).toFormat("dd-MM-yyyy")}
                    </div>
                    <div className="flex-1 flex items-center justify-center text-sm">
                      {typeof value.Guia === "string" ? value.Guia : "-"}
                    </div>
                    <div className="w-28 flex items-center justify-center text-sm">
                      {typeof value.Pza === "string" ? value.Pza : "-"}
                    </div>
                    <div className="w-28 flex items-center justify-center text-sm">
                      {typeof value.PesoCobrado === "string"
                        ? value.PesoCobrado
                        : "-"}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="bg-te-blue-100 mt-5 rounded-lg p-4 md:mx-3">
            <p className=" font-semibold text-te-gray-800 text-lg flex justify-between">
              <span className="">Peso Acumulado:</span>{" "}
              <span className=" text-te-blue-800">{states[0].TotalLbs}</span>
            </p>
          </div>

          <div className="w-full mt-5 md:hidden pb-10">
            <div className="w-full space-y-4">
              {states.length &&
                states.map((value, index) => (
                  <div
                    key={index}
                    className="flex flex-col bg-te-gray-700 bg-opacity-50 rounded-lg p-3 text-te-blue-800"
                  >
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Referencia:
                      </span>{" "}
                      {typeof value.RegId === "string" ? value.RegId : "-"}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Fecha:
                      </span>{" "}
                      {DateTime.fromISO(
                        value.Fecha.replace(/ /g, "T")
                      ).toFormat("dd-MM-yyyy")}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Descripción:
                      </span>{" "}
                      {typeof value.Guia === "string" ? value.Guia : "-"}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Pieza:
                      </span>{" "}
                      {typeof value.Pza === "string" ? value.Pza : "-"}
                    </div>
                    <div className="">
                      <span className=" text-te-gray-800 font-semibold">
                        Peso:
                      </span>{" "}
                      {typeof value.PesoCobrado === "string"
                        ? value.PesoCobrado
                        : "-"}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
