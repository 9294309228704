import React, { useState } from "react";
import { useAuth } from "../../../contexts/auth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Promotion() {
  const { user } = useAuth();
  const [isApplying, setIsApplying] = useState(false);

  const handleApplyClick = async () => {
    setIsApplying(true);

    const requestData = {
      casillero: user.PoBox,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/promotion`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );

      if (response.status === 400) {
        const { message } = response.data;
        if (message === "Cliente ya esta registrado en la promoción.") {
          toast.info("Ya estás registrado en la promoción");
        }
      }
      if (response.status === 200) {
        toast.success("Has accedido a la promoción correctamente");
      }
    } catch (error) {
      console.error("Petition error:", error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("No se pudo acceder a la promoción");
      }
    } finally {
      setIsApplying(false);
    }
  };

  return (
    <>
      <div className="flex-1 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-2">
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          closeOnClick
          pauseOnHover
          draggable
          progress={undefined}
          theme="dark"
        />

        <h1 className="text-te-blue-800 text-2xl md:text-3xl font-bold mb-2">
          PROMOCIÓN
        </h1>
        <h2>
          TE REGALAMOS 50% del peso por cada libra recibida
          <br />
          01 DE SEPTIEMBRE AL 31 DE OCTUBRE DE 2023
        </h2>
        <h2 className="text-te-blue-800 text-xl md:text-2xl font-bold mt-4">
          Términos y condiciones
        </h2>
        <ol className="pl-6 mt-2 list-decimal">
          <li>
            Acumulan las libras de los paquetes recibidos del 01 septiembre al
            31 de octubre 2023.
          </li>
          <li>Las libras se acumularán el primer día de cada mes.</li>
          <li>Aplica para paquetes con pesos de 1 hasta 19.99 libras.</li>
          <li>
            La vigencia para canjear es del 01 octubre a 31 de diciembre 2023,
            cualquier remanente de libras después de este periodo serán
            anuladas.
          </li>
          <li>
            No aplica para acumular o canjear pesos de cargas marítimas y
            aéreas.
          </li>
          <li>
            No aplica para acumular libras la correspondencia entrante y
            saliente.
          </li>
          <li>
            No aplican las compras en línea en shoppingmall
          </li>
          <li>Las libras no son transferibles a terceros.</li>
          <li>No aplica promoción sobre promoción.</li>
        </ol>

        <div className="flex justify-center md:justify-start my-6">
          <button
            className="text-white bg-te-blue-800 font-semibold px-5 py-2 rounded-lg relative"
            onClick={handleApplyClick}
            disabled={isApplying}
          >
            {isApplying ? "Applying..." : "Aplicar a la promoción"}
          </button>
        </div>
      </div>
    </>
  );
}
