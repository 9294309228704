import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/auth";

export default function DeliveryAddressMobile() {
  const [, setError] = useState("");
  const [address, setAddress] = useState({});
  const [, setIsLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/address`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setAddress(res.data.data);
        } else {
          setError("No se encontraron datos de dirección.");
        }
      })
      .catch(() => {
        setError("No se encontraron datos de dirección.");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <h2 className=" text-te-blue-800 text-xl font-semibold mt-5">
        Mi Domicilio
      </h2>

      <div className="mt-3 mb-10">
        <div className="">
          <label
            htmlFor="email"
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Dirección de entrega
          </label>
          <span
            type="email"
            id="text"
            className="block rounded-lg bg-te-gray-700 mt-1 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {address.Direccion || "-"}
          </span>
        </div>
        <div className="mt-3">
          <label
            htmlFor="email"
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Municipio
          </label>
          <span
            type="email"
            id="text"
            className="block rounded-lg bg-te-gray-700 mt-1 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {address.Ciudad || "-"}
          </span>
        </div>
        <div className="mt-3">
          <label
            htmlFor="email"
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Departamento
          </label>
          <span
            type="email"
            id="text"
            className="block rounded-lg bg-te-gray-700 mt-1 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {address.Departamento || "-"}
          </span>
        </div>

        <p className="mt-5 mb-5 text-te-blue-800 font-semibold text-sm">
          Si deseas editar tu dirección de entrega llámanos al 2209-1509 ó
          escríbenos a nuestro WhatsApp 2209-1511.
        </p>
      </div>
    </div>
  );
}
