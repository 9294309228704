import { ChevronRightIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { ReactComponent as Dollar } from "../../../assets/images/icons/dollar.svg";
import { ReactComponent as Star } from "../../../assets/images/icons/star.svg";
import Spinner from "../../utils/Spinner";
import RedeemPointsModal from "./RedeemPointsModal";

export default function RedeemPoints({ isLoading, userPoints, getPoints }) {
  const [values, setValues] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <div className="">
      {isLoading ? (
        <div className="flex items-center py-12 justify-center">
          <Spinner color="#0d1256" type="spinningBubbles" />
        </div>
      ) : (
        <div>
          {userPoints > 500 ? (
            <p className=" text-center text-te-blue-800 font-semibold text-xl my-6">
              Certificados disponibles
            </p>
          ) : (
            <span className="block text-center font-semibold text-te-blue-800 py-10">
              No tiene certificados disponibles.
            </span>
          )}
          <div className="px-5 space-y-4 pb-5">
            {userPoints >= 500 && (
              <div className=" bg-te-gray-700 bg-opacity-50 py-3 px-5 flex justify-between items-center rounded-lg">
                <div className="flex items-center">
                  <div className="w-64 flex items-center">
                    <Dollar className="h-6 mr-3" />
                    <span className=" text-lg text-te-blue-800 font-semibold">
                      $5.00
                    </span>
                  </div>
                  <div className="w-64 flex items-center">
                    <Star className="h-6 mr-3" />
                    <span className=" text-lg text-te-orange-400 font-semibold">
                      500 pts.
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setValues({
                      points: 500,
                      description: "Certificado por $5",
                    });
                  }}
                  className=" cursor-pointer py-3 px-5 w-32 justify-center flex items-center bg-te-blue-800 text-white font-semibold rounded-lg relative text-sm"
                >
                  Canjear{" "}
                  <ChevronRightIcon className="h-4 absolute right-2 text-white" />
                </div>
              </div>
            )}
            {userPoints >= 1000 && (
              <div className=" bg-te-gray-700 bg-opacity-50 py-3 px-5 flex justify-between items-center rounded-lg">
                <div className="flex items-center">
                  <div className="w-64 flex items-center">
                    <Dollar className="h-6 mr-3" />
                    <span className=" text-lg text-te-blue-800 font-semibold">
                      $10.00
                    </span>
                  </div>
                  <div className="w-64 flex items-center">
                    <Star className="h-6 mr-3" />
                    <span className=" text-lg text-te-orange-400 font-semibold">
                      1000 pts.
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setValues({
                      points: 1000,
                      description: "Certificado por $10",
                    });
                  }}
                  className=" cursor-pointer py-3 px-5 w-32 justify-center flex items-center bg-te-blue-800 text-white font-semibold rounded-lg relative text-sm"
                >
                  Canjear{" "}
                  <ChevronRightIcon className="h-4 absolute right-2 text-white" />
                </div>
              </div>
            )}
            {userPoints >= 2000 && (
              <div className=" bg-te-gray-700 bg-opacity-50 py-3 px-5 flex justify-between items-center rounded-lg">
                <div className="flex items-center">
                  <div className="w-64 flex items-center">
                    <Dollar className="h-6 mr-3" />
                    <span className=" text-lg text-te-blue-800 font-semibold">
                      $20.00
                    </span>
                  </div>
                  <div className="w-64 flex items-center">
                    <Star className="h-6 mr-3" />
                    <span className=" text-lg text-te-orange-400 font-semibold">
                      2000 pts.
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setValues({
                      points: 2000,
                      description: "Certificado por $20",
                    });
                  }}
                  className=" cursor-pointer py-3 px-5 w-32 justify-center flex items-center bg-te-blue-800 text-white font-semibold rounded-lg relative text-sm"
                >
                  Canjear{" "}
                  <ChevronRightIcon className="h-4 absolute right-2 text-white" />
                </div>
              </div>
            )}
            {userPoints >= 5000 && (
              <div className=" bg-te-gray-700 bg-opacity-50 py-3 px-5 flex justify-between items-center rounded-lg">
                <div className="flex items-center">
                  <div className="w-64 flex items-center">
                    <Dollar className="h-6 mr-3" />
                    <span className=" text-lg text-te-blue-800 font-semibold">
                      $50.00
                    </span>
                  </div>
                  <div className="w-64 flex items-center">
                    <Star className="h-6 mr-3" />
                    <span className=" text-lg text-te-orange-400 font-semibold">
                      5000 pts.
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setValues({
                      points: 5000,
                      description: "Certificado por $50",
                    });
                  }}
                  className=" cursor-pointer py-3 px-5 w-32 justify-center flex items-center bg-te-blue-800 text-white font-semibold rounded-lg relative text-sm"
                >
                  Canjear{" "}
                  <ChevronRightIcon className="h-4 absolute right-2 text-white" />
                </div>
              </div>
            )}
            {/* {userPoints >= 10000 } */}
            {false && (
              <div className=" bg-te-gray-700 bg-opacity-50 py-3 px-5 flex justify-between items-center rounded-lg">
                <div className="flex items-center">
                  <div className="w-64 flex items-center">
                    <Dollar className="h-6 mr-3" />
                    <span className=" text-lg text-te-blue-800 font-semibold">
                      $100.00
                    </span>
                  </div>
                  <div className="w-64 flex items-center">
                    <Star className="h-6 mr-3" />
                    <span className=" text-lg text-te-orange-400 font-semibold">
                      10000 pts.
                    </span>
                  </div>
                </div>
                <div
                  onClick={() => {
                    setIsOpen(true);
                    setValues({
                      points: 10000,
                      description: "Certificado por $100",
                    });
                  }}
                  className=" cursor-pointer py-3 px-5 w-32 justify-center flex items-center bg-te-blue-800 text-white font-semibold rounded-lg relative text-sm"
                >
                  Canjear{" "}
                  <ChevronRightIcon className="h-4 absolute right-2 text-white" />
                </div>
              </div>
            )}
          </div>
          <RedeemPointsModal
            values={values}
            isOpen={isOpen}
            closeModal={closeModal}
            getPoints={getPoints}
          />
        </div>
      )}
    </div>
  );
}
