import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import closeIcon from "../../../assets/images/icons/close-modal.svg";
import { useAuth } from "../../../contexts/auth";
import Spinner from "../../utils/Spinner";

export default function RedeemPointsModal({
  isOpen,
  closeModal,
  values,
  getPoints,
}) {
  const closeButtonRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { user } = useAuth();

  const redeem = () => {
    const body = {
      email: user.Correo,
      points: values.points,
      description: values.description,
    };

    setIsLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/points`,
        body,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        closeModal();
        getPoints();
      })
      .catch(() => {
        setError("Algo salió mal. Inténtalo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const { handleSubmit } = useForm();

  const onSubmit = () => {
    redeem();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left align-middle transition-all transform">
              <div className="flex justify-end mb-3">
                <span
                  ref={closeButtonRef}
                  onClick={closeModal}
                  className="cursor-pointer"
                >
                  <img src={closeIcon} alt="" className="h-8" />
                </span>
              </div>
              <div className="bg-white shadow-xl rounded-2xl p-6">
                <Dialog.Title
                  as="h3"
                  className="text-2xl text-center font-bold text-te-blue-900"
                >
                  Está seguro de canjear {values.points} puntos?
                </Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                  <div className="flex flex-col lg:flex-row lg:space-x-5">
                    <button
                      type="button"
                      onClick={closeModal}
                      disabled={isLoading}
                      className=" bg-white w-full text-te-blue-900 border-2 border-te-blue-900 font-semibold px-10 py-2 rounded-lg mt-3"
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className=" bg-te-blue-900 w-full text-white font-semibold px-10 py-2 rounded-lg mt-3 relative"
                    >
                      Canjear
                      {isLoading && (
                        <div className="absolute right-3 top-0 h-full flex items-center">
                          <Spinner color="#ffffff" type="spinningBubbles" />
                        </div>
                      )}
                    </button>
                  </div>
                  {error && (
                    <div className="block mt-4 text-te-red-800 font-semibold text-center">
                      *{error}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}