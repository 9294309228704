import React from "react";
import { Link } from "react-router-dom";

export default function CustomLink({ children, to, className }) {
  if (to.charAt(0) === "h") {
    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}
      </a>
    );
  }

  if (to.charAt(0) === "/") {
    return (
      <Link to={to} className={className}>
        {children}
      </Link>
    );
  }

  return (
    <Link to="/" className={className}>
      {children}
    </Link>
  );
}
