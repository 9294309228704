import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import closeIcon from "../../assets/images/icons/close-modal.svg";
import ReactMarkdown from "react-markdown";

export default function MainPopUp({ contentPopUp }) {
  const closeButtonRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(contentPopUp?.ActivarPopUp || false);
  }, [contentPopUp?.ActivarPopUp]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={() => setIsOpen(false)}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block my-8 w-full overflow-hidden text-left align-middle transition-all transform">
              <div className="max-w-xl 2xl:max-w-2xl mx-auto">
                <div className="flex justify-end mb-3">
                  <span
                    ref={closeButtonRef}
                    onClick={() => setIsOpen(false)}
                    className=" cursor-pointer"
                  >
                    <img src={closeIcon} alt="" className="h-8" />
                  </span>
                </div>
                <div className="bg-white shadow-xl rounded-2xl p-3 lg:p-6 prose max-w-none">
                  <ReactMarkdown>{contentPopUp.Contenido}</ReactMarkdown>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
