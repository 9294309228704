import React, { useEffect, useState } from "react";
import blank from "../../../assets/images/locker/blank-invoice.png";
import addFile from "../../../assets/images/icons/add-file.svg";
import { ReactComponent as Alert } from "../../../assets/images/icons/alert.svg";
import { ReactComponent as Question } from "../../../assets/images/icons/question.svg";
import { useAuth } from "../../../contexts/auth";
import { useForm } from "react-hook-form";
// import CopyPasteInvoice from "../../sendInvoice/CopyPasteInvoice";
import { sendInvoice } from "../../../services/sendInvoice";
import Spinner from "../../utils/Spinner";
import s3, { config } from "../../../configs/spaces";
import { DateTime } from "luxon";
import { getFileExtension } from "../../utils/getFileExtension";
import Tooltip from "../../tooltip/Tooltip";

export default function SendInvoice() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState("");
  // const [isOpen, setIsOpen] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({});

  const watchFile = watch("file", false);

  // const closeModal = () => {
  //   setIsOpen(false);
  // };

  // const openModal = () => {
  //   setIsOpen(true);
  // };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setError("");

    const blob = data.file[0];

    blob.hash = DateTime.now().toMillis();

    const ramdom = Math.random().toString(36).slice(2);

    const extension = getFileExtension(blob.name);

    const params = {
      ACL: "public-read",
      Body: blob,
      Bucket: `${config.bucketName}`,
      Key: `te-facturas/${blob.hash}${ramdom}.${extension}`,
    };

    // Sending the file to the Spaces
    s3.putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          setError("No se pudo subir el archivo.");
          return;
        } else {
          const imageUrl = `${config.digitalOceanSpaces}te-facturas/${blob.hash}${ramdom}.${extension}`;
          callback(imageUrl, `${blob.hash}${ramdom}.${extension}`);
        }
      });

    const callback = (imageUrl, fileName) => {
      sendInvoice(data, user, imageUrl, fileName)
        .then((res) => {
          if (res.data.message === "Email no existe") {
            setError("El correo ingresado no existe.");
            return;
          }
          if (res.data && !res.data.error) {
            setSuccessful(true);
            reset();
          } else {
            setError("El servicio no está disponible. Inténtelo más tarde.");
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  };

  useEffect(() => {
    if (user) {
      reset({
        email: user.Correo,
        locker: user.PoBox,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-1">
      <h1 className="text-te-blue-800 text-3xl font-bold hidden lg:block">
        Envíanos tu factura
      </h1>

      <div className="mt-10 bg-te-blue-100 text-te-blue-800 items-center rounded-xl p-4 font-semibold text-2xl hidden lg:flex">
        <Alert className=" text-te-blue-800 h-6 mr-3" /> Alértanos tus compras antes de recibirlas en Miami y evita retrasos.
      </div>

      <div className="mt-10 lg:mt-5 border-2 border-te-gray-700 rounded-xl p-6 mb-20">
        <div className="md:flex md:space-x-5">
          <div className="md:w-1/2">
            <div className="">
              <label htmlFor="Contenido" className="text-sm text-te-gray-800 font-semibold flex justify-between items-center">
                Contenido del paquete <Tooltip tooltip="No colocar caracteres especial puntos, comas o signos." />
              </label>
              <input
                id="Contenido"
                type="text"
                {...register("Contenido", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.Contenido && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="mt-3 ">
              <label htmlFor="tracking" className="text-sm text-te-gray-800 font-semibold mt-1 flex justify-between items-center" >
                N. de Tracking de tu transportista <Tooltip tooltip="El número de tracking o guía de rastreo del transportista debe ser correcto, para que su prealerta sea efectiva." />
              </label>
              <input
                id="tracking"
                type="text"
                {...register("tracking", { required: true })}
                placeholder="Agregar número de tracking"
                className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
              />
              {errors.tracking && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>

            <div className="mt-10 hidden md:block">
              <img src={blank} alt="" className="mx-auto w-32" />
            </div>
          </div>

          <div className="md:w-1/2 mt-4 lg:mt-0">
            <div className="">
              <label
                htmlFor="Remitente"
                className="block font-semibold text-te-gray-800 text-sm"
              >
                Remitente/Tienda
              </label>
              <input
                id="Remitente"
                type="text"
                {...register("Remitente", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.Remitente && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>

            <div className="mt-3">
              <label
                htmlFor="email"
                className=" font-semibold text-te-gray-800 text-sm"
              >
                Correo electrónico
              </label>
              <input
                id="email"
                type="email"
                {...register("email", { required: true })}
                className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
              />
              {errors.email && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>

            <div className="">
              <label
                htmlFor=""
                className="text-sm text-te-gray-800 font-semibold mt-5 flex justify-between items-center"
              >
                Factura comercial
                <Tooltip tooltip="Asegúrate de subir la factura correspondiente al tracking o guía de rastreo del transportista. " />
              </label>
              {!watchFile?.length ? (
                <div
                  onClick={() => document.getElementById("file").click()}
                  className="border-2 border-te-gray-700 bg-te-blue-100 lg:bg-white bg-opacity-30 hover:bg-te-blue-100 transition hover:bg-opacity-30 border-dashed rounded-xl py-5 mt-2 cursor-pointer"
                >
                  <div className="">
                    <img src={addFile} alt="" className="h-14 mx-auto" />
                  </div>
                  <>
                    <p className="text-center mt-3 text-sm">
                      <span className=" text-te-orange-400 font-semibold">
                        Sube un archivo
                      </span>
                    </p>
                    <p className="text-te-gray-800 text-center text-sm">
                      PNG, JPG, JPEG, DOCX, XLSX, PDF
                    </p>
                  </>
                </div>
              ) : (
                <div className=" text-te-blue-800 text-center px-5 py-5 mt-5 font-semibold flex flex-col justify-center items-center border rounded-lg">
                  {document.getElementById("file").files[0].name}

                  <span
                    className=" text-te-blue-800 underline cursor-pointer"
                    onClick={() => {
                      reset({ ...getValues(), file: "" });
                    }}
                  >
                    Quitar archivo
                  </span>
                </div>
              )}
              {errors.file?.type === "acceptedFormats" && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *{errors.file.message}
                </span>
              )}
              {errors.file?.type === "limitSize" && (
                <span className="block text-te-red-900 text-xs mt-2">
                  *{errors.file.message}
                </span>
              )}
              {errors.file?.type === "required" && (
                <span className="block text-te-red-900 text-xs mt-2">
                  *Es necesario subir tu factura.
                </span>
              )}
              <input
                type="file"
                className="hidden"
                id="file"
                accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
                {...register("file", {
                  required: true,
                  validate: {
                    acceptedFormats: (files) => {
                      if (files[0]) {
                        return (
                          [
                            "image/jpg",
                            "image/jpeg",
                            "image/png",
                            "application/pdf",
                            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "application/vnd.ms-excel",
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "application/msword",
                          ].includes(files[0]?.type) ||
                          "Este tipo de archivo no es permitido."
                        );
                      } else {
                        return null;
                      }
                    },
                    limitSize: (files) => {
                      if (files[0]) {
                        const maxAllowedSize = 4 * 1024 * 1024;
                        return (
                          files[0].size < maxAllowedSize ||
                          "El tamaño maximo de archivo debe ser de 4MB."
                        );
                      } else {
                        return null;
                      }
                    },
                  },
                })}
              />
            </div>

            {/* <div className="items-center justify-between mt-3 hidden lg:flex">
              <div className="text-te-gray-800 font-semibold text-sm flex items-center">
                <input
                  type="checkbox"
                  onChange={() => {
                    openModal(true);
                  }}
                  className="mr-2"
                />
                Copiar y pegar factura comercial.
              </div>
              <Question className="h-4" />
              <CopyPasteInvoice
                isOpen={isOpen}
                closeModal={closeModal}
                register={register}
              />
            </div> */}

            <div className="mt-6 lg:flex lg:space-x-5">
              <button
                type="submit"
                disabled={isLoading}
                className=" w-full bg-te-blue-400 text-white font-semibold rounded-lg py-2 relative"
              >
                Enviar factura
                {isLoading && (
                  <div className="absolute right-2 top-0 h-full flex items-center">
                    <Spinner color="#ffffff" type="spinningBubbles" />
                  </div>
                )}
              </button>
              <button
                type="button"
                onClick={() => {
                  reset();
                }}
                disabled={isLoading}
                className=" w-full bg-white text-blue-800 border border-te-blue-800 rounded-lg font-semibold py-2 mt-4 lg:mt-0"
              >
                Limpiar campos
              </button>
            </div>

            {error && (
              <div className=" text-te-red-900 font-semibold mt-5">
                *{error}
              </div>
            )}

            {successful && (
              <div className=" text-te-green-800 font-semibold mt-5">
                *La factura de tu compra ha sido enviada con éxito.
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
