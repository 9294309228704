import axios from "axios";
import React, { useEffect, useState } from "react";
import Content from "../../components/terms/Content";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function Terms() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/terminos-y-condiciones?populate[Seo][populate]=*`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <Seo data={content.Seo} />

      <SiteLayout>
        <Content data={content} />
      </SiteLayout>
    </main>
  );
}
