import React, { useEffect } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";

export default function PaymentMethodStep({ setMeta, setCardNumber, setExpiryDate, setCvc, cardNumber, expiryDate, cvc }) {
  const ERROR_MESSAGES = {
    emptyCardNumber: "El número de la tarjeta es inválido",
    invalidCardNumber: "El número de la tarjeta es inválido",
    emptyExpiryDate: "La fecha de expiración es inválida",
    monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
    yearOutOfRange: "El año de expiración no puede estar en el pasado",
    dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
    invalidExpiryDate: "La fecha de expiración es inválida",
    emptyCVC: "El código de seguridad es inválido",
    invalidCVC: "El código de seguridad es inválido",
  };

  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
  });

  useEffect(() => {
    setMeta(meta);

    //eslint-disable-next-line
  }, [meta.error]);

  return (
    <div>
      <div className="mt-4">
        <div className="w-full mt-3 md:mt-0">
          <label
            htmlFor="cardNumber"
            className=" font-semibold text-te-blue-800 text-sm"
          >
            Número de la tarjeta
          </label>
          <div className="bg-te-gray-700 rounded-lg px-3 mt-2 flex items-center py-2">
            <input
              {...getCardNumberProps({
                onChange: (e) => setCardNumber(e.target.value),
              })}
              id="cardNumber"
              value={cardNumber}
              placeholder="0000 0000 0000 0000"
              className="bg-transparent appearance-none w-full focus:outline-none border-0 focus:ring-0 font-semibold text-te-blue-800"
            />
            <svg {...getCardImageProps({ images })} className="flex-shrink-0" />
          </div>
          {meta.touchedInputs?.cardNumber && meta.erroredInputs?.cardNumber && (
            <span className=" text-te-red-900 text-xs mt-2">
              *{meta.erroredInputs?.cardNumber}
            </span>
          )}
        </div>
      </div>

      <div className="mt-4">
        <div className="">
          <label
            htmlFor="lastname"
            className=" font-semibold text-te-blue-800 text-sm"
          >
            Fecha de expiración
          </label>
          <div className="bg-te-gray-700 rounded-lg px-3 mt-2 flex items-center py-2">
            <input
              {...getExpiryDateProps({
                onChange: (e) => setExpiryDate(e.target.value),
              })}
              value={expiryDate}
              placeholder="MM / YY"
              className="bg-transparent appearance-none w-full focus:outline-none border-0 focus:ring-0 font-semibold text-te-blue-800"
            />
          </div>
          {meta.touchedInputs?.expiryDate && meta.erroredInputs?.expiryDate && (
            <span className=" text-te-red-900 text-xs mt-2">
              {meta.erroredInputs?.expiryDate}
            </span>
          )}
        </div>
        <div className="mt-4">
          <label
            htmlFor="lastname"
            className=" font-semibold text-te-blue-800 text-sm"
          >
            Código de seguridad
          </label>
          <div className="bg-te-gray-700 rounded-lg px-3 mt-2 flex items-center py-2">
            <input
              {...getCVCProps({
                onChange: (e) => setCvc(e.target.value),
              })}
              value={cvc}
              placeholder="000"
              className="bg-transparent appearance-none w-full focus:outline-none border-0 focus:ring-0 font-semibold text-te-blue-800"
            />
          </div>
          {meta.touchedInputs?.cvc && meta.erroredInputs?.cvc && (
            <span className=" text-te-red-900 text-xs mt-2">
              *{meta.erroredInputs?.cvc}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
