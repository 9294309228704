export const getStatusColorbyTracking = (status) => {
  if (status.entregado === "S") {
    return "text-te-green-800 bg-te-green-200";
  } else if (status.domicilio === "S") {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (status.bodega === "S") {
    return "text-te-yellow-300 bg-te-yellow-200";
  } else if (status.aduana === "S") {
    return "text-te-blue-300 bg-te-blue-200";
  } else if (status.Despachado === "S") {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (status.Retenido === "S") {
    return "text-te-red-800 bg-te-red-200";
  } else if (status.Miami === "S") {
    return "text-te-blue-400 bg-te-blue-100";
  }
};

export const getStatusTextbyTracking = (status) => {
  if (status.entregado === "S") {
    return "Entregado";
  } else if (status.domicilio === "S") {
    return "Domicilio";
  } else if (status.bodega === "S") {
    return "Recibido Bodega";
  } else if (status.aduana === "S") {
    return "En Aduana";
  } else if (status.Despachado === "S") {
    return "Despachado";
  } else if (status.Retenido === "S") {
    return "Retenido";
  } else if (status.Miami === "S") {
    return "Despachado Miami";
  }
};
