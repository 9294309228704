import React from "react";
import servicesAndTypes1 from "../../assets/images/importCargo/servicesAndTypes1.png";
import servicesAndTypes2 from "../../assets/images/importCargo/servicesAndTypes2.png";

export default function ServicesAndTypes({ dataCMS }) {
  return (
    <section className="bg-te-gray-700">
      <div className="container lg;px-32 py-10 lg:py-16">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-3xl font-semibold"
        >
          {dataCMS?.TituloServicios}
        </h3>

        <div className="mt-8 lg:mt-16 lg:container flex flex-col lg:flex-row lg:justify-center">
          <div
            data-aos="fade-up"
            className="lg:w-1/2 flex flex-col lg:flex-row"
          >
            <div className="mt-2">
              <img src={servicesAndTypes1} alt="" className="w-24" />
            </div>
            <div className="lg:ml-5">
              <h5 className=" text-3xl text-blue-900 font-bold">
                {dataCMS?.TituloServicio1}
              </h5>
              <p className=" text-te-gray-800 mt-5 whitespace-pre-line">
                {dataCMS?.DescripcionServicio1}
              </p>
            </div>
          </div>
          <div
            data-aos="fade-up"
            className="lg:w-1/2 flex flex-col lg:flex-row mt-8 lg:mt-0"
          >
            <div className="mt-2">
              <img src={servicesAndTypes2} alt="" className="w-24" />
            </div>
            <div className="lg:ml-5">
              <h5 className=" text-3xl text-blue-900 font-bold">
                {dataCMS?.TituloServicio2}
              </h5>
              <p className=" text-te-gray-800 mt-5 whitespace-pre-line">
                {dataCMS?.DescripcionServicio2}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
