import React from "react";
import ReactMarkdown from "react-markdown";

export default function ContentReferrals({ data }) {
  return (
    <div>
      <section className="lg:container">
        <div className="lg:mt-10 h-64 lg:h-96 lg:rounded-2xl overflow-hidden relative">
          <div className="absolute h-full w-full bg-te-gray-700"></div>
          <div className="absolute w-full h-full flex flex-col items-center justify-center">
            <h1
              data-aos="fade-up"
              className=" text-te-blue-800 relative text-2xl lg:text-3xl font-bold text-center"
            >
              Términos y Condiciones <br />
              Referidos
            </h1>
          </div>
        </div>
      </section>

      <section className="text-te-gray-800 container xl:px-32 mb-20">
        <div data-aos="fade-up" className="mt-16 prose lg:prose-lg max-w-none text-justify">
          <ReactMarkdown>{data.Contenido}</ReactMarkdown>
        </div>
      </section>
    </div>
  );
}
