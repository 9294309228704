import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
//import { useAuth } from "../../contexts/auth";

export default function Dropdown({ content }) {
  //const { user } = useAuth();

  return (
    <div className="">
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <Menu.Button
              className={`inline-flex justify-center items-center w-full px-2 py-1 focus:outline-none font-semibold rounded-lg hover:bg-te-blue-100 hover:text-te-blue-800 text-sm z${
                open ? "bg-te-blue-100 text-te-blue-800" : "text-te-gray-800"
              }`}
            >
              {content?.TituloDropDown}
              <ChevronDownIcon
                className={`ml-1 h-5 w-5 transform transition duration-200 ${
                  open ? "rotate-180" : ""
                }`}
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-52 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-2">
                  {content.OpcionesDropDown.map((navItem, index) => (
                    <Menu.Item key={index}>
                      {({ active }) => (
                        <NavLink
                          to={navItem.Enlace}
                          activeClassName="bg-te-blue-100"
                          className={`${
                            active ? "bg-te-blue-100" : ""
                          } group flex rounded-md items-center w-full px-2 py-2 text-te-blue-800 font-semibold`}
                        >
                          {navItem.Etiqueta}
                        </NavLink>
                      )}
                    </Menu.Item>
                  ))}
                  {/* {content?.TituloDropDown === "Nuestros Servicios" && (
                    <Menu.Item>
                      {({ active }) => (
                        <span
                          onClick={() => {
                            user?.autologinUrl
                              ? window.location.replace(user.autologinUrl)
                              : window.location.replace(
                                  process.env.REACT_APP_SHOPPING_MALL_BASE_URL
                                );
                          }}
                          className={`${
                            active ? "bg-te-blue-100" : ""
                          } group flex rounded-md items-center w-full px-2 py-2 text-te-blue-800 font-semibold cursor-pointer`}
                        >
                          Shopping hola
                        </span>
                      )}
                    </Menu.Item>
                  )} */}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
