import React from "react";
import CalculatorForm from "./CalculatorForm";

export default function Main({ dataCMS }) {
  function scrollToSection(id) {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <section className="container flex flex-col lg:flex-row py-10 lg:py-20 2xl:px-20">
      <div className="lg:w-1/2 flex justify-center items-center">
        <div className="">
          <h1
            data-aos="fade-up"
            className="text-te-orange-400 text-2xl font-semibold"
          >
            {dataCMS?.TituloNaranja}
          </h1>
          <h3
            data-aos="fade-up"
            className="font-bold text-te-blue-800 text-3xl lg:text-5xl mt-5 whitespace-pre-line"
          >
            {dataCMS?.Titulo}
          </h3>
          {/* <p
            data-aos="fade-up"
            className="text-te-gray-800 mt-5 max-w-md lg:text-xl whitespace-pre-line"
          >
            {dataCMS?.Descripcion}
          </p> */}
          <p
            data-aos="fade-up"
            className="text-te-blue-800 mt-5 max-w-md font-semibold lg:text-xl border-l-4 border-te-blue-400 pl-5"
          >
            Por compras menores de $300 <br /> no pagas *DAI, sólo IVA.
          </p>
          <p
            data-aos="fade-up"
            className="text-te-blue-800 mt-5 max-w-md font-semibold lg:text-xl cursor-pointer underline"
            onClick={() => scrollToSection("scroll-section")}
            id="scroll-section"
          >
            *Aplica Términos y Condiciones
          </p>
        </div>
      </div>
      <div data-aos="fade-up" className="lg:w-1/2 flex justify-center">
        <CalculatorForm />
      </div>
    </section>
  );
}
