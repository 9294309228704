import {
  ArrowRightIcon,
  CheckIcon,
} from "@heroicons/react/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ButtonBasic from "../buttons/Basic";

export default function Content() {
  const [content, setContent] = useState([]);
  const [selected, setSelected] = useState(0);
  const history = useHistory();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/tipos-de-mercaderia-restringidas?populate[Listado][populate]=*`
      )
      .then((response) => {
        setContent(response.data?.data);
        setSelected(response.data?.data[0]);
      });
  }, []);

  return (
    <>
      <section className="mt-5 lg:mt-20 container lg:px-32 flex flex-col lg:flex-row pb-20 lg:pb-32">
        <Options
          types={content}
          selected={selected}
          setSelected={setSelected}
        />

        <div data-aos="fade-up" className="lg:w-2/3">
          <p className=" text-te-gray-800 lg:text-lg whitespace-pre-line">
            {selected.attributes?.Descripcion}
          </p>

          <ul className="space-y-1 bg-te-gray-600 rounded-xl p-1 lg:p-4 mt-8 inline-block w-full max-w-xl">
            {selected.attributes?.Listado.map((value, index) => (
              <li
                key={index}
                className=" text-te-gray-800 font-semibold flex items-start text-sm lg:text-lg"
              >
                <CheckIcon className=" text-te-green-700 h-5 mr-2 mt-1 flex-shrink-0" />{" "}
                {value.label}
              </li>
            ))}
          </ul>

          <p className=" text-te-gray-800 lg:text-lg whitespace-pre-line mt-8">
            {selected.attributes?.Contenido}
          </p>
        </div>
      </section>

      <div className="h-40 bg-te-blue-100">
        <div
          data-aos="fade-up"
          className="container lg:px-32 h-full flex justify-between items-center"
        >
          <p className=" text-3xl font-semibold text-te-blue-800">
            ¿Tienes más preguntas?
          </p>
          <ButtonBasic
            onClick={() => history.push("/servicio-al-cliente")}
            classNameFather="bg-te-blue-900"
            className="bg-te-blue-800 text-white"
          >
            Contáctanos
          </ButtonBasic>
        </div>
      </div>
    </>
  );
}

const Options = ({ types, selected, setSelected }) => {
  return (
    <div data-aos="fade-up" className="w-1/3 space-y-2 hidden lg:block">
      {types.map((value, index) => (
        <div
          key={index}
          className={`flex p-4 w-64 items-end justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all ${
            selected.id === value.id ? "bg-te-blue-100" : ""
          }`}
          onClick={() => setSelected(value)}
        >
          {value.attributes?.Titulo}
          <ArrowRightIcon
            className={`h-5 ${
              selected.id === value.id
                ? "text-te-blue-800 "
                : "text-te-orange-400"
            }`}
          />
        </div>
      ))}
    </div>
  );
};
