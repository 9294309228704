import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import closeIcon from "../../assets/images/icons/close-modal.svg";
import Spinner from "../utils/Spinner";

export default function RequestPickupModal({ isOpen, closeModal }) {
  const closeButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [successful, setSuccessful] = useState("");
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const body = {
      to: "Pod2@transexpress.com.sv",
      from: "notificacion@transexpress.com.sv",
      subject: "Solicitud de recolección Courier",
      html: `
      <div className="">
        <p>Un usuario envió una solicitud de recolección Courier en TransExpress, a continuación sus datos:</p>
        <table>
          <tr>
            <td>Nombre de la empresa:</td>
            <td>${data.name}</td>
          </tr>
          <tr>
            <td>Persona que lo solicita:</td>
            <td>${data.solicitante}</td>
          </tr>
          <tr>
            <td>Correo electrónico:</td>
            <td>${data.email}</td>
          </tr>
          <tr>
            <td>Dirección de recolección:</td>
            <td>${data.address}</td>
          </tr>
          <tr>
            <td>Teléfono para contactar:</td>
            <td>${data.phone}</td>
          </tr>
          <tr>
            <td>Libraje estimado:</td>
            <td>${data.libraje}</td>
          </tr>
          <tr>
            <td>Hora en que estará listo:</td>
            <td>${data.hora}</td>
          </tr>
          <tr>
            <td>Comentario:</td>
            <td>${data.comentario}</td>
          </tr>
        </table>
      </div>
      `,
      bcc: ["tecupon2021@gmail.com"],
      cc: [],
    };

    setIsLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/sendmail`, body)
      .then(() => {
        setSuccessful("Se ha enviado tu solicitud!");
        reset();
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left align-middle transition-all transform">
              <div className="flex justify-end mb-3">
                <span
                  ref={closeButtonRef}
                  onClick={closeModal}
                  className=" cursor-pointer"
                >
                  <img src={closeIcon} alt="" className="h-8" />
                </span>
              </div>
              <div className="bg-white shadow-xl rounded-2xl p-6">
                <Dialog.Title
                  as="h3"
                  className="text-2xl text-center font-bold text-te-blue-900"
                >
                  Solicitud de recolección Courier
                </Dialog.Title>
                <p className="mt-3 text-te-gray-800 font-semibold text-lg text-center">
                  Completa el siguiente formulario
                </p>
                <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                  <div className="">
                    <label
                      htmlFor="name"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Nombre de la empresa
                    </label>
                    <input
                      type="text"
                      id="name"
                      {...register("name", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    />
                    {errors.name && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="solicitante"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Persona que lo solicita (Nombre y apellido)
                    </label>
                    <input
                      type="text"
                      id="solicitante"
                      {...register("solicitante", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    />
                    {errors.solicitante && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="email"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Correo electrónico
                    </label>
                    <input
                      type="email"
                      id="email"
                      {...register("email", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    />
                    {errors.email && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="address"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Dirección de recolección
                    </label>
                    <input
                      type="text"
                      id="address"
                      {...register("address", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    />
                    {errors.address && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="phone"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Teléfono para contactar
                    </label>
                    <input
                      type="text"
                      id="phone"
                      {...register("phone", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    />
                    {errors.phone && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>

                  <div className="space-x-5 flex">
                    <div className="mt-3 w-1/2">
                      <label
                        htmlFor="libraje"
                        className=" font-semibold text-te-gray-800 text-sm"
                      >
                        Libraje estimado
                      </label>
                      <input
                        type="text"
                        id="libraje"
                        {...register("libraje", { required: true })}
                        className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                      />
                      {errors.libraje && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *Este campo es requerido.
                        </span>
                      )}
                    </div>
                    <div className="mt-3 w-1/2">
                      <label
                        htmlFor="hora"
                        className=" font-semibold text-te-gray-800 text-sm"
                      >
                        Hora en que estará listo
                      </label>
                      <input
                        type="text"
                        id="hora"
                        {...register("hora", { required: true })}
                        className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                      />
                      {errors.hora && (
                        <span className=" text-te-red-900 text-xs mt-2">
                          *Este campo es requerido.
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="mt-3">
                    <label
                      htmlFor="comentario"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Comentario
                    </label>
                    <textarea
                      id="comentario"
                      rows="3"
                      {...register("comentario", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    ></textarea>
                    {errors.comentario && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="bg-te-blue-900 text-white font-semibold w-full py-2 rounded-lg mt-3 relative"
                  >
                    Solicitar recolección
                    {isLoading && (
                      <div className="absolute right-2 top-0 h-full flex items-center">
                        <Spinner color="#ffffff" type="spinningBubbles" />
                      </div>
                    )}
                  </button>
                </form>

                {error && (
                  <div className="mt-5 text-te-red-900 font-semibold">
                    *{error}
                  </div>
                )}
                {successful && (
                  <div className="mt-5 text-te-green-700 font-semibold">
                    *{successful}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
