import axios from "axios";
import React, { useEffect, useState } from "react";
import ReadyForHelp from "../../components/general/ReadyForHelp";
import MainSendInvoice from "../../components/sendInvoice/Main";
import WhatIs from "../../components/sendInvoice/WhatIs";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function SendInvoice() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/envianos-tu-factura?populate[Seo][populate]=*`,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
          },
        }
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <SiteLayout>
        <Seo data={content.Seo} />

        <MainSendInvoice dataCMS={content} />

        <WhatIs dataCMS={content} />

        <ReadyForHelp />
      </SiteLayout>
    </main>
  );
}
