import React from "react";
import { useHistory } from "react-router";
import lockerService2 from "../../assets/images/lockerService/lockerService2.png";
import ButtonBasic from "../buttons/Basic";

export default function CalculateCost({ dataCMS }) {
  const history = useHistory();

  return (
    <section className="container py-10 lg:py-16 flex flex-col lg:flex-row">
      <div className="lg:w-1/2 flex justify-center items-center">
        <div data-aos="fade-up" className="mb-10 lg:mt-0">
          <h3 className=" text-blue-800 font-bold text-3xl whitespace-pre-line">
            {dataCMS.TituloCalculadora}
          </h3>
          <p className=" text-te-gray-800 mt-4 lg:mt-8 max-w-md text-lg whitespace-pre-line">
            {dataCMS.DescripcionCalculadora}
          </p>
          <ButtonBasic
            onClick={() => history.push("/cotizar-envio")}
            classNameFather="bg-te-blue-800 mt-5 lg:mt-10 w-full lg:w-auto"
            className="bg-te-blue-900 text-white"
          >
            Cotiza tus compras
          </ButtonBasic>
        </div>
      </div>
      <div data-aos="fade-up" className="lg:w-1/2 md:px-10 lg:px-20">
        <img src={lockerService2} alt="" />
      </div>
    </section>
  );
}
