import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Spinner from "../../utils/Spinner";
import { useAuth } from "../../../contexts/auth";
import cities from "../../register/cities.json";
import SelectCities from "../../register/SelectCities";

export default function EditAddressForm({ setShowForm, isEdit }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { user, setUser } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
    reset,
    setValue,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    const departamento = JSON.stringify(
      cities.find((element) => element.id === user.deptodomicilio)
    );

    if (isEdit) {
      reset({
        dir1: user.domi1,
        dir2: user.domi2,
        departamento: departamento,
      });

      setTimeout(() => {
        setValue("municipio", user.domiciudad);
      }, 200);
    }
    // eslint-disable-next-line
  }, [isEdit]);

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");

    const depa = JSON.parse(data.departamento);

    if (isEdit) {
      const body = {
        // ref: user.idcou,
        codigo: user.codigo,
        dir1: data.dir1,
        dir2: data.dir2,
        ciudad: data.municipio,
        depto: depa.id,
      };

      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/address`,
          body,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            const newUser = { ...user };
            newUser.domi1 = data.dir1;
            newUser.domi2 = data.dir2;
            newUser.domiciudad = data.municipio;
            newUser.deptodomicilio = depa.id;
            newUser.domipais = depa.nombre;

            localStorage.setItem("te-user", JSON.stringify(newUser));
            setUser(newUser);
            setShowForm(false);
          } else {
            setError("No se pudo actualizar el domicilio.");
          }
        })
        .catch(() => {
          setError("No se pudo actualizar el domicilio.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const body = {
        codigo: user.codigo,
        dir1: data.dir1,
        dir2: data.dir2,
        ciudad: data.municipio,
        depto: depa.id,
      };

      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/address`,
          body,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 && !res.error) {
            const newUser = { ...user };
            newUser.domi1 = data.dir1;
            newUser.domi2 = data.dir2;
            newUser.domiciudad = data.municipio;
            newUser.deptodomicilio = depa.id;
            newUser.domipais = depa.nombre;
            newUser.CobroPorEntrega = "S";

            localStorage.setItem("te-user", JSON.stringify(newUser));
            setUser(newUser);
            setShowForm(false);
          } else {
            setError("No se pudo guardar el domicilio.");
          }
        })
        .catch(() => {
          setError("No se pudo guardar el domicilio.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <div className="">
        <label
          htmlFor="dir1"
          className=" font-semibold text-te-gray-800 text-sm"
        >
          Dirección de entrega
        </label>
        <input
          id="dir1"
          {...register("dir1", { required: true })}
          className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
        />
        {errors.dir1 && (
          <span className=" text-te-red-900 text-xs mt-2">
            *Este campo es requerido.
          </span>
        )}
        <input
          id="dir2"
          {...register("dir2")}
          className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
        />
        {errors.dir2 && (
          <span className=" text-te-red-900 text-xs mt-2">
            *Este campo es requerido.
          </span>
        )}
      </div>

      <div className="mt-3">
        <SelectCities register={register} watch={watch} errors={errors} />
      </div>

      <div className="mt-5 flex justify-end space-x-5">
        <button
          onClick={() => {
            setShowForm(false);
          }}
          type="reset"
          className="text-te-blue-800 border-2 border-te-blue-800 bg-white font-semibold px-10 py-2 rounded-lg flex items-center relative"
        >
          Cancelar
        </button>
        <button
          disabled={isLoading || !isValid}
          className="text-white bg-te-blue-800 font-semibold px-10 py-2 rounded-lg flex items-center relative disabled:bg-te-gray-800"
        >
          Guardar
          {isLoading && (
            <div className="absolute right-2 top-0 h-full flex items-center">
              <Spinner color="#ffffff" type="spinningBubbles" />
            </div>
          )}
        </button>
      </div>

      <div className="flex justify-end">
        {error && (
          <div className="mt-3 text-te-red-900 font-semibold">*{error}</div>
        )}
      </div>
    </form>
  );
}
