import React from "react";
import ReactMarkdown from "react-markdown";

export default function Main({ dataCMS }) {
  return (
    <section className="lg:container">
      <div className="lg:mt-10 h-64 lg:h-96 lg:rounded-2xl overflow-hidden relative">
        <div className="absolute h-full w-full">
          <img
            src={dataCMS?.MainBanner?.data?.attributes?.url}
            alt=""
            className="h-full object-cover w-full"
          />
        </div>
        <div className=" bg-te-blue-800 bg-opacity-40 absolute w-full h-full"></div>
        <div
          data-aos="fade-up"
          className="absolute w-full h-full flex items-center justify-center"
        >
          <h1 className="text-white relative text-2xl lg:text-3xl font-bold">
            {dataCMS?.MainTitle}
          </h1>
        </div>
      </div>

      <div className="container lg:px-32 mt-6 lg:mt-12 text-te-gray-800">
        <div data-aos="fade-up" className="mt-16 prose lg:prose-lg max-w-none ">
          <ReactMarkdown>{dataCMS.Content}</ReactMarkdown>
        </div>
      </div>
    </section>
  );
}
