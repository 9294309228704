import React, { useState } from "react";
import { useAuth } from "../../../contexts/auth";
import blank from "../../../assets/images/blank.png";
import { PencilIcon } from "@heroicons/react/outline";
import EditPersonalInfoForm from "./EditPersonalInfoForm";

export default function PersonalInfo() {
  const { user } = useAuth();
  const [showForm, setShowForm] = useState(false);

  return (
    <div className="mt-5 border-2 border-te-gray-700 rounded-xl p-6 mb-20">
      <div className="flex items-center justify-between border-b-2 pb-8 pt-4 ">
        <div className="flex items-center">
          <div className="flex items-center">
            <div className="rounded-full overflow-hidden w-16 h-16 mx-3">
              <img src={blank} alt="" className="h-full w-full object-cover" />
            </div>
            <div className="flex flex-col items-start font-semibold mr-3 text-xl">
              <span className=" text-te-blue-800">
                {user?.Name || user?.Company}
              </span>
              <span className=" text-te-orange-400">{user?.PoBox}</span>
            </div>
          </div>
        </div>
        {!showForm && (
          <div className="">
            <button
              onClick={() => {
                setShowForm(true);
              }}
              className=" text-white bg-te-blue-800 font-semibold px-5 py-2 rounded-lg flex items-center relative"
            >
              Editar <PencilIcon className="text-white h-5 ml-2" />
            </button>
          </div>
        )}
      </div>

      {showForm && <EditPersonalInfoForm setShowForm={setShowForm} />}

      {!showForm && (
        <div className="mt-5">
          <div className="flex space-x-5">
            <div className="w-1/2 ">
              <label className="block font-semibold text-te-gray-800 text-sm">
                Nombre o Empresa
              </label>
              <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                {user?.Name || user?.Company}
              </span>
            </div>
            <div className="w-1/2 ">
              <label className="block font-semibold text-te-gray-800 text-sm">
                DUI (Sin guiones)
              </label>
              <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                {typeof user?.dui === "string" ? user?.dui : "-"}
              </span>
            </div>
          </div>
          <div className="flex space-x-5 mt-5">
            <div className="w-1/2">
              <label className="block font-semibold text-te-gray-800 text-sm">
                Correo electrónico
              </label>
              <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                {user?.Correo || "-"}
              </span>
            </div>
            <div className="w-1/2">
              <label className="block font-semibold text-te-gray-800 text-sm">
                NIT (Sin guiones)
              </label>
              <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                {typeof user?.nit === "string" ? user?.nit : "-"}
              </span>
            </div>
          </div>

          <div className="mt-5">
            <div className="flex space-x-5">
              <div className="w-1/2">
                <label className="block font-semibold text-te-gray-800 text-sm ">
                  Teléfono
                </label>
                <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                  {user?.telefono || "-"}
                </span>
              </div>
              <div className="w-1/2"></div>
            </div>
          </div>

          <p className=" text-te-gray-800 font-semibold mt-5">
            Dirección de Residencia
          </p>
          <div className="mt-3">
            <label className="block font-semibold text-te-gray-800 text-sm">
              Dirección
            </label>
            <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
              {user?.Direccion || "-"}
            </span>
            <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
              {user?.Direccion2 || "-"}
            </span>
          </div>
          <div className="flex space-x-5 mt-5">
            <div className="w-1/2">
              <label className="block font-semibold text-te-gray-800 text-sm">
                Municipio
              </label>
              <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                {user?.ciudad || "-"}
              </span>
            </div>
            <div className="w-1/2">
              <label className="block font-semibold text-te-gray-800 text-sm">
                Departamento
              </label>
              <span className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800">
                {user?.Departamento || "-"}
              </span>
            </div>
          </div>
        </div>
      )}

      {/* <p className="mt-10 mb-5 text-te-blue-800 font-semibold">
        <span className=" text-te-gray-800">Nota:</span> Si deseas editar tu
        dirección de entrega llámanos al 2209-1509 ó escríbenos a nuestro
        WhatsApp 2209-1511.
      </p> */}
    </div>
  );
}
