import React from "react";
import check from "../../assets/images/icons/check.svg";

export default function CargoTypes({ dataCMS }) {
  return (
    <section className="hidden lg:block container px-32 py-20">
      <div
        data-aos="fade-up"
        className="bg-te-blue-800 text-white rounded-2xl py-14 px-7 relative"
      >
        <div className="absolute -top-5 left-16 h-10 text-white bg-te-orange-400 flex items-center justify-center px-8 rounded-lg text-xl font-semibold">
          Carga Aérea
        </div>
        <div className="grid grid-cols-2 h-full pb-10">
          <div className="max-w-md h-full">
            <p className="pl-10 text-white font-semibold text-2xl">
              Carga Regular
            </p>
            <div className="mt-5 grid grid-rows-3 h-full">
              {dataCMS.CargaRegular?.map((data) => (
                <div className="flex items-start" key={data.id}>
                  <img src={check} alt="orange check" className="w-4 mt-2" />
                  <p className="pl-6 text-lg">{data.Text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="max-w-md h-full">
            <p className="pl-10 text-white font-semibold text-2xl">
              Carga Expresa
            </p>
            <div className="mt-5 grid grid-rows-3 h-full">
              {dataCMS.CargaExpresa?.map((data) => (
                <div className="flex items-start" key={data.id}>
                  <img src={check} alt="orange check" className="w-4 mt-2" />
                  <p className="pl-6 text-lg">{data.Text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        className="bg-te-blue-800 text-white rounded-2xl py-14 px-7 relative mt-20"
      >
        <div className="absolute -top-5 left-16 h-10 text-white bg-te-orange-400 flex items-center justify-center px-4 rounded-lg text-xl font-semibold">
          Carga Marítima
        </div>
        <div className="grid grid-cols-3 gap-10 h-full">
          {dataCMS.CargaMaritima?.map((data) => (
            <div className="flex items-start" key={data.id}>
              <img src={check} alt="orange check" className="w-4 mt-2" />
              <p className="pl-6 text-lg">{data.Text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
