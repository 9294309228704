import React, { useState } from "react";
import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../contexts/auth";
import axios from "axios";
import Spinner from "../../utils/Spinner";

export default function FormNewCard({ setCards, setIsAddingNewCard }) {
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [error, setError] = useState("");

  const {
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: "all" });

  const ERROR_MESSAGES = {
    emptyCardNumber: "El número de la tarjeta es inválido",
    invalidCardNumber: "El número de la tarjeta es inválido",
    emptyExpiryDate: "La fecha de expiración es inválida",
    monthOutOfRange: "El mes de expiración debe estar entre 01 y 12",
    yearOutOfRange: "El año de expiración no puede estar en el pasado",
    dateOutOfRange: "La fecha de expiración no puede estar en el pasado",
    invalidExpiryDate: "La fecha de expiración es inválida",
    emptyCVC: "El código de seguridad es inválido",
    invalidCVC: "El código de seguridad es inválido",
    unionPay: "La tarjeta debe ser Visa, Mastercard o UnionPay.",
  };

  const cardNumberValidator = ({ cardType }) => {
    if (
      cardType.displayName === "Visa" ||
      cardType.displayName === "Mastercard" ||
      cardType.displayName === "UnionPay"
    ) {
      return;
    }
    return "La tarjeta debe ser Visa ,Mastercard o UnionPay.";
  };

  const {
    meta,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    errorMessages: ERROR_MESSAGES,
    cardNumberValidator,
  });

  const getCardNumber = (type) => {
    if (type === "Visa" || type === "visa") {
      return 1;
    } else if (type === "Mastercard" || type === "mastercard") {
      return 2;
    } else if (type === "UnionPay" || type === "unionpay") {
      return 3;
    } else {
      return 4;
    }
  };

  const onSubmit = () => {
    if (!meta.error) {
      const body = {
        idUser: user.idUser,
        tipo: getCardNumber(meta.cardType?.displayName),
        numero: cardNumber.replace(/ /g, ""),
        mes: expiryDate.slice(0, 2),
        anio: expiryDate.slice(-2),
        cvv: cvc,
      };

      setIsLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/cards`,
          //sent body to backend in formta of json
          body,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        .then((res) => {
          if (res.status === 200 || !res.data.codigo_respuesta === 200) {
            if (res.data.result?.lista_tarjetas.length) {
              window.scrollTo(0, 0);
              setCards(res.data.result?.lista_tarjetas);
            }
            setIsAddingNewCard(false);
          } else {
            setError(res.data.informacion_adicional);
          }
        })
        .catch((error) => {
          if (error.response?.data?.message) {
            setError("El servicio no está disponible. Inténtelo más tarde.");
          } else {
            setError("El servicio no está disponible. Inténtelo más tarde.");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="md:rounded-xl mt-6 md:shadow-md md:border-2 md:border-te-gray-700 md:p-6"
    >
      <h3 className=" text-te-blue-800 text-lg font-semibold">
        Agregar método de pago
      </h3>
      <div className="md:flex md:space-x-8 mt-4">
        <div className="md:w-1/2 mt-3 md:mt-0">
          <label
            htmlFor="cardNumber"
            className=" font-semibold text-te-blue-800 text-sm"
          >
            Número de la tarjeta
          </label>
          <div className="bg-te-gray-700 rounded-lg px-3 mt-2 flex items-center py-2">
            <input
              {...getCardNumberProps({
                onChange: (e) => setCardNumber(e.target.value),
              })}
              id="cardNumber"
              value={cardNumber}
              placeholder="0000 0000 0000 0000"
              className="bg-transparent appearance-none w-full focus:outline-none border-0 focus:ring-0"
            />
            <svg {...getCardImageProps({ images })} className="flex-shrink-0" />
          </div>
          {meta.touchedInputs?.cardNumber && meta.erroredInputs?.cardNumber && (
            <span className=" text-te-red-900 text-xs mt-2">
              *{meta.erroredInputs?.cardNumber}
            </span>
          )}
        </div>
        <div className="md:w-1/2 mt-3 md:mt-0"></div>
      </div>

      <div className="md:flex md:space-x-8 mt-4">
        <div className="md:w-1/2">
          <label
            htmlFor="lastname"
            className=" font-semibold text-te-blue-800 text-sm"
          >
            Fecha de expiración
          </label>
          <div className="bg-te-gray-700 rounded-lg px-3 mt-2 flex items-center py-2">
            <input
              {...getExpiryDateProps({
                onChange: (e) => setExpiryDate(e.target.value),
              })}
              value={expiryDate}
              placeholder="MM / YY"
              className="bg-transparent appearance-none w-full focus:outline-none border-0 focus:ring-0"
            />
          </div>
          {meta.touchedInputs?.expiryDate && meta.erroredInputs?.expiryDate && (
            <span className=" text-te-red-900 text-xs mt-2">
              {meta.erroredInputs?.expiryDate}
            </span>
          )}
        </div>
        <div className="md:w-1/2 mt-3 md:mt-0">
          <label
            htmlFor="lastname"
            className=" font-semibold text-te-blue-800 text-sm"
          >
            Código de seguridad
          </label>
          <div className="bg-te-gray-700 rounded-lg px-3 mt-2 flex items-center py-2">
            <input
              {...getCVCProps({
                onChange: (e) => {
                  if (e.target.value.length <= 3) {
                    setCvc(e.target.value);
                  }
                },
              })}
              value={cvc}
              placeholder="000"
              className="bg-transparent appearance-none w-full focus:outline-none border-0 focus:ring-0"
            />
          </div>
          {meta.touchedInputs?.cvc && meta.erroredInputs?.cvc && (
            <span className=" text-te-red-900 text-xs mt-2">
              *{meta.erroredInputs?.cvc}
            </span>
          )}
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <button
          type="submit"
          disabled={isLoading || meta.error || !isValid}
          className="px-14 py-2 bg-te-orange-400 text-white font-semibold rounded-md relative disabled:bg-te-gray-800 disabled:cursor-not-allowed"
        >
          Guardar
          {isLoading && (
            <div className="absolute right-3 top-0 h-full flex items-center">
              <Spinner color="#ffffff" type="spinningBubbles" />
            </div>
          )}
        </button>
      </div>

      {error && <div className="text-te-red-900 text-xs mt-4">{error}</div>}
    </form>
  );
}
