import React, { useState } from "react";
import { LockClosedIcon } from "@heroicons/react/outline";
import FormNewCard from "./FormNewCard";
import ButtonDeleteCard from "./ButtonDeleteCard";

export default function PaymentMethodMobile({ cards, setCards, error }) {
  const [isAddingNewCard, setIsAddingNewCard] = useState(false);

  return (
    <div>
      <h2 className=" text-te-blue-800 text-xl font-semibold mt-5">
        Método de pago
      </h2>

      {!error && cards.length ? (
        <div className="mt-8 space-y-5">
          {cards.map((value, index) => (
            <div
              key={index}
              className=" bg-te-green-100 bg-opacity-30 p-6 rounded-xl flex justify-between"
            >
              <div className="flex items-center">
                <div className="flex flex-col items-start font-semibold mr-5 text-xl">
                  <span className=" text-te-blue-800">
                    {value.numero_tarjeta}
                  </span>
                </div>
              </div>
              <ButtonDeleteCard value={value} setCards={setCards} />
            </div>
          ))}
        </div>
      ) : (
        <div className=" text-center font-semibold text-te-gray-800 py-10">
          No tienes Metodos de pago agregados.
        </div>
      )}

      {error && (
        <div className=" text-center font-semibold text-te-gray-900 py-10">
          {error}
        </div>
      )}

      {!isAddingNewCard ? (
        <div
          onClick={() => setIsAddingNewCard(true)}
          className="rounded-xl mt-6 text-te-blue-800 text-xl underline font-semibold cursor-pointer"
        >
          Agregar método de pago
        </div>
      ) : (
        <FormNewCard
          setCards={setCards}
          setIsAddingNewCard={setIsAddingNewCard}
        />
      )}

      <div className="mt-6 mb-8 text-te-blue-800 flex items-center font-semibold text-sm">
        <LockClosedIcon className=" text-te-blue-800 h-6 mr-3 flex-shrink-0" />
        <p>
          Todos tus pagos estan asegurados con nuestro sistema de tokenización.{" "}
          <a
            href="https://www.paycomet.com/news/glosario/que-es-tokenizacion/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            Leer más.
          </a>
        </p>
      </div>
    </div>
  );
}
