export const sendEmailData = (casillero, correo, telefono, direccion) => {
  return `
  <div style="margin-top:20px;">
  <img
    src="https://te-prod-space.nyc3.cdn.digitaloceanspaces.com/te-cms/babfee05a253acadfb65aeaafbb1fb3d.png?updated_at=2022-02-15T04:52:11.625Z"
    alt=""
    style="display: block; border: 0; outline: none; text-decoration: none"
    width="139"
    height="33"
    class="CToWUd"
  />

  <h1
    style="
      margin-top: 20px;
      line-height: 43px;
      font-family: 'source sans pro', 'helvetica neue', helvetica, arial,
        sans-serif;
      font-size: 28px;
      font-style: normal;
      font-weight: normal;
      color: #003399;
    "
  >
    <strong>Confirmacion de datos actualizados</strong>
  </h1>

  <p
    style="
      margin-top: 20px;
      line-height: 1.5;
      font-family: 'source sans pro', 'helvetica neue', helvetica, arial,
        sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      color: #003399;
    "
  >
    El casillero: ${casillero}, ha actualizados sus datos correctamente.
  </p>

  <p
    style="
      margin-top: 20px;
      line-height: 1.5;
      font-family: 'source sans pro', 'helvetica neue', helvetica, arial,
        sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      color: #003399;
    "
  >
    Correo para la factura electronica es: ${correo}
  </p>

  <p
    style="
      margin-top: 20px;
      line-height: 1.5;
      font-family: 'source sans pro', 'helvetica neue', helvetica, arial,
        sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      color: #003399;
    "
  >
    Telefono para la factura electronica es: ${telefono}
  </p>

  <p
    style="
      margin-top: 20px;
      line-height: 1.5;
      font-family: 'source sans pro', 'helvetica neue', helvetica, arial,
        sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: normal;
      color: #003399;
    "
  >
    Direccion para la factura electronica es: ${direccion}
  </p>
</div>
  `;
};
