import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import App from './App';

AOS.init({
  offset: 100,
  delay: 100, // values from 0 to 3000, with step 50ms
  duration: 1500,
  once: true,
  mirror: true,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
