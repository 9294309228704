import React from "react";
import { Helmet } from "react-helmet";

export default function Seo({ data }) {
  const { metaTitle, metaDescription, shareImage, keywords, preventIndexing } =
    data || {};

  return (
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} key="description" />
      <meta name="keywords" content={keywords} />
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta property="og:title" content={metaTitle} key="og:title" />
      <meta
        property="og:description"
        content={metaDescription}
        key="og:description"
      />
      <meta
        property="og:image"
        content={`${process.env.REACT_APP_CMS_URL}${shareImage?.data?.attributes?.url}`}
        key="og:image"
      />
      {preventIndexing && (
        <>
          <meta name="robots" content="noindex"></meta>
          <meta name="googlebot" content="noindex"></meta>
        </>
      )}
    </Helmet>
  );
}
