import React from "react";
import { DateTime } from "luxon";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import box from "../../assets/images/icons/locker-box.svg";
import { useHistory } from "react-router-dom";

export default function CourierResult({ courierInfo, packageInfo }) {
  const history = useHistory();

  const courierEvents = courierInfo.data?.ShipmentInfo?.ShipmentEvent || [];

  const courierEventsInverted = [...courierEvents].reverse();

  const getValidDate = (date) => {
    const dateFromServer = DateTime.fromISO(date);

    if (dateFromServer.year > 1980) {
      return dateFromServer.toFormat("dd / MM / yyyy") || "";
    }

    return "-";
  };

  return (
    <>
      <div className="container mt-10 lg:px-32">
        <p className="text-te-blue-800 text-2xl md:text-3xl lg:text-center font-bold">
          Información de la Guia
        </p>

        <div className="md:border-2 border-te-gray-700 rounded-2xl mt-10 px-5 lg:px-10 w-full shadow-lg md:shadow-none">
          <div className="my-5 flex justify-between">
            <div className="flex flex-col md:flex-row w-full">
              <div className="flex justify-between flex-shrink-0 items-center">
                <div className="md:block inline-flex items-center justify-center rounded-full p-3 md:border-2 border-te-gray-700">
                  <img src={box} alt="" className="h-8" />
                </div>
              </div>
              <div className="md:pl-10 space-y-4 text-sm md:text-base mt-3 md:mt-5 w-full">
                <div className="font-semibold w-full flex md:block">
                  <p className="text-te-gray-800">
                    Airway Bill No.:{" "}
                    <span className="flex-1 text-te-blue-800 truncate">
                      {
                        courierInfo.data?.ShipmentInfo?.ShipperReference
                          ?.ReferenceID
                      }
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 flex flex-col lg:flex-row lg:items-end lg:space-x-20 pb-5">
            <div className="font-semibold space-y-2 hidden lg:block">
              <span className="block text-te-gray-800">Compañía:</span>
              <span className="block text-te-gray-800">Nombre:</span>
            </div>
            <div className="font-semibold space-y-2">
              <span className="block text-te-gray-800">Remitente</span>
              <span className="block text-te-blue-800 truncate">
                {packageInfo.CompañiaOrigen?.attributes?.["xml:space"] ||
                  packageInfo.CompañiaOrigen ||
                  "-"}
              </span>

              <span className="block text-te-blue-800 truncate">
                {packageInfo.NombreOrigen?.attributes?.["xml:space"] ||
                  packageInfo.NombreOrigen ||
                  "-"}
              </span>
            </div>
            <div className="font-semibold space-y-2 mt-5 lg:mt-0">
              <span className="block text-te-gray-800">Destinatario</span>
              <span className="block text-te-blue-800 truncate">
                {packageInfo.CompañiaDestino || "-"}
              </span>
              <span className="block text-te-blue-800 truncate">
                {typeof packageInfo.NombreDestino === "string"
                  ? packageInfo.NombreDestino
                  : "-"}
              </span>
            </div>
          </div>

          <p className="mt-5 text-te-blue-800 font-semibold text-lg">
            Prueba de Entrega (POD)
          </p>

          <div className="mt-3 flex flex-col lg:flex-row lg:items-end lg:space-x-20 pb-5">
            <div className="font-semibold space-y-2 hidden lg:block">
              {/* <span className="block text-te-gray-800">Destino:</span> */}
              <span className="block text-te-gray-800">Fecha:</span>
              <span className="block text-te-gray-800">Hora:</span>
              <span className="block text-te-gray-800">Recibido:</span>
            </div>
            <div className="font-semibold space-y-2">
              {/* <span className="block text-te-blue-800 truncate">
              {packageInfo.CompañiaDestino}
            </span> */}
              <div className="block text-te-blue-800 truncate">
                <span className=" text-te-gray-800 lg:hidden">Fecha:</span>{" "}
                {getValidDate(packageInfo.FechaPod)}
              </div>
              <div className="block text-te-blue-800 truncate">
                <span className=" text-te-gray-800 lg:hidden">Hora:</span>{" "}
                {typeof packageInfo?.HoraPod === "string"
                  ? packageInfo?.HoraPod
                  : "-"}
              </div>
              <div className="block text-te-blue-800 truncate">
                <span className=" text-te-gray-800 lg:hidden">Recibido:</span>{" "}
                {packageInfo.RecibioPod || "-"}
              </div>
            </div>
          </div>
        </div>

        <div className="border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold mt-5 hidden md:flex">
          <div className="flex-1">FECHA</div>
          <div className="flex-1">HORA</div>
          <div className="flex-1">CIUDAD</div>
          <div className="flex-1">DESCRIPCION</div>
        </div>

        <div className="w-full space-y-3 py-3">
          {courierEventsInverted.map((value, index) => (
            <div
              key={index}
              className="md:flex bg-te-gray-700 bg-opacity-50 rounded-lg p-3"
            >
              <div className="flex-1 flex items-center text-sm">
                <div className="text-te-gray-800 font-semibold">
                  {DateTime.fromISO(value.Date).toFormat("dd / MM / yyyy")}
                </div>
              </div>
              <div className="flex-1 flex items-center text-sm">
                <div className="text-te-gray-800 font-semibold">
                  {value.Time}
                </div>
              </div>
              <div className="flex-1 flex items-center text-sm">
                <div className="text-te-gray-800 font-semibold">
                  {value.ServiceArea?.Description}
                </div>
              </div>
              <div className="flex-1 flex items-center text-sm">
                <div className="text-te-gray-800 font-semibold">
                  {value.ServiceEvent?.Description}
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center mt-10 mb-20">
          <p
            onClick={() => {
              history.goBack();
            }}
            className="flex items-center underline text-te-blue-800 font-semibold text-2xl cursor-pointer"
          >
            <ArrowLeftIcon className="h-6 text-te-blue-800 mr-2" />
            Regresar
          </p>
        </div>
      </div>
    </>
  );
}
