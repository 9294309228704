import axios from "axios";
import React, { useEffect, useState } from "react";
import Benefits from "../../components/courierService/Benefits";
import Destinations from "../../components/courierService/Destinations";
import MainCourierService from "../../components/courierService/MainCourierService";
import ServicesAndTypes from "../../components/courierService/ServicesAndTypes";
import RequestPickup from "../../components/general/RequestPickup";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function CourierService() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/servicio-courier?populate[0]=ImagenPrincipal&populate[1]=Seo.ShareImage&populate[2]=Beneficio.Imagen&populate[3]=ImagenServicio1&populate[4]=ImagenServicio2`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <Seo data={content?.Seo} />

      <SiteLayout>
        <MainCourierService dataCMS={content} />

        <Benefits dataCMS={content} />

        <ServicesAndTypes dataCMS={content} />

        <Destinations dataCMS={content} />

        <RequestPickup dataCMS={content} />
      </SiteLayout>
    </main>
  );
}
