import React, { useEffect, useState } from "react";
import axios from "axios";
import "./index.css";
// react-toastify
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from "../../../contexts/auth";

export default function ActualizarDatos() {
    const [loading, setLoading] = useState(true);
    const { user } = useAuth();

    const [dui, setDui] = useState("");
    const [correo, setCorreo] = useState("");
    const [creditoFiscal, setCreditoFiscal] = useState("");
    const [nit, setNit] = useState("");
    const [isValid, setIsValid] = useState(false);

    const stylesInput = "block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 disabled:bg-te-gray-300 disabled:text-te-blue-300 disabled:cursor-not-allowed";
    const stylesLabel = "block font-semibold text-te-gray-800 text-sm";

    axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    axios.defaults.headers.common['Content-Type'] = "application/json";

    // Toast Datos Actualizados
    const notify = () => toast.success("Datos actualizados correctamente", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });

    // Toast Error
    const notifyError = () => toast.error("Error al actualizar datos", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
        axios.defaults.headers.common['Content-Type'] = "application/json";

        var formdata = {
            dui: dui,
            correo: correo,
            credito_fiscal: creditoFiscal,
            nit: nit
        };

        let url = `${process.env.REACT_APP_BASE_URL}/actualizar-datos-fac`;

        await axios.post(url, formdata).then((response) => {
            console.log(response);
            if (response.data.status == 200) {
                notify();
            } else {
                notifyError();
            }
        }).catch((error) => {
            console.log(error);
        });
        setLoading(false);
    };

    useEffect(() => {
    }, [])

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (user.dui == null) {
                setIsValid(false);
                setLoading(false);
                return;
            }
            setIsValid(true);
            var formdata = {
                dui: user.dui
            };

            let url = `${process.env.REACT_APP_BASE_URL}/buscar-cliente-por-fac`;
            try {
                await axios.post(url, formdata).then((response) => {
                    console.log(response.data);
                    if (response.data.status == 404) {
                        setIsValid(false);
                    } else {
                        setIsValid(true);
                        setDui(user.dui);
                        // valida si el campo es string
                        if (typeof response.data.data.Cou_EmailFacElectronica === 'string' || response.data.data.Cou_EmailFacElectronica instanceof String) {
                            setCorreo(response.data.data.Cou_EmailFacElectronica);
                        } else {
                            setCorreo("");
                        }
                        // valida si el campo es string
                        if (typeof response.data.data.cou_regiva === 'string' || response.data.data.cou_regiva instanceof String) {
                            setCreditoFiscal(response.data.data.cou_regiva);
                        } else {
                            setCreditoFiscal("");
                        }
                        // valida si el campo es string
                        if (typeof response.data.data.Cou_Nit === 'string' || response.data.data.Cou_Nit instanceof String) {
                            setNit(response.data.data.Cou_Nit);
                        } else {
                            setNit("");
                        }
                    }
                    setLoading(false);
                }).catch((error) => {
                    console.log(error);
                });
            }	catch (error) {
                console.log(error);
            }
        }
        fetchData()
    }, [user]);

    return (
        <>
            <div className="lg:flex-1 hidden lg:block">
                <div className="flex flex-col">
                    <div className="w-full">
                        <h1 className=" text-te-blue-800 text-3xl font-bold">
                            Actualiza tus Datos
                        </h1>
                    </div>
                    <div className="w-1/2 mt-4 border-2 rounded-lg p-4">
                        <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-4 relative">
                            <div className={`absolute top-0 left-0 w-full h-full bg-white justify-center items-center ${isValid == true ? 'hidden' : 'flex'}`}>
                                <div className="text-center text-xl text-te-blue-800 font-semibold">
                                    No se puede actualizar datos de facturación si aún no haz registrado un DUI, te invitamos a llamar a nuestro call center para más información.
                                    <br />
                                    <br />
                                    <strong>Teléfono:</strong> 2209-1508
                                    <br />
                                    <strong>Correo electrónico:</strong> <a target="_blank" className="underline" href="mailto:clientes@transexpress.com.sv">clientes@transexpress.com.sv</a>
                                </div>
                            </div>
                            <div className={`absolute top-0 left-0 w-full h-full bg-white justify-center items-center ${loading == false ? 'hidden' : 'flex'}`}>
                                <div className="loader"></div>
                            </div>
                            <div>
                                <label htmlFor="dui" className={stylesLabel}>DUI</label>
                                <input type="text" id="dui" disabled className={stylesInput} value={dui} onChange={(e) => setDui(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="correo" className={stylesLabel}>Correo</label>
                                <input type="email" id="correo" className={stylesInput} value={correo} onChange={(e) => setCorreo(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="credito-fiscal" className={stylesLabel}>Crédito Fiscal</label>
                                <input type="text" id="credito-fiscal" className={stylesInput} value={creditoFiscal} onChange={(e) => setCreditoFiscal(e.target.value)} />
                            </div>
                            <div>
                                <label htmlFor="email" className={stylesLabel}>NIT</label>
                                <input type="text" id="email" className={stylesInput} value={nit} onChange={(e) => setNit(e.target.value)} />
                            </div>
                            <div>
                                <button type="submit" className="p-2 bg-te-blue-800 text-white text-center block w-full rounded-lg">Guardar información</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
