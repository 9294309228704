import React from "react";
import check from "../../assets/images/icons/check.svg";

export default function Details({ dataCMS }) {
  return (
    <section className="bg-te-gray-700">
      <div className="container flex flex-col lg:flex-row py-10 lg:py-16 lg:px-32">
        <div className="lg:w-1/2">
          <h3
            data-aos="fade-up"
            className="text-te-blue-800 text-2xl lg:text-3xl font-semibold whitespace-pre-line"
          >
            {dataCMS?.TituloBeneficios}
          </h3>

          {dataCMS?.Beneficio?.map((benefit) => (
            <div data-aos="fade-up" className="mt-10" key={benefit.id}>
              <div className="flex">
                <div className="w-8">
                  <img src={check} alt="" className="mt-1" />
                </div>
                <h4 className="font-bold text-te-blue-900 text-xl">
                  {benefit?.Titulo}
                </h4>
              </div>
              <p className="mt-5 text-te-gray-800 max-w-sm ml-8 text-justify">
                {benefit?.Descripcion}
              </p>
            </div>
          ))}
        </div>
        <div
          data-aos="fade-up"
          className="lg:w-1/2 px-10 2xl:px-20 hidden lg:block"
        >
          <img src={dataCMS?.ImagenBeneficios?.data?.attributes?.url} alt="" />
        </div>
      </div>
    </section>
  );
}
