import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, LogoutIcon } from "@heroicons/react/outline";
import { NavLink, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import blank from "../../assets/images/blank.png";
import flag from "../../assets/images/svflag.png";
import axios from "axios";

export default function DropdownUser() {
  const { user, signout } = useAuth();
  const history = useHistory();

  async function remoteLogout() {
    await axios.get(
      `${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/login/logout`,
      { withCredentials: true }
    );
  }

  return (
    <div className="hidden lg:flex items-center">
      <img src={flag} alt="Bandera de El Salvador" className="h-10 rounded" />
      <Menu as="div" className="relative inline-block text-left ">
        {({ open }) => (
          <>
            <Menu.Button
              className={`inline-flex justify-center items-center w-full px-2 py-1 focus:outline-none font-semibold rounded-l text-te-gray-800`}
            >
              <span className="hidden 2xl:inline">Mi Cuenta</span>
              <div className=" rounded-full overflow-hidden w-12 h-12 mx-3">
                <img
                  src={blank}
                  alt=""
                  className="h-full w-full object-cover"
                />
              </div>
              <div className="flex flex-col items-start font-semibold mr-3">
                <span className=" text-te-blue-800 truncate w-32 text-left">
                  {user?.Name || user?.Company}
                </span>
                <span className=" text-te-orange-400 truncate w-32 text-left">
                  {user?.PoBox}
                </span>
              </div>
              <ChevronDownIcon
                className={`ml-1 h-5 w-5 transform transition duration-200 ${
                  open ? "rotate-180" : ""
                }`}
              />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 w-44 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="p-2">
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink
                        to="/micasillero"
                        exact
                        activeClassName="bg-te-blue-100"
                        className={`${
                          active ? "bg-te-blue-100" : ""
                        } group flex rounded-md items-center w-full px-2 py-2 text-te-blue-800 font-semibold`}
                      >
                        Mi Casillero
                      </NavLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <NavLink
                        to="/micasillero/packages"
                        activeClassName="bg-te-blue-100"
                        className={`${
                          active ? "bg-te-blue-100" : ""
                        } group flex rounded-md items-center w-full px-2 py-2 text-te-blue-800 font-semibold mt-1`}
                      >
                        Mis Paquetes
                      </NavLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={() => {
                          history.push("/");
                          signout();
                          remoteLogout();
                        }}
                        className={`${
                          active ? "bg-te-blue-100" : ""
                        } cursor-pointer flex rounded-md items-center justify-between w-full px-2 py-2 text-te-blue-800 font-semibold mt-1`}
                      >
                        Cerrar Sesión{" "}
                        <LogoutIcon className="h-5 text-te-blue-800" />
                      </div>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
