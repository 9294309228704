import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Content from "../../components/dynamicPage/Content";
import SiteLayout from "../../layouts/site";

export default function DynamicPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({});
  const { slug } = useParams();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/paginass?filters[slug][$eq]=${slug}&populate=*`
      )
      .then((response) => {
        setContent(response.data?.data[0]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [slug]);

  return (
    <main>
      <Helmet>
        <title>TransExpress El Salvador</title>
        <meta
          property="og:title"
          content="TransExpress El Salvador"
          key="og:title"
        />
        <meta name="robots" content="noindex"></meta>
        <meta name="googlebot" content="noindex"></meta>
      </Helmet>

      <SiteLayout>
        <Content data={content} isLoading={isLoading} />
      </SiteLayout>
    </main>
  );
}
