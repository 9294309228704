import React, { useEffect } from "react";
import { useAuth } from "../../../contexts/auth";

export default function MyAddress() {
  const { user } = useAuth();

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-5 border-2 border-te-gray-700 rounded-xl p-3 md:p-6 mb-10 md:mb-20">
      <div className="flex flex-col md:flex-row">
        <div className="md:w-1/2 md:pr-5 md:border-r-2">
          <p className=" text-te-blue-800 text-lg md:text-xl font-semibold bg-te-blue-100 p-3 rounded-lg">
            Dirección fisica: <br /> Para paquetes y carga
          </p>
          <div className="space-y-2 mt-6">
            <span className="block text-te-gray-800 font-semibold">
              {user.Name || user.Company}
            </span>
            <span className="block text-te-gray-800 font-semibold">
              VIPSAL {user.PoBox}
            </span>
            <span className="block text-te-gray-800 font-semibold">
              7801 N.W 37 Th St
            </span>
            <span className="block text-te-gray-800 font-semibold">
              Doral, FL 33195-6503
            </span>
            <span className="block text-te-gray-800 font-semibold">
              Phone, (305) 592-0839
            </span>
          </div>
        </div>

        <div className="md:w-1/2 md:pl-5 mt-5 md:mt-0">
          <p className=" text-te-blue-800 text-lg md:text-xl font-semibold bg-te-blue-100 p-3 rounded-lg">
            Dirección postal: <br /> Para correspondencia, revistas y periódicos
          </p>
          <div className="space-y-2 mt-6">
            <span className="block text-te-gray-800 font-semibold">
              {user.Name || user.Company}
            </span>
            <span className="block text-te-gray-800 font-semibold">
              VIPSAL {user.PoBox}
            </span>
            <span className="block text-te-gray-800 font-semibold">
              P.O Box 025364
            </span>
            <span className="block text-te-gray-800 font-semibold">
              Miami, FL 33102-5364
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
