import React, { useEffect, useState } from "react";
import { ArrowRightIcon } from "@heroicons/react/solid";
import axios from "axios";
import Spinner from "../utils/Spinner";

export default function Content({ searchValue }) {
  const [selected, setSelected] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/faqs?filters[$or][0][question][$containsi]=${searchValue}&filters[$or][1][Answer][$containsi]=${searchValue}`
      )
      .then((response) => {
        setSelected(response.data?.data[0]);
        setFaqs(response.data?.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [searchValue]);

  const getAnswer = (selected) => {
    const q = faqs.find((q) => q?.id === selected?.id);
    return q?.attributes?.Answer;
  };

  return (
    <div className="">
      {!isLoading ? (
        <>
          {!faqs.length ? (
            <div className=" text-center font-semibold text-te-blue-800 text-lg py-10 lg:py-32">
              No se encontraron resultados
            </div>
          ) : (
            <>
              <section className="mt-10 lg:mt-20 container lg:px-32 flex-col lg:flex-row pb-32 hidden lg:flex">
                <Options
                  faqs={faqs}
                  selected={selected}
                  setSelected={setSelected}
                />
                <div
                  data-aos="fade-up"
                  className="lg:w-2/3 mt-5 lg:mt-16 lg:pl-10"
                >
                  <p className="text-te-gray-800 lg:text-lg border-b-2 pb-10 whitespace-pre-line">
                    {getAnswer(selected)}
                  </p>
                </div>
              </section>

              <section className="mt-10 container pb-32 lg:hidden">
                {faqs.map((q, index) => (
                  <div className="mt-10" key={index}>
                    <div
                      className={`flex items-center justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all`}
                    >
                      {q.attributes?.question}
                    </div>
                    <p className="mt-3 whitespace-pre-line">
                      {q.attributes?.Answer}
                    </p>
                  </div>
                ))}
              </section>
            </>
          )}
        </>
      ) : (
        <div className="flex justify-center h-screen mt-20">
          <Spinner color="#141c82" type="spinningBubbles" />
        </div>
      )}
    </div>
  );
}

const Options = ({ faqs, selected, setSelected }) => {
  function groupBy(arr, criteria) {
    const newObj = arr.reduce(function (acc, currentValue) {
      if (!acc[currentValue.attributes[criteria]]) {
        acc[currentValue.attributes[criteria]] = [];
      }
      acc[currentValue.attributes[criteria]].push(currentValue);
      return acc;
    }, {});
    return newObj;
  }

  const questionByCategory = groupBy(faqs, "Category");

  return (
    <div data-aos="fade-up" className="lg:w-1/3">
      {questionByCategory?.SobreServicios && (
        <>
          <p className="mb-5 text-te-blue-900 text-2xl font-semibold">
            Sobre nuestros servicios
          </p>
          {questionByCategory?.SobreServicios.map((q, index) => (
            <div
              key={index}
              className={`flex p-4 items-center justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all ${
                selected?.id === q?.id ? "bg-te-blue-100" : ""
              }`}
              onClick={() => {
                window.scrollTo({ top: 500, behavior: "smooth" });
                setSelected(q);
              }}
            >
              {q.attributes?.question}
              <ArrowRightIcon
                className={`h-5 flex-shrink-0 ${
                  selected === 0 ? "text-te-blue-800 " : "text-te-orange-400"
                }`}
              />
            </div>
          ))}
        </>
      )}

      {questionByCategory?.SobreRastreoenLinea && (
        <>
          <p className="mt-10 mb-5 text-te-blue-900 text-2xl font-semibold">
            Sobre Rastreo en Línea
          </p>
          {questionByCategory?.SobreRastreoenLinea.map((q, index) => (
            <div
              key={index}
              className={`flex p-4 items-center justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all ${
                selected?.id === q?.id ? "bg-te-blue-100" : ""
              }`}
              onClick={() => {
                window.scrollTo({ top: 500, behavior: "smooth" });
                setSelected(q);
              }}
            >
              {q.attributes?.question}
              <ArrowRightIcon
                className={`h-5 flex-shrink-0 ${
                  selected === 0 ? "text-te-blue-800 " : "text-te-orange-400"
                }`}
              />
            </div>
          ))}
        </>
      )}

      {questionByCategory?.SobreSeguros && (
        <>
          <p className="mt-10 mb-5 text-te-blue-900 text-2xl font-semibold">
            Sobre Seguros
          </p>
          {questionByCategory?.SobreSeguros.map((q, index) => (
            <div
              key={index}
              className={`flex p-4 items-center justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all ${
                selected?.id === q?.id ? "bg-te-blue-100" : ""
              }`}
              onClick={() => {
                window.scrollTo({ top: 500, behavior: "smooth" });
                setSelected(q);
              }}
            >
              {q.attributes?.question}
              <ArrowRightIcon
                className={`h-5 flex-shrink-0 ${
                  selected === 0 ? "text-te-blue-800 " : "text-te-orange-400"
                }`}
              />
            </div>
          ))}
        </>
      )}

      {questionByCategory?.SobrePagos && (
        <>
          <p className="mt-10 mb-5 text-te-blue-900 text-2xl font-semibold">
            Sobre Pagos
          </p>
          {questionByCategory?.SobrePagos.map((q, index) => (
            <div
              key={index}
              className={`flex p-4 items-center justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all ${
                selected?.id === q?.id ? "bg-te-blue-100" : ""
              }`}
              onClick={() => {
                window.scrollTo({ top: 500, behavior: "smooth" });
                setSelected(q);
              }}
            >
              {q.attributes?.question}
              <ArrowRightIcon
                className={`h-5 flex-shrink-0 ${
                  selected === 0 ? "text-te-blue-800 " : "text-te-orange-400"
                }`}
              />
            </div>
          ))}
        </>
      )}

      {questionByCategory?.TiposdeDeclaraciones && (
        <>
          <p className="mt-10 mb-5 text-te-blue-900 text-2xl font-semibold">
            Tipos de Declaraciones
          </p>
          {questionByCategory?.TiposdeDeclaraciones.map((q, index) => (
            <div
              key={index}
              className={`flex p-4 items-center justify-between rounded-xl font-semibold cursor-pointer text-te-blue-800 transition-all ${
                selected?.id === q?.id ? "bg-te-blue-100" : ""
              }`}
              onClick={() => {
                window.scrollTo({ top: 500, behavior: "smooth" });
                setSelected(q);
              }}
            >
              {q.attributes?.question}
              <ArrowRightIcon
                className={`h-5 flex-shrink-0 ${
                  selected === 0 ? "text-te-blue-800 " : "text-te-orange-400"
                }`}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};
