import React from "react";
import { Tab } from "@headlessui/react";
import NaturalPerson from "./NaturalPerson";
import JuridicPerson from "./JuridicPerson";
import Exempt from "./Exempt";
import NaturalPersonContribuyent from "./NaturalPersonContribuyent";

export default function UpdateData() {
  return (
    <>
      <div className="flex-1 p-4 sm:p-6 md:p-8 lg:p-10 xl:p-2">
        <h1 className="text-te-blue-800 text-2xl md:text-3xl font-bold mb-2">
          Actualiza tus datos
        </h1>
        <br />
        <p className="text-te-gray-800 font-semibold mb-6">
          Estimado Cliente:
          <br />
          <br />
          Estamos iniciando con la implementación de facturación electrónica
          para dar cumplimiento a los requerimientos establecidos por el
          Ministerio de Hacienda y evitar demora en la entrega de sus paquetes.
        </p>

        <Tab.Group>
          <Tab.List className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-4">
            <Tab className="w-full">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-base border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Persona Natural
                </span>
              )}
            </Tab>
            <Tab className="w-full">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-base sm:text-sm border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Persona Natural Contribuyente
                </span>
              )}
            </Tab>
            <Tab className="w-full">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-base border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Persona Jurídica
                </span>
              )}
            </Tab>
            <Tab className="w-full">
              {({ selected }) => (
                <span
                  className={`font-semibold inline-block text-base border-b-4 w-full py-3 ${
                    selected
                      ? "border-te-blue-800 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  Exentos - Tasa 0
                </span>
              )}
            </Tab>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <NaturalPerson />
            </Tab.Panel>
            <Tab.Panel>
              <NaturalPersonContribuyent />
            </Tab.Panel>
            <Tab.Panel>
              <JuridicPerson />
            </Tab.Panel>
            <Tab.Panel>
              <Exempt />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="mt-6 mb-10 bg-te-blue-100 text-te-blue-800 rounded-xl p-4 font-semibold text-sm md:text-base">
          <p>
            Para mayor información puede llamar al 2209-1524, 2209-1525 o 2209-1526 o si prefiere pasar
            a nuestras oficinas a realizar la actualización con el departamento
            de servicio al cliente. Si usted ya realizo este proceso hacer caso
            omiso a la notificación.
          </p>
        </div>
      </div>
    </>
  );
}
