import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import axios from "axios";
import { DateTime } from "luxon";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import box from "../../assets/images/icons/locker-box.svg";
import { getFileExtension } from "../utils/getFileExtension";
import s3, { config } from "../../configs/spaces";
import Spinner from "../utils/Spinner";
import addFile from "../../assets/images/icons/add-file.svg";
import { useAuth } from "../../contexts/auth";

export default function UnknownResult({ packageInfo, tracking }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm({});

  const watchFile = watch("file", false);

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");

    const blob = data.file[0];

    blob.hash = DateTime.now().toMillis();

    const ramdom = Math.random().toString(36).slice(2);

    const extension = getFileExtension(blob.name);

    const params = {
      ACL: "public-read",
      Body: blob,
      Bucket: `${config.bucketName}`,
      Key: `te-facturas/${blob.hash}${ramdom}.${extension}`,
    };

    s3.putObject(params)
      .on("httpUploadProgress", (evt) => {
        console.log(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) {
          setError("No se pudo subir el archivo.");
          return;
        } else {
          const imageUrl = `${config.digitalOceanSpaces}te-facturas/${blob.hash}${ramdom}.${extension}`;
          callback(imageUrl, `${blob.hash}${ramdom}.${extension}`);
        }
      });

    const callback = (imageUrl, fileName) => {
      const body = {
        to: "clientes@transexpress.com.sv",
        from: "notificacion@transexpress.com.sv",
        subject: "Información de paquete desconocido.",
        html: `
        <div className="">
          <p>Un usuario envió información para reconocer paquete:</p>
          <table>
            <tr>
              <td>Guía Desconocido:</td>
              <td>${packageInfo.guiadesconocido}</td>
            </tr>
            <tr>
              <td>Remitente:</td>
              <td>${data.Remitente}</td>
            </tr>
            <tr>
              <td>Destinatario:</td>
              <td>${data.Consigna}</td>
            </tr>
            <tr>
              <td>Contenido:</td>
              <td>${data.Contenido}</td>
            </tr>
            <tr>
              <td>Email:</td>
              <td>${data.email}</td>
            </tr>
            <tr>
              <td>Casillero:</td>
              <td>${data.locker}</td>
            </tr>
            <tr>
              <td>N. de Tracking de tu transportista:</td>
              <td>${data.tracking}</td>
            </tr>
            <tr>
              <td>Nombre de archivo:</td>
              <td>${fileName}</td>
            </tr>
            <tr>
              <td>Enlace de archivo:</td>
              <td>${imageUrl}</td>
            </tr>
            <tr>
              <td>Fecha de ingreso:</td>
              <td>${
                DateTime.fromFormat(
                  packageInfo.fecha_x0020_ingreso.split(" ")[0],
                  "M/d/yyyy"
                ).toFormat("dd / MM / yyyy") || "-"
              }</td>
            </tr>
            <tr>
              <td>Peso:</td>
              <td>${packageInfo.peso}</td>
            </tr>
          </table>
        </div>
        `,
        bcc: ["tecupon2021@gmail.com"],
        cc: [],
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/sendmail`, body)
        .then(() => {
          setIsOpen(true);
        })
        .catch(() => {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
  };

  useEffect(() => {
    if (user) {
      reset({
        email: user.Correo,
        locker: user.PoBox,
        Remitente: packageInfo.remitente,
        Consigna: packageInfo.destinatario,
        tracking: tracking,
      });
    }

    // eslint-disable-next-line
  }, [user, packageInfo, tracking]);

  return (
    <div className="container mt-10 lg:px-32">
      <p className="text-te-blue-800 text-2xl md:text-3xl lg:text-center font-bold">
        Información de Paquete Desconocido
      </p>

      <div className="md:border-2 border-te-gray-700 rounded-2xl mt-10 px-5 lg:px-10 w-full shadow-lg md:shadow-none">
        <div className="my-5 flex justify-between">
          <div className="flex flex-col md:flex-row w-full">
            <div className="flex justify-between flex-shrink-0 items-center">
              <div className="md:block inline-flex items-center justify-center rounded-full p-3 md:border-2 border-te-gray-700">
                <img src={box} alt="" className="h-8" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-col lg:flex-row lg:items-end lg:space-x-20">
          <div className="font-semibold space-y-2 hidden lg:block">
            <span className="block text-te-gray-800">Remitente:</span>
            <span className="block text-te-gray-800">Destinatario:</span>
            <span className="block text-te-gray-800">Fecha de Ingreso:</span>
            <span className="block text-te-gray-800">Peso:</span>
          </div>
          <div className="font-semibold space-y-2">
            <span className="block text-te-blue-800 truncate">
              {packageInfo.remitente || "-"}
            </span>
            <span className="block text-te-blue-800 truncate">
              {packageInfo.destinatario || "-"}
            </span>
            <span className="block text-te-blue-800 truncate">
              {DateTime.fromFormat(
                packageInfo.fecha_x0020_ingreso.split(" ")[0],
                "M/d/yyyy"
              ).toFormat("dd / MM / yyyy") || "-"}
            </span>
            <span className="block text-te-blue-800 truncate">
              {packageInfo.peso || "-"}
            </span>
          </div>
        </div>

        <div className="flex flex-col mt-5 pb-5">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full rounded-lg border lg:shadow-lg mt-5 lg:mt-0 text-te-blue-800"
          >
            <div className="p-6 lg:flex lg:space-x-5">
              <div className="lg:w-1/2">
                <div className="">
                  <label
                    htmlFor="locker"
                    className="text-sm text-te-gray-800 font-semibold block"
                  >
                    Casillero
                  </label>
                  <input
                    id="locker"
                    type="text"
                    {...register("locker", { required: true })}
                    disabled
                    className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                  />
                  {errors.locker && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
                <div className="">
                  <label
                    htmlFor="email"
                    className="text-sm text-te-gray-800 font-semibold mt-3 block"
                  >
                    Correo electrónico
                  </label>
                  <input
                    id="email"
                    type="email"
                    {...register("email", { required: true })}
                    disabled
                    className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                  />
                  {errors.email && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
                <div className="">
                  <label
                    htmlFor="Contenido"
                    className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                  >
                    Contenido del paquete
                  </label>
                  <input
                    id="Contenido"
                    type="text"
                    {...register("Contenido", { required: true })}
                    className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                  />
                  {errors.Contenido && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
                <div className="">
                  <label
                    htmlFor="Remitente"
                    className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                  >
                    Remitente/Tienda
                  </label>
                  <input
                    id="Remitente"
                    type="text"
                    {...register("Remitente", {
                      required: true,
                    })}
                    disabled
                    className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                  />
                  {errors.Remitente && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
              </div>
              <div className="lg:w-1/2">
                <div className="">
                  <label
                    htmlFor="Consigna"
                    className="text-sm text-te-gray-800 font-semibold block"
                  >
                    Nombre
                  </label>
                  <input
                    id="Consigna"
                    type="text"
                    {...register("Consigna", {
                      required: true,
                    })}
                    disabled
                    className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                  />
                  {errors.Consigna && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
                <div className="">
                  <label
                    htmlFor="tracking"
                    className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                  >
                    N. de Tracking de tu transportista
                  </label>
                  <input
                    id="tracking"
                    type="text"
                    {...register("tracking", {
                      required: true,
                    })}
                    disabled
                    className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                  />
                  {errors.tracking && (
                    <span className=" text-te-red-900 text-xs mt-2">
                      *Este campo es requerido.
                    </span>
                  )}
                </div>
                <div className="">
                  <label
                    htmlFor=""
                    className="text-sm text-te-gray-800 font-semibold mt-3 flex justify-between items-center"
                  >
                    Factura comercial
                  </label>
                  {!watchFile?.length ? (
                    <div
                      onClick={() => document.getElementById("file").click()}
                      className="border-2 border-te-gray-700 bg-te-blue-100 lg:bg-white bg-opacity-30 hover:bg-te-blue-100 transition hover:bg-opacity-30 border-dashed rounded-xl py-5 mt-2 cursor-pointer"
                    >
                      <div className="">
                        <img src={addFile} alt="" className="h-14 mx-auto" />
                      </div>
                      <>
                        <p className="text-center mt-3 text-sm">
                          <span className=" text-te-orange-400 font-semibold">
                            Sube un archivo
                          </span>
                        </p>
                        <p className="text-te-gray-800 text-center text-sm">
                          PNG, JPG, JPEG, DOCX, XLSX, PDF
                        </p>
                      </>
                    </div>
                  ) : (
                    <div className=" text-center px-5 py-5 mt-5 font-semibold flex flex-col justify-center items-center border rounded-lg">
                      {document.getElementById("file").files[0].name}
                      <span
                        className=" text-te-blue-800 underline cursor-pointer"
                        onClick={() => {
                          reset({ ...getValues(), file: "" });
                        }}
                      >
                        Quitar archivo
                      </span>
                    </div>
                  )}
                  {errors.file?.type === "acceptedFormats" && (
                    <span className="block text-te-red-900 text-xs mt-2">
                      *{errors.file.message}
                    </span>
                  )}
                  {errors.file?.type === "limitSize" && (
                    <span className="block text-te-red-900 text-xs mt-2">
                      *{errors.file.message}
                    </span>
                  )}
                  {errors.file?.type === "required" && (
                    <span className="block text-te-red-900 text-xs mt-2">
                      *Es necesario subir tu factura.
                    </span>
                  )}
                  <input
                    type="file"
                    className="hidden"
                    id="file"
                    accept=".png, .jpg, .jpeg, .pdf, .xls, .xlsx, .doc, .docx"
                    {...register("file", {
                      required: true,
                      validate: {
                        acceptedFormats: (files) => {
                          if (files[0]) {
                            return (
                              [
                                "image/jpg",
                                "image/jpeg",
                                "image/png",
                                "application/pdf",
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                "application/vnd.ms-excel",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/msword",
                              ].includes(files[0]?.type) ||
                              "Este tipo de archivo no es permitido."
                            );
                          } else {
                            return null;
                          }
                        },
                        limitSize: (files) => {
                          if (files[0]) {
                            const maxAllowedSize = 4 * 1024 * 1024;
                            return (
                              files[0].size < maxAllowedSize ||
                              "El tamaño maximo de archivo debe ser de 4MB."
                            );
                          } else {
                            return null;
                          }
                        },
                      },
                    })}
                  />
                </div>
                <div className="mt-6 flex justify-end space-x-4">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="lg:w-1/2 w-full py-2 bg-te-blue-800 text-white font-semibold rounded-lg relative disabled:bg-te-gray-800 disabled:cursor-not-allowed"
                  >
                    Reconocer Paquete
                    {isLoading && (
                      <div className="absolute right-3 top-0 h-full flex items-center">
                        <Spinner color="#ffffff" type="spinningBubbles" />
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </form>
          {error && (
            <div className="block mt-4 text-te-red-800 font-semibold text-center">
              *{error}
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center mt-10 mb-20">
        <p
          onClick={() => {
            history.goBack();
          }}
          className="flex items-center underline text-te-blue-800 font-semibold text-2xl cursor-pointer"
        >
          <ArrowLeftIcon className="h-6 text-te-blue-800 mr-2" />
          Regresar
        </p>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => {}}
        >
          <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Solicitud recibida
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Se ha recibido tu solicitud, en un lapso de 24 a 48 horas
                    será reconocido.
                  </p>
                </div>

                <div className="mt-4 flex justify-end">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Aceptar
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
