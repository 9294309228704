import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import arrow from "../../assets/images/icons/arrow-banner.svg";
import CustomLink from "../utils/CustomLink";

export default function Banners({ dataCMS }) {
  const slider = useRef(null);
  const slider2 = useRef(null);
  const [current, setCurrent] = useState(0);
  const [currentMobile, setCurrentMobile] = useState(0);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => {
      setCurrent(next);
    },
    appendDots: (dots) => (
      <div>
        <div className="h-16 flex justify-center space-x-5">
          {dots.map((item, index) => {
            return (
              <div key={index} className="flex w-3 h-3">
                {item.props.children}
              </div>
            );
          })}
        </div>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`w-3 h-3 border-2 border-white rounded-full transition-all duration-300 cursor-pointer ${
          i === current ? "opacity-100 bg-white" : ""
        }`}
      ></div>
    ),
  };

  const settings2 = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (prev, next) => {
      setCurrentMobile(next);
    },
    appendDots: (dots) => (
      <div>
        <div className="h-16 flex justify-center space-x-5">
          {dots.map((item, index) => {
            return (
              <div key={index} className="flex w-3 h-3">
                {item.props.children}
              </div>
            );
          })}
        </div>
      </div>
    ),
    customPaging: (i) => (
      <div
        className={`w-3 h-3 border-2 border-white rounded-full transition-all duration-300 cursor-pointer ${
          i === currentMobile ? "opacity-100 bg-white" : ""
        }`}
      ></div>
    ),
  };

  return (
    <>
      <section className="relative hidden md:block">
        <Slider ref={slider} {...settings}>
          {dataCMS?.SliderPrincipal?.data?.map((dataImage, index) => (
            <div key={index} className="focus:outline-none">
              <CustomLink to={dataImage.attributes?.caption}>
                <img
                  src={dataImage.attributes?.formats?.xlarge?.url}
                  alt=""
                  className=" w-full"
                />
              </CustomLink>
            </div>
          ))}
        </Slider>
        {dataCMS?.SliderPrincipal?.data?.length > 1 && (
          <>
            <button
              onClick={() => slider?.current?.slickPrev()}
              className="absolute top-1/2 -mt-8 z-20 left-20 focus:outline-none"
            >
              <img src={arrow} alt="" className="transform rotate-180 h-10" />
            </button>
            <button
              onClick={() => slider?.current?.slickNext()}
              className="absolute top-1/2 -mt-8 z-20 right-20 focus:outline-none"
            >
              <img src={arrow} alt="" className="h-10" />
            </button>
          </>
        )}
      </section>

      <section className="relative md:hidden">
        <Slider ref={slider2} {...settings2}>
          {dataCMS?.SliderPrincipalMobile?.data?.map((dataImage, index) => (
            <div key={index} className="focus:outline-none">
              <CustomLink to={dataImage.attributes?.caption}>
                <img
                  src={dataImage.attributes?.url}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </CustomLink>
            </div>
          ))}
        </Slider>
      </section>
    </>
  );
}
