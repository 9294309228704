import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import React from "react";
import coupon5 from "../../../assets/images/locker/coupon5.png";
import coupon10 from "../../../assets/images/locker/coupon10.png";
import coupon20 from "../../../assets/images/locker/coupon20.png";
import coupon50 from "../../../assets/images/locker/coupon50.png";
import coupon100 from "../../../assets/images/locker/coupon100.png";

export default function CertificatesMobile() {
  return (
    <div>
      <div className="mt-5 border-2 border-te-gray-700 rounded-xl overflow-hidden p-4">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex items-center justify-between w-full text-left focus:outline-none font-semibold text-te-blue-800`}
              >
                <span>LISTA DE CERTIFICADOS</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "transform rotate-180" : ""
                  } w-5 h-5 transition text-te-blue-800`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pb-2 mt-5">
                  <p className=" text-te-gray-800 font-semibold mt-5">
                    5 Certificados
                  </p>

                  <div className="mt-5 grid grid-cols-2 gap-2">
                    {[
                      { value: "$5.00", cost: 500, img: coupon5 },
                      { value: "$10.00", cost: 1000, img: coupon10 },
                      { value: "$20.00", cost: 2000, img: coupon20 },
                      { value: "$50.00", cost: 5000, img: coupon50 },
                     /*  { value: "$100.00", cost: 10000, img: coupon100 }, */
                    ].map((value, index) => (
                      <div
                        key={index}
                        className="bg-te-green-50 bg-opacity-30 rounded-lg p-2"
                      >
                        <img src={value.img} alt="" className="mx-auto" />
                        <div className="mt-2 flex items-center justify-between font-semibold">
                          <span className=" text-te-gray-800 text-xs">
                            {value.value}
                          </span>
                          <span className=" text-te-blue-800 text-xs">
                            {value.cost} pts.
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
