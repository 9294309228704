import { ChevronDownIcon } from "@heroicons/react/solid";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/auth";
import ButtonBasic from "../buttons/Basic";
import Spinner from "../utils/Spinner";

export default function ContactForm() {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState("");
  const [topics, setTopics] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  const getTopics = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/topics`)
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setTopics(res.data.data);
        }
      })
      .catch(() => {});
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    const selected = JSON.parse(data.option);

    const body = {
      deptoid: selected.deptoid,
      grupoid: selected.grupoid,
      agenteid: selected.agenteid,
      email: data.email,
      asunto: selected.asunto,
      cuerpo: data.message,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/customer-services`, body)
      .then((res) => {
        if (res.data && !res.data.error && res.status === 200) {
          reset();
          setIsCompleted("Mensaje enviado con éxito.");
        } else {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getTopics();

    if (user) {
      reset({
        email: user.Correo,
        locker: user.PoBox,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {!successful ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className=" w-full max-w-lg rounded-lg border shadow-lg text-te-blue-800"
        >
          <div className="p-4 lg:p-6">
            <div className="">
              <p className="text-xl lg:text-3xl text-te-blue-800 font-bold lg:text-center mt-2">
                Ponte en contacto con nosotros
              </p>
            </div>
            <div className="mt-5 lg:mt-8">
              <label
                htmlFor="options"
                className="text-sm text-te-gray-800 font-semibold"
              >
                Tema de consulta
              </label>
              <div className="flex items-center relative">
                <select
                  id="options"
                  {...register("option", { required: true })}
                  className="py-3 px-2 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm appearance-none"
                >
                  {topics.map((data, index) => (
                    <option key={index} value={JSON.stringify(data)}>
                      {data.asunto}
                    </option>
                  ))}
                </select>
                <ChevronDownIcon className="absolute h-6 right-2 mt-2 pointer-events-none" />
              </div>
              {errors.option && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="">
              <label
                htmlFor="email"
                className="text-sm text-te-gray-800 font-semibold mt-3 block"
              >
                Correo electrónico
              </label>
              <input
                type="email"
                id="email"
                {...register("email", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.email && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="">
              <label
                htmlFor="locker"
                className="text-sm text-te-gray-800 font-semibold mt-3 block"
              >
                N. Casillero
              </label>
              <input
                id="locker"
                {...register("locker")}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              />
              {errors.locker && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <div className="">
              <label
                htmlFor="message"
                className="text-sm text-te-gray-800 font-semibold mt-3 block"
              >
                Mensaje
              </label>
              <textarea
                id="message"
                rows="5"
                {...register("message", { required: true })}
                className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
              ></textarea>
              {errors.message && (
                <span className=" text-te-red-900 text-xs mt-2">
                  *Este campo es requerido.
                </span>
              )}
            </div>
            <ButtonBasic
              classNameFather="bg-te-orange-600 mt-5 w-full lg:w-auto"
              className=" bg-te-orange-400 text-white px-10"
              disabled={isLoading}
            >
              Enviar mensaje
              {isLoading && (
                <div className="absolute right-2 top-0 h-full flex items-center">
                  <Spinner color="#ffffff" type="spinningBubbles" />
                </div>
              )}
            </ButtonBasic>
            {error && (
              <div className="mt-5 text-te-red-900 font-semibold">*{error}</div>
            )}
            {isCompleted && (
              <div className="mt-5 text-te-green-700 font-semibold">
                *{isCompleted}
              </div>
            )}
          </div>
        </form>
      ) : (
        <div className="bg-te-gray-700 rounded-lg p-10 flex flex-col w-full max-w-md">
          <p className=" text-te-blue-800 text-xl text-center">
            ¡Tu solicitud ha sido realizada!
          </p>
          <p className=" text-te-gray-800 mt-5 text-center">
            Apparently we had reached a great height in the atmosphere, for the
            skywas a dead black.{" "}
          </p>

          <div className="flex justify-center mt-5">
            <ButtonBasic
              className=" bg-te-blue-800 text-white"
              classNameFather="bg-te-blue-900"
              onClick={() => {
                setSuccessful(false);
                reset();
              }}
            >
              Enviar otro mensaje
            </ButtonBasic>
          </div>
        </div>
      )}
    </>
  );
}
