import React from "react";

export default function Notes({ dataCMS }) {

  return (
    <section className="py-10 lg:py-16 bg-te-gray-700">
      <div className="container">
        <h3
          data-aos="fade-up"
          className=" font-semibold text-te-blue-800 text-3xl"
        >
          Notas y avisos
        </h3>
        <div className="flex flex-wrap lg:justify-around mt-10 lg:mt-16 gap-10">
          {dataCMS?.Notas?.map((item) => (
            <div
              data-aos="fade-up"
              className="lg:w-1/3 max-w-sm flex"
              key={item.id}
            >
              <span className="block h-3 w-3 mr-2 mt-2 bg-te-orange-400 rounded-full flex-shrink-0"></span>
              <p className=" text-te-gray-800">{item?.Text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
