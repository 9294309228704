import axios from "axios";
import React, { useEffect, useState } from "react";
import Main from "../../components/calculator/Main";
import Notes from "../../components/calculator/Notes";
import ReadyForHelp from "../../components/general/ReadyForHelp";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function Calculator() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/cotiza-tus-compras?populate[0]=Seo.ShareImage&populate[1]=Notas`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <Seo data={content?.Seo} />

      <SiteLayout>
        <Main dataCMS={content} />

        <Notes dataCMS={content} />

        <ReadyForHelp />
      </SiteLayout>
    </main>
  );
}
