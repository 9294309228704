import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import points from "../../../assets/images/locker/points.png";
import pointsMobile from "../../../assets/images/locker/points-mobile.png";
import { ReactComponent as Star } from "../../../assets/images/icons/star.svg";
import RedeemPoints from "./RedeemPoints";
import { ArrowRightIcon } from "@heroicons/react/outline";
import PointsDetail from "./PointsDetail";
import Certificates from "./Certificates";
import axios from "axios";
import { useAuth } from "../../../contexts/auth";
import RedeemPointsMobile from "./RedeemPointsMobile";
import PointsDetailsMobile from "./PointsDetailsMobile";
import CertificatesMobile from "./CertificatesMobile";
import Spinner from "../../utils/Spinner";
import { Link } from "react-router-dom";

export default function Points() {
  // 
  const [userPoints, setPoints] = useState(0);
  const [userPointsDetails, setPointsDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetails, setIsLoadingDetails] = useState(false);
  const { user } = useAuth();

  const getPoints = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/points`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setPoints(res.data.data);
        } else {
          setPoints(0);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPointsDetails = () => {
    setIsLoadingDetails(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}/redeems`,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setPointsDetails(res.data.data);
        } else {
          setPointsDetails([]);
        }
      })
      .catch(() => {})
      .finally(() => {
        setIsLoadingDetails(false);
      });
  };

  useEffect(() => {
    getPoints();
    getPointsDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="flex-1">
      <h1 className="text-te-blue-800 text-2xl md:text-3xl font-bold">
        Puntos Trans-Express
      </h1>
      <p className="text-te-gray-800 mt-1 font-semibold">
        Administra tus puntos
      </p>

      <div className="mt-5">
        <img src={points} alt="" className="hidden md:inline" />
        <img src={pointsMobile} alt="" className="md:hidden" />
      </div>

      <div className="flex items-center justify-between lg:justify-start mt-5">
        <p className=" text-te-blue-800 font-bold text-lg">Puntos acumulados</p>
        <div className=" bg-te-blue-100 px-2 md:px-5 py-2 flex items-center rounded-lg ml-5 md:ml-10 relative w-48">
          <Star className="h-6 mr-10 flex-shrink-0" />

          {isLoading ? (
            <div className="absolute right-2 top-0 h-full flex items-center">
              <Spinner color="#0d1256" type="spinningBubbles" />
            </div>
          ) : (
            <span className=" text-te-blue-800 font-bold text-lg">
              {userPoints}
            </span>
          )}
        </div>
      </div>

      <div className="border-2 border-te-gray-700 mt-10 rounded-xl hidden lg:block">
        <Tab.Group>
          <Tab.List className=" w-full">
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-bold inline-block uppercase border-b-2 w-full py-5 ${
                    selected
                      ? "border-te-orange-400 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  canje de puntos
                </span>
              )}
            </Tab>
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-bold inline-block uppercase border-b-2 w-full py-5 ${
                    selected
                      ? "border-te-orange-400 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  detalle de puntos
                </span>
              )}
            </Tab>
            <Tab className="w-1/4">
              {({ selected }) => (
                <span
                  className={`font-bold inline-block uppercase border-b-2 w-full py-5 ${
                    selected
                      ? "border-te-orange-400 text-te-blue-800"
                      : " border-te-gray-700 text-te-gray-800"
                  }`}
                >
                  lista de certificados
                </span>
              )}
            </Tab>
            <div className="w-1/4 inline-block">
              <p className={`h-full inline-flex border-b-2 w-full py-5 `}>
                <span className="invisible ">A</span>
              </p>
            </div>
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <RedeemPoints
                isLoading={isLoading}
                userPoints={userPoints}
                getPoints={getPoints}
              />
            </Tab.Panel>
            <Tab.Panel>
              <PointsDetail
                isLoadingDetails={isLoadingDetails}
                userPointsDetails={userPointsDetails}
              />
            </Tab.Panel>
            <Tab.Panel>
              <Certificates />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="lg:hidden">
        <RedeemPointsMobile
          isLoading={isLoading}
          userPoints={userPoints}
          getPoints={getPoints}
        />

        <PointsDetailsMobile />

        <CertificatesMobile />
      </div>

      <Link
        to="/puntostransexpress"
        className="text-te-blue-800 md:text-lg text-center mt-6 flex items-center justify-center font-semibold mb-20 underline"
      >
        Términos y condiciones de Puntos Trans-Express{" "}
        <ArrowRightIcon className="ml-3 h-5 text-te-orange-400 hidden lg:inline" />
      </Link>
    </div>
  );
}
