import React from "react";
import { Link } from "react-router-dom";
import ButtonBasic from "../buttons/Basic";

export default function Benefits({ dataCMS }) {
  return (
    <section className="bg-white">
      <div className="py-5 lg:py-16">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-3xl font-semibold text-center"
        >
          {dataCMS?.TituloBeneficios}
        </h3>
        <p
          data-aos="fade-up"
          className="text-te-gray-800 lg:text-lg text-center max-w-3xl mx-auto mt-4"
        >
          {dataCMS?.DescripcionBeneficios}
        </p>

        <div className="mt-5 lg:mt-10 container grid grid-cols-1 lg:grid-cols-5 gap-7 lg:gap-10 place-items-stretch">
          {dataCMS?.Beneficios?.map((benefit) => (
            <BenefitCard data={benefit} key={benefit.id} />
          ))}
        </div>

        <div data-aos="fade-up" className="mt-8 flex justify-center">
          <Link to="/register">
            <ButtonBasic
              classNameFather="bg-te-blue-800"
              className="bg-te-blue-900 text-white"
            >
              Crea tu casillero
            </ButtonBasic>
          </Link>
        </div>
      </div>
    </section>
  );
}

export const BenefitCard = ({ data }) => {
  return (
    <div data-aos="fade-up" className="w-full border rounded-2xl px-3 py-10">
      <img
        src={data?.Imagen?.data?.attributes?.url}
        alt=""
        className="h-16 mx-auto"
      />
      <p className="text-te-blue-800 text-xl font-bold mt-4 text-center">
        {data?.Titulo}
      </p>
      <p className="text-te-gray-800 text-center mt-2">{data?.Descripcion}</p>
    </div>
  );
};
