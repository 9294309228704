import axios from "axios";

export const calculate = (data) => {
  const category = JSON.parse(data.category);

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/dutys/calculate`, {
      precio: data.value,
      peso: data.weight,
      arancel: category.Porcentaje,
      cesc: 0,
      tipoMercaderia: category.IdArticulo,
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
