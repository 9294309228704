import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import React, { Fragment, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import closeIcon from "../../assets/images/icons/close-modal.svg";
import Spinner from "../utils/Spinner";
import jwt from "jsonwebtoken";

export default function RestorePasswordModal({ isOpen, closeModal }) {
  const closeButtonRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [error, setError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const token = jwt.sign({ email: data.email }, "te-restore", {
      expiresIn: "60m",
    });

    const body = {
      to: data.email,
      from: "notificacion@transexpress.com.sv",
      subject: "Restablecer Contraseña",
      html: `
      <div>
        <p>Solicitud para restablecer contraseña:</p>
        <p>Copia y pega este enlace en tu navegador para restablecer tu contraseña:</p>
        <p>
        <a rel="noopener" style="text-decoration: underline; color: #0068A5;" target="_blank">
          https://www.transexpress.com.sv/resetpassw?c=${token}
        </a>
        </p>
      </div>
      `,
      bcc: [],
      cc: [],
    };

    setIsLoading(true);

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/sendmail`, body)
      .then(() => {
        setIsCompleted(true);
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        initialFocus={closeButtonRef}
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto w-full"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4 text-center w-full">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-te-blue-800 bg-opacity-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg my-8 overflow-hidden text-left align-middle transition-all transform">
              <div className="flex justify-end mb-3">
                <span
                  ref={closeButtonRef}
                  onClick={closeModal}
                  className="cursor-pointer"
                >
                  <img src={closeIcon} alt="" className="h-8" />
                </span>
              </div>
              <div className="bg-white shadow-xl rounded-2xl p-6">
                <Dialog.Title
                  as="h3"
                  className="text-2xl text-center font-bold text-te-blue-900"
                >
                  Restablecer contraseña
                </Dialog.Title>
                <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                  <div className="">
                    <label
                      htmlFor="email"
                      className=" font-semibold text-te-gray-800 text-sm"
                    >
                      Correo electrónico
                    </label>
                    <input
                      type="email"
                      id="email"
                      {...register("email", { required: true })}
                      className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
                    />
                    {errors.email && (
                      <span className=" text-te-red-900 text-xs mt-2">
                        *Este campo es requerido.
                      </span>
                    )}
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="bg-te-blue-900 text-white font-semibold px-12 py-2 rounded-lg mt-3 relative"
                    >
                      Enviar
                      {isLoading && (
                        <div className="absolute right-3 top-0 h-full flex items-center">
                          <Spinner color="#ffffff" type="spinningBubbles" />
                        </div>
                      )}
                    </button>
                  </div>
                  {isCompleted && (
                    <div className="block mt-4 text-te-green-600 font-semibold text-center">
                      *Se le ha enviado un correo para recuperar la contraseña.
                    </div>
                  )}
                  {error && (
                    <div className="block mt-4 text-te-red-800 font-semibold text-center">
                      *{error}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
