import React, { useEffect, useState } from "react";
import ButtonBasic from "../buttons/Basic";
import { ReactComponent as WhatsApp } from "../../assets/images/icons/whatsapp.svg";
import axios from "axios";

export default function ReadyForHelp() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/banner-ayuda?populate=*`)
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <section className="lg:py-16">
      <div className="lg:container lg:px-32">
        <div
          data-aos="fade-up"
          className="lg:rounded-xl bg-te-blue-800 flex flex-col justify-center items-center px-5 lg:px-16 py-14 text-white"
        >
          <p className=" text-3xl lg:text-4xl text-center">
            {content?.Titulo || "¡Estamos listos para ayudarte!"}
          </p>
          <p className="lg:text-lg mt-5 text-center">{content?.Subtitulo}</p>
          <a
            href="https://api.whatsapp.com/message/RJPQ4WSV4XHHN1"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-10"
          >
            <ButtonBasic
              classNameFather="bg-te-green-700  w-full lg:w-auto"
              className=" text-white bg-te-green-600 flex items-center justify-center "
            >
              <WhatsApp className="h-5 mr-2 fill-current " /> Escríbenos por
              Whatsapp
            </ButtonBasic>
          </a>
        </div>
      </div>
    </section>
  );
}
