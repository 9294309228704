import React from "react";
import { Route } from "react-router";
import AccountState from "../../components/locker/AccountState";
import DropdownMobile from "../../components/locker/DropdownMobile";
import Information from "../../components/locker/Information";
import ActualizarDatos from "../../components/locker/ActualizarTusDatos";
import Packages from "../../components/locker/Packages";
import Points from "../../components/locker/Points";
import Referred from "../../components/locker/Referred";
import SendInvoice from "../../components/locker/SendInvoice";
import Sidebar from "../../components/locker/Sidebar";
import WeightControl from "../../components/locker/WeightControl";
import Seo from "../../components/utils/Seo";
import { useAuth } from "../../contexts/auth";
import SiteLayout from "../../layouts/site";
import UpdateData from "../../components/locker/UpdateData";
import Promotion from "../../components/locker/Promotion";

export default function Locker() {
  const { user } = useAuth();
  return (
    <main>
      <Seo
        data={{
          keywords: null,
          metaDescription: "Global e-Commerce Logistics",
          metaTitle: "Trans-Express El Salvador  –  Casillero",
          preventIndexing: false,
        }}
      />

      <SiteLayout>
        <div className="mt-5 lg:mt-12 lg:flex container">
          <Sidebar />

          <DropdownMobile />

          <Route exact path="/micasillero">
            <Information />
          </Route>
          <Route path="/micasillero/packages">
            <Packages />
          </Route>
          <Route path="/micasillero/enviar-factura">
            <SendInvoice />
          </Route>
          <Route path="/micasillero/puntos">
            <Points />
          </Route>
          <Route path="/micasillero/referidos">
            <Referred />
          </Route>
          <Route path="/micasillero/actualiza-tus-datos">
            <ActualizarDatos />
          </Route>
          {user.vip === "S" && (
            <>
              <Route path="/micasillero/estado-cuenta">
                <AccountState />
              </Route>
              <Route path="/micasillero/control-peso">
                <WeightControl />
              </Route>
            </>
          )}
          {user.valido === "N" && (
            <>
              <Route path="/micasillero/actualizacion-datos">
                <UpdateData />
              </Route>
            </>
          )}
          {/* {user.promocion === "N" && ( */}
            <>
              <Route path="/micasillero/promocion">
                <Promotion />
              </Route>
            </>
          {/* )} */}
        </div>
      </SiteLayout>
    </main>
  );
}
