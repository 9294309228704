import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as User } from "../../assets/images/icons/user.svg";
import { ReactComponent as Building } from "../../assets/images/icons/building.svg";
import agricola from "../../assets/images/agricola.jpg";
import PersonalForm from "./PersonalForm";
import BusinessForm from "./BusinessForm";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";

export default function RegisterForm({ dataCMS }) {
  const [isCompleted, setIsCompleted] = useState(false);
  const [formType, setFormType] = useState(0);
  const [promotion, setPromotion] = useState({});
  const [step, setStep] = useState(1);
  const [isAgricola, setIsAgricola] = useState(false);

  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    const id1 = query.get("id1");
    const id2 = query.get("id2");
    const id3 = query.get("id3");

    if (id1 && id2 && id3) {
      const body = {
        id1,
        id2,
        id3,
      };

      axios
        .post(`${process.env.REACT_APP_BASE_URL}/decrypt`, body)
        .then((res) => {
          if (res.status === 200 && !res.data.error) {
            setPromotion(res.data.data);
          }
        });
      return;
    }

    const ref = query.get("ref");

    if (ref === "WBA") {
      setIsAgricola(true);
    }
  }, [query]);

  return (
    <div className="container flex flex-col lg:flex-row items-center justify-center">
      {dataCMS?.VistaBancoAgricola && isAgricola && (
        <div className="flex flex-col lg:w-1/2 justify-center items-center mt-10 pr-10">
          <img src={agricola} alt="" className="w-full max-w-xl" />
          <Link
            to="/terminos-agricola"
            className="flex justify-center text-te-blue-800 underline"
          >
            Leer términos y condiciones
          </Link>
        </div>
      )}
      <div className="w-full max-w-lg">
        {!isCompleted && (
          <div className="mt-10 flex flex-col md:flex-row md:justify-between">
            <div className="bg-te-blue-100 rounded-full p-1 inline-flex mx-auto md:mx-0">
              <button
                onClick={() => {
                  setStep(1);
                  setFormType(0);
                }}
                className={`flex items-center rounded-full py-1 px-3 text-sm font-semibold ${
                  formType === 0
                    ? " bg-te-blue-800 text-white"
                    : " bg-transparent text-te-blue-800"
                }`}
              >
                <User className="h-3 fill-current mr-1" />
                Personal
              </button>
              <button
                onClick={() => {
                  setStep(1);
                  setFormType(1);
                }}
                className={`flex items-center rounded-full py-1 px-3 text-sm font-semibold${
                  formType === 1
                    ? " bg-te-blue-800 text-white"
                    : " bg-transparent text-te-blue-800"
                }`}
              >
                <Building className="h-3 fill-current mr-1" />
                Empresa
              </button>
            </div>

            <div className="md:w-1/2 flex justify-end mt-5 md:mt-0">
              <div
                onClick={() => setStep(1)}
                className={`font-semibold rounded-full h-10 w-10 flex items-center justify-center border-2 ${
                  step >= 1
                    ? "text-white  bg-te-blue-800 border-te-blue-800"
                    : " text-te-gray-700 border-te-gray-800"
                } `}
              >
                1
              </div>
              <div
                className={`border-t-2 mt-5 h-5 flex-1 md:flex-none md:w-4 ${
                  step >= 1 ? "border-te-blue-800" : "border-te-gray-800"
                }`}
              ></div>
              <div
                className={`border-t-2 mt-5 h-5 flex-1 md:flex-none md:w-4 ${
                  step >= 2 ? "border-te-blue-800" : "border-te-gray-800"
                }`}
              ></div>
              <div
                onClick={() => setStep(2)}
                className={`font-semibold rounded-full h-10 w-10 flex items-center justify-center border-2 ${
                  step >= 2
                    ? "text-white  bg-te-blue-800 border-te-blue-800"
                    : " text-te-gray-800 border-te-gray-800"
                } `}
              >
                2
              </div>
              <div
                className={`border-t-2 mt-5 h-5 flex-1 md:flex-none md:w-4 ${
                  step >= 2 ? "border-te-blue-800" : "border-te-gray-800"
                }`}
              ></div>
              <div
                className={`border-t-2 mt-5 h-5 flex-1 md:flex-none md:w-4 ${
                  step >= 3 ? "border-te-blue-800" : "border-te-gray-800"
                }`}
              ></div>
              <div
                className={`font-semibold rounded-full h-10 w-10 flex items-center justify-center border-2 ${
                  step >= 3
                    ? "text-white  bg-te-blue-800 border-te-blue-800"
                    : " text-te-gray-800 border-te-gray-800"
                } `}
              >
                3
              </div>
            </div>
          </div>
        )}
        {formType === 0 && (
          <PersonalForm
            step={step}
            setStep={setStep}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
            promotion={promotion.promocion}
            locker={promotion.casillero}
          />
        )}
        {formType === 1 && (
          <BusinessForm
            step={step}
            setStep={setStep}
            isCompleted={isCompleted}
            setIsCompleted={setIsCompleted}
            promotion={promotion.promocion}
            locker={promotion.casillero}
          />
        )}
      </div>
    </div>
  );
}
