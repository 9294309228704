import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../contexts/auth";
import Spinner from "../utils/Spinner";

export default function AddNewReferred() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState("");
  const [error, setError] = useState("");
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");
    setIsCompleted();

    const body = {
      casillero: user?.PoBox || null, //En este caso mandamelo null
      email: user ? null : data.emailRefiere, //manda aca el mail
      nombreremite: null,
      nombrereferido: data.name,
      emailreferido: data.email,
    };

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/user/references`, body)
      .then((res) => {
        if (res.data.data && res.status === 200) {
          if (res.data.message === "Usuario ya esta referido") {
            setError("Este usuario ya ha sido referido.");
          } else {
            reset();
            setIsCompleted("¡Hemos recibido a tu referido!");
          }
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (user) {
      reset({
        emailRefiere: user.Correo,
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="mt-8">
      <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
        <div className="">
          <span className="block text-te-gray-800 font-semibold mt-5">
            Correo electrónico:
          </span>
          <input
            type="email"
            className={`inline-block mt-1 bg-te-gray-700 bg-opacity-50 py-2 px-3 border-2  rounded-lg placeholder-te-gray-800 focus:outline-none w-72 font-semibold text-te-blue-800 ${
              errors.emailRefiere ? "border-te-red-900" : "border-transparent"
            }`}
            {...register("emailRefiere", { required: true })}
            placeholder="Correo electrónico de quien refiere"
          />
        </div>

        <div className="mt-10 font-bold text-2xl text-te-blue-800 text-center">
          Tus Referidos
        </div>

        <div className=" flex justify-center mt-10">
          <div className="">
            <span className="block text-te-gray-800 font-semibold mt-5">
              Nombre:
            </span>
            <input
              type="text"
              className={`inline-block mt-1 bg-te-gray-700 bg-opacity-50 py-2 px-3 border-2 rounded-lg placeholder-te-gray-400 focus:outline-none w-72 font-semibold text-te-blue-800 ${
                errors.name ? "border-te-red-900" : "border-transparent"
              }`}
              {...register("name", { required: true })}
              placeholder="Nombre"
            />
          </div>
          <div className="ml-4 ">
            <span className="block text-te-gray-800 font-semibold mt-5">
              Correo electrónico:
            </span>
            <input
              type="email"
              className={`inline-block mt-1 bg-te-gray-700 bg-opacity-50 py-2 px-3 border-2  rounded-lg placeholder-te-gray-400 focus:outline-none w-72 font-semibold text-te-blue-800 ${
                errors.email ? "border-te-red-900" : "border-transparent"
              }`}
              {...register("email", { required: true })}
              placeholder="Correo"
            />
          </div>
        </div>

        <div className="mt-10 flex justify-center">
          <button
            type="submit"
            disabled={isLoading}
            className="text-te-blue-800 border-2 border-te-blue-800 px-12 py-2 rounded-lg font-semibold relative"
          >
            Referir
            {isLoading && (
              <div className="absolute right-1 top-0 h-full flex items-center">
                <Spinner color="#141c82" type="spinningBubbles" />
              </div>
            )}
          </button>
        </div>
      </form>

      {isCompleted && (
        <div className="text-center font-semibold text-te-green-700 mt-4">
          {isCompleted}
        </div>
      )}

      {error && (
        <div className="text-te-red-900 text-center font-semibold mt-4">
          *{error}
        </div>
      )}
    </div>
  );
}
