import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ScrollToTop from "./components/utils/ScrollToTop";
import { ProvideAuth, useAuth } from "./contexts/auth";
import { ProviderCalculator } from "./contexts/calculator";
import { ProviderNav } from "./contexts/Navigation";
import About from "./pages/about";
import Calculator from "./pages/calculator";
import CourierService from "./pages/courierService";
import CustomerServices from "./pages/customerServices";
import FAQ from "./pages/faq";
import Home from "./pages/home";
import ImportCargo from "./pages/importCargo";
import Locker from "./pages/locker";
import LockerService from "./pages/lockerService";
import Login from "./pages/login";
import PackageDetails from "./pages/PackageDetails";
import Privacy from "./pages/privacy";
import Referrals from "./pages/referrals";
import Register from "./pages/register";
import RestorePassword from "./pages/restorePassword";
import RestrictedMerchandise from "./pages/restrictedMerchandise";
import SendInvoice from "./pages/sendInvoice";
import Terms from "./pages/terms";
import TermsPoints from "./pages/terms/TermsPoints";
import TermsReferrals from "./pages/terms/TermsReferrals";
import Tracking from "./pages/tracking";
import DynamicPage from "./pages/dynamicPage";
import ValidateUser from "./pages/validateUser";
import BlackTerms from "./pages/blackTerms/blackTerms";

function App() {
  return (
    <Router>
      <ProvideAuth>
        <ProviderCalculator>
          <ProviderNav>
            <div className="font-SourceSansPro">
              <ScrollToTop />
              <Switch>
                <Redirect from="/bancocuscatlan" to="/registrate?ref=PCU" />
                <Route exact path="/validacion">
                  <ValidateUser />
                </Route>
                <Route exact path="/acerca-de-nosotros">
                  <About />
                </Route>
                <Route exact path="/terminos-black">
                  <BlackTerms />
                </Route>
                <Route exact path="/(cotizar-envio|calculadora)">
                  <Calculator />
                </Route>
                <Route exact path="/carga-de-importacion">
                  <ImportCargo />
                </Route>
                <Route exact path="/casillero">
                  <LockerService />
                </Route>
                <Route exact path="/courier">
                  <CourierService />
                </Route>
                <Route exact path="/envianos-tu-factura">
                  <SendInvoice />
                </Route>
                <Route exact path="/(servicio-al-cliente|contactanos)">
                  <CustomerServices />
                </Route>
                <Route exact path="/mercaderia-restringida">
                  <RestrictedMerchandise />
                </Route>
                <Route exact path="/preguntas-frecuentes">
                  <FAQ />
                </Route>
                <Route exact path="/terminos-y-condiciones">
                  <Terms />
                </Route>
                <Route exact path="/terminos-referidos">
                  <TermsReferrals />
                </Route>
                <Route exact path="/puntostransexpress">
                  <TermsPoints />
                </Route>
                <Route exact path="/privacidad">
                  <Privacy />
                </Route>
                <Route exact path="/referidos">
                  <Referrals />
                </Route>
                <PrivateRoute path="/micasillero">
                  <Locker />
                </PrivateRoute>
                <PrivateRoute path="/packages/:IdPackage">
                  <PackageDetails />
                </PrivateRoute>
                <Route path="/tracking/:IdPackage">
                  <Tracking />
                </Route>
                <UnregisteredRoute exact path="/login">
                  <Login />
                </UnregisteredRoute>
                <UnregisteredRoute exact path="/resetpassw">
                  <RestorePassword />
                </UnregisteredRoute>
                <Route exact path="/(register|registrate)">
                  <Register />
                </Route>
                <Route exact path="/:slug">
                  <DynamicPage />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
            </div>
          </ProviderNav>
        </ProviderCalculator>
      </ProvideAuth>
    </Router>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function UnregisteredRoute({ children, ...rest }) {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/micasillero",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default App;
