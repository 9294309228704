import React, { useState } from "react";
import boxIcon from "../../assets/images/icons/box.svg";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function TrackingMobile() {
  const [value, setValue] = useState("");

  return (
    <div className="bg-te-gray-700 lg:hidden flex flex-col">
      <div className="flex items-center justify-center mt-3">
        <img
          src={boxIcon}
          alt="ilustración de un paquete"
          className="h-4 mr-3"
        />
        <span className=" text-te-blue-900 font-bold">Localiza tu paquete</span>
      </div>
      <div className="w-full mb-3">
        <div className="flex items-center justify-center mt-3 mx-auto max-w-xs md:max-w-xl">
          <div className="rounded-full bg-white px-3 py-2 flex w-full items-center justify-between">
            <LocationMarkerIcon
              className={`${
                value ? "text-te-orange-400" : "text-te-gray-800"
              } h-5 w-5`}
            />
            <input
              type="text"
              placeholder="Ubica aquí tu número de rastreo o guía"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className=" font-semibold focus:outline-none w-full mx-2 placeholder-te-gray-800 text-te-blue-800 text-sm"
            />
            <Link
              to={`/tracking/${value}`}
              className="text-te-blue-800 font-semibold mr-2"
            >
              Rastrear
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
