import React from "react";
import { DateTime } from "luxon";

export default function TrackingElSalvador({ trakingSalvador }) {
  return (
    <div className=" w-full mt-10">
      <p className="font-bold text-xl text-te-blue-800">
        Información de embarque (El Salvador)
      </p>

      {!!trakingSalvador.length ? (
        <>
          <div className="border-2 border-te-gray-700 rounded-xl mt-5 hidden md:flex">
            <div className="w-full">
              <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold mt-5">
                <div className="flex-1">fecha</div>
                <div className="flex-1">hora</div>
                <div className="flex-1">estatus</div>
              </div>
              <div className="w-full space-y-3 p-3">
                {trakingSalvador.map((value, index) => (
                  <div
                    key={index}
                    className="flex bg-te-gray-700 bg-opacity-50 rounded-lg py-3"
                  >
                    <div className="flex-1 flex items-center text-sm">
                      <div className=" pl-4 text-te-gray-800 font-semibold">
                        {DateTime.fromISO(value.Dpa_MovFecha).toFormat(
                          "dd/MM/yyyy"
                        )}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Dpa_Hora}
                      </div>
                    </div>
                    <div className="flex-1 flex items-center text-sm">
                      <div className="text-te-gray-800 font-semibold">
                        {value.Est_DescripcionWEB}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="md:hidden mt-5">
            {trakingSalvador.map((value, index) => (
              <div key={index} className="py-2">
                <div className="flex items-center text-sm border-b-2 border-te-gray-700 pb-2">
                  <div className="text-te-blue-800 font-semibold">
                    - {value.Est_DescripcionWEB}
                  </div>
                </div>
                <div className="flex items-center text-sm mt-2">
                  <div className="text-te-gray-800 font-semibold">
                    Fecha:{" "}
                    {DateTime.fromISO(value.Dpa_MovFecha).toFormat(
                      "dd/MM/yyyy"
                    )}
                  </div>
                </div>
                <div className="flex items-center text-sm">
                  <div className="text-te-gray-800 font-semibold">
                    Hora: {value.Dpa_Hora}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="mt-5 bg-te-blue-100 py-4 px-5 text-te-blue-800 font-semibold rounded-xl">
          No se encontraron movimientos para ese numero de guía en agencia
          Local.
        </div>
      )}
    </div>
  );
}
