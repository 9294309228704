import React from "react";
import shieldCheck from "../../assets/images/icons/shieldCheck.svg";

export default function Insurances({ dataCMS }) {
  return (
    <section className=" bg-te-gray-700">
      <div className="container py-10 lg:py-16 lg:px-32">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-3xl font-semibold"
        >
          {dataCMS?.TituloSeguros}
        </h3>

        <div className="flex justify-center">
          <div className="grid grid-cols-1 lg:grid-cols-3 mt-5 lg:mt-16 gap-5 lg:gap-10">
            {dataCMS?.Seguros?.map((seguro) => (
              <div data-aos="fade-up" className="flex" key={seguro.id}>
                <span className="block mr-2 mt-1 flex-shrink-0">
                  <img src={shieldCheck} alt="" className="h-6" />
                </span>
                <p className=" text-te-gray-800 text-lg">{seguro.Text}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
