import { ChevronUpIcon, XIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useCalculator } from "../../contexts/calculator";
import { useForm } from "react-hook-form";
import CalculatorModal from "../calculator/CalculatorModal";
import axios from "axios";
import { calculate } from "../../services/calculate";
import Spinner from "../utils/Spinner";

export default function PendingToCalculate() {
  const [isShowing, setIsShowing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dutys, setDutys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [calculatedData, setCalculatedData] = useState({});

  const location = useLocation();

  const { values, setValues } = useCalculator();
  const { register, handleSubmit, reset, watch } = useForm();

  const onSubmit = async (data) => {
    setIsLoading(true);
    calculate(data)
      .then((res) => {
        if (res.data.error) {
          setError("El servicio no está disponible. Inténtelo más tarde.");
        } else {
          setCalculatedData(res.data.data);
          openModal();
        }
      })
      .catch(() => {
        setError("El servicio no está disponible. Inténtelo más tarde.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const cleanForm = () => {
    reset();
    setValues({ isActive: true, value: "", weight: "", category: "" });
  };

  useEffect(() => {
    reset(values);
    getDutys();
    // eslint-disable-next-line
  }, []);

  const getDutys = () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/dutys`)
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setDutys(res.data.data);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (values.isActive && location.pathname !== "/cotizar-envio") {
    return (
      <>
        <div
          className={`fixed bottom-0 w-full transform transition ${
            isShowing ? "translate-y-0" : "lg:translate-y-60 translate-y-96"
          }`}
        >
          <div
            onClick={() => {
              setIsShowing(!isShowing);
            }}
            className="h-16 bg-te-blue-400 rounded-t-2xl container mx-auto flex items-center lg:justify-center relative cursor-pointer"
          >
            <p className=" text-white font-semibold text-center lg:text-xl">
              ¿Deseas continuar con la calculadora?
            </p>
            {!isShowing ? (
              <div
                onClick={() => {
                  setIsShowing(true);
                }}
                className="absolute right-5 lg:right-10 w-8 h-8 bg-white rounded-full flex items-center justify-center"
              >
                <ChevronUpIcon className="h-6 text-te-blue-800" />
              </div>
            ) : (
              <div
                onClick={() => {
                  setValues({ isActive: false });
                }}
                className="absolute right-5 lg:right-10 w-8 h-8 bg-white rounded-full flex items-center justify-center cursor-pointer"
              >
                <XIcon className="h-5 text-te-blue-800" />
              </div>
            )}
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="bg-white container mx-auto h-96 lg:h-60 flex flex-col lg:flex-row p-5 lg:p-10 shadow-lg"
          >
            <div className="lg:w-1/3">
              <p className="text-3xl text-te-blue-800 font-bold ">
                Calculadora
              </p>
              <p className="text-te-gray-800 mt-2 hidden lg:block">
                Completa los siguientes campos y recibe <br /> el costo total de
                tu compra
              </p>
            </div>
            <div className="lg:w-1/3 mt-4 lg:mt-0">
              <div className=" w-full max-w-lg">
                <label
                  htmlFor="category"
                  className="text-sm text-te-gray-800 font-semibold"
                >
                  Tipo de mercadería
                </label>
                <select
                  id="category"
                  {...register("category", { required: true })}
                  className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none mt-2 font-semibold text-sm"
                >
                  <option value="">
                    Selecciona una categoría de tu compra
                  </option>
                  {dutys.map((duty, index) => (
                    <option value={JSON.stringify(duty)} key={index}>
                      {duty.Descripcion} ({duty.Porcentaje}%)
                    </option>
                  ))}
                </select>
                <div className="mt-3 flex space-x-4">
                  <div className="w-1/2">
                    <label
                      htmlFor="weight"
                      className="text-sm text-te-gray-800 font-semibold"
                    >
                      Peso de la mercadería
                    </label>
                    <div className="flex items-center relative bg-te-gray-700 mt-2 rounded-lg ">
                      <input
                        type="number"
                        step="0.01"
                        {...register("weight", { required: true })}
                        className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none font-semibold text-sm"
                      />
                      <span className="inline-flex right-0 h-full items-center text-te-gray-800 font-semibold mr-3">
                        LBS.
                      </span>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="value"
                      className="text-sm text-te-gray-800 font-semibold"
                    >
                      Valor de la factura
                    </label>
                    <div className="flex items-center relative bg-te-gray-700 mt-2 rounded-lg ">
                      <input
                        type="number"
                        step="0.01"
                        {...register("value", { required: true })}
                        className="py-3 px-3 bg-te-gray-700 w-full rounded-lg focus:outline-none font-semibold text-sm"
                      />
                      <span className="inline-flex right-0 h-full items-center text-te-gray-800 font-semibold mr-3">
                        USD
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:w-1/3 max-w-sm flex flex-col h-full items-end">
              <button
                disabled={isLoading}
                type="submit"
                className="w-full lg:w-64 bg-te-blue-400 text-white font-semibold rounded-lg mt-4 lg:mt-8 py-2 relative"
              >
                Calcular
                {isLoading && (
                  <div className="absolute right-2 top-0 h-full flex items-center">
                    <Spinner color="#ffffff" type="spinningBubbles" />
                  </div>
                )}
              </button>
              <button
                onClick={cleanForm}
                type="button"
                className="w-full lg:w-64 bg-white text-blue-800 border border-te-blue-800 rounded-lg font-semibold mt-3 py-2"
              >
                Limpiar campos
              </button>

              {error && (
                <div className="mt-3 text-te-red-900 font-semibold text-center">
                  *{error}
                </div>
              )}
            </div>
          </form>
        </div>

        <CalculatorModal
          isOpen={isOpen}
          closeModal={closeModal}
          results={calculatedData}
          category={watch().category}
        />
      </>
    );
  } else {
    return null;
  }
}
