import React from "react";

export default function ButtonBasic({
  className,
  classNameFather,
  children,
  onClick,
  disabled,
}) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`inline-flex rounded-lg ${classNameFather}`}
    >
      <span
        className={`${className} py-3 px-4 rounded-lg text-sm w-full focus:outline-none font-semibold transition transform hover:-translate-y-1 active:translate-y-0`}
      >
        {children}
      </span>
    </button>
  );
}
