import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import React from "react";
import Spinner from "../../utils/Spinner";
import { ButtonRemember } from "./AdministrateReferred";

export default function AdministrateReferredMobile({
  manageReferrals,
  isLoadingManageReferrals,
  errorManageReferrals,
  getManageReferrals,
}) {
  return (
    <div>
      <div className="mt-5 border-2 border-te-gray-700 rounded-xl overflow-hidden">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex items-center justify-between w-full text-left focus:outline-none font-semibold text-te-blue-800 border-b  p-4`}
              >
                <span>ADMINISTRAR REFERIDOS</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "transform rotate-180" : ""
                  } w-5 h-5 transition text-te-blue-800`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="">
                  <div className="">
                    {!isLoadingManageReferrals ? (
                      <div className="w-full">
                        {manageReferrals.length ? (
                          <>
                            <div className="w-full space-y-3 p-3">
                              {manageReferrals.map((value, index) => (
                                <div
                                  key={index}
                                  className="flex flex-col bg-te-gray-700 bg-opacity-50 rounded-lg p-3 space-y-3"
                                >
                                  <div className="text-sm">
                                    <div className="text-te-gray-800 font-semibold">
                                      Nombre: <span className="text-te-blue-800">{value.Nombre}</span>
                                    </div>
                                  </div>
                                  <div className="text-sm">
                                    <div className="text-te-gray-800 font-semibold">
                                      Correo: <span className="text-te-blue-800">{value.Email}</span>
                                    </div>
                                  </div>
                                  <div className="text-sm">
                                    <div className="text-te-gray-800 font-semibold">
                                      Estado: <span className="text-te-blue-800">{value.Estado}</span>
                                    </div>
                                  </div>
                                  <div className="text-sm">
                                    <div className="text-te-gray-800 font-semibold">
                                      Observación: <span className="text-te-blue-800">{value.Observacion}</span>
                                    </div>
                                  </div>
                                  <div className="w-full">
                                    <ButtonRemember
                                      value={value}
                                      getManageReferrals={getManageReferrals}
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <span className="flex justify-center py-6 text-te-gray-800 font-semibold text-lg">
                            {errorManageReferrals}
                          </span>
                        )}
                      </div>
                    ) : (
                      <div className="flex items-center py-6 justify-center">
                        <Spinner color="#0d1256" type="spinningBubbles" />
                      </div>
                    )}
                  </div>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
