import { SearchIcon } from "@heroicons/react/solid";
import React from "react";
import { useForm } from "react-hook-form";

export default function Main({ setValue }) {
  const {
    handleSubmit,
    register,
  } = useForm();

  const onSubmit = async (data) => {
    setValue(data.search);
  };

  return (
    <section className="lg:container">
      <div className="lg:mt-10 h-64 lg:h-96 lg:rounded-2xl overflow-hidden relative">
        <div className="absolute h-full w-full bg-te-gray-700"></div>
        <div className="absolute w-full h-full flex flex-col items-center justify-center">
          <h1
            data-aos="fade-up"
            className=" text-te-blue-800 relative text-lg lg:text-2xl font-bold"
          >
            Preguntas frecuentes
          </h1>
          <p
            data-aos="fade-up"
            className=" text-te-blue-800 relative text-2xl lg:text-4xl font-bold mt-2 lg:mt-5"
          >
            ¿En que podemos ayudarte?
          </p>

          <div
            data-aos="fade-up"
            className="w-full max-w-xl mt-8 px-5 hidden lg:block"
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="rounded-full bg-white px-3 py-2 flex items-center justify-between w-full shadow-lg"
            >
              <SearchIcon className="text-te-gray-800 h-6 w-5" />
              <input
                type="text"
                {...register("search")}
                placeholder="Escribe tu pregunta"
                className="font-semibold focus:outline-none w-full mx-2 placeholder-te-gray-800 text-te-blue-800"
              />
              <button
                type="submit"
                className="text-te-blue-800 font-semibold mr-2"
              >
                Buscar
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
