import React from "react";
import details from "../../../assets/images/locker/details.svg";
import box from "../../../assets/images/icons/locker-box.svg";
import Spinner from "../../utils/Spinner";
import { Link } from "react-router-dom";

export const getStatus = (status) => {
  if (status === "INSTRUCTIONS / Instrucciones") {
    return "Retenido";
  } else if (
    status ===
    "MANIFEST SHIPPED ARRIVED CONFIRMATION/Confirmacion de arrivo de Master"
  ) {
    return "En arrivo";
  } else if (
    status === "DELIVERED / Entregado" ||
    status === "Recibido por destinatario"
  ) {
    return "Entregado";
  } else if (status === "ABANDONED / Abandonado") {
    return "Abandonado";
  } else if (status === "SCREENING / En revision de contenido") {
    return "En revisión";
  } else if (
    status === "LOCATION AT WHSE (MIA) / Localizacion bodega (MIA)" ||
    status === "Recibido en Miami"
  ) {
    return "En Miami";
  } else if (
    status === "SHIPPED / Despachado" ||
    status === "Despachado de Miami"
  ) {
    return "Despachado en Miami";
  } else if (status === "AT CUSTOMS / En Aduanas") {
    return "En aduana";
  } else if (
    status === "AT WHSE (DES)/ Posicion Bodega Destino" ||
    status === "Recibido en San Salvador"
  ) {
    return "En bodega";
  } else if (status === "INFORMATION TO AGENCY / Informacion a la agencia") {
    return "";
  } else if (
    status === "IN ROUTE TO DELIVERY / En ruta para entrega" ||
    status === "Enviado a domicilio"
  ) {
    return "Domicilio";
  } else if (status === "VOID / Anulado") {
    return "Anulado";
  } else if (status === "RELEASE / Desbloqueado") {
    return "Desbloqueado";
  } else if (status === "RELEASE FROM WHSE / En Despacho") {
    return "En despacho";
  } else if (status === "En Proceso") {
    return "En Proceso";
  } else if (status === "HOLD / Retenido") {
    return "Retenido";
  } else if (status === "Falta la factura" || status === "Falta Factura") {
    return "Falta factura";
  } else if (status === "Recibido en aduana") {
    return "Recibido en aduana";
  } else if (status === "Requiere manejo especial") {
    return "Requiere manejo especial";
  }
};

export const getStatusColor = (status) => {
  if (status === "INSTRUCTIONS / Instrucciones") {
    return "text-te-red-800 bg-te-red-200";
  } else if (
    status ===
    "MANIFEST SHIPPED ARRIVED CONFIRMATION/Confirmacion de arrivo de Master"
  ) {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (
    status === "DELIVERED / Entregado" ||
    status === "Recibido por destinatario"
  ) {
    return "text-te-green-800 bg-te-green-200";
  } else if (status === "ABANDONED / Abandonado") {
    return "text-te-red-800 bg-te-red-200";
  } else if (status === "SCREENING / En revision de contenido") {
    return "text-te-yellow-800 bg-te-yellow-200";
  } else if (status === "LOCATION AT WHSE (MIA) / Localizacion bodega (MIA)") {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (status === "SHIPPED / Despachado") {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (status === "AT CUSTOMS / En Aduanas") {
    return "text-te-blue-300 bg-te-blue-200";
  } else if (status === "AT WHSE (DES)/ Posicion Bodega Destino") {
    return "text-te-yellow-800 bg-te-yellow-200";
  } else if (status === "INFORMATION TO AGENCY / Informacion a la agencia") {
    return "text-te-yellow-800 bg-te-yellow-200";
  } else if (status === "IN ROUTE TO DELIVERY / En ruta para entrega") {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (status === "VOID / Anulado") {
    return "text-te-red-800 bg-te-red-200";
  } else if (status === "RELEASE / Desbloqueado") {
    return "text-te-green-800 bg-te-green-200";
  } else if (status === "RELEASE FROM WHSE / En Despacho") {
    return "text-te-blue-400 bg-te-blue-100";
  } else if (status === "HOLD / Retenido") {
    return "text-te-red-800 bg-te-red-200";
  }
};

export default function PackagesDelivered({ isLoading, packages, error }) {
  return (
    <div className="mt-5 border-2 border-te-gray-700 rounded-xl mb-20">
      {isLoading && (
        <div className="py-10 flex justify-center">
          {" "}
          <Spinner color="#141c82" type="spinningBubbles" />{" "}
        </div>
      )}

      {!isLoading && error && (
        <span className="block text-center py-10">{error}</span>
      )}

      {!isLoading && !!packages.length && (
        <div className="w-full">
          <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold">
            <div className="flex-1 text-center">Guía</div>
            <div className="flex-1 text-center">Tracking</div>
            {/* <div className="flex-1 text-center">recibido en miami</div> */}
            <div className="flex-1 text-center">acción pendiente</div>
            <div className="w-32 text-center">Detalle</div>
          </div>

          <div className="w-full space-y-4 p-3">
            {packages.length &&
              packages.map((value, index) => (
                <div
                  key={index}
                  className="flex bg-te-gray-700 bg-opacity-50 rounded-lg"
                >
                  <div className="flex-1 py-3 flex">
                    <div className="ml-5">
                      <img src={box} alt="" className="h-7" />
                    </div>
                    <div className="ml-3">
                      <span className="block text-sm font-semibold text-te-blue-800">
                        {value.Guia}
                      </span>
                      <span
                        className={`mt-1 inline-block ${getStatusColor(
                          value.UtlimoEstatus
                        )} rounded-r-full rounded-bl-full px-3 py-1 text-xs font-semibold`}
                      >
                        {getStatus(value.UtlimoEstatus)}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center justify-center text-sm">
                    <div className="rounded-lg px-3 py-2 bg-gray-200 text-te-gray-800 font-semibold w-48 text-center max-w-sm truncate">
                      {value.NumeroTracking}
                    </div>
                  </div>
                  {/* <div className="flex-1 flex items-center justify-center text-sm">
                    <div className="text-te-gray-800 font-semibold">
                      {value.FechaDeIngreso}
                    </div>
                  </div> */}
                  <div className="flex-1 flex items-center justify-center text-sm">
                    <div className="text-te-gray-800 font-semibold">
                      No Aplica
                    </div>
                  </div>
                  <div className="w-32 flex items-center">
                    <div className="mx-auto">
                      <Link to={`/packages/${value.Guia}`}>
                        <img
                          src={details}
                          alt=""
                          className="h-8 cursor-pointer"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
