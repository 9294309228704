import { ArrowLeftIcon, LogoutIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PencilIcon } from "@heroicons/react/outline";
import userI from "../../assets/images/icons/locker-user.svg";
import userGray from "../../assets/images/icons/locker-user-gray.svg";
import box from "../../assets/images/icons/locker-box.svg";
import boxGray from "../../assets/images/icons/locker-box-gray.svg";
import coupon from "../../assets/images/icons/locker-coupons.svg";
import couponGray from "../../assets/images/icons/locker-coupons-gray.svg";
import points from "../../assets/images/icons/locker-points.svg";
import pointsGray from "../../assets/images/icons/locker-points-gray.svg";
import refer from "../../assets/images/icons/refer.svg";
import referGray from "../../assets/images/icons/refer-gray.svg";
import { useAuth } from "../../contexts/auth";
import axios from "axios";

export default function Sidebar() {
  const { signout } = useAuth();
  const [selected, setSelected] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    const pathname = location.pathname;
    if (pathname === "/micasillero") {
      setSelected(0);
    } else if (pathname === "/micasillero/packages") {
      setSelected(1);
    } else if (pathname === "/micasillero/enviar-factura") {
      setSelected(2);
    } else if (pathname === "/micasillero/puntos") {
      setSelected(3);
    } else if (pathname === "/micasillero/referidos") {
      setSelected(4);
    } else if (pathname === "/micasillero/estado-cuenta") {
      setSelected(5);
    } else if (pathname === "/micasillero/control-peso") {
      setSelected(6);
    } else if (pathname === "/micasillero/actualiza-tus-datos") {
      setSelected(9);
    }
    // } else if (pathname === "/micasillero/actualizacion-datos") {
    //   setSelected(7);
    // } else if (pathname === "/micasillero/promocion") {
    //   setSelected(8);
    // }
  }, [location]);

  async function remoteLogout() {
    await axios.get(
      `${process.env.REACT_APP_SHOPPING_MALL_BASE_URL}/login/logout`,
      { withCredentials: true }
    );
  }

  return (
    <div className="w-1/4 hidden lg:block">
      <Link
        to="/"
        className=" flex items-center text-te-blue-800 text-lg font-bold underline"
      >
        <ArrowLeftIcon className=" text-te-blue-800 h-5 mr-3" /> Regresar al
        inicio
      </Link>

      <div className="mt-12 mb-20 border-2 border-te-gray-700 rounded-xl p-3 2xl:p-5 w-64 2xl:w-auto max-w-xs">
        <ul className=" space-y-2 2xl:text-lg">
          <li>
            <Link
              to="/micasillero"
              onClick={() => {
                setSelected(0);
              }}
              className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 0
                  ? "text-white bg-te-blue-800"
                  : "text-te-blue-800"
                }`}
            >
              <span className="w-8 mr-3">
                <img src={selected === 0 ? userI : userGray} alt="" />
              </span>
              Mi Casillero
            </Link>
          </li>
          <li>
            <Link
              to="/micasillero/packages"
              onClick={() => {
                setSelected(1);
              }}
              className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 1
                  ? "text-white bg-te-blue-800"
                  : "text-te-blue-800"
                }`}
            >
              <span className="w-8 mr-3">
                <img src={selected === 1 ? box : boxGray} alt="" />
              </span>
              Mis Paquetes
            </Link>
          </li>
          <li>
            <Link
              to="/micasillero/enviar-factura"
              onClick={() => {
                setSelected(2);
              }}
              className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 2
                  ? "text-white bg-te-blue-800"
                  : "text-te-blue-800"
                }`}
            >
              <span className="w-8 mr-3">
                <img src={selected === 2 ? coupon : couponGray} alt="" />
              </span>
              Envíanos tu Factura
            </Link>
          </li>
          <li>
            <Link
              to="/micasillero/puntos"
              onClick={() => {
                setSelected(3);
              }}
              className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 3
                  ? "text-white bg-te-blue-800"
                  : "text-te-blue-800"
                }`}
            >
              <span className="w-8 mr-3">
                <img src={selected === 3 ? points : pointsGray} alt="" />
              </span>
              Puntos TE
            </Link>
          </li>
          <li>
            <Link
              to="/micasillero/referidos"
              onClick={() => {
                setSelected(4);
              }}
              className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 4
                  ? "text-white bg-te-blue-800"
                  : "text-te-blue-800"
                }`}
            >
              <span className="w-8 mr-3">
                <img
                  src={selected === 4 ? refer : referGray}
                  alt=""
                  className="w-full h-full"
                />
              </span>
              Referir Amigos
            </Link>
          </li>
          <li>
            <Link to="/micasillero/actualiza-tus-datos" onClick={() => { setSelected(9) }} className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 9 ? "text-white bg-te-blue-800" : "text-te-blue-800" }`} >
              <span className="w-8 mr-3">
                {/* <img src={selected === 9 ? coupon : couponGray} alt="" /> */}
                <PencilIcon className={`w-6 h-6`} />
              </span>
              Datos de Facturación
            </Link>
          </li>
          {user.vip === "S" && (
            <>
              <li>
                <Link
                  to="/micasillero/estado-cuenta"
                  onClick={() => {
                    setSelected(5);
                  }}
                  className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 5
                      ? "text-white bg-te-blue-800"
                      : "text-te-blue-800"
                    }`}
                >
                  <span className="w-8 mr-3">
                    <img src={selected === 5 ? coupon : couponGray} alt="" />
                  </span>
                  Estado de Cuenta
                </Link>
              </li>
              <li>
                <Link
                  to="/micasillero/control-peso"
                  onClick={() => {
                    setSelected(6);
                  }}
                  className={`flex w-full items-center font-semibold rounded-lg p-3 ${selected === 6
                      ? "text-white bg-te-blue-800"
                      : "text-te-blue-800"
                    }`}
                >
                  <span className="w-8 mr-3">
                    <img src={selected === 6 ? coupon : couponGray} alt="" />
                  </span>
                  Control de Pesos
                </Link>
              </li>
            </>
          )}
          {/* {user.PoBox === "100" && ( */}
          {/* {user.valido === "N" && (
            <>
              <li>
                <Link
                  to="/micasillero/actualizacion-datos"
                  onClick={() => {
                    setSelected(7);
                  }}
                  className={`flex w-full items-center font-semibold rounded-lg p-3 ${
                    selected === 7
                      ? "text-white bg-te-blue-800"
                      : "text-te-blue-800"
                  }`}
                >
                  <span className="w-8 mr-3">
                    <img src={selected === 7 ? userI : userGray} alt="" />
                  </span>
                  Actualización de Datos
                </Link>
              </li>
            </>
          )} */}
          {/* {user.promocion === "N" && (
            <>
              <Link
                to="/micasillero/promocion"
                onClick={() => {
                  setSelected(8);
                }}
                className={`flex w-full items-center font-semibold rounded-lg p-3 ${
                  selected === 8
                    ? "text-white bg-te-blue-800"
                    : "text-te-blue-800"
                }`}
              >
                <span className="w-8 mr-3">
                  <img src={selected === 8 ? points : pointsGray} alt="" />
                </span>
                Promoción
              </Link>
            </>
          )} */}
          <li>
            <button
              onClick={() => {
                history.push("/");
                remoteLogout();
                signout();
              }}
              className={`flex w-full items-center font-semibold rounded-lg p-3 text-te-blue-800`}
            >
              <span className="w-8 mr-3">
                <LogoutIcon className={`w-6 text-te-blue-800 mx-auto`} />
              </span>{" "}
              Cerrar Sesión
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
}
