import React, { useContext, createContext, useState, useEffect } from "react";
import axios from "axios";

const NavContext = createContext();

export function ProviderNav({ children }) {
  const nav = useProvideNav();
  return <NavContext.Provider value={nav}>{children}</NavContext.Provider>;
}

export function useNav() {
  return useContext(NavContext);
}

function useProvideNav() {
  const [navigation, setNavigation] = useState(null);
  const [footer, setFooter] = useState(null);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/navbar?populate[0]=Navegacion&populate[1]=Navegacion.OpcionesDropDown`
      )
      .then((response) => {
        setNavigation(response.data?.data?.attributes);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/footer?populate=*`)
      .then((response) => {
        setFooter(response.data?.data?.attributes);
      });
  }, []);

  return {
    navigation,
    footer,
  };
}
