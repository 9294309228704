import React from "react";
import { useHistory } from "react-router";
import ButtonBasic from "../buttons/Basic";

export default function CreateLocker({ dataCMS }) {
  const history = useHistory();

  return (
    <section className="lg:py-16">
      <div className="lg:container lg:px-32">
        <div
          data-aos="fade-up"
          className="lg:rounded-xl bg-te-blue-100 flex flex-col justify-center items-center px-5 lg:px-16 py-14 "
        >
          <p className="text-3xl lg:text-4xl text-center text-blue-800 font-semibold">
            {dataCMS?.TituloSubBanner}
          </p>
          <p className="lg:text-lg mt-5 text-center text-te-gray-800">
            {dataCMS?.DescripcionSubBanner}
          </p>
          <ButtonBasic
            onClick={() => history.push("/register")}
            classNameFather="bg-te-orange-600 mt-10 w-full lg:w-auto"
            className="text-white bg-te-orange-400"
          >
            Crear una cuenta
          </ButtonBasic>
        </div>
      </div>
    </section>
  );
}
