import React, { useEffect, useState } from "react";
import ButtonBasic from "../buttons/Basic";
import lines from "../../assets/images/ilustrations/lines.svg";
import { useHistory } from "react-router";
import axios from "axios";

export default function HowDoesItWork() {
  const history = useHistory();
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/seccion-como-funciona?populate=*`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <section className="relative">
      <img src={lines} alt="" className="absolute -left-2 hidden lg:inline" />

      <div className="py-10 lg:py-16 relative container">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-2xl lg:text-3xl font-semibold text-center"
        >
          {content?.TituloComoFunciona || ""}
        </h3>
        <p
          data-aos="fade-up"
          className="text-te-gray-800 lg:text-lg text-center max-w-3xl mx-auto mt-4"
        >
          {content?.DescripcionComoFunciona || ""}
        </p>

        <div
          data-aos="fade-up"
          className="container lg:space-x-40 space-y-12 lg:space-y-0 flex flew flex-col lg:flex-row items-center justify-center mt-14"
        >
          <div className="w-64 rounded-lg bg-te-gray-600 flex-col items-center justify-center pt-10 pb-8 px-5 relative">
            <div className="flex justify-center absolute inset-x-0 -top-7">
              <div className="flex items-center justify-center bg-te-orange-400 bg-opacity-20 h-14 w-14 rounded-full">
                <div className="h-10 w-10 bg-te-orange-400 rounded-full flex items-center justify-center text-white font-semibold mx-auto text-2xl border-4 border-te-orange-400 border-opacity-20">
                  1
                </div>
              </div>
            </div>

            <div className="">
              <img
                src={content?.ImagenPaso1?.data?.attributes?.url}
                alt=""
                className="h-20 mx-auto"
              />
            </div>

            <p className="text-lg text-te-blue-800 font-bold text-center mt-4">
              {content?.TituloPaso1}
            </p>

            <p className=" text-center text-te-gray-800 text-sm mt-3">
              {content?.DescripcionPaso1}
            </p>
          </div>
          <div className="w-64 rounded-lg bg-te-gray-600 flex-col items-center justify-center pt-10 pb-8 px-5 relative">
            <div className="flex justify-center absolute inset-x-0 -top-7">
              <div className="flex items-center justify-center bg-te-orange-400 bg-opacity-20 h-14 w-14 rounded-full">
                <div className="h-10 w-10 bg-te-orange-400 rounded-full flex items-center justify-center text-white font-semibold mx-auto text-2xl border-4 border-te-orange-400 border-opacity-20">
                  2
                </div>
              </div>
            </div>

            <div className="">
              <img
                src={content?.ImagenPaso2?.data?.attributes?.url}
                alt=""
                className="h-20 mx-auto"
              />
            </div>

            <p className="text-lg text-te-blue-800 font-bold text-center mt-4">
              {content?.TituloPaso2}
            </p>

            <p className=" text-center text-te-gray-800 text-sm mt-3">
              {content?.DescripcionPaso2}
            </p>
          </div>
          <div className="w-64 rounded-lg bg-te-gray-600 flex-col items-center justify-center pt-10 pb-8 px-5 relative">
            <div className="flex justify-center absolute inset-x-0 -top-7">
              <div className="flex items-center justify-center bg-te-orange-400 bg-opacity-20 h-14 w-14 rounded-full">
                <div className="h-10 w-10 bg-te-orange-400 rounded-full flex items-center justify-center text-white font-semibold mx-auto text-2xl border-4 border-te-orange-400 border-opacity-20">
                  3
                </div>
              </div>
            </div>

            <div className="">
              <img
                src={content?.ImagenPaso3?.data?.attributes?.url}
                alt=""
                className="h-20 mx-auto"
              />
            </div>

            <p className="text-lg text-te-blue-800 font-bold text-center mt-4">
              {content?.TituloPaso3}
            </p>

            <p className=" text-center text-te-gray-800 text-sm mt-3">
              {content?.DescripcionPaso3}
            </p>
          </div>
        </div>

        <div data-aos="fade-up" className="flex justify-center mt-10">
          <ButtonBasic
            onClick={() => history.push("/register")}
            classNameFather="bg-te-blue-800"
            className="bg-te-blue-900 text-white"
          >
            Obtener casillero gratis
          </ButtonBasic>
        </div>
      </div>
    </section>
  );
}
