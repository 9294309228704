import React, { useEffect, useState } from "react";
import Newsletter from "../../components/general/Newsletter";
import Banners from "../../components/home/Banners";
import Benefits from "../../components/home/Benefits";
import HowDoesItWork from "../../components/home/HowDoesItWork";
import Services from "../../components/home/Services";
import Stores from "../../components/home/Stores";
import SubBanners from "../../components/home/SubBanners";
import SiteLayout from "../../layouts/site";
import axios from "axios";
import Seo from "../../components/utils/Seo";
import MainPopUp from "../../components/home/MainPopUp";
import {  useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";

export default function Home() {
  const [content, setContent] = useState({});
  const [contentPopUp, setContentPopUp] = useState({});
  const { signout } = useAuth();
  
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/inicio?populate=*`)
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });

    axios
      .get(`${process.env.REACT_APP_CMS_URL}/api/pop-op-principal?populate=*`)
      .then((response) => {
        setContentPopUp(response.data?.data?.attributes);
      });

      if(query.get("logout")){
        signout();
        window.close();
      }

  }, []);

  return (
    <main>
      <Seo data={content?.Seo} />

      <MainPopUp contentPopUp={contentPopUp} />

      <SiteLayout>
        <Banners dataCMS={content} />

        <HowDoesItWork />

        <Stores dataCMS={content} />

        <Benefits dataCMS={content} />

        <SubBanners dataCMS={content} />

        <Services />

        <Newsletter />
      </SiteLayout>
    </main>
  );
}
