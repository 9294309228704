import AWS from "aws-sdk";

/**
 * Digital Ocean Spaces Connection
 */

const spacesEndpoint = new AWS.Endpoint("nyc3.digitaloceanspaces.com");

const s3 = new AWS.S3({
  endpoint: spacesEndpoint,
  accessKeyId: "XJZF4Y4KDIA2NHEKMEV6",
  secretAccessKey: "AT7d80By1mefxtqlkplryKXdO8sdbs3HCm7ySFzOrtA",
});

export default s3;

export const config = {
  digitalOceanSpaces: "https://te-prod-space.nyc3.digitaloceanspaces.com/",
  bucketName: "te-prod-space",
};
