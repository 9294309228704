import React from "react";
import brandWhite from "../../assets/images/brand-white.svg";
import { ReactComponent as Facebook } from "../../assets/images/icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets/images/icons/instagram.svg";
import { ReactComponent as Whatsapp } from "../../assets/images/icons/whatsapp.svg";
import { ReactComponent as Appstore } from "../../assets/images/appstore.svg";
import { ReactComponent as Googleplay } from "../../assets/images/googleplay.svg";
import { useNav } from "../../contexts/Navigation";
import CustomLink from "../utils/CustomLink";
import { useAuth } from "../../contexts/auth";
import serfinsa from "../../assets/images/logo-serfinsa.png";
import cloudflareLogo from "../../assets/images/logo-cloudflare.png";
import garantia from "../../assets/images/sello-garantia.png";
import "./assets/footer.css";
import pdf from "./assets/Carta-informativa-Transexpress.pdf";

export default function Footer() {
  const { user } = useAuth();

  const { footer } = useNav();

  //get the current year
  const year = new Date().getFullYear();

  const disableContextMenu = (event) => {
    alert("Imagen protegida");
    event.preventDefault();
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = pdf; // Ruta relativa al archivo PDF
    downloadLink.download = "TransExpress de El Salvador.pdf"; // Nombre del archivo a descargar
    downloadLink.click();

    window.location.href =
      "https://www.defensoria.gob.sv/certificados/TransExpressdeElSalvador/TransExpressdeElSalvador.pdf";
  };

  return (
    <footer className="bg-te-blue-900 overflow-hidden">
      <div className="container pt-10 lg:py-14">
        <div data-aos="fade-up" className="">
          <img src={brandWhite} alt="" className="h-8 lg:h-10" />
        </div>

        <div className="flex flex-wrap text-white mt-10">
          <div data-aos="fade-up" className="w-1/2 lg:w-1/5">
            <h5 className="font-bold">{footer?.TituloColumna1}</h5>
            <ul className=" text-sm mt-5 space-y-3">
              {footer?.OpcionesColumna1.map((data, index) => (
                <li key={index}>
                  <CustomLink to={data.Enlace}>{data.Etiqueta}</CustomLink>
                </li>
              ))}
            </ul>
          </div>
          <div data-aos="fade-up" className="w-1/2 lg:w-1/5">
            <h5 className="font-bold">{footer?.TituloColumna2}</h5>
            <ul className=" text-sm mt-5 space-y-3">
              {footer?.OpcionesColumna2.map((data, index) => (
                <li key={index}>
                  <CustomLink to={data.Enlace}>{data.Etiqueta}</CustomLink>
                </li>
              ))}
            </ul>
          </div>
          <div data-aos="fade-up" className="w-1/2 lg:w-1/5 mt-10 lg:mt-0">
            <h5 className="font-bold">{footer?.TituloColumna3}</h5>
            <ul className=" text-sm mt-5 space-y-3">
              {footer?.OpcionesColumna3.map((data, index) => (
                <li key={index}>
                  <CustomLink to={data.Enlace}>{data.Etiqueta}</CustomLink>
                </li>
              ))}
              <li>
                <span
                  onClick={() => {
                    user?.autologinUrl
                      ? window.location.replace(user.autologinUrl)
                      : window.location.replace(
                          process.env.REACT_APP_SHOPPING_MALL_BASE_URL
                        );
                  }}
                  className="cursor-pointer"
                >
                  Shopping Mall
                </span>
              </li>
            </ul>
          </div>
          <div data-aos="fade-up" className="w-1/2 lg:w-1/5 mt-10 lg:mt-0">
            <h5 className="font-bold">{footer?.TituloColumna4}</h5>
            <ul className=" text-sm mt-5 space-y-3">
              {footer?.OpcionesColumna4.map((data, index) => (
                <li key={index}>
                  <CustomLink to={data.Enlace}>{data.Etiqueta}</CustomLink>
                </li>
              ))}
            </ul>
          </div>

          <div className=" w-full lg:w-1/5 border-t-2 border-te-blue-800 lg:border-none mt-6 lg:mt-0 pt-6 lg:pt-0 flex lg:block">
            <div
              data-aos="fade-up"
              className="w-1/2 lg:w-auto order-2 lg:order-1"
            >
              <h5 className="font-bold">Nuestras Redes</h5>
              <div className="space-x-5 flex mt-3">
                <CustomLink to="https://www.facebook.com/TransExpressSV">
                  <Facebook className="fill-current" />
                </CustomLink>
                <CustomLink to="https://www.instagram.com/transexpresssv">
                  <Instagram className="fill-current" />
                </CustomLink>
                <CustomLink to="https://api.whatsapp.com/message/RJPQ4WSV4XHHN1">
                  <Whatsapp className="fill-current" />
                </CustomLink>
              </div>
            </div>

            <div
              data-aos="fade-up"
              className="w-1/2 lg:w-auto order-1 lg:order-2"
            >
              <h5 className="font-bold lg:mt-10">¡Descarga la App!</h5>
              <div className="flex flex-wrap lg:flex-nowrap lg:space-x-1 mt-3">
                <CustomLink to="https://apps.apple.com/app/id1479901573">
                  <Appstore className="h-12" />
                </CustomLink>
                <CustomLink to="https://play.google.com/store/apps/details?id=com.mobilesv.trans_express">
                  <Googleplay className="h-12" />
                </CustomLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-offset="-100"
        className="lg:border-t border-te-blue-800"
      >
        <div className="container py-5 flex flex-col lg:flex-row justify-between text-te-gray-800 text-sm">
          <div className="flex flex-col">
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              TransExpress de El Salvador S.A.de C.V.
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              NIT: 0614-070285-001-0
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              Dirección:{" "}
              <CustomLink to="https://www.google.com/maps/place/TransExpress+El+Salvador/@13.6974973,-89.2392713,17z/data=!3m1!4b1!4m6!3m5!1s0x8f6330248a4f2559:0x907e71c095c00bf1!8m2!3d13.6974973!4d-89.2392713!16s%2Fg%2F11rrzg7kx">
                Calle y Colonia La Mascota #521-A San Salvador, El Salvador.
              </CustomLink>
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              Tel: 2209-1500
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              Correo electrónico: clientes@transexpress.com.sv
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              <CustomLink
                to="/terminos-y-condiciones"
                className="text-white lg:text-te-gray-800"
              >
                Términos y Condiciones
              </CustomLink>
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              <CustomLink
                to="/privacidad"
                className="text-white lg:text-te-gray-800"
              >
                Avisos de Privacidad
              </CustomLink>
            </p>
            <p className="order-2 lg:order-1 mt-5 lg:mt-0 text-center lg:text-left lg:inline pb-4 lg:py-0">
              © TransExpress El Salvador {year}.{" "}
              <span className="hidden lg:inline">All Rights Reserved.</span>{" "}
            </p>
            {/* <a
              to="https://12aeme.studio/"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-4 lg:inline text-center py-2 lg:py-0 block"
            >
              ®2022 - by 12aeme Studio™
            </a> */}
          </div>

          <div className="space-x-5 order-1 lg:order-2 flex lg:block w-full lg:w-auto justify-center relative flex-col items-center">
            <div className="flex flex-col">
              <div className="flex flex-col custom-width-nivekino">
                <img src={serfinsa} alt="" className="img-width-2" />
                <img src={cloudflareLogo} alt="" className="img-width" />
              </div>
            </div>

            <div
              className="sello-container"
              onContextMenu={disableContextMenu}
              onClick={handleDownload}
            >
              <img
                src={garantia}
                alt="Imagen protegida"
                className="img-sello"
              />
              <h5 className="font-bold text-center text-white">
                Sello de Garantía
              </h5>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
