import React from "react";
import details from "../../../assets/images/locker/details.svg";
import box from "../../../assets/images/icons/locker-box.svg";
import Spinner from "../../utils/Spinner";
import { Link } from "react-router-dom";
import { getStatus, getStatusColor } from "./PackagesDelivered";
import ButtonPay from "./ButtonPay";
import ButtonSendInvoive from "./ButtonSendInvoive";
export default function PackagesDelivered({ isLoading, packages, error }) {
  const getPendingAction = (data) => {
    switch (data.UtlimoEstatus) {
      case "Recibido en San Salvador":
        return <ButtonPay data={data} />;
      case "Falta la factura":
        return <ButtonSendInvoive data={data} />;
      case "Falta Factura":
        return <ButtonSendInvoive data={data} />;
      default:
        return <div className="text-te-gray-800 font-semibold">{data.UtlimoEstatus}</div>;
    }
  };

  return (
    <div className="mt-5 border-2 border-te-gray-700 rounded-xl mb-20">
      {isLoading && (
        <div className="py-10 flex justify-center">
          {" "}
          <Spinner color="#141c82" type="spinningBubbles" />{" "}
        </div>
      )}

      {!isLoading && error && (
        <span className="block text-center py-10">{error}</span>
      )}

      {!isLoading && !!packages.length && (
        <div className="w-full">
          <div className="flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold">
            <div className="flex-1 text-center">Guía</div>
            <div className="flex-1 text-center">Tracking</div>
            {/* <div className="flex-1 text-center">recibido en miami</div> */}
            <div className="flex-1 text-center">acción pendiente</div>
            <div className="w-32 text-center">Detalle</div>
          </div>

          <div className="w-full space-y-4 p-3">
            {packages.length &&
              packages.map((value, index) => (
                <div
                  key={index}
                  className="flex bg-te-gray-700 bg-opacity-50 rounded-lg"
                >
                  <div className="flex-1 py-3 flex">
                    <div className="ml-5">
                      <img src={box} alt="" className="h-7" />
                    </div>
                    <div className="ml-3">
                      <span className="block text-sm font-semibold text-te-blue-800">
                        {value.Guia}
                      </span>
                      <span
                        className={`mt-1 inline-block ${getStatusColor(
                          value.UtlimoEstatus
                        )} rounded-r-full rounded-bl-full px-3 py-1 text-xs font-semibold`}
                      >
                        {getStatus(value.UtlimoEstatus)}
                      </span>
                    </div>
                  </div>
                  <div className="flex-1 flex items-center justify-center text-sm">
                    <div className="rounded-lg px-3 py-2 bg-gray-200 text-te-gray-800 font-semibold w-48 text-center max-w-sm truncate">
                      {value.NumeroTracking}
                    </div>
                  </div>
                  {/* <div className="flex-1 flex items-center justify-center text-sm">
                    <div className="text-te-gray-800 font-semibold">
                      {value.FechaDeIngreso}
                    </div>
                  </div> */}
                  <div className="flex-1 flex items-center justify-center text-sm">
                    {getPendingAction(value)}
                  </div>
                  <div className="w-32 flex items-center">
                    <div className="mx-auto">
                      <Link to={`/packages/${value.Guia}`}>
                        <img
                          src={details}
                          alt=""
                          className="h-8 cursor-pointer"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
