import axios from "axios";
import React, { useEffect, useState } from "react";
import RequestQuote from "../../components/general/RequestQuote";
import Benefits from "../../components/importCargo/Benefits";
import CargoTypes from "../../components/importCargo/CargoTypes";
import Insurances from "../../components/importCargo/Insurances";
import MainImportCargo from "../../components/importCargo/MainImportCargo";
import ServicesAndTypes from "../../components/importCargo/ServicesAndTypes";
import Seo from "../../components/utils/Seo";
import SiteLayout from "../../layouts/site";

export default function ImportCargo() {
  const [content, setContent] = useState({});

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/api/servicio-carga?populate[0]=Seo.ShareImage&populate[1]=ImagenPrincipal&populate[2]=Beneficios.Imagen&populate[3]=CargaRegular&populate[4]=CargaExpresa&populate[5]=CargaMaritima&populate[6]=Seguros`
      )
      .then((response) => {
        setContent(response.data?.data?.attributes);
      });
  }, []);

  return (
    <main>
      <Seo data={content?.Seo} />

      <SiteLayout>
        <MainImportCargo dataCMS={content} />

        <Benefits dataCMS={content} />

        <ServicesAndTypes dataCMS={content} />

        <CargoTypes dataCMS={content} />

        <Insurances dataCMS={content} />

        <RequestQuote dataCMS={content} />
      </SiteLayout>
    </main>
  );
}
