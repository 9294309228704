import React from "react";

export default function WhatIs({ dataCMS }) {
  return (
    <section className="py-10 lg:py-16 bg-te-gray-700">
      <div className="container flex flex-col lg:flex-row pb-10">
        <div className="lg:w-1/2">
          <h3
            data-aos="fade-up"
            className="font-semibold text-te-blue-800 text-2xl lg:text-3xl"
          >
            {dataCMS?.TituloNota1}
          </h3>
          <p
            data-aos="fade-up"
            className=" text-te-gray-800 max-w-xl mt-5 lg:mt-10 whitespace-pre-line text-justify"
          >
            {dataCMS?.DescripcionNota1}
          </p>
        </div>
        <div className="lg:w-1/2 mt-5 lg:mt-0">
          <h3
            data-aos="fade-up"
            className="font-semibold text-te-blue-800 text-2xl lg:text-3xl"
          >
            {dataCMS?.TituloNota2}
          </h3>

          <p
            data-aos="fade-up"
            className=" text-te-gray-800 max-w-xl mt-5 lg:mt-10 whitespace-pre-line text-justify"
          >
            {dataCMS?.DescripcionNota2}
          </p>
        </div>
      </div>
    </section>
  );
}
