import React, { useState, useContext, createContext } from "react";

const CalculatorContext = createContext();

export function ProviderCalculator({ children }) {
  const state = useProvideCalculator();
  return (
    <CalculatorContext.Provider value={state}>
      {children}
    </CalculatorContext.Provider>
  );
}

export const useCalculator = () => {
  return useContext(CalculatorContext);
};

function useProvideCalculator() {
  const [values, setValues] = useState({
    isActive: false,
    value: "",
    weight: "",
    category: "",
  });

  return {
    values,
    setValues,
  };
}
