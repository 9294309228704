// import { Disclosure, Transition } from "@headlessui/react";
// import { ChevronUpIcon } from "@heroicons/react/outline";
import React from "react";

export default function PointsDetailsMobile() {
  return (
    <div>
      {/* <div className="mt-5 border-2 border-te-gray-700 rounded-xl overflow-hidden p-4">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex items-center justify-between w-full text-left focus:outline-none font-semibold text-te-blue-800`}
              >
                <span>DETALLE DE PUNTOS</span>
                <ChevronUpIcon
                  className={`${
                    !open ? "transform rotate-180" : ""
                  } w-5 h-5 transition text-te-blue-800`}
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel className="pb-2 mt-5">
                  <p className=" text-te-blue-800 font-semibold mt-5 underline text-center">
                    Ver todos los detalles
                  </p>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </div> */}
    </div>
  );
}
