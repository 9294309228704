import React, { useState } from "react";
import { useAuth } from "../../../contexts/auth";
import blank from "../../../assets/images/blank.png";
import visa from "../../../assets/images/locker/visa.svg";
import mc from "../../../assets/images/locker/mc.svg";
import unionPay from "../../../assets/images/locker/UNIONPAY.png";
import card from "../../../assets/images/locker/card.svg";
import { LockClosedIcon } from "@heroicons/react/outline";
import FormNewCard from "./FormNewCard";
import ButtonDeleteCard from "./ButtonDeleteCard";

export default function PaymentMethod({ cards, setCards, error }) {
  const [isAddingNewCard, setIsAddingNewCard] = useState(false);
  const { user } = useAuth();

  const getCardImage = (type) => {
    if (type === 1) {
      return <img src={visa} alt="" className="h-4" />;
    } else if (type === 2) {
      return <img src={mc} alt="" className="h-8" />;
    } else if (type === 4) {
      return <img src={unionPay} alt="" className="h-8" />;
    } else {
      return <img src={card} alt="" className="h-8" />;
    }
  };

  return (
    <>
      <div className="mt-5 border-2 border-te-gray-700 rounded-xl p-6 ">
        <div className="flex items-center pb-8 pt-4 border-b-2">
          <div className="rounded-full overflow-hidden w-16 h-16 mx-3">
            <img src={blank} alt="" className="h-full w-full object-cover" />
          </div>
          <div className="flex flex-col items-start font-semibold mr-3 text-xl">
            <span className=" text-te-blue-800">
              {user?.Name || user?.Company}
            </span>
            <span className=" text-te-orange-400">{user?.PoBox}</span>
          </div>
        </div>
        {!error && cards.length ? (
          <div className="mt-8 space-y-5">
            {cards.map((value, index) => (
              <div
                key={index}
                className=" bg-te-green-100 bg-opacity-30 p-6 rounded-xl flex justify-between"
              >
                <div className="flex items-center">
                  <div className="flex flex-col items-start font-semibold mr-5 text-xl">
                    {/* <span className=" text-te-blue-800">{value.Name || user?.Company}</span> */}
                    <span className=" text-te-blue-800">
                      {value.numero_tarjeta}
                    </span>
                  </div>
                  <div>{getCardImage(value.id_tipo_tarjeta)}</div>
                </div>
                <ButtonDeleteCard value={value} setCards={setCards} />
              </div>
            ))}
          </div>
        ) : (
          <div className="py-10 text-te-blue-800 font-semibold text-lg text-center">
            No tienes Metodos de pago agregados.
          </div>
        )}
      </div>

      {!isAddingNewCard ? (
        <div
          onClick={() => setIsAddingNewCard(true)}
          className="h-32 rounded-xl mt-6 shadow-md border-2 border-te-gray-700 text-te-blue-800 text-xl flex items-center font-semibold p-6 cursor-pointer hover:text-white hover:bg-te-blue-800 transition"
        >
          Agregar método de pago
        </div>
      ) : (
        <FormNewCard
          setCards={setCards}
          setIsAddingNewCard={setIsAddingNewCard}
        />
      )}

      <div className="mt-6 mb-20 bg-te-blue-100 text-te-blue-800 flex items-center rounded-xl p-4 font-semibold">
        <LockClosedIcon className=" text-te-blue-800 h-6 mr-3" />{" "}
        <p>
          Todos tus pagos estan asegurados con nuestro sistema de tokenización.{" "}
          <a
            href="https://www.paycomet.com/news/glosario/que-es-tokenizacion/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:underline"
          >
            Leer más.
          </a>
        </p>
      </div>
    </>
  );
}
