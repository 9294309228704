import React from "react";

export default function Information({ dataCMS }) {
  return (
    <section className=" lg:bg-te-gray-700 lg:py-16 ">
      <div className="container flex flex-col lg:flex-row lg:px-20">
        <div className="lg:w-1/2 flex justify-center items-center">
          <div className="">
            <h3
              data-aos="fade-up"
              className=" text-2xl lg:text-3xl text-te-blue-800 font-semibold"
            >
              {dataCMS?.TituloInformacion}
            </h3>
            <p
              data-aos="fade-up"
              className=" text-te-gray-800 mt-5 lg:mt-10 max-w-md whitespace-pre-line text-justify"
            >
              {dataCMS?.DescripcionInformacion}
            </p>
          </div>
        </div>
        <div
          data-aos="fade-up"
          className="lg:w-1/2 flex justify-center items-center mt-10 lg:mt-0"
        >
          <img src={dataCMS?.ImagenInformacion?.data?.attributes?.url} alt="" className="w-96" />
        </div>
      </div>
    </section>
  );
}
