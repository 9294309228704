import React, { useState } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../contexts/auth";
import ButtonBasic from "../buttons/Basic";
import TrackingMobile from "./TrackingMobile";

export default function MobileButtons() {
  const history = useHistory();
  const [showTracking, setShowTracking] = useState(false);

  const { user } = useAuth();

  return (
    <>
      {user || showTracking ? (
        <TrackingMobile />
      ) : (
        <div className="bg-te-gray-700 py-5 px-5 lg:hidden flex items-center justify-center space-x-5">
          <ButtonBasic
            classNameFather="bg-te-blue-900 w-full"
            className=" bg-te-blue-800 text-white"
            onClick={() => {
              setShowTracking(true);
            }}
          >
            Tracking
          </ButtonBasic>
          <ButtonBasic
            onClick={() => history.push("/register")}
            classNameFather="bg-te-orange-600 w-full"
            className="bg-gradient-to-r from-te-orange-400 to-te-orange-600 text-white"
          >
            Regístrate
          </ButtonBasic>
        </div>
      )}
    </>
  );
}
