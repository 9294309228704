import React from "react";
import { useAuth } from "../../../contexts/auth";
import blank from "../../../assets/images/blank.png";

export default function PersonalInfoMobile() {
  const { user } = useAuth();

  return (
    <div className="pb-5">
      <h2 className=" text-te-blue-800 text-xl font-semibold mt-5">
        Información personal
      </h2>
      <div className="flex items-center mt-5">
        <div className="rounded-full overflow-hidden w-12 h-12 mr-3 flex-shrink-0">
          <img src={blank} alt="" className="h-full w-full object-cover" />
        </div>
        <div className="flex flex-col items-start font-semibold mr-3 text-xl pr-5 ">
          <span className=" text-te-gray-800 truncate w-64 md:w-auto">
            {user?.Name || user?.Company}
          </span>
          <span className=" text-te-orange-400">{user?.PoBox}</span>
        </div>
      </div>

      <div className="mt-5">
        <div className="">
          <label
            htmlFor="email"
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Nombre
          </label>
          <span
            type="text"
            id=""
            className="block rounded-lg bg-te-gray-700 mt-1 w-full truncate focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {user?.Name || user?.Company}
          </span>
        </div>
        <div className="mt-3">
          <label
            htmlFor=""
            className=" font-semibold text-te-gray-800 text-sm"
          >
            DUI
          </label>
          <span
            type="text"
            id=""
            className="block rounded-lg bg-te-gray-700 mt-1 w-full truncate focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {typeof user?.dui === "string" ? user?.dui : "-"}
          </span>
        </div>
        <div className="mt-3">
          <label
            htmlFor=""
            className=" font-semibold text-te-gray-800 text-sm"
          >
            NIT
          </label>
          <span
            type="text"
            id=""
            className="block rounded-lg bg-te-gray-700 mt-1 w-full truncate focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {typeof user?.nit === "string" ? user?.nit : "-"}
          </span>
        </div>
        <div className="mt-3">
          <label
            htmlFor=""
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Correo electrónico
          </label>
          <span
            type="text"
            id=""
            className="block rounded-lg bg-te-gray-700 mt-1 w-full truncate focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {user?.Correo || "-"}
          </span>
        </div>
        <div className="mt-3">
          <label
            htmlFor=""
            className=" font-semibold text-te-gray-800 text-sm"
          >
            Teléfono
          </label>
          <span
            type="text"
            id=""
            className="block rounded-lg bg-te-gray-700 mt-1 w-full truncate focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          >
            {user?.Telefono || "-"}
          </span>
        </div>
      </div>
    </div>
  );
}
