import React, { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import Spinner from "../../utils/Spinner";
import { useAuth } from "../../../contexts/auth";
import SelectCities from "../../register/SelectCities";
import cities from "../../register/cities.json";

export default function EditPersonalInfoForm({ setShowForm }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { user, setUser } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    const departamento = JSON.stringify(
      cities.find((element) => element.id === user.deptoresidencia)
    );

    reset({
      dui: user.dui,
      nit: user.nit,
      telefono: user.telefono,
      dir1: user.Direccion,
      dir2: user.Direccion2,
      departamento: departamento,
    });

    setTimeout(() => {
      setValue("municipio", user.ciudad);
    }, 200);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data) => {
    setIsLoading(true);
    setError("");

    const depa = JSON.parse(data.departamento);

    const body = {
      ref: user.idcou,
      codigo: user.codigo,
      dui: data.dui,
      nit: data.nit,
      telefono: data.telefono,
      dir1: data.dir1,
      dir2: data.dir2,
      ciudad: data.municipio,
      depto: depa.id,
    };

    axios
      .put(`${process.env.REACT_APP_BASE_URL}/users/${user.PoBox}`, body, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        if (res.status === 200 && res.data.data === "1") {
          const newUser = { ...user };
          newUser.dui = data.dui;
          newUser.nit = data.nit;
          newUser.telefono = data.telefono;
          newUser.Direccion = data.dir1;
          newUser.Direccion2 = data.dir2;
          newUser.ciudad = data.municipio;
          newUser.deptoresidencia = depa.id;
          newUser.Departamento = depa.nombre;

          localStorage.setItem("te-user", JSON.stringify(newUser));
          setUser(newUser);
          setShowForm(false);
        } else {
          setError("No se pudo actualizar el domicilio.");
        }
      })
      .catch(() => {
        setError("No se pudo actualizar el domicilio.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
      <div className="md:flex md:space-x-3 mt-3">
        <div className="md:w-1/2">
          <label
            htmlFor="dui"
            className="block font-semibold text-te-gray-800 text-sm"
          >
            DUI (Sin guiones)
          </label>
          <input
            id="text"
            {...register("dui", { required: true, disabled: true })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          />
          {errors.dui && (
            <span className=" text-te-red-900 text-xs mt-2">
              *Este campo es requerido.
            </span>
          )}
        </div>

        <div className="md:w-1/2 mt-3 md:mt-0">
          <label
            htmlFor="nit"
            className="block font-semibold text-te-gray-800 text-sm"
          >
            NIT (Sin guiones)
          </label>
          <input
            id="text"
            {...register("nit", { required: true, disabled: true })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          />
          {errors.nit && (
            <span className=" text-te-red-900 text-xs mt-2">
              *Este campo es requerido.
            </span>
          )}
        </div>
      </div>
      <div className="md:flex md:space-x-3 mt-3">
        <div className="md:w-1/2">
          <label
            htmlFor="telefono"
            className="block font-semibold text-te-gray-800 text-sm"
          >
            Teléfono
          </label>
          <input
            id="text"
            {...register("telefono", { required: true })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 lowercase"
          />
          {errors.telefono && (
            <span className=" text-te-red-900 text-xs mt-2">
              *Este campo es requerido.
            </span>
          )}
        </div>
      </div>
      <p className=" text-te-gray-800 font-semibold mt-5">
        Dirección de Residencia
      </p>
      <div className="mt-3">
        <div className="">
          <label
            htmlFor="dir1"
            className="block mt-3 font-semibold text-te-gray-800 text-sm"
          >
            Dirección
          </label>
          <input
            type="text"
            id="dir1"
            {...register("dir1", { required: true, maxLength: 50 })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
            maxLength="50"
          />
          {errors.dir1 && (
            <span className=" text-te-red-900 text-xs mt-2">
              *Este campo es requerido.
            </span>
          )}
          <input
            type="text"
            id="dir2"
            {...register("dir2", { maxLength: 50 })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800"
            maxLength="50"
          />
        </div>

        <SelectCities register={register} watch={watch} errors={errors} />
      </div>

      <div className="mt-5 flex justify-end space-x-5">
        <button
          onClick={() => {
            setShowForm(false);
          }}
          type="reset"
          className="text-te-blue-800 border-2 border-te-blue-800 bg-white font-semibold px-10 py-2 rounded-lg flex items-center relative"
        >
          Cancelar
        </button>
        <button
          disabled={isLoading || !isValid}
          className="text-white bg-te-blue-800 font-semibold px-10 py-2 rounded-lg flex items-center relative disabled:bg-te-gray-800"
        >
          Guardar
          {isLoading && (
            <div className="absolute right-2 top-0 h-full flex items-center">
              <Spinner color="#ffffff" type="spinningBubbles" />
            </div>
          )}
        </button>
      </div>
      <div className="flex justify-end">
        {error && (
          <div className="mt-3 text-te-red-900 font-semibold">*{error}</div>
        )}
      </div>
    </form>
  );
}
