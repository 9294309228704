import axios from "axios";
import React, { useState } from "react";
import Spinner from "../../utils/Spinner";
import { useAuth } from "../../../contexts/auth";

export default function ButtonDeleteCard({ value, setCards }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const deleteCard = () => {
    const body = {
      id_usuario: value.id_usuario,
      id_tarjeta: value.id_tarjeta,
    };
    setIsLoading(true);
    axios
      .delete(
        `${process.env.REACT_APP_BASE_URL}/users/cards`,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
          data: body,
        },
      )
      .then((res) => {
        if (res.status === 200 || !res.data.result?.codigo_respuesta === 200) {
          if (res.data.result?.lista_tarjetas) {
            setCards(res.data.result?.lista_tarjetas);
          }
        }
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <button
      onClick={deleteCard}
      disabled={isLoading}
      className="px-10 py-2 rounded-lg bg-white font-semibold text-te-blue-800 relative"
    >
      Eliminar
      {isLoading && (
        <div className="absolute right-2 top-0 h-full flex items-center">
          <Spinner color="#141c82" type="spinningBubbles" />
        </div>
      )}
    </button>
  );
}
