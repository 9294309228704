import { ArrowLeftIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SiteLayout from "../../layouts/site";
import axios from "axios";
import Information from "../../components/packageDetails/Information";
import DeliveryDetails from "../../components/packageDetails/DeliveryDetails";
import ProductsDetails from "../../components/packageDetails/ProductsDetails";
import TrackingMiami from "../../components/packageDetails/TrackingMiami";
import TrackingElSalvador from "../../components/packageDetails/TrackingElSalvador";
import Seo from "../../components/utils/Seo";

export default function PackageDetails() {
  const [packageInfo, setPackageInfo] = useState({});
  const [trakingMiami, setTrakingMiami] = useState([]);
  const [trakingSalvador, setTrakingSalvador] = useState([]);
  const [, setError] = useState("");
  const [, setIsLoading] = useState(false);
  const { IdPackage } = useParams();

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/packages/${IdPackage}/details`)
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setPackageInfo(res.data.data);
        } else {
          setError("No hay información de este paquete.");
        }
      })
      .catch((error) => {
        setError("No hay información de este paquete.");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/packages/${IdPackage}/movements?from=miami`
      )
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setTrakingMiami(res.data.data);
        } else {
          setError("No hay información de este paquete.");
        }
      })
      .catch((error) => {
        setError("No hay información de este paquete.");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/packages/${IdPackage}/movements?from=salvador`
      )
      .then((res) => {
        if (res.data.data && !res.data.error) {
          setTrakingSalvador(res.data.data);
        } else {
          setError("No hay información de este paquete.");
        }
      })
      .catch(() => {
        setError("No hay información de este paquete.");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <main>
      <Seo
        data={{
          keywords: null,
          metaDescription: "Global e-Commerce Logistics",
          metaTitle: "Trans-Express El Salvador",
          preventIndexing: false,
        }}
      />

      <SiteLayout>
        <div className="flex container flex-col lg:flex-row mb-20 mt-5 lg:mt-12">
          <div className="lg:w-1/4">
            <Link
              to="/micasillero/packages"
              className=" flex items-center text-te-blue-800 md:text-lg font-bold underline"
            >
              <ArrowLeftIcon className=" text-te-blue-800 h-5 mr-3" /> Regresar
              a Mis Paquetes
            </Link>
          </div>

          <div className="flex-1 mt-5 lg:mt-0">
            <h1 className=" text-te-blue-800 text-2xl lg:text-3xl font-bold hidden md:block">
              Detalle del paquete
            </h1>

            <Information packageInfo={packageInfo} />

            <DeliveryDetails packageInfo={packageInfo} />

            <ProductsDetails packageInfo={packageInfo} />

            <TrackingMiami trakingMiami={trakingMiami} />

            <TrackingElSalvador trakingSalvador={trakingSalvador} />
          </div>
        </div>
      </SiteLayout>
    </main>
  );
}
