import { ChevronDownIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import cities from "./cities.json";

export default function SelectCitiesAddress({
  register,
  watch,
  errors,
  disabled,
}) {
  const [selected, setSelected] = useState({
    municipios: [],
  });

  useEffect(() => {
    if (watch("departamento")) {
      const cities = JSON.parse(watch("departamento"));

      setSelected(cities);
    }

    // eslint-disable-next-line
  }, [watch("departamento")]);

  return (
    <div className="md:flex md:space-x-3 mt-3">
      <div className="md:w-1/2">
        <label
          htmlFor="deptodomi"
          className="block font-semibold text-te-gray-800 text-sm"
        >
          Departamento
        </label>
        <div className="flex items-center relative">
          <select
            id="deptodomi"
            disabled={disabled}
            {...register("deptodomi", { required: true })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 relative appearance-none"
          >
            <option value="">Seleccionar</option>
            {cities.map((data, index) => (
              <option key={index} value={JSON.stringify(data)}>
                {data.nombre}
              </option>
            ))}
          </select>
          <ChevronDownIcon className="absolute h-4 right-2 mt-2 pointer-events-none" />
        </div>
        {errors.deptodomi && (
          <span className=" text-te-red-900 text-xs mt-2">
            *Este campo es requerido.
          </span>
        )}
      </div>

      <div className="md:w-1/2 mt-3 md:mt-0">
        <label
          htmlFor="ciudaddomi"
          className="block font-semibold text-te-gray-800 text-sm"
        >
          Municipio
        </label>
        <div className="flex items-center relative">
          <select
            id="ciudaddomi"
            disabled={disabled}
            {...register("ciudaddomi", { required: true })}
            className="block rounded-lg bg-te-gray-700 mt-2 w-full focus:outline-none px-3 py-2 font-semibold text-te-blue-800 relative appearance-none"
          >
            <option value="">Seleccionar</option>
            {(selected.municipios || []).map((data, index) => (
              <option key={index} value={data.nombre}>
                {data.nombre}
              </option>
            ))}
          </select>
          <ChevronDownIcon className="absolute h-4 right-2 mt-2 pointer-events-none" />
        </div>
        {errors.ciudaddomi && (
          <span className=" text-te-red-900 text-xs mt-2">
            *Este campo es requerido.
          </span>
        )}
      </div>
    </div>
  );
}
