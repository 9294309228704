import React from "react";

export default function ProductsDetails({ packageInfo }) {
  return (
    <div className=" w-full mt-10 ">
      <p className="font-bold text-xl text-te-blue-800">Detalle del producto</p>

      <div className="md:border-2 border-te-gray-700 rounded-xl mt-5 flex shadow-xl md:shadow-none">
        <div className="w-full">
          <div className="hidden md:flex border-b-2 border-te-gray-700 p-3 uppercase text-te-blue-800 font-bold mt-5">
            <div className="flex-1">cantidad</div>
            <div className="flex-1">contenido</div>
            <div className="flex-1">descripción</div>
          </div>

          <div className="w-full space-y-3 pt-3 px-3">
            {/* {[1, 2, 3, 4].map((value, index) => (
              <React.Fragment key={index}> */}
            <div className="hidden md:flex bg-te-gray-700 bg-opacity-50 rounded-lg py-3">
              <div className="flex-1 flex items-center text-sm">
                <div className=" pl-4 text-te-gray-800 font-semibold">1</div>
              </div>
              <div className="flex-1 flex items-center text-sm">
                <div className="text-te-gray-800 font-semibold pr-6">
                  {typeof packageInfo.Contenido === "string"
                    ? packageInfo.Contenido
                    : "No definido"}
                </div>
              </div>
              <div className="flex-1 flex items-center text-sm">
                <div className="text-te-gray-800 font-semibold pr-6">
                  {packageInfo.Descripcion}
                </div>
              </div>
            </div>

            <div className="md:hidden text-te-gray-800 font-semibold border-b border-te-gray-700 pb-3">
              (1){" "}
              {typeof packageInfo.Contenido === "string"
                ? packageInfo.Contenido
                : "No definido"}{" "}
              - {packageInfo.Descripcion}
            </div>
            {/* </React.Fragment>
            ))} */}
          </div>

          <div className="flex flex-col md:flex-row p-3 md:space-x-3">
            <div className="md:border-2 border-te-blue-800 rounded-lg md:w-1/2 p-3">
              <p className=" uppercase text-te-blue-800 font-bold text-sm">
                peso
              </p>
              <p className=" text-te-gray-800 font-semibold">
                {packageInfo.Peso}
              </p>
            </div>
            <div className="md:border-2 border-te-blue-800 rounded-lg md:w-1/2 p-3">
              <p className=" uppercase text-te-blue-800 font-bold text-sm">
                dimensiones
              </p>
              <p className=" text-te-gray-800 font-semibold">
                -
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
