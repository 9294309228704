import React from "react";
import { BenefitCard } from "../importCargo/Benefits";

export default function Benefits({ dataCMS }) {
  return (
    <section className="bg-white">
      <div className="py-10 lg:py-16">
        <h3
          data-aos="fade-up"
          className="text-te-blue-800 text-3xl font-semibold text-center"
        >
          {dataCMS?.TituloBeneficios}
        </h3>
        <p
          data-aos="fade-up"
          className="text-te-gray-800 lg:text-lg text-center max-w-3xl mx-auto mt-4"
        >
          {dataCMS?.DescripcionBeneficios || ""}
        </p>

        <div className="mt-5 lg:mt-10 container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-20 lg:space-y-0">
          {dataCMS?.Beneficio?.map((benefit) => (
            <BenefitCard data={benefit} key={benefit.id} />
          ))}
        </div>

        {/* <div data-aos="fade-up" className="mt-8 flex justify-center">
          <Link to="/register">
            <ButtonBasic
              classNameFather="bg-te-blue-800"
              className="bg-te-blue-900 text-white"
            >
              Comienza YA a recibir y enviar paquetes
            </ButtonBasic>
          </Link>
        </div> */}
      </div>
    </section>
  );
}
