import React from "react";
import SimpleNavbar from "../../components/simpleNavbar";

export default function SimpleLayout({ children }) {
  return (
    <div className="min-h-screen">
      <SimpleNavbar />

      {children}
    </div>
  );
}
