import axios from "axios";
import { DateTime } from "luxon";

export const sendInvoice = (data, user, imageUrl, fileName, guia = "") => {
  const body = {
    NombreArchivo: fileName, //Toma el nombre del archivo
    Email: data.email, //Ya lo tienes
    Orden: data.tracking || "", //El tracking
    link: imageUrl,
    Remitente: data.Remitente, //Agregar campo de "Remitente" al form
    Fecha: DateTime.now().toFormat("dd/MM/yyyy"), //Fecha en en DD/MM/YYYY
    DescProd: "", //Descripcion del producto
    Consigna: data.Consigna || user?.Name, //El nombre de la persona
    Contenido: data.Contenido, //Contenido
    guia: guia,
    casillero: data.locker || "",
  };

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/packages/files`, body)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
